import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BookingPaymentOverview from "../BookingPaymentOverview";
import StripePaymentMethods from "../../payments/StripePaymentMethods";
import { useHistory, useLocation } from "react-router-dom";
import apiUrl from "../../../data/api-url";
import submitForm from "../../../data/submit-form";
import { useAuth } from "../../../hooks/use-auth";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../hooks/use-store";

export default observer(function BookingPayment() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { userStore } = useStore();
  const history = useHistory();

  const location = useLocation();
  const { reservation, bookingId, paymentRequest } = location.state;

  const search = useLocation().search;
  const paymentRequestId = new URLSearchParams(search).get("payment_request");

  useEffect(() => {
    async function acceptBooking() {
      const putData = {
        accountId: userStore.contextAccount.id,
        initialReceiptQuantity: reservation.receiptHandling.initialReceiptQty,
        estimatedMovements: reservation.receiptHandling.estimatedMovements,
        estimatedThroughput: reservation.receiptHandling.estimatedThroughput,
        termsVersion: 1,
      };

      await submitForm(
        apiUrl("bookings", `/bookings/${bookingId}/accept`),
        putData,
        user.token,
        "PUT"
      );
    }
    acceptBooking();
  });

  const successCallback = () => {
    history.replace(`/booking/confirmation`, { bookingId: bookingId });
  };

  return (
    <div className="container mx-auto mt-7 space-y-8">
      <h1>{t("booking.payment.title")}</h1>

      <div className="grid md:grid-cols-2 md:gap-8 xl:gap-16 space-y-8 md:space-y-0">
        <BookingPaymentOverview
          paymentRequest={paymentRequest}
          reservation={reservation}
        />
        <StripePaymentMethods
          returnUrl={`/booking/confirmation?booking_id=${bookingId}`}
          requestId={paymentRequestId}
          successCallback={successCallback}
        />
      </div>
    </div>
  );
});
