import { createContext, useContext, useEffect, useState } from "react";
import PaginationControls from "./PaginationControls";

const paginationContext = createContext();

export default function PaginationContainer({ items, pageSize, children }) {
  const pagination = usePaginationContainer(items, pageSize);

  return (
    <paginationContext.Provider value={pagination}>
      {children}
      <PaginationControls />
    </paginationContext.Provider>
  );
}

export const usePagination = () => {
  return useContext(paginationContext);
};

function usePaginationContainer(items, pageSize) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pagedItems, setPagedItems] = useState();
  const totalItems = items ? items.length : 0;

  const getPageCount = () => {
    if (!items) return 1;
    return Math.ceil(items?.length / pageSize);
  };

  const prevPage = () => {
    let prevPageVal = currentPage - 1;
    if (prevPageVal < 1) prevPageVal = 1; // Bind to min page = 1

    setCurrentPage(prevPageVal);
    setPagedItems(getPagedItems(items, pageSize, currentPage));
  };

  const nextPage = () => {
    let nextPageVal = currentPage + 1;
    if (nextPageVal > getPageCount()) nextPageVal = getPageCount(); // Bind to max page

    setCurrentPage(nextPageVal);
    setPagedItems(getPagedItems(items, pageSize, currentPage));
  };

  const setPage = (pageNum) => {
    setCurrentPage(pageNum);
    setPagedItems(getPagedItems(items, pageSize, currentPage));
  };

  const getPagedItems = (items, pageSize, currentPage) => {
    if (!items) return [];

    const skip = (currentPage - 1) * pageSize;
    return items.slice(skip, pageSize);
  };

  useEffect(() => {
    setPagedItems(getPagedItems(items, pageSize, currentPage));
  }, [items, pageSize, currentPage]);

  return {
    currentPage,
    pagedItems,
    totalItems,
    getPageCount,
    prevPage,
    nextPage,
    setPage,
    getPagedItems,
  };
}

//export default function PaginationContainer({data, children}) {
//
//  return (
//    <PaginationContext.Provider value={data}>
//      {children}
//      <div className="flex items-center space-x-1">
//        <a
//          href="/"
//          className="flex items-center px-4 py-2 text-gray-500 bg-gray-300 rounded-md"
//        >
//          <svg
//            xmlns="http://www.w3.org/2000/svg"
//            className="w-6 h-6"
//            fill="none"
//            viewBox="0 0 24 24"
//            stroke="currentColor"
//          >
//            <path
//              strokeLinecap="round"
//              strokeLinejoin="round"
//              strokeWidth="2"
//              d="M11 17l-5-5m0 0l5-5m-5 5h12"
//            />
//          </svg>
//        </a>
//
//        <a
//          href="/"
//          className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-blue-400 hover:text-white"
//        >
//          1
//        </a>
//        <a
//          href="/"
//          className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-blue-400 hover:text-white"
//        >
//          2
//        </a>
//        <a
//          href="/"
//          className="px-4 py-2 text-gray-700 bg-gray-200 rounded-md hover:bg-blue-400 hover:text-white"
//        >
//          3
//        </a>
//
//        <a
//          href="/"
//          className="px-4 py-2 text-gray-500 bg-gray-300 rounded-md hover:bg-blue-400 hover:text-white"
//        >
//          <svg
//            xmlns="http://www.w3.org/2000/svg"
//            className="w-6 h-6"
//            fill="none"
//            viewBox="0 0 24 24"
//            stroke="currentColor"
//          >
//            <path
//              strokeLinecap="round"
//              strokeLinejoin="round"
//              strokeWidth="2"
//              d="M13 7l5 5m0 0l-5 5m5-5H6"
//            />
//          </svg>
//        </a>
//      </div>
//    </PaginationContext.Provider>
//  )
//
//}
