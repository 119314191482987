import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/date-transformations";
import PriceDisplay from "../common/PriceDisplay";

export default function BookingPaymentOverview({
  paymentRequest,
  reservation,
}) {
  const { t } = useTranslation();

  const paymentFees =
    paymentRequest.priceDetails.paymentTransferFee +
    paymentRequest.priceDetails.cardProcessingFee;

  return (
    <div>
      <h2>{t("booking.payment.bookingOverview")}</h2>
      <div className="space-y-3 mt-4">
        <div className="flex justify-between border-b border-b-gray-300 pb-1">
          <p className="text-gray-500">{t("booking.payment.bookingDates")}</p>
          <p className="font-bold">
            {formatDate(reservation.startDate)} -{" "}
            {formatDate(reservation.endDate)}
          </p>
        </div>
        <div className="flex justify-between border-b border-b-gray-300 pb-1">
          <p className="text-gray-500">{t("booking.payment.bookedQuantity")}</p>
          <p className="font-bold">{reservation.totalQuantity} pallets</p>
        </div>
        <div className="flex justify-between border-b border-b-gray-300 pb-1">
          <p className="text-gray-500">{t("booking.payment.storekatFees")}</p>
          <PriceDisplay
            price={paymentRequest.priceDetails.serviceFee}
            className="font-bold"
          />
        </div>
        <div className="flex justify-between border-b border-b-gray-300 pb-1">
          <p className="text-gray-500">{t("booking.payment.taxAmount")}</p>
          <PriceDisplay
            price={paymentRequest.priceDetails.taxAmount}
            className="font-bold"
          />
        </div>
        <div className="flex justify-between border-b border-b-gray-300 pb-1">
          <p className="text-gray-500">{t("booking.payment.paymentFees")}</p>
          <PriceDisplay price={paymentFees} className="font-bold" />
        </div>
        <div className="flex justify-between border-b border-b-gray-300 pb-1">
          <p className="text-gray-500">
            {t("booking.payment.totalBookingPrice")}
          </p>
          <PriceDisplay
            price={paymentRequest.priceDetails.totalChargeAmount}
            className="font-bold"
          />
        </div>
      </div>
    </div>
  );
}
