import { Address } from '../app/model/address';

export default function formatAddress(address:Address) {
  if (!address) {
    return "";
  }

  if (address.addressLine1 === null || address.addressLine1 === undefined || address.addressLine1.trim() === '') {
    return `${address.city} ${address.postcode}, ${address.country}`;
  }

  return `${address.addressLine1}, ${address.city} ${address.postcode}, ${address.country}`;
}
