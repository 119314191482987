import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

export default function OrganisationNavigation() {
  const { t } = useTranslation();
  const { organisationId } = useParams<{organisationId: string}>();

  const showUsersPage = true;

  return (
    <div className="md:col-span-4 lg:col-span-3">
      <ul>
        <li className="py-2 border-b-2 border-grey-500">
          <Link to={`/account/organisations/${organisationId}`}>
            {t("accountOrganisations.subNavigation.overview")}
          </Link>
        </li>
        <li className="py-2 border-b-2 border-grey-500">
          <Link to={`/account/organisations/${organisationId}/plan`}>
            {t("accountOrganisations.subNavigation.planDetails")}
          </Link>
        </li>
        <li className="py-2 border-b-2 border-grey-500">
          <Link to={`/account/organisations/${organisationId}/invoices`}>
            {t("accountOrganisations.subNavigation.invoices")}
          </Link>
        </li>
        <li className="py-2 border-b-2 border-grey-500">
          <Link to={`/account/organisations/${organisationId}/payment-history`}>
            {t("accountOrganisations.subNavigation.paymentHistory")}
          </Link>
        </li>
        <li className="py-2 border-b-2 border-grey-500">
          <Link to={`/account/organisations/${organisationId}/payment-methods`}>
            {t("accountOrganisations.subNavigation.paymentMethods")}
          </Link>
        </li>
        <li className="py-2 border-b-2 border-grey-500">
          <Link to={`/account/organisations/${organisationId}/bank-accounts`}>
            {t("accountOrganisations.subNavigation.bankAccounts")}
          </Link>
        </li>
        {showUsersPage && (
          <li className="py-2 border-b-2 border-grey-500">
            <Link to={`/account/organisations/${organisationId}/users`}>
              {t("accountOrganisations.subNavigation.users")}
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
}
