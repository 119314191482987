import React, { useState } from "react";
import ResultsSelect from "./ResultsSelect";
import FieldLoadingSpinner from "../forms/FieldLoadingSpinner";
import addressSearch, { formatAddress } from "./address-search";
import { useTranslation } from 'react-i18next';
import { Field, FieldErrorMessage, TextInput } from '../forms';
import { Address } from '../../../app/model/address';

interface AddressLookupProps {
  country?: string;
  label: string;
  defaultAddress?: Address;
  onSelected?: (address?:Address) => void;
  requiredMsg?: string;
  formMethods?: any
}

export default function AddressLookup(props:AddressLookupProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [hasValue, setHasValue] = useState(false);
  const [timeoutId, setTimeoutId] = useState<number | undefined>();
  const [results, setResults] = useState();

  const {
    register,
    setValue,
    clearErrors,
    formState: { errors },
  } = props.formMethods;

  const setAddressText = (addressText:string) => {
    setValue("addressField", addressText);
    setHasValue(addressText !== null && addressText.length > 0 ? true : false);
    clearErrors('addressField');
  };

  const search = (e:React.ChangeEvent<HTMLInputElement>) => {
    if (typeof timeoutId === "number") {
      window.clearTimeout(timeoutId);
    }

    // If empty value, set address as undefined to clear value	
    if (e.target.value === '' && props.onSelected !== undefined)
    {
      props.onSelected(undefined);
      setHasValue(false);
    }

    if (e.target.value.length > 5) {
      var timeoutIdVal = window.setTimeout(() => {
        const { country = "uk" } = props;
        setLoading(true);

        addressSearch(e.target.value.trim(), country).then(
          (data) => {
            setLoading(false);
            setResults(data.results);
          },
          (error) => {
            console.log(error);
            setLoading(false);
          }
        );
      }, 500);
      setTimeoutId(timeoutIdVal);
    }
  };

  return (
    <div className="address-lookup-wrapper relative">
      <Field label={props.label} htmlFor='addressField'>
        <div className="relative">
          <div className="relative">
            <TextInput
              autoComplete="off"
              name='addressField'
              defaultValue={props.defaultAddress !== undefined ? formatAddress(props.defaultAddress) : ''}
              register={register("addressField", {
                validate: () => {
                  if (props.requiredMsg === undefined)
                    return true;

                  return hasValue === false ? props.requiredMsg : true;
                },
                onChange: (e:React.ChangeEvent<HTMLInputElement>) => search(e),
              })}
              error={errors.addressField}
              placeholder={t("addressLookup.placeholder")}
            />
            {loading && <FieldLoadingSpinner />}
          </div>
          {results && (
            <ResultsSelect
              data={results}
              onSelected={props.onSelected}
              onChange={setAddressText}
            />
          )}
        </div>
        <FieldErrorMessage error={errors?.addressField} />
      </Field>
    </div>
  );
}
