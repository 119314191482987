import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import apiUrl from '../../../../data/api-url';
import submitForm from '../../../../data/submit-form';
import { useAuth } from '../../../../hooks/use-auth';
import getBaseUrl from '../../../../utils/base-url';
import Button from '../../../common/Button';

export default function CompleteOnboarding() {

  const { t } = useTranslation();
  const { user } = useAuth();
  const { organisationId } = useParams<any>();
  const [loadingOnboarding, setLoadingOnboarding] = useState(false);

  const getOnboardingLink = async () => {
    setLoadingOnboarding(true);
    const postData = {
      returnUrl:
        getBaseUrl() +
        `/account/organisations/${organisationId}/onboarding-complete`,
      refreshUrl:
        getBaseUrl() +
        `/account/organisations/${organisationId}/onboarding-refresh`,
    };

    await submitForm(
      apiUrl(
        "payments",
        `/payment-accounts/${organisationId}/onboarding-request`
      ),
      postData,
      user.token
    )
      .then(({ data }) => {
        window.location.replace(data.url);
      })
      .catch((err) => {
        console.log(err);
        setLoadingOnboarding(false);
      });
  };

  return (
    <div className="space-y-8">
      <h2>{t("accountOrganisations.onboarding.title")}</h2>
      <p>{t("accountOrganisations.onboarding.completeOnboardingMsg")}</p>
      <p>
        <Button
          label={t("accountOrganisations.onboarding.completeOnboardingLink")}
          loading={loadingOnboarding}
          onClick={getOnboardingLink}
        />
      </p>
    </div>
  )
}