import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { PaymentAccount } from '../../../../app/model/account';
import apiUrl from "../../../../data/api-url";
import { tokenFetcher } from "../../../../data/fetchers";
import { useAuth } from "../../../../hooks/use-auth";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import BankAccountList from "../bank-accounts/BankAccountList";
import ManageBankAccounts from "../bank-accounts/ManageBankAccounts";
import AddressDisplay from "./AddressDisplay";
import { useStore } from '../../../../hooks/use-store';
import { observer } from 'mobx-react-lite';

export default observer(function ShowOrganisation() {
  const { organisationId } = useParams<{organisationId: string}>();
  const { user } = useAuth();
  const { accountStore } = useStore();
  const { account, loadAccount, loading, updatedAddress, clearUpdatedAddress } = accountStore;

  useEffect(() => {
    loadAccount(organisationId);

    return () => {
      clearUpdatedAddress();
    }
  
  }, [loadAccount, organisationId, clearUpdatedAddress])
  

  const { data: paymentAccount } = useSWR<PaymentAccount>(
    [apiUrl("payments", `/payment-accounts/${organisationId}`), user.token],
    tokenFetcher
  );

  if (!account || loading) return <LoadingBlock />;

  return (
    <div className="space-y-8">
      <AddressDisplay address={updatedAddress ?? account?.streetAddress} />

      {!paymentAccount && <LoadingBlock />}

      {paymentAccount && paymentAccount.confirmed && (
        <>
          <BankAccountList
            bankAccounts={paymentAccount?.externalBankAccounts}
          />
          <ManageBankAccounts />
        </>
      )}
    </div>
  );
});
