import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import apiUrl from "../../../../data/api-url";
import { tokenFetcher } from "../../../../data/fetchers";
import { useAuth } from "../../../../hooks/use-auth";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import PaymentMethodItem from "./PaymentMethodItem";

export default function PaymentMethods() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { organisationId } = useParams();
  const [paymentMethods, setPaymentMethods] = useState();

  const { data } = useSWR(
    [
      apiUrl("payments", `/payment-accounts/${organisationId}/payment-methods`),
      user.token,
    ],
    tokenFetcher
  );

  useEffect(() => {
    setPaymentMethods(data);
  }, [data]);

  return (
    <div className="space-y-6">
      <h2>{t("accountOrganisations.paymentMethods.title")}</h2>

      <p>{t("accountOrganisations.paymentMethods.leadText")}</p>
      <div className="">
        <Link
          to={`/account/organisations/${organisationId}/payment-methods/add`}
          className="btn-brand"
        >
          {t("accountOrganisations.paymentMethods.addPaymentMethod")}
        </Link>
      </div>

      {paymentMethods && (
        <ul className="space-y-6">
          {paymentMethods.length === 0 && (
            <li>
              {t("accountOrganisations.paymentMethods.noPaymentMethods") + " "}
              <Link
                to={`/account/organisations/${organisationId}/payment-methods/add`}
              >
                {t("accountOrganisations.paymentMethods.addPaymentMethod")}
              </Link>
            </li>
          )}
          {paymentMethods.length > 0 &&
            paymentMethods.map((paymentMethod) => (
              <li key={paymentMethod.id} className="border-gray-300 border-t">
                <PaymentMethodItem
                  paymentMethod={paymentMethod}
                  paymentMethods={paymentMethods}
                  setPaymentMethods={setPaymentMethods}
                  hideRemove={paymentMethods.length === 1}
                />
              </li>
            ))}
        </ul>
      )}
      {!paymentMethods && <LoadingBlock />}
    </div>
  );
}
