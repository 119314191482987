import React from "react";
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";

import configureAmplify from "./configureAmplify";
import defaultFetcher from "./data/fetchers";
import { ProvideAuth } from "./hooks/use-auth";
import "./index.css";
import "./i18n";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { LoadingProvider } from "./hooks/use-loading";
import LoadingBar from "./components/common/loading/LoadingBar";
import ScrollToTop from "./components/common/ScrollToTop";
import TagManager from "react-gtm-module";
import { ProvideFeatureFlags } from './hooks/use-feature-flags';

// Initialise Google Analytics
const tagManagerArgs = {
  gtmId: "GTM-N7LLSH",
};

TagManager.initialize(tagManagerArgs);

configureAmplify();

// Require API mocks in development
if (process.env.NODE_ENV === "development") {
  require("./mocks");
}

const container = document.getElementById('root');

// create a root
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  //<React.StrictMode> // TODO: Re-enable after react-router-dom upgrade
    <ProvideFeatureFlags>
      <LoadingProvider>
        <LoadingBar />
        <ProvideAuth>
          <SWRConfig value={{ fetcher: defaultFetcher }}>
            <BrowserRouter>
              <ScrollToTop />
              <App />
            </BrowserRouter>
          </SWRConfig>
        </ProvideAuth>
      </LoadingProvider>
    </ProvideFeatureFlags>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
