import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import StripePaymentMethods from "../../../payments/StripePaymentMethods";
import PlanConfirmation from "../../plans/PlanConfirmation";

export default function Payment() {
  const { t } = useTranslation();
  const history = useHistory();

  const location = useLocation();
  const search = useLocation().search;
  const paymentRequest = new URLSearchParams(search).get("payment_request");

  const { organisationId } = useParams();
  const { plan } = location.state;

  const returnUrl = `/account/organisations/${organisationId}/plan-changed?account_plan_id=${plan.id}`;

  const successCallback = () => {
    history.replace(returnUrl);
  };

  return (
    <div className="space-y-6">
      <h2>{t("auth.payment.title")}</h2>
      <div className="grid lg:grid-cols-2 md:gap-6">
        <PlanConfirmation plan={plan} />
        <StripePaymentMethods
          returnUrl={returnUrl}
          requestId={paymentRequest}
          successCallback={successCallback}
        />
      </div>
    </div>
  );
}
