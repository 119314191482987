import React from 'react'
import { useTranslation } from 'react-i18next';
import { BiImport } from 'react-icons/bi'

export default function ImportComplete() {

  const { t } = useTranslation();

  return (
    <div className="container mx-auto mt-7 space-y-8 text-center">
      <div className="text-center text-[100px] text-lime-500">
        <BiImport className="inline-block" />
      </div>
      <h1>{t("stockManagement.import.confirmation.title")}</h1>
      <p className="text-xl max-w-3xl text-center mx-auto">
       {t('stockManagement.import.confirmation.leadText')}
      </p>
    </div>
  )
}