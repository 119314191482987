import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import useSWR from "swr";

import apiUrl from "../../../../data/api-url";
import submitForm from "../../../../data/submit-form";
import { Form } from "../../../common/forms";
import { useAuth } from "../../../../hooks/use-auth";
import { tokenFetcher } from "../../../../data/fetchers";
import SiteFormFields from "./FormFields";
import { toastError } from "../../../common/messages/toast-messages";
import { useState } from "react";
import Button, { LinkButton } from "../../../common/Button";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../hooks/use-store";

export default observer(function EditSite() {
  const { t } = useTranslation();
  const formMethods = useForm({ mode: "onBlur" });
  const history = useHistory();
  const { user } = useAuth();
  const { userStore } = useStore();
  const { siteId } = useParams();
  const [submitting, setSubmitting] = useState(false);

  const { data: site } = useSWR(
    userStore.contextAccount
      ? [apiUrl("spaces", `/sites/${siteId}`), {}]
      : null,
    tokenFetcher
  );

  const { data: allAmenities } = useSWR(
    [apiUrl("spaces", "/amenities"), {}],
    tokenFetcher
  );

  const submitSite = async (formData) => {
    setSubmitting(true);

    formData.accountId = userStore.contextAccount.id;
    formData.amenities = [];

    formData.amenities = formData.amenities.concat(formData.accessAmenities);
    formData.amenities = formData.amenities.concat(formData.generalAmenities);
    formData.amenities = formData.amenities.concat(formData.securityAmenities);

    const { data, err: remoteErrors } = await submitForm(
      apiUrl("spaces", `/sites/${siteId}`, { longPolling: new Date() }),
      formData,
      user.token, // TODO: Removed unused parameter
      "PUT"
    );

    if (data) {
      toast.success(t("toasts.siteUpdated"));
      history.push(`/account/sites/${siteId}`);
    } else if (remoteErrors) {
      setSubmitting(false);
      toastError(t, remoteErrors.message, remoteErrors.errorCode);
      console.log(remoteErrors);
    }
  };

  return (
    <>
      <div className="space-y-8">
        <h1>{t("accountSites.site.edit")}</h1>

        {(!site || !allAmenities) && <LoadingBlock />}

        {site && (
          <Form onSubmit={formMethods.handleSubmit(submitSite)}>
            <SiteFormFields
              formMethods={formMethods}
              site={site}
              allAmenities={allAmenities}
            />

            <div>
              <Button
                label={t("accountSites.site.update")}
                loading={submitting}
              />
              <LinkButton
                label={t("common.cancel")}
                onClick={() => {
                  history.push(`/account/sites/${siteId}`);
                }}
              />
            </div>
          </Form>
        )}
      </div>
    </>
  );
});
