import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../../../common/Button';
import submitForm from '../../../../data/submit-form';
import apiUrl from '../../../../data/api-url';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../hooks/use-auth';
import { useStore } from '../../../../hooks/use-store';
import { mutate } from 'swr';
import { ServiceRequest } from '../../../../app/model/service-requests';

interface ApproveDeclineServiceRequestProps {
  serviceRequest: ServiceRequest,
  canApprove: boolean,
  canDecline: boolean
}

export default function ApproveDeclineServiceRequest({
  serviceRequest, canApprove, canDecline
}: ApproveDeclineServiceRequestProps) {

  const { t } = useTranslation();
  const { user } = useAuth();
  const { userStore } = useStore();
  const { requestId, bookingId } = useParams<{requestId?: string, bookingId?: string}>();
  const [approvingRequest, setApprovingRequest] = useState(false);
  const [decliningRequest, setDecliningRequest] = useState(false);

  const approveServiceRequest = async () => {
    setApprovingRequest(true);

    await submitForm(
      apiUrl(
        "bookings",
        `/bookings/${bookingId}/service-requests/${requestId}/approve`
      ),
      { accountId: userStore.contextAccount?.id },
      user.token,
      "PUT"
    )
      .then(() => {
        mutate(
          apiUrl(
            "bookings",
            `/bookings/${bookingId}/service-requests/${requestId}`
          )
        );
        serviceRequest!.status = "Approved";
      })
      .finally(() => {
        setApprovingRequest(false);
      });
  };

  const declineServiceRequest = async () => {
    setDecliningRequest(true);

    await submitForm(
      apiUrl(
        "bookings",
        `/bookings/${bookingId}/service-requests/${requestId}/decline`
      ),
      { accountId: userStore.contextAccount?.id },
      user.token,
      "PUT"
    )
      .then(() => {
        mutate(
          apiUrl(
            "bookings",
            `/bookings/${bookingId}/service-requests/${requestId}`
          )
        );
        serviceRequest!.status = "Declined";
      })
      .finally(() => {
        setDecliningRequest(false);
      });
  };
  
  return (
    <div className="space-y-6">
      <h3>{t("accountBookings.serviceRequestDetail.actions.title")}</h3>

      <div className="flex">
        <Button
          type="button"
          onClick={() => approveServiceRequest()}
          label={t("accountBookings.serviceRequestDetail.actions.approve")}
          className="btn-brand mr-6"
          loading={approvingRequest}
          disabled={!canApprove}
        />
        <Button
          type="button"
          onClick={() => declineServiceRequest()}
          label={t("accountBookings.serviceRequestDetail.actions.decline")}
          className="btn-brand mr-6"
          loading={decliningRequest}
          disabled={!canDecline}
        />
      </div>
    </div>
  )
}