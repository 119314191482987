import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAuth } from "../../../hooks/use-auth";
import CompanyLogo from "../CompanyLogo";
import UserMenu from "./UserMenu";

export default function SiteHeader() {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <header className="space-y-4 px-4">
      <div className="flex justify-between items-center text-gray-700">
        <Link to="/" className="p-4">
          <CompanyLogo />
        </Link>

        <ul className="inline-flex space-x-3 sm:space-x-8 font-medium">
          {user ? (
            <li>
              <UserMenu user={user} />
            </li>
          ) : (
            <>
              <li>
                <Link to="/sign-in" className="block py-4 text-gray-700">
                  {t("auth.signIn")}
                </Link>
              </li>
              <li>
                <Link to="/register" className="block py-4 text-gray-700">
                  {t("auth.register")}
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </header>
  );
}
