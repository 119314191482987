import { useTranslation } from "react-i18next";
import "./LoadingBlock.css";

export default function LoadingBlock() {
  const { t } = useTranslation();

  return (
    <div className="z-50 overflow-hidden flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-2 border-t-2 border-gray-200 h-8 w-8 mb-1"></div>
      <h2 className="text-center text-sm font-semibold text-brandSecondary-100">
        {t("common.loading")}
      </h2>
    </div>
  );
}
