import { createContext, useContext, useEffect, useState } from 'react'
import featureFlags from '../feature-flags';

interface FeautreFlagsProviderProps {
  children?: React.ReactNode;
};

interface FeatureFlag {
  name: string,
  description?: string,
  active: boolean | string | undefined
};

interface FeatureFlagContextData {
  flags: FeatureFlag[] | null,
  isActive: (name: string) => boolean,
  getFlag: (name: string) => FeatureFlag | null
}

const defaultContextData: FeatureFlagContextData = {
  flags: [], 
  isActive: (name:string) => {return false},
  getFlag: (name:string) => {return null}
};

export const flagsContext = createContext<FeatureFlagContextData>(defaultContextData);

export const ProvideFeatureFlags = ({ children }:FeautreFlagsProviderProps) => {
  const flags = useProvideFeatureFlags();

  return (
    <flagsContext.Provider value={flags}>
      {children}
    </flagsContext.Provider>
  )
}

export const useFeatureFlags = () => {
  return useContext(flagsContext);
}

export function useProvideFeatureFlags(): FeatureFlagContextData {
  const [flags, setFlags] = useState<FeatureFlag[]>([]);

  const getFlag = (name:string):FeatureFlag | null => {
    if (flags === null || flags === undefined || name === null || name === undefined)
      return null;

    let matchedFlag = null;

    flags.forEach((flag) => {
      if (flag.name.toLowerCase() === name.toLowerCase())
        matchedFlag = flag;
    });

    // If we got to here, no flag names matched, so return false
    return matchedFlag;
  }

  // Checks wether the flag is active based on the name
  const isActive = (name:string):boolean => {

    const flag = getFlag(name);

    if (flag === null)
      return false;

    return getActiveStatus(flag.active);
    
  };

  const getActiveStatus = (active: boolean | string | undefined) => {
    if (active === undefined)
      return false;

    if (typeof active === 'boolean')
      return active;
  
    return active.toLowerCase() === "true";
  }

  useEffect(() => {
    setFlags(featureFlags);
  }, []);

  return {
    flags,
    isActive,
    getFlag
  };
}