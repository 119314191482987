import { Route, Switch } from "react-router-dom";
import Feature from '../common/feature-flags/Feature';

import NavigationBar from "../common/NavigationBar";
import StockManagementRouter from "./stock-management/StockManagementRouter";
import routes from "./routes";

export default function AccountRouter() {
  return (
    <>
      <div className="border-b">
        <div className="container mx-auto text-gray-700">
          <NavigationBar navItems={routes} />
        </div>
      </div>

      <div className="container mx-auto">
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
          <Feature name='stock-management'>
            <Route
              path='/account/stock-management'
              component={StockManagementRouter}
            />
          </Feature>
        </Switch>
      </div>
    </>
  );
}
