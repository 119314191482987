import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          menu: "Menu",
          common: {
            validation: {
              required: "This is required",
              invalidDate: "Must be a valid date",
              min: "Must be at least {{value}}",
              max: "Must be no greater than {{value}}",
              invalidRecaptcha: "Please confirm you are a real person",
            },
            cancel: "Cancel",
            update: "Update",
            save: "Save",
            create: "Create",
            next: "Next",
            previous: "Previous",
            price: "Price",
            loading: "Loading...",
            address: {
              address: "Address",
              line1: "Line 1",
              line2: "Line 2",
              postcode: "Postcode or Zipcode",
              city: "City",
              state: "State or Province",
              country: "Country",
              selectAddressCountry: "Select country",
            },
          },
          generalActions: {
            add: "Add",
            edit: "Edit",
            remove: "Remove",
            pleaseSelect: "Please select",
            view: "View",
          },
          brand: {
            name: "Storekat",
          },
          addressLookup: {
            manualAddress: "I can't find the address",
            searchAddress: "Search for address",
            placeholder: "Type the address...",
          },
          account: {
            dashboard: "Dashboard",
            selling: "Selling",
            buying: "Buying",
            accountSettings: "Account settings",
          },
          accountBookings: {
            breadcrumbs: {
              bookings: "Bookings",
              newServiceRequest: "New service request",
              viewServiceRequest: "View service request",
              bookingDetails: "Booking details",
              invoice: "Invoice",
              stockMovements: "Stock movements"
            },
            bookings: "Bookings",
            savedSearches: "Saved searches",
            dashboard: "Bookings",
            noBookings: "There are no bookings to show.",
            activeBookings: "Active bookings",
            pastBookings: "Past bookings",
            newServiceRequestButton: "New service request",
            returnToBooking: "Return to booking",
            bookingDetails: {
              reference: "Booking reference",
              title: "Booking details",
              startDate: "Start date",
              endDate: "End date",
              spaceAllocations: "Space allocations",
              quantity: "Quantity",
              totalBookedSpace: "Total booked space",
              status: "Status",
              stockMovementsLink: "View all movements"
            },
            booking: {
              reference: "Booking reference",
              startDate: "Start date",
              endDate: "End date",
              quantity: "Quantity",
              status: "Status",
            },
            bookingStock: {
              title: 'Booking stock',
              noStockItems: 'No stock has been moved into any of the booking sites yet.',
              site: 'Site'
            },
            spaceAllocations: {
              siteName: "Site",
              spaceName: "Space",
            },
            newServiceRequest: {
              title: "New service request",
              service: "Service",
              serviceRequired: "Please select a service",
              requestDetails: "Request details",
              requestDetailsHelp:
                "More detailed information helps the storage provider provide an accurate response to the request.",
              requestDetailsRequired:
                "Please enter as much information as possible about your service request",
              selectSites: "Select sites",
              selectSitesHelp:
                "The service request will be sent to each of the selected sites for this booking.",
              submit: "Submit request",
              siteIdErrorTitle: "Invalid site details",
              siteIdErrorMessage:
                "Unable to create a new a new service request with the site details provided.",
              noServicesTitle: "No services available",
              noServicesMessage:
                "You are unable to create a new service request for this space at this time. The space provider has not setup any available services.",
              customerDetails: "Customer details",
              stockItems: "Stock items",
              palletQuantity: "Pallet quantity",
              palletQuantityHelp: "The number of pallets to be moved or modified within the service request.",
              palletQuantityBookedHelp: "Quantity available: ",
              palletQuantityUnit: "pallets",
              palletQuantityReq: "Please enter the number of pallets for the request",
              palletQuantityMin: "Pallet quantity cannot be less than zero",
              palletQuantityMax: "Pallet quantity cannot be more than booked quantity of ",
              requiredByDate: "Required by",
              requiredByDateHelp: "The date the service request needs to be completed by.",
              requiredByDateReq: "Please specify the required by date",
              requiredByMinVal: "The required by date cannot be in the past",
              stockQuantityReq: 'Please ensure all stock item quantities are set.',
              stockMovementLabel: 'I want to',
              stockMovementReq: 'A stock movement is required when stock are selected',
              moveStockIn: 'Move stock in',
              moveStockOut: 'Move stock out'
            },
            serviceRequests: {
              title: "Service requests",
              noRequests:
                "There are no service requests to show for this booking",
              reference: "Request Reference",
              site: "Site",
              service: "Service",
              created: "Created",
              status: "Status",
              requiresAction: "Requires action",
              itemsRequireActionMsg:
                "You have service requests that require action",
            },
            serviceRequestDetail: {
              title: "Service request",
              created: "Date created",
              status: "Status",
              serviceName: "Service name",
              requestDetails: "Request details",
              notes: "Request Notes",
              noNotes: "No notes submitted for request yet",
              price: "Nominated price",
              priceNotAvailable: "Not yet available",
              setPriceRequired:
                "Please enter a valid price for the service request",
              priceHelpText:
                "This price includes your applicable tax rate, Storekat service and payment processing fees.",
              message: "Message",
              messageRequired: "You need to enter a message to reply",
              sendNote: "Send note",
              siteName: "Site name",
              palletQuantity: "Pallet quantity",
              requiredByDate: "Required by date",
              actions: {
                title: "Actions",
                close: "Close",
                finalise: "Finalise",
                finaliseAgree:
                  "By finalising the service request, you confirm that the request has been fulfilled and the customer will be invoiced for payment.",
                approve: "Approve",
                decline: "Decline",
                setPrice: "Set price",
                setPriceHelp:
                  "Please enter a price exclusive of tax. Your applicable tax rate will be included in the final price.",
                priceSetMsgTitle: "Price successfully set",
                priceSetMsgText:
                  "The price has been successfully set for the service request and the customer has been notified.",
              },
              confirmStock: {
                confirmStock: "Confirm stock movement",
                stockQuantityReq: 'Please ensure all quantities are correctly confirmed',
                overstock: 'Overstock',
                overstockDesc: 'The quantities of stock recieved indicate the buyer has sent an overstock from the original quoted service request. If required, you can specify an additonal charge for the receipt and handling of the additional stock quantity. This charge will be added to the invoice sent to the customer.',
                overstockPrice: 'Amount to charge:'
              }
            },
            bookingInvoice: {
              title: "View invoice",
              leadText: "Download a PDF copy of your booking invoice.",
              buttonText: "Download invoice",
            },
            serviceRequestInvoice: {
              title: "View invoice",
              leadText: "Download a PDF copy of your service request invoice.",
              buttonText: "Download invoice",
            },
            stockMovements: {
              title: "Stock movements",
              moveIn: "Move in",
              moveOut: "Move out",
              detailedMovements: "Detailed product movements",
              date: 'Date',
              product: 'Product',
              movement: 'Movement',
              quantity: 'Quantity',
              bookingQuantityDesc: 'Shows the current quantity of products per site for this booking.',
              detailedMovementsDesc: 'Detailed history of stock movements for each site within the booking.'
            }
          },
          accountServiceRequests: {
            title: "Service Requests",
            menuTitle: "Service Requests",
            breadcrumbs: {
              serviceRequests: "Service Requests"
            },
            sellerRequestsTitle: "Service requests for action",
            noSellerRequests: 'No service requests have been created for your sites.',
            buyerRequestsTitle: "Service requests I made",
            noBuyerRequests: 'You have not created any service requests for your bookings.'
          },
          accountSiteBookings: {
            dashboard: "Site Bookings",
            siteBookings: "Site bookings",
            breadcrumbs: {
              siteBookings: "Site bookings",
              siteBookingDetails: "Site booking details",
            },
            siteBookingDetails: {
              title: "Site booking details",
            },
          },
          accountSettings: {
            accountSettings: "Account settings",
            profile: "Profile",
            accountSecurity: "Account security",
            billing: "Plan & Billing",
            organisations: "Organisations",
          },
          profileSettings: {
            title: "Profile settings",
            save: "Save",
          },
          billingDetails: {
            title: "Billing details",
            defaultCurrency: "Default currency",
          },
          planDetails: {
            title: "Plans",
            currentPlanTitle: "Current plan information",
            cardsCta: {
              currentBill: "Current monthly bill",
              nextPaymentDue: "Next payment due",
              changePlan: "Change plan",
            },
          },
          changePlan: {
            buttonText: "Change plan",
          },
          accountOrganisations: {
            breadcrumbs: {
              organisations: "Organisations",
              payment: "Payment",
              new: "New organisation",
              invite: "Invite user",
              inviteSent: "Invite sent",
              changePlan: "Change plan",
              planChanged: "Plan changed",
              paymentDetails: "Payment details",
              planDetails: "Plan details",
              paymentMethods: "Payment methods",
              addPaymentMethod: "Add payment method",
              users: "Users",
              paymentHistory: "Payment history",
              edit: "Edit",
              onboardingComplete: "Payment account complete",
              onboarding: "Payment account onboarding",
              bankAccounts: "Bank accounts",
              invoices: "Invoices",
              payInvoice: "Pay invoice",
              invoicePaid: "Invoice paid"
            },
            subNavigation: {
              overview: "Overview",
              planDetails: "Plan details",
              paymentMethods: "Payment methods",
              users: "Users",
              paymentHistory: "Payment history",
              bankAccounts: "Bank accounts",
              invoices: "Invoices"
            },
            organisation: "Organisation",
            organisations: "Organisations",
            listTitle: "Organisations",
            addOrganisation: "New organisation",
            site: "site",
            sites: "sites",
            space: "space",
            spaces: "spaces",
            manageButtonText: "Manage",
            companyAddress: "Company registered address",
            changePlan: "Change plan",
            planChanged: "Plan changed",
            address: {
              title: "Address",
              noAddress: "No address set",
              noAddressMsg: "No address has been set for your organisation.",
              noAddressBtn: "Set an address",
            },
            userList: {
              title: "Organisation users",
              members: "Members",
              role: "Role",
              introText:
                "Invite users to your organisation so that they can transact on Storekat and help manage your business.",
              freeAccountLimit:
                "Your current plan allows for only a single user to be associated with your organisation.",
              paidAccountLimit:
                "There are #USERS# in your account. Your account allows up to a maximum of 5 users.",
              userLimitReached:
                "Your account has reached the maximum number of users allowed by your current plan.",
              user: "user",
              users: "users",
              inviteUser: "Invite new user",
            },
            inviteUser: {
              title: "Invite user",
              leadText:
                "Invite users to your account so that they can transact on Storekat and help manage your business.",
              userNotifyText:
                "The user will receive an invitational email with details on how to complete their account setup.",
              emailAddress: "Email address",
              givenName: "First name",
              familyName: "Last name",
              buttonText: "Invite user",
              inviteErrorTitle: "Error inviting user",
            },
            inviteSent: {
              title: "Invitation sent",
              leadText:
                "#FIRST_NAME# has been sent an invition successfully, and should receive an email notification shortly.",
              buttonText: "Back to organisation",
            },
            new: {
              submitButtonText: "Create organisation",
              paymentDetails: "Payment details",
              companyName: "Company name",
              accountTerms: "I agree to the Storekat Terms and Conditions",
              accountTermsRequired:
                "You must agree to the Storekat terms and conditions before you can create a new organisation.",
            },
            edit: {
              buttonText: "Edit organisation",
              saveAddress: "Save address",
            },
            paymentMethods: {
              title: "Payment methods",
              leadText:
                "Manage the payment methods associated with your organisation.",
              noPaymentMethods:
                "There are no payment methods associated with this account.",
              addPaymentMethod: "Add payment method",
              cardType: "Card type",
              cardNumber: "Card number",
              cardExpiry: "Card expiry",
              paymentMethodRequired:
                "Default payment method cannot be removed. Your account must has at least one payment method.",
            },
            paymentHistory: {
              title: "Payment history",
              leadText:
                "View your previous payments, view and download associated invoices.",
              noPayments: "No payments found for this account.",
              showReceipt: "View receipt",
              downloadInvoice: "Download invoice",
              detailedHistoryTitle: "View detailed history",
              detailedHistoryText:
                "Login to the payment provider to view detailed payment history details.",
              detailedHistoryLogin: "Payment provider login",
            },
            onboarding: {
              title: "Complete account onboarding",
              completeOnboardingMsg:
                "Additional steps are required to complete your account banking details.",
              completeOnboardingLink: "Complete onboarding",
              topBannerBadge: "Note",
              topBannerMsg: "Complete account onboarding to list your space",
            },
            onboardingComplete: {
              title: "Payment account setup complete",
              leadText: "You're all set",
              message:
                "Your organisation's company management and payment account details have been successfully saved. You are now able to publish your site and space availabilities on Storekat and accept bookings.",
            },
            bankAccounts: {
              title: "Bank accounts",
              manageBankAccounts: "Manage bank accounts",
              leadText:
                "Login to the payment provider to view and manage bank account details that payments are paid into.",
              login: "Payment provider login",
              noBankAccounts: "No bank accounts have been setup.",
              loginLinkError:
                "There was an problem with the login link. Please contact Storekat support for assistance.",
            },
            invoices: {
              title: "Invoices",
              leadText: "Review and pay your pending invoices.",
              list: {
                invoiceNumber: 'Invoice number',
                dueDate: 'Due date',
                amount: 'Amount',
                download: 'Download',
                receipt: 'Receipt',
                payInvoice: 'Pay invoice',
                status: 'Status',
                noInvoices: "Your account currently has no invoices."
              },
              payment: {
                title: "Pay invoice",
                leadText: "Enter your payment details below to complete your invoice payment.",
                invoiceOverview: "Invoice overview",
                amountDue: "Amount due",
                invoiceNumber: "Invoice number"
              },
              paymentComplete: {
                title: "Invoice paid successfully",
                successText: "Thank you! Payment for invoice #INVOICE_NO# has been successfully processed.",
              }
            }
          },
          accountSites: {
            breadcrumbs: {
              sites: "Sites",
              new: "New",
              edit: "Edit",
              addAvailability: "Add availability",
              editAvailability: "Edit availability",
              addSpace: "Add space",
              addSite: "Add site",
              addService: "Add service",
              removeService: "Remove service",
              editService: "Edit service",
            },
            sites: "Sites",
            spaces: "Spaces",
            services: "Services",
            bookings: "Bookings",
            dashboard: "Sites dashboard",
            addSite: "Add new site",
            addSpace: "Add new space",
            addService: "Add service",
            removeService: "Remove service",
            availability: {
              availability: "Availability",
              add: "Add availability",
              edit: "Edit availability",
              create: "Create availability",
              update: "Update availability",
              noWindows: "No availabilities added yet",
              addFirstWindow: "Add the first availability to this space",
              startDate: "From",
              endDate: "To",
              minBookingPeriod: "Minimum booking period",
              minBookingPeriodUnit: "weeks",
              leadTime: "Lead time",
              leadTimeUnit: "weeks",
              minBookingQuantity: "Minimum booking quantity",
              maxAvailableQuantity: "Maximum available quantity",
              unitPrice: "Unit price per week",
              unitPriceHelp:
                "Please enter a price exclusive of tax. Your applicable tax rate will be included in the final price.",
              unitPriceWithUnitAndMeasure:
                "Unit price per week ({{ unit }} – {{ unitMeasure }})",
              rhd: "Receipt, Handling, and Dispatch",
              rhdPriceHelp:
                "Please enter a price exclusive of tax. Your applicable tax rate will be included in the final price.",
              rhdInUnitPrice: "In",
              rhdStdUnitPrice: "Storage",
              rhdOutUnitPrice: "Out",
              currency: "Currency",
              publish: "Publish availability",
              publishHelp:
                "Publishing the availability will ensure the availability window can be searched.",
              published: "Published",
              validation: {
                dateFromRequired:
                  "Please specify the date the window is available from",
                dateToRequired:
                  "Please specify the date the window is available to",
                leadTimeRequired:
                  "Please specify a booking lead time, in weeks",
                maxQuantityRequired:
                  "Set the maximum available quantity for this window",
                minQuantityRequired:
                  "Set the minimum quantity that can be booked",
                unitPriceRequired:
                  "Please specify the price per unit, per week",
                rhdInPriceRequired: "Please specify the RH&D in unit price",
                rhdOutPriceRequired: "Please specify the RH&D out unit price",
                minBookingPeriod: {
                  greaterThanDateRange:
                    "Cannot be larger than the availability window",
                },
                startDate: {
                  dateInPast: "Can’t start in the past",
                },
                endDate: {
                  beforeStartDate: "Can't be before the start date",
                },
                maxAvailableQuantity: {
                  greaterThanSpaceMaximum:
                    "Can't be greater than maximum space capacity, currently {{value}}",
                },
                minBookingQuantity: {
                  greaterThanMaximum:
                    "Can't be greater than maximum available quantity",
                },
              },
              completeOnboardingMsg:
                "Before you can list your availability, you must complete your account onboarding.",
            },
            site: {
              site: "Site",
              status: "Status",
              name: "Name",
              description: "Description",
              addAmenities: "Add amenities",
              generalAmenities: "General",
              securityAmenities: "Security features",
              accessAmenities: "Access",
              create: "Create site",
              update: "Update site",
              edit: "Edit site",
              certificationStatusValues: {
                pendingCertification: "Pending Certification",
                certified: "Certified",
              },
              noSites: "No sites yet",
              addFirst: "Add the first site",
              address: "Address",
              validation: {
                nameRequired: "You need to enter a name for your site",
                addressRequired:
                  "You need to specify an address for your site.",
              },
            },
            space: {
              space: "Space",
              name: "Name",
              description: "Description",
              type: "Type",
              class: "Class",
              quantity: "Maximum quantity",
              unit: "Unit",
              unitMeasure: "Measure",
              status: "Status",
              noSpaces: "No spaces yet",
              addFirst: "Add the first space to this site",
              create: "Create space",
              update: "Update space",
              edit: "Edit space",
              validation: {
                nameRequired: "You need to enter a name for your space",
                classRequired: "The storage class type is required",
                quantityRequired:
                  "You need to specify a maximum available quanity",
                unitRequired: "The storage unit is required",
                measureRequired: "The unit measure type is required",
              },
            },
            service: {
              service: "Service",
              name: "Name",
              description: "Description",
              customerInformation: "Customer information",
              customerInformationHelp:
                "Add instructions for your customers requesting the service.",
              noSpaces: "No services listed for this site",
              addFirst: "Add the first service to this site",
              add: "Add service",
              edit: "Edit service",
              remove: "Remove",
              removeServiceLeadText:
                "Removing the service from the site will prevent any new service requests from customers",
              serviceName: "Service name",
              update: "Update",
            },
            metrics: {
              sites: "Sites",
              spaces: "Spaces",
              openServiceRequests: "Open Service Requests",
              closedServiceRequests: "Closed service requests",
              totalServiceRequestsValue: "Total requests value",
              listedPalletSpace: "Listed pallet space",
              siteCapacity: "Site capacity",
              siteTotalValue: 'Total potential value',
              serviceRequestsFulfilledPercent: "Requests fulfilled",
              siteRemainingCapacity: "Remaining capacity"
            },
          },
          stockManagement: {
            breadcrumbs: {
              stockManagement: "Stock management",
              stockItemDetails: "stock details",
              new: "New stock item",
              edit: "Edit stock item",
              import: "Import stock items",
              importPending: 'Importing...'
            },
            title: 'Stock management',
            navTitle: 'Stock management',
            addStock: 'Add stock',
            noStockItems: 'No stock items have been added or imported yet.',
            stockItem: {
              sku: "SKU",
              gtin: "GTIN",
              upc: "UPC",
              name: "Name",
              description: "Description",
              created: "Created",
              updated: "Updated"
            },
            stockItemDetails: {
              title: "Stock item details"
            },
            stockItemUsage: {
              title: "Usage"
            },
            new: {
              title: 'New stock item',
              create: "Add stock item",
              formErrorTitle: 'Error creating stock item.'
            },
            edit: {
              title: "Update stock item",
              button: "Edit stock item",
              formErrorTitle: 'Error updating stock item.'
            },
            form: {
              productCodes: 'Product codes',
              productDetails: 'Product details',
              validation: {
                skuRequired: "Product SKU required",
                nameRequired: "Product name required"
              }
            },
            tooltips: {
              sku: "Stock Keeping Unit",
              gtin: "Global Trade Item Number",
              upc: "Universal Product Code",
              duplicate: 'Duplicate SKU will be skipped'
            },
            import: {
              button: 'Import',
              title: 'Import stock items',
              leadText: '',
              sampleFile: 'Sample file',
              sampleFileLead: 'Your import data file file needs to be a comma-separated values (CSV) file, containing the columns below. For these columns, the Sku field is required, while others are optional. Alternately you can download a sample file.',
              sampleFileDownload: 'Download sample file',
              form: {
                title: 'Upload import file',
                leadText: 'Select and upload your stock imem import data file.',
                selectFile: 'Select file',
                validate: 'Validate',
                validationResults: 'Validation results',
                import: 'Import',
                line: 'Line'
              },
              confirmation: {
                title: 'Stock import complete',
                leadText: 'Your stock items have been successfully imported.'
              },
              processing: {
                title: 'Importing stock items...',
                leadText: 'Please wait while your stock items are imported.'
              }
            },
            metrics: {
              totalStockItems: 'Total stock items',
              storedStock: 'Stored stock',
              stockMovements: 'Stock movements',
              stockMovementsUnit: 'year'
            }
          },
          toasts: {
            loading: "Loading…",
            exception: "An error has occurred. Please try again",
            availabilityCreating:
              "Please wait while the availability is created...",
            availabilityCreated: "Space availability successfully created",
            availabilityCreationFailed: "Availability creation failed",
            availabilityUpdating:
              "Please wait while the availability is updated...",
            availabilityUpdated: "Availability updated",
            availabilityUpdateFailed: "Availability update failed",
            availabilityRemoved: "Availability within space removed",
            signOutSuccess: "You have been signed out",
            signingOut: "Signing you out...",
            siteCreating: "Please wait while your site is created...",
            siteCreated: "Your site has been created",
            siteCreationFailed: "Site creation failed",
            siteUpdated: "Site updated",
            siteUpdateFailed: "Site update failed",
            spaceCreating: "Please wait while your space is created...",
            spaceCreated: "Your space has been created",
            spaceCreationFailed: "Space creation failed",
            accountConfirmed: "Your account has been successfully confirmed",
            changePasswordSuccess:
              "Your account password has been successfully changed",
            changePasswordFailed: "Your account password could not be updated",
            changePasswordIncorrectPassword:
              "The current account password is incorrect",
            invalidCredentials:
              "The username and password you have entered is incorrect",
            profileSettingsSaved:
              "Your account profile settings have been updated",
            profileSettingsFailed:
              "Unable to save your account profile changes",
            serviceRequestCreating:
              "Please wait while your service request is created...",
            serviceRequestCreated: "Your service request has been created",
          },
          meta: {
            title: "Storekat",
            description: "It's Storekat!",
          },
          homeCTA: "Get connected to endless warehousing",
          auth: {
            register: "Register",
            registerErrorTitle: "Unable to register account",
            signIn: "Sign in",
            signOut: "Sign out",
            firstName: "First name",
            lastName: "Last name",
            displayName: "Display name",
            displayNameHelp:
              "Your display name is publically visible on Storekat and must be unique",
            email: "Email",
            password: "Password",
            confirmPassword: "Confirm password",
            passwordsDiffer: "Passwords don't match",
            changePassword: "Change password",
            passwordRequirements:
              "Password must be at least 8 characters, and have an upper and lowercase, a number and a symbol",
            accountTerms: "I agree to the Storekat Terms and Conditions",
            accountTermsRequired:
              "You must agree to the Storekat terms and conditions before you can register an account.",
            companyName: "Company name",
            companyNameHelp:
              "Your company name will be displayed on StoreKat. You can leave this blank and fill these details in later if you wish.",
            termsOfUse: "Terms of Use",
            registerForm: {
              firstNameRequired: "Please enter your first name",
              lastNameRequired: "Please enter your last name",
              displayNameRequired: "Please enter your StoreKat display name",
              displayNameNotUnique:
                "Sorry, that display name is already in use",
              displayNameUnique:
                "Your display name is available to use on StoreKat",
              emailRequired: "Please enter your email address",
              emailNotUnique: "Sorry, that email address is already in use",
              emailUnique: "Your email address is available to use on StoreKat",
              passwordRequired: "Please enter a strong account password",
              confirmPasswordRequired: "Please confirm your account password",
              passwordsNotMatch: "The passwords you have entered do not match",
              passwordNotStrong:
                "The password you have entered does not match the security requirements",
            },
            payment: {
              title: "Payment details",
            },
            loginForm: {
              emailRequired: "Please enter your email address",
              passwordRequired: "Please enter your account password",
              loginError:
                "The username and/or password details you have entered is incorrect",
            },
          },
          changePassword: {
            title: "Change your password",
            desc: "To change your account password, please enter your current account password and the new account password.",
            formButtonText: "Change password",
            existingPassword: "Existing password",
            newPassword: "New password",
            confirmPassword: "Confirm password",
            errorTitle: "Cannot change password",
            validation: {
              existingPasswordRequired: "Enter your existing account password",
              newPasswordRequired: "Enter a new account password",
              confirmPasswordRequired: "Confirm your new account password",
              passwordsNotMatch: "The passwords you have entered do not match",
              passwordNotStrong:
                "The password you have entered does not match the security requirements",
            },
          },
          confirmAccount: {
            title: "Confirm your account",
            desc: "Your account has been successfully created. Before you can begin using Storekat, you need to confirm your account. Please check your email and enter your confirmation code below.",
            confirmCode: "Confirmation code",
            confirmCodeRequired:
              "Please enter your confirmation code sent to your email",
            formButtonText: "Confirm account",
          },
          resendAccountConfirmation: {
            formButtonText: "Resend code",
            desc: "If you didn't receive an activation code, resend your account activation email.",
            codeResentText:
              "Your confirmation code has been resent to your email address.",
            noCodeLinkText: "I didn't receive a confirmation code",
          },
          resetPassword: {
            title: "Forgot your password?",
            setPasswordTitle: "Set new password",
            desc: "Enter your email address below and details on how to reset your password will be sent to you.",
            setPasswordDesc:
              "Enter the confirmation code you received in your email inbox to reset your account password.",
            formButtonText: "Reset password",
          },
          search: {
            startDate: "From",
            endDate: "To",
            quantity: "Pallet quantity",
            formTitle: "Find space",
            leadText:
              "Instantly book space across multiple warehouse providers.",
            buttonText: "Search",
            radius: "Distance",
            radiusUnit: "km",
            location: "Location",
            type: "Space type",
            returnToSearch: "Back to results",
            validation: {
              spaceTypeRequired: "You need to specify a search type",
              quantityRequired: "Specify the storage quantity requirements",
              locationRequired: "Starting location required",
              fromRequired:
                "You need to specify the start date of your space booking",
              toRequired:
                "You need to specify the end date of your space booking",
            },
          },
          booking: {
            stepOne: {
              title: "Complete your booking",
            },
            stepTwo: {
              title: "Booking terms and conditions",
            },
            paymentButtonText: "Payment",
            bookingTerms: {
              title: "Booking terms and conditions",
              checkboxLabel: "I agree to the above terms and conditions",
              requiredMessage:
                "You must agree to the Storekat Terms and Conditions to proceed.",
            },
            rhd: {
              title: "Receipt, handling & dispatch",
              leadText:
                "Estimate the number of units to be received on the first day of the booking. Once the booking has been completed, you can create a service request for the initial receipt of goods.",
              estimatesTitle: "Estimates",
              estimatesLeadText:
                "To help setup your booking please estimate additional movements. Additional RH&D movements are billed in arrears utilising service requests.",
              estimatedThroughput: "Estimated throughput",
              estimatedThroughputHelp:
                "The total number of units expected to be moved through the space during the course of the booking.",
              estimatedMovements: "Estimated movements",
              estimatedMovementsHelp:
                "The number of movements in and out of the space for the booking.",
              initialReceipt: "Initial receipt movements",
              initialReceiptHelp:
                "The number of units stored on the first day of the booking.",
              validation: {
                initialReceiptQty: {
                  greaterThanBooking: "Must not exceed booking quantity.",
                },
              },
            },
            serviceAddons: {
              title: "Purchase additional services",
              leadText: "Add additional services to your space booking",
              addService: "Add service",
            },
            optionOverview: {
              price: "Total price: ",
            },
            payment: {
              title: "Payment details",
              bookingOverview: "Booking overview",
              bookingPrice: "Booking price",
              subTotal: "Sub total",
              storekatFees: "Booking fees",
              paymentFees: "Payment processing fees",
              taxAmount: "Tax amount",
              totalBookingPrice: "Total booking price",
              bookingDates: "Booking dates",
              bookedQuantity: "Booked quanity",
            },
            confirmation: {
              title: "Booking confirmed!",
              navTitle: "Booking confirmed",
              buttonText: "View booking details",
              confirmedText:
                "Your booking for #QUANTITY# pallets starting on #START_DATE# has been confirmed. View the booking details and create a service request to create an initial movement.",
            },
          },
          payments: {
            formTitle: "Payment details",
            buttonText: "Pay now",
            addPaymentMethod: "Add payment method",
            selectPaymentMethodText:
              "Pay with an existing payment method or enter new card details",
            paymentMethodRequired: "Please select a method of payment",
            savedAsDefaukt:
              "Your payment method will be securely saved for use with future payments",
            saveCardLabel: "Save card details for future use",
            cardPayments: {
              title: "Payment card details",
              cardNumber: "Credit card number",
              nameOnCard: "Name on card",
              cardExpiry: "Card expiry",
              cardExpiryHelp: "Card expiry must be in mm/yy format",
              cardVerification: "Card CCV number",
              makePayment: "Pay now",
              paymentErrorTitle: "Error processing payment",
              paymentProcessingText: "Processing",
            },
          },
          accountPlans: {
            priceSuffix: " / month",
            singleAccountUser: " account user",
            multipleAccountUsersSuffix: " account users",
            multipleAccountUsersPrefix: "Upto",
          },
          notFound: {
            title: "Nothing here...",
            leadText:
              "Sorry, the page you are looking for cannot be found. ¯\\_(ツ)_/¯",
          },
          termsConditions: {
            title: "Terms of Use",
            menuTitle: "Terms of Use",
          },
          support: {
            title: "Support request",
            menuTitle: "Support request",
            leadText:
              "Use the form below to submit a support request to Storekat.",
            breadcrumbs: "Support request",
            accountDetails: {
              title: "Account details",
              name: "Name",
              email: "Email",
              accountName: "Account name",
            },
            form: {
              title: "Request title",
              titleRequired: "Please enter a support request title",
              category: "Category",
              categoryRequired: "Please select a category",
              description: "Request description",
              descriptionRequired:
                "Please enter a detailed description for your support request",
              submit: "Submit",
            },
            submitted: {
              title: "Support request submitted",
              leadText:
                "Your support request has been successfully submitted an a member of the Storekat team will be in contact shortly.",
            },
          },
        },
      },
    },
  });

export default i18n;
