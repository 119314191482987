import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import apiUrl from "../../../../../data/api-url";
import { tokenFetcher } from "../../../../../data/fetchers";
import { useAuth } from "../../../../../hooks/use-auth";
import { formatDate } from "../../../../../utils/date-transformations";
import LoadingBlock from "../../../../common/loading/LoadingBlock";
import ServiceRequestList from "../service-requests/ServiceRequestList";

export default function ShowSiteBooking() {
  const { t } = useTranslation();
  const { bookingId, siteId } = useParams();
  const { user } = useAuth();

  const { data: booking } = useSWR(
    [apiUrl("bookings", `/site-bookings/${bookingId}/${siteId}`), user.token],
    tokenFetcher
  );

  // TODO: Get the account from the current context
  const { data: serviceRequests } = useSWR(
    [
      apiUrl(
        "bookings",
        `/bookings/${bookingId}/service-requests/site/${siteId}`
      ),
      user.token,
    ],
    tokenFetcher
  );

  if (!booking || !serviceRequests) return <LoadingBlock />;

  return (
    <div className="space-y-12">
      <header className="flex justify-between items-end">
        <h1>{t("accountSiteBookings.siteBookingDetails.title")}</h1>
      </header>

      <div>
        <h2>
          <small className="text-sm font-medium uppercase">
            {" "}
            {t("accountBookings.booking.reference")}
          </small>
          <br />
          {booking.reference}
        </h2>
      </div>

      <div className="grid md:grid-cols-2 md:gap-6 space-y-4 md:space-y-0">
        <div>
          <span className="font-bold block">
            {t("accountBookings.bookingDetails.startDate")}
          </span>
          {formatDate(booking.startDate)}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.bookingDetails.endDate")}
          </span>
          {formatDate(booking.endDate)}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.bookingDetails.totalBookedSpace")}
          </span>
          {booking.quantity.toLocaleString()} {booking.unit}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.bookingDetails.status")}
          </span>
          {booking.status}
        </div>
      </div>

      <div className="space-y-6">
        <h2>{t("accountBookings.serviceRequests.title")}</h2>
        <ServiceRequestList serviceRequests={serviceRequests} />
      </div>
    </div>
  );
}
