import React, { useEffect, useState } from 'react'
import StockItemList from './StockItemList'
import StockMovements from '../dashboard-widgets/StockMovements'
import { useStore } from '../../../hooks/use-store'
import { observer } from 'mobx-react-lite'
import { BookingStockMetrics } from '../../../app/model/bookings'
import requests from '../../../data/requests'
import apiUrl from '../../../data/api-url'
import TotalStockItems from '../dashboard-widgets/TotalStockItems'
import StoredStockItems from '../dashboard-widgets/StoredStockItems'

export default observer(function StockDashboard() {
  
  const { stockStore, userStore } = useStore();
  const { loadStockItems, clearStockItems, stockItems } = stockStore
  const [ metrics, setMetrics ] = useState<BookingStockMetrics | undefined>(undefined);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    
    async function loadDashboard() {

      if (userStore.contextAccount !== undefined) {
        
        loadStockItems(userStore.contextAccount.id);
        
        if (metrics === undefined) {
          var results = await requests.get<BookingStockMetrics>(apiUrl('bookings', `/metrics/account-booking-stock/${userStore.contextAccount?.id}`));
          setMetrics(results);         
        }
      }
      setLoading(false);
    }
    loadDashboard();

    return clearStockItems();

  }, [loadStockItems, clearStockItems, userStore.contextAccount, metrics, loading]);

  return (
    <div className='space-y-8'>


      <ul className="grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-6">
        <li className="p-4 shadow-md rounded border border-gray-200 mb-4">
          <TotalStockItems count={stockItems?.length} loading={loading} />
        </li>
        <li className="p-4 shadow-md rounded border border-gray-200 mb-4">
          <StoredStockItems filled={metrics?.filledSpace ?? 0} totalBooked={metrics?.bookedSpace ?? 0} loading={loading} />
        </li>
        <li className="p-4 shadow-md rounded border border-gray-200 mb-4">
          <StockMovements count={metrics?.serviceRequestsLastYear} loading={loading} />
        </li>
      </ul>

      <StockItemList />
    </div>
  )
});