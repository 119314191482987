export function getSiteCertificationStatus(status:string | undefined, t:any) {
  if (status === undefined || status === "") {
    return "";
  }

  if (status.toLowerCase() === "pendingcertification") {
    return t(
      "accountSites.site.certificationStatusValues.pendingCertification"
    );
  } else if (status.toLowerCase() === "certified") {
    return t("accountSites.site.certificationStatusValues.certified");
  }
}
