import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

import { useAuth } from "../../../hooks/use-auth";
import styles from "./UserMenu.module.css";
import { useEffect, useRef, useState } from "react";
import useOutsideAlerter from "../../../hooks/use-outside-alerter";
import { toastLoading } from "../messages/toast-messages";
import Feature from '../feature-flags/Feature';
import ContextSwitcher from './ContextSwitcher';

interface UserMenuProps {
  user: any
}

export default function UserMenu({ user }:UserMenuProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { signOut } = useAuth();
  const [isChecked, setIsChecked] = useState(false);
  const organisationId = useParams<{organisationId: string}>();

  const handleSignOut = async () => {
    const toastId = toastLoading(t("toasts.signingOut"));
    await signOut();
    toast.success(t("toasts.signOutSuccess"), { id: toastId });
    history.push("/");
  };

  const wrapperRef = useRef(null);

  const callback = () => {
    setIsChecked(false);
  };

  useOutsideAlerter(wrapperRef, callback);

  useEffect(() => {
    const contextSwitched = new URLSearchParams(location.search).get("context_switched");
    if (contextSwitched !== null && contextSwitched === "1") {
      setIsChecked(false);
    }
  }, [location.search, organisationId]);

  return (
    <div ref={wrapperRef}>
      <input
        type="checkbox"
        value="selected"
        id="user-menu-toggle"
        className={styles.userMenuToggle}
        onChange={(event) => setIsChecked(event.currentTarget.checked)}
        checked={isChecked}
      />
      <label
        htmlFor="user-menu-toggle"
        className="block p-4 cursor-pointer font-medium hover:underline bg-white"
      >
        <svg width="67" height="67" className="pr-2 inline">
          <g transform="matrix(0.13958333 0 0 0.13958333 0 0)">
            <path
              d="M0 32C 0 14.326888 14.326888 0 32 0L32 0L448 0C 465.67313 0 480 14.326888 480 32L480 32L480 448C 480 465.67313 465.67313 480 448 480L448 480L32 480C 14.326888 480 0 465.67313 0 448L0 448L0 32z"
              stroke="none"
              fill="#FFFFFF"
              fillRule="nonzero"
              fillOpacity="0"
            />
            <path
              d="M374.308 240C 374.308 311.691 316.191 369.80798 244.50002 369.80798C 172.80902 369.80798 114.692 311.691 114.692 240C 114.692 168.30899 172.809 110.192 244.5 110.192C 316.19098 110.192 374.308 168.309 374.308 240z"
              stroke="#FFFFFF"
              strokeWidth="10.385"
              fill="#EFEFEF"
              fillRule="nonzero"
            />
            <path
              d="M244.5 256.2C 222.873 256.2 179.7 267.05402 179.7 288.6L179.7 288.6L179.7 304.80002L309.3 304.80002L309.3 288.6C 309.3 267.05402 266.12698 256.2 244.49998 256.2M244.49998 240.00002C 262.40097 240.00002 276.9 225.50102 276.9 207.6C 276.9 189.699 262.401 175.20001 244.5 175.20001C 226.599 175.20001 212.1 189.699 212.1 207.6C 212.1 225.501 226.599 240 244.5 240"
              stroke="none"
              fill="#35374A"
              fillRule="nonzero"
              fillOpacity="0.5"
            />
          </g>
        </svg>
      </label>
      <div
        className={`${styles.userMenu} absolute left-0 w-full py-8 bg-brand z-50`}
      >
        <div className="container mx-auto px-4 text-gray-700">
          <div className="md:flex justify-between">
            
            <div className="text-2xl font-bold">
              {user.givenName} {user.familyName}
            </div>

            <div className='pl-0 md:pl-6 mt-4 md:mt-0'>
              <ContextSwitcher />
            </div>

          </div>

          <nav className="mt-6 md:flex justify-between">
            <ul className="md:flex md:space-x-12 space-y-3 md:space-y-0">
              <li>
                <Link
                  to="/account/bookings"
                  onClick={() => setIsChecked(!isChecked)}
                >
                  {t("accountBookings.bookings")}
                </Link>
              </li>
              <li>
                <Link
                  to="/account/service-requests"
                  onClick={() => setIsChecked(!isChecked)}
                >
                  {t("accountServiceRequests.menuTitle")}
                </Link>
              </li>
              <li>
                <Link
                  to="/account/sites"
                  onClick={() => setIsChecked(!isChecked)}
                >
                  {t("accountSites.sites")}
                </Link>
              </li>
              <Feature name='stock-management'>
                <li>
                  <Link
                    to="/account/stock-management"
                    onClick={() => setIsChecked(!isChecked)}
                  >
                    {t("stockManagement.navTitle")}
                  </Link>
                </li>
              </Feature>
              <li>
                <Link
                  to="/account/organisations"
                  onClick={() => setIsChecked(!isChecked)}
                >
                  {t("accountOrganisations.organisations")}
                </Link>
              </li>
            </ul>

            <ul className="mt-3 md:mt-0 pt-3 md:pt-0 border-t border-t-gray-600 md:border-t-0 md:flex md:space-x-4 space-y-2 md:space-y-0">
              <li>
                <Link
                  to="/account/settings"
                  onClick={() => setIsChecked(!isChecked)}
                >
                  {t("accountSettings.accountSettings")}
                </Link>
              </li>
              <li>
                <button className="cursor-pointer hover:underline" onClick={handleSignOut}>
                  {t("auth.signOut")}
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
