import useBreadcrumbs, { BreadcrumbsRoute } from "use-react-router-breadcrumbs";
import { Link } from "react-router-dom";
import { BiChevronRight } from "react-icons/bi";

interface BreadcrumbsProps {
  routes: BreadcrumbsRoute[]
}

export default function Breadcrumbs({ routes }:BreadcrumbsProps) {
  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: true,
    excludePaths: ["/", "/account"],
  });

  if (breadcrumbs.length <= 1) {
    return null;
  }

  return (
    <div className="border-b">
      <nav className="container mx-auto text-gray-700" aria-label="Breadcrumb">
        <ol className="flex pb-2 items-center">
          {breadcrumbs.map(({ match, breadcrumb }, i) => (
            <li key={match.url} className="flex flex-nowrap items-center">
              {i !== 0 && (
                <BiChevronRight
                  width={20}
                  height={14}
                  className="text-gray-500"
                />
              )}
              {i + 1 !== breadcrumbs.length ? (
                <Link to={match.url} className="block text-blue-600">
                  {breadcrumb}
                </Link>
              ) : (
                <span className="text-gray-500">{breadcrumb}</span>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}
