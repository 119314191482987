import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Feature from "./feature-flags/Feature";

export interface NavigationRoute {
  path: string;
  component: () => JSX.Element;
  label: string;
  exact?: boolean;
  hideFromNav?: boolean;
}

interface NavigationBarProps {
  navItems: NavigationRoute[]
}

export default function NavigationBar({ navItems }:NavigationBarProps) {
  const { t } = useTranslation();
  return (
    <nav>
      <ul className="flex space-x-4">
        {navItems.map((item) => (
          <NavigationBarItem key={item.path} {...item} />
        ))}
        <Feature name="stock-management">
          <NavigationBarItem
            label={t("stockManagement.navTitle")}
            path="/account/stock-management"
            hideFromNav={false}
          />
        </Feature>
      </ul>
    </nav>
  );
}

interface NavigationBarItemProps {
  label: string;
  path: string;
  exact?: boolean;
  isActive?: (match: any, _location: any) => boolean;
  hideFromNav?: boolean
}

function NavigationBarItem ({
  label,
  path,
  exact = false,
  isActive = defaultIsActive,
  hideFromNav,
}:NavigationBarItemProps) {

  if (hideFromNav === true) 
    return null;

  return (
    <li>
      <NavLink
        to={path}
        exact={exact}
        activeClassName="border-gray-700 font-medium"
        className="block pt-4 pb-2 border-b-4 border-transparent text-gray-700 hover:text-gray-700 hover:border-gray-700 hover:no-underline"
        isActive={isActive}
      >
        {label}
      </NavLink>
    </li>
  );
};

const defaultIsActive = (match:any, _location:any) => !!match;
