import i18n from "../../../i18n";
import ServiceRequests from './ServiceRequests';

const serviceRequestRoutes = [
  {
    exact: true,
    path: "/account/service-requests",
    component: ServiceRequests,
    breadcrumb: i18n.t("accountServiceRequests.breadcrumbs.serviceRequests"),
  },
];

export default serviceRequestRoutes;
