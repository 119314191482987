import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import requests from '../../../../data/requests';
import Button from '../../../common/Button';
import { Field, FileInput, Form } from '../../../common/forms';
import ErrorMessage from '../../../common/messages/ErrorMessage';
import SuccessMessage from '../../../common/messages/SuccessMessage';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import { ImportFileResult } from '../../../../app/model/stock-management';
import { useStore } from '../../../../hooks/use-store';
import Tooltip from '../../../common/Tooltip';
import apiUrl from '../../../../data/api-url';

interface ImportFormValues { 
  file: string
}

export default function ImportForm() {

  const { t } = useTranslation();
  const { handleSubmit, register } = useForm<ImportFormValues>();
  const [results, setResults] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<any>();
  const [filename, setFileName] = useState<any>();
  const history = useHistory();
  const { stockStore, userStore } = useStore();
  const { setProcessingImport } = stockStore;
    
  const validateFile = async (formData:any) => {

    setResults(undefined);
    setLoading(true);

    const uploadData = new FormData();
    uploadData.append("formFile", formData.file[0]);
    uploadData.append("fileName", formData.file[0].name);

    setFile(formData.file[0]);
    setFileName(formData.file[0].name);

    requests.post<ImportFileResult>(apiUrl('stock', `/stock/import/${userStore.contextAccount?.id}/validate`), uploadData)
      .then((data) => {
        setResults(data);
      })
      .catch(() => {
        setResults({valid: false, errorMessage: "Error uploading data file"});
      })
      .finally(() => {
        setLoading(false);
      });

  }

  const importFile = async () => {

    setProcessingImport(true);

    const uploadData = new FormData();
    uploadData.append("formFile", file);
    uploadData.append("fileName", filename);

    requests.post(apiUrl('stock', `/stock/import/${userStore.contextAccount?.id}`), uploadData)
      .then((data) => {
        history.push('/account/stock-management/import/complete');
      })
      .catch(() => {
        setResults({valid: false, errorMessage: "Error uploading data file"});
      })
      .finally(() => {
        setProcessingImport(false);
      }); 
  }

  return (
    <div className='space-y-6'>
      <h2>{t("stockManagement.import.form.title")}</h2>
      <Form onSubmit={handleSubmit(validateFile)}>

        <Field>
          <FileInput name='file' register={register('file')} />
        </Field>

        <Button label={t("stockManagement.import.form.validate")} loading={loading} />
      </Form>

      {results && (
        <div className="space-y-6">
          <h3>{t('stockManagement.import.form.validationResults')}</h3>
          {results.valid && (
            <>
              <SuccessMessage message='The data file has been validated and can be imported.' />
              <table className='border-collapse w-full'>
                <thead>
                  <tr className='border-b border-b-gray-300'>
                    <th className='text-left pl-2'>{t("stockManagement.import.form.line")}</th>
                    <th className='text-left'>{t("stockManagement.stockItem.sku")}</th>
                    <th className='text-left'>{t("stockManagement.stockItem.gtin")}</th>
                    <th className='text-left'>{t("stockManagement.stockItem.upc")}</th>
                    <th className='text-left'>{t("stockManagement.stockItem.name")}</th>
                    <th className='text-left'>{t("stockManagement.stockItem.description")}</th>
                  </tr>
                </thead>
                <tbody>
                  {results.lineItems && results.lineItems.length > 0 && (
                    results.lineItems.map((elem: any, index: number) => (
                      <tr key={index} className={`border-b border-b-gray-300 ${elem.duplicate ? 'bg-orange-50' : ''} `}>
                      {(elem.errorMessage !== null && elem.errorMessage !== undefined && elem.errorMessage !== '') ? (
                        <>
                          <td className='py-1 pl-2 bg-rose-50 text-rose-800'>{index + 1}</td>
                          <td colSpan={5} className='bg-rose-50 text-rose-800'>{elem.errorMessage}</td>
                        </>
                      ) : (
                        <>
                          <td className='py-1 pl-2'>{index + 1}</td>
                          <td className='py-1'>{elem.sku} {elem.duplicate && (
                            <Tooltip tooltip={t('stockManagement.tooltips.duplicate')}>
                              <BsFillExclamationTriangleFill className='inline-block text-orange-500' />
                            </Tooltip>
                          )}</td>
                          <td className='py-1'>{elem.gtin}</td>
                          <td className='py-1'>{elem.upc}</td>
                          <td className='py-1'>{elem.name}</td>
                          <td className='py-1'>{elem.description}</td>
                        </>
                      )}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>

              <Button label={t("stockManagement.import.form.import")} onClick={importFile} />
            </>
          )}
          {!results.valid && (
            <ErrorMessage message={results.errorMessage} />
          )}
        </div>
      )}

    </div>
  )
}