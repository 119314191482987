import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import apiUrl from '../../../data/api-url';
import submitForm from '../../../data/submit-form';
import { useAuth } from '../../../hooks/use-auth';
import { useStore } from '../../../hooks/use-store';
import Button from '../../common/Button';
import { Field, FieldErrorMessage, Form, SelectInput, TextArea, TextInput } from '../../common/forms';
import { toastError } from '../../common/messages/toast-messages';

interface SupportFormValues {
  title: string,
  category?: string,
  description: string
}

export default observer(function SupportForm() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { userStore } = useStore();
  const { register, handleSubmit, formState: { errors } } = useForm<SupportFormValues>({ mode: "onBlur" });
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const submitSupportRequest = async (formData:SupportFormValues) => {
    setSubmitting(true);

    await submitForm(
      apiUrl("accounts", "/support-request"),
      formData
    )
    .then(() => {
      history.replace('/account/support/submitted');
    })
    .catch((error: any) => {
      setSubmitting(false);
      toastError(t, error.message, error.errorCode);
      console.log(error);
    })
    .finally(() => {
      setSubmitting(false)
    });
  };

  return (
    <div className="space-y-8">
      <h1>{t("support.title")}</h1>

      <Form onSubmit={handleSubmit(submitSupportRequest)}>

        <p>{t('support.leadText')}</p>
          
        <p className='text-xl'>{t('support.accountDetails.title')}:</p>
        <div className='sm:flex'>
          <div className='sm:w-44 font-bold'>{t('support.accountDetails.name')}: </div>
          <div>{user?.givenName} {user?.familyName}</div>
        </div>
        <div className='sm:flex'>
          <div className='sm:w-44 font-bold'>{t('support.accountDetails.email')}: </div>
          <div>{user?.email}</div>
        </div>
        <div className='sm:flex'>
          <div className='sm:w-44 font-bold'>{t('support.accountDetails.accountName')}: </div>
          <div>{userStore.contextAccount?.name}</div>
        </div>

        <fieldset className="space-y-6">
          <Field label={`${t("support.form.title")} *`} htmlFor="title">
            <TextInput
              name="title"
              className='md:w-1/2'
              register={register("title", {
                required: t("support.form.titleRequired"),
              })}
              error={errors.title}
            />
            <FieldErrorMessage error={errors.title} />
          </Field>

          <div className='md:w-1/2'>
            <Field label={t("support.form.category")} htmlFor="category">    
              <SelectInput
                name="category"
                register={register("category", {
                  required: t("support.form.categoryRequired"),
                })}
                >
                    <option value='Billing'>Billing</option>
                    <option value='Technical'>Technical</option>
              </SelectInput>
            </Field>
          </div>

          <Field label={t("support.form.description")} htmlFor="description">
            <TextArea
              name="description"
              rows={5}
              register={register("description", { required: t('support.form.descriptionRequired') })}
            />
            <FieldErrorMessage error={errors.description} />
          </Field>
        </fieldset>

        <div>
          <Button label={t("support.form.submit")} loading={submitting} />
        </div>
      </Form>
    </div>
  )
});