import React from 'react'
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next'
import { StockItemFormFields } from '../../../../app/model/stock-management';
import { Field, FieldErrorMessage, TextArea, TextInput } from '../../../common/forms';

interface FormFieldsProps {
  formMethods: UseFormReturn<StockItemFormFields>;
}

export default function FormFields({formMethods}:FormFieldsProps) {
  const { t } = useTranslation();
  const { register, formState: { errors } } = formMethods;

  return (
    <>
      <h2>{t('stockManagement.form.productCodes')}</h2>
      <div className="grid md:grid-cols-3 md:gap-6 space-y-6 md:space-y-0">
        <Field
            label={`${t("stockManagement.stockItem.sku")} *`}
            labelTooltip={t("stockManagement.tooltips.sku")}
            htmlFor="sku"
            data-cy="field-sku"
          >
            <TextInput
              name="sku"
              register={register("sku", {
                required: t("stockManagement.form.validation.skuRequired"),
              })}
            />
            <FieldErrorMessage error={errors.sku} />
        </Field>
          
        <Field
            label={t("stockManagement.stockItem.gtin")}
            labelTooltip={t("stockManagement.tooltips.gtin")}
            htmlFor="gtin"
            data-cy="field-gtin"
          >
            <TextInput
              name="gtin"
              register={register("gtin")}
            />
        </Field>
          
        <Field
            label={t("stockManagement.stockItem.upc")}
            labelTooltip={t("stockManagement.tooltips.upc")}
            htmlFor="upc"
            data-cy="field-upc"
          >
            <TextInput
              name="upc"
              register={register("upc")}
            />
        </Field>
      </div>    

      <h2>{t('stockManagement.form.productDetails')}</h2>  
          
      <Field
            label={`${t("stockManagement.stockItem.name")} *`}
            htmlFor="name"
            data-cy="field-name"
          >
            <TextInput
              name="name"
              register={register("name", {
                required: t("stockManagement.form.validation.nameRequired"),
              })}
            />
            <FieldErrorMessage error={errors.name} />
        </Field>

      <Field label={t("stockManagement.stockItem.description")} htmlFor="description">
        <TextArea
          name="description"
          rows={5}
          register={register("description")}
        />
      </Field>
    </>
  )
}