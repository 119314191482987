import { observer } from 'mobx-react-lite';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import apiUrl from '../../../../data/api-url';
import { tokenFetcher } from '../../../../data/fetchers';
import { useStore } from '../../../../hooks/use-store';
import LoadingBlock from '../../../common/loading/LoadingBlock';

export default observer(function ServiceRequestInvoice() {
  const { t } = useTranslation();
  const { requestId } = useParams<{requestId: string}>();
  const { userStore } = useStore();

  const { data: payment } = useSWR(
    [apiUrl("payments", `/payment-accounts/${userStore.contextAccount?.id}/payments/service-request/${requestId}`), {}],
    tokenFetcher
  );

  if (!payment) return <LoadingBlock />;

  return (
    <div className="container mt-7 mx-auto">
      <h1>{t("accountBookings.serviceRequestInvoice.title")}</h1>
      <p className='text-lg mt-6'>{t("accountBookings.serviceRequestInvoice.leadText")}</p>
      <p className='mt-6'>
        <a href={payment.invoicePdfUrl} className='btn-brand' target='_blank' rel="noopener noreferrer">{t('accountBookings.serviceRequestInvoice.buttonText')}</a>
      </p>
    </div>
  )
});