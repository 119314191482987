import useSWR from "swr";
import { tokenFetcher } from "../../../data/fetchers";
import apiUrl from "../../../data/api-url";
import { useAuth } from "../../../hooks/use-auth";

export function ShowSiteBreadcrumb({ match }:any) {
  return "Site";

  // TODO: Figure out a way to get the site, conditionally using long polling as required.
  //const { siteId } = match.params;
  //
  //const { data: site } = useSWR(
  //  siteId ? [
  //    apiUrl("spaces", `/sites/${siteId}`), {}
  //  ] : null,
  //  tokenFetcher
  //);
  //
  //return site?.name || null;
}

export function ShowSpaceBreadcrumb({ match }:any) {
  return "Space";

  // TODO: Figure out a way to get the space, conditionally using long polling as required.

  //const { user } = useAuth();
  //const { siteId, spaceId } = match.params;
  //
  //const { data: space } = useSWR(
  //  user && siteId && spaceId
  //    ? [
  //      apiUrl("spaces", `/sites/${siteId}/spaces/${spaceId}`), {}
  //    ]
  //    : null,
  //  tokenFetcher
  //);
  //
  //return space?.name || null;
}

export function ShowServiceBreadcrumb({ match }:any) {
  const { user } = useAuth();
  const { siteId, serviceId } = match.params;

  const { data: service } = useSWR(
    user && siteId && serviceId
      ? [apiUrl("spaces", `/sites/${siteId}/services/${serviceId}`), user.token]
      : null,
    tokenFetcher
  );

  return service?.name || null;
}
