import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/date-transformations";
import PriceDisplay from "../common/PriceDisplay";

export default function OptionOverview(props) {
  const { t } = useTranslation();

  const spaceCount = props.option.spaces.length;
  const daySpan = props.option.spaces[0].availabilities.length;

  return (
    <div className="space-y-3 border-t border-b border-gray-200 py-4">
      <div className="flex justify-between max-w-lg">
        <div>
          <strong>Start date:</strong> <br />
          {formatDate(props.query.startDate)}
        </div>
        <div className="mr-10">
          <strong>End date:</strong> <br />
          {formatDate(props.query.endDate)}
        </div>
      </div>
      <div>
        <p>
          <strong>Booking overview</strong>
        </p>
        <p>
          {props.query.quantity.toLocaleString()} pallets for {daySpan} day
          {daySpan === 1 ? "" : "s"} across {spaceCount} site
          {spaceCount === 1 ? "" : "s"}
        </p>
      </div>
      <div className="pt-3">
        <PriceDisplay
          label={t("booking.optionOverview.price")}
          price={props.option.totalPrice}
          className="font-bold text-2xl text-brandSecondary-100"
        />
      </div>
    </div>
  );
}
