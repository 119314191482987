import { useTranslation } from "react-i18next";
import { BiBox } from "react-icons/bi";

interface SiteCapacityProps {
  capacity?: number;
  loading: boolean;
}

export default function SiteCapacity({ capacity, loading }:SiteCapacityProps) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <div className="mr-4">
        <BiBox className="text-4xl text-purple-400" />
      </div>
      <div>
        <small className="text-sm font-medium uppercase text-gray-400">
          {t("accountSites.metrics.siteCapacity")}
        </small>
        <br />
        <div className="text-3xl font-bold leading-6">
          {loading === true ? (
            <div className="h-8 w-6 bg-gray-200 animate-pulse rounded"></div>
          ) : capacity?.toLocaleString()
          }
        </div>
      </div>
    </div>
  );
}
