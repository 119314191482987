import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import apiUrl from "../../../data/api-url";
import { useAuth } from "../../../hooks/use-auth";
import LoadingBlock from "../../common/loading/LoadingBlock";
import OrganisationList from "./OrganisationList";

export default function UserOrganisations() {
  const { t } = useTranslation();
  const [organisations, setOrganisations] = useState();
  const { user } = useAuth();

  const location = useLocation<any>();

  const loadOrganisations = useCallback(async () => {
    let headers:any = {
      Authorization: `Bearer ${user.token}`,
    };

    if (location.state && location.state.newOrganisationId !== undefined) {
      headers["X-Long-Polling"] = location.state.newOrganisationId;
    }

    await fetch(apiUrl("accounts", "/users"), {
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setOrganisations(data.accounts);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, location.state]);

  useEffect(() => {
    loadOrganisations();
  }, [loadOrganisations]);

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h1>{t("accountOrganisations.listTitle")}</h1>
        <Link to="/account/organisations/new" className="btn-brand">
          {t("accountOrganisations.addOrganisation")}
        </Link>
      </div>

      <main className="space-y-8">
        {organisations ? (
          <OrganisationList organisations={organisations} />
        ) : (
          <LoadingBlock />
        )}
      </main>
    </div>
  );
}
