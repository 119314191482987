export function Form({
  className = "space-y-6",
  children,
  onSubmit = () => {},
}) {
  return (
    <form onSubmit={onSubmit} className={className}>
      {children}
    </form>
  );
}

export default Form;
