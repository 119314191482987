const featureFlags = [
  {
    name: 'test-feature',
    description: 'Feature used by automated tests. Do not remove.',
    active: true
  },
  {
    name: 'stock-management', 
    description: 'Functionality that allow management of stock items', 
    active: process.env.REACT_APP_STOCK_MANAGEMENT_ENABLED
  }
];

export default featureFlags;