import { Auth } from "aws-amplify";

export default async function submitForm(
  url,
  data,
  token,
  httpMethod = "POST",
  useLongPolling = false
) {
  const cognitoSession = await Auth.currentSession();
  const idToken = cognitoSession.idToken.jwtToken;

  var headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${idToken}`,
  };
  if (useLongPolling) headers["X-Long-Polling"] = new Date().toJSON();

  const res = await fetch(url, {
    method: httpMethod,
    headers: headers,
    body: JSON.stringify(data),
  });

  if (res.ok) {
    var resData = await res.text();
    if (resData !== "") {
      return { data: JSON.parse(resData) };
    } else {
      return { data: {} };
    }
  } else {
    const error = buildError(await res.json());
    throw error;
  }
}

export async function submitDelete(url, token, httpMethod = "DELETE") {
  const cognitoSession = await Auth.currentSession();
  const idToken = cognitoSession.idToken.jwtToken;

  const res = await fetch(url, {
    method: httpMethod,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  });

  if (res.ok) {
    var resData = await res.text();
    if (resData !== "") {
      return { data: JSON.parse(resData) };
    } else {
      return { data: {} };
    }
  } else {
    return { err: await res.json() };
  }
}

export async function submitAnonymousForm(
  url,
  data,
  httpMethod = "POST",
  useLongPolling = false
) {
  var headers = {
    "Content-Type": "application/json",
  };
  if (useLongPolling) headers["X-Long-Polling"] = new Date().toJSON();

  const res = await fetch(url, {
    method: httpMethod,
    headers: headers,
    body: JSON.stringify(data),
  });

  if (res.ok) {
    var resData = await res.text();
    if (resData !== "") {
      return { data: JSON.parse(resData) };
    } else {
      return { data: {} };
    }
  } else {
    const error = buildError(await res.json());
    throw error;
  }
}

function buildError(response) {
  const error = new Error();
  error.message = response.Description;
  //error.errorCode = response.errorCode;
  return error;
}
