import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import useSWR from "swr";

import apiUrl from "../../../../data/api-url";
import { Form } from "../../../common/forms";
import submitForm from "../../../../data/submit-form";
import { useAuth } from "../../../../hooks/use-auth";
import { tokenFetcher } from "../../../../data/fetchers";

import AvailabilityFormFields from "./FormFields";
import {
  toastError,
  toastLoading,
} from "../../../common/messages/toast-messages";
import Button, { LinkButton } from "../../../common/Button";
import { useState } from "react";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import InfoMessage from "../../../common/messages/InfoMessage";
import { useStore } from "../../../../hooks/use-store";
import { observer } from "mobx-react-lite";

export default observer(function NewAvailability() {
  const { t } = useTranslation();
  const formMethods = useForm({ mode: "onBlur" });
  const history = useHistory();
  const { user } = useAuth();
  const { userStore } = useStore();
  const { siteId, spaceId } = useParams();
  const [submitting, setSubmitting] = useState(false);

  // TODO: Load currency from account
  const currency = { symbol: "£", code: "GBP" }; //TODO: Get from account

  const { data: space } = useSWR(
    userStore.contextAccount
      ? [apiUrl("spaces", `/sites/${siteId}/spaces/${spaceId}`), user.token]
      : null,
    tokenFetcher
  );

  if (!space) {
    return <LoadingBlock />;
  }

  const submitAvailability = async (formData) => {
    setSubmitting(true);
    const toastId = toastLoading(t("toasts.availabilityCreating"));

    formData.currencyType = 1; // TODO: Fix currency types
    formData.publish = formData.publish === "publish" ? true : false;

    const { data, err: remoteErrors } = await submitForm(
      apiUrl(
        "spaces",
        `/sites/${siteId}/spaces/${spaceId}/availability-windows`
      ),
      formData,
      user.token
    );

    if (data) {
      history.replace(
        `/account/sites/${siteId}/spaces/${spaceId}?new_availability=1`,
        {
          availabilityLongPolling: new Date(data.timestamp),
          toastId: toastId,
          newAvailability: true,
        }
      );
    } else if (remoteErrors) {
      setSubmitting(false);
      toastError(t, remoteErrors.message, remoteErrors.errorCode);
      console.log(remoteErrors);
    }
  };

  return (
    <div className="space-y-16">
      <h1>{t("accountSites.availability.add")}</h1>

      <Form onSubmit={formMethods.handleSubmit(submitAvailability)}>
        <AvailabilityFormFields
          availability={{}}
          space={space}
          currency={currency}
          formMethods={formMethods}
        />

        {userStore.contextAccount?.paymentAccountConfirmed !== true && (
          <InfoMessage title="Complete Storekat onboarding">
            <p className="mb-2">
              {t("accountSites.availability.completeOnboardingMsg")}
              &nbsp;
              <a
                href={`/account/organisations/${userStore.contextAccount?.id}`}
                className="text-link"
              >
                {t("accountOrganisations.onboarding.completeOnboardingLink")}
              </a>
            </p>
          </InfoMessage>
        )}

        <div>
          <Button
            label={t("accountSites.availability.create")}
            loading={submitting}
          />
          <LinkButton
            label={t("common.cancel")}
            onClick={() => {
              history.push(
                `/account/sites/${siteId}/spaces/${spaceId}/availability-windows`
              );
            }}
          />
        </div>
      </Form>
    </div>
  );
});
