import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import useSWR from "swr";

import apiUrl from "../../../../data/api-url";
import submitForm from "../../../../data/submit-form";
import { useAuth } from "../../../../hooks/use-auth";
import { tokenFetcher } from "../../../../data/fetchers";
import { Form } from "../../../common/forms";
import SpaceFormFields from "./FormFields";
import { toastError } from "../../../common/messages/toast-messages";
import { useState } from "react";
import Button, { LinkButton } from "../../../common/Button";

export default function EditSpace() {
  const { t } = useTranslation();
  const formMethods = useForm({ mode: "onBlur" });
  const history = useHistory();
  const { user } = useAuth();
  const { siteId, spaceId } = useParams();
  const [submitting, setSubmitting] = useState(false);

  const { data: space } = useSWR(
    user
      ? [apiUrl("spaces", `/sites/${siteId}/spaces/${spaceId}`), user.token]
      : null,
    tokenFetcher
  );

  const submitSpace = async (formData) => {
    setSubmitting(true);
    // Default value
    formData.minimumBookingPeriod = 2;
    formData.type = "Storage";

    const { data, err: remoteErrors } = await submitForm(
      apiUrl("spaces", `/sites/${siteId}/spaces/${spaceId}`),
      formData,
      user.token,
      "PUT"
    );

    if (data) {
      toast.success(t("toasts.spaceUpdated"));
      history.push(`/account/sites/${siteId}/spaces/${spaceId}`, {
        longPolling: new Date(),
      });
    } else if (remoteErrors) {
      setSubmitting(false);
      toastError(t, remoteErrors.message, remoteErrors.errorCode);
      console.log(remoteErrors);
    }
  };

  return (
    <div className="space-y-8">
      <h1>{t("accountSites.space.edit")}</h1>

      {space && (
        <Form onSubmit={formMethods.handleSubmit(submitSpace)}>
          <SpaceFormFields formMethods={formMethods} space={space} />

          <div>
            <Button
              label={t("accountSites.space.update")}
              loading={submitting}
            />
            <LinkButton
              label={t("common.cancel")}
              onClick={() => {
                history.push(`/account/sites/${siteId}/spaces/${spaceId}`);
              }}
            />
          </div>
        </Form>
      )}
    </div>
  );
}
