import { useTranslation } from "react-i18next";
import { BiSend } from "react-icons/bi";

interface StockMovementsProps {
  count?: number;
  loading: boolean;
}

export default function StockMovements({ count, loading }:StockMovementsProps) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <div className="mr-4">
        <BiSend className="text-4xl text-purple-400" />
      </div>
      <div>
        <small className="text-sm font-medium uppercase text-gray-400">
          {t("stockManagement.metrics.stockMovements")} / {t("stockManagement.metrics.stockMovementsUnit")}
        </small>
        <br />
        <div className="text-3xl font-bold leading-6">
          {(count === undefined || loading === true) && (
            <div className="h-8 w-6 bg-gray-200 animate-pulse rounded"></div>
          )}
          {count?.toLocaleString()}
        </div>
      </div>
    </div>
  );
}
