import { BiCheckCircle } from "react-icons/bi";

interface FieldSuccessMessageProps {
  message?: string
}

export default function FieldSuccessMessage({ message }:FieldSuccessMessageProps) {
  return message ? (
    <p className="text-green-600 text-base">
      <BiCheckCircle className="inline text-2xl mr-1" />
      <span className="inline-block align-middle">{message}</span>
    </p>
  ) : null;
}
