import { usePagination } from "./PaginationContainer";

export default function PaginationControls() {
  const { currentPage, prevPage, nextPage, setPage, getPageCount } =
    usePagination();

  if (getPageCount() === 1) return null;

  return (
    <div className="flex items-center space-x-1">
      {currentPage !== 1 && (
        <button
          onClick={() => prevPage()}
          type="button"
          className="flex items-center px-4 py-2 text-gray-700 bg-gray-300 rounded-md"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M11 17l-5-5m0 0l5-5m-5 5h12"
            />
          </svg>
        </button>
      )}

      {[...Array(getPageCount())].map((x, i) => (
        <button
          key={i}
          onClick={() => setPage(i + 1)}
          type="button"
          className={`px-4 py-2 text-gray-700 ${
            currentPage === i + 1 ? "bg-gray-300" : "bg-gray-200"
          } rounded-md hover:bg-brand`}
        >
          {i + 1}
        </button>
      ))}

      {currentPage !== getPageCount() && (
        <button
          type="button"
          onClick={() => nextPage()}
          className="px-4 py-2 text-gray-700 bg-gray-300 rounded-md hover:bg-brand"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 7l5 5m0 0l-5 5m5-5H6"
            />
          </svg>
        </button>
      )}
    </div>
  );
}
