import { BiErrorAlt } from "react-icons/bi";
import { MessageProps } from './message';

export default function ErrorMessage({ title, message, children }:MessageProps) {
  return (
    <div
      className="bg-red-100 border-t-4 border-l border-r border-b border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md bg-opacity-40 border-opacity-40 text-opacity-60"
      role="alert"
    >
      <div className="flex">
        <div className="py-1 mr-2">
          <BiErrorAlt className="text-xl" />
        </div>
        <div>
          {title && <p className="font-bold">{title}</p>}
          {message && <p>{message}</p>}
          {children}
        </div>
      </div>
    </div>
  );
}
