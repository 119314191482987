import React from 'react'
import { useTranslation } from 'react-i18next'
import apiUrl from '../../../../data/api-url';

export default function SampleDataFile() {
  const { t } = useTranslation();
  return (
    <div className='space-y-6'>
      <h2>{t("stockManagement.import.sampleFile")}</h2>
      <p>{t("stockManagement.import.sampleFileLead")}</p>
      <pre className='border-t-2 border-b-2 border-t-gray-300 border-b-gray-300 border-dashed px-2 py-2 bg-gray-50'>
        sku,gtin,upc,name,description
      </pre>
      <p>
        <a href={apiUrl('stock', '/stock/import/sample')} target="_blank" rel="noreferrer">{t("stockManagement.import.sampleFileDownload")}</a>
      </p>
    </div>
  )
}