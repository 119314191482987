import { observer } from 'mobx-react-lite';
import React, { useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import { HiSwitchHorizontal } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { Account } from '../../../app/model/account';
import { useAuth } from '../../../hooks/use-auth';
import { useStore } from '../../../hooks/use-store';

export default observer(function ContextSwitcher() {

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const { user } = useAuth();
  const { userStore } = useStore();
  const { loading, setContextAccount } = userStore;
  const history = useHistory();

  const selectContext = (account: Account) => {
    
    setContextAccount(account);
    setDropdownVisible(false);

    history.replace(`/account/organisations/${account.id}?context_switched=1`);
  }

  if (loading || user === undefined || userStore.user === undefined || userStore.user.accounts.length <= 1)
    return null;

  return (
    <div>
      <div className='text-xs font-normal pb-1'><HiSwitchHorizontal className='inline text-lg'/> Switch accounts</div>
      <div className='border-gray-700 border-2 rounded-md bg-white w-full md:w-[300px] py-1 relative'>
        <div className='flex justify-between w-full cursor-pointer' onClick={() => setDropdownVisible(!dropdownVisible)}>
          <div className='px-2 pt-[4px] text-sm font-normal'>{userStore.contextAccount?.name}</div>
          <div className='bg-brand rounded-full mx-2 my-[2px]'>
            <BiChevronDown className='text-2xl' />
          </div>
        </div>
        <div className={`absolute top-[37px] border border-gray-600 rounded w-full bg-white text-sm font-normal ${dropdownVisible ? '' : 'hidden' } `}>
          <ul>
            {userStore.user.accounts.map((account:any) => (
              <li 
              key={account.id} 
              className='p-2 w-full cursor-pointer'
              onClick={() => selectContext(account)}
              >
                  {account.name}
                </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
});