import i18n from "../../../i18n";

import ListOrganisations from "./UserOrganisations";
import NewOrganisation from "./new/NewOrganisation";
import NewPayment from "./new/NewOrganisationPayment";
import { ShowOrganisationBreadcrumb } from "./Breadcrumbs";
import OrganisationDetailsRouter from "./OrganisationDetailsRouter";

const organisationRoutes = [
  {
    path: "/account/organisations",
    component: ListOrganisations,
    exact: true,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.organisations"),
  },
  {
    path: "/account/organisations/new/payment",
    component: NewPayment,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.payment"),
  },
  {
    path: "/account/organisations/new",
    component: NewOrganisation,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.new"),
  },
  {
    path: "/account/organisations/:organisationId",
    component: OrganisationDetailsRouter,
    breadcrumb: ShowOrganisationBreadcrumb,
  },
];

export default organisationRoutes;
