export type ServiceType = 'accounts' | 'bookings' | 'spaces' | 'payments' | 'stock';

export default function apiUrl(service:ServiceType, path:string) {
  if (service.toLowerCase() === "accounts") {
    return `${process.env.REACT_APP_ACCOUNTS_API_ENDPOINT}/api/v1${path}`;
  } else if (service.toLowerCase() === "bookings") {
    return `${process.env.REACT_APP_BOOKINGS_API_ENDPOINT}/api/v1${path}`;
  } else if (service.toLowerCase() === "spaces") {
    return `${process.env.REACT_APP_SPACES_API_ENDPOINT}/api/v1${path}`;
  } else if (service.toLowerCase() === "payments") {
    return `${process.env.REACT_APP_PAYMENTS_API_ENDPOINT}/api/v1${path}`;
  } else if (service.toLowerCase() === "stock") {
    return `${process.env.REACT_APP_STOCK_API_ENDPOINT}/api/v1${path}`;
  } else {
    return "";
  }
}

export function localApiUrl(path:string) {
  var url = "https://localhost:5001/api/v1";
  return `${url}${path}`;
}
