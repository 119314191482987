import Amplify from "aws-amplify";

export default function configureAmplify() {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: false,
      cookieStorage: {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        secure: process.env.REACT_APP_COOKIE_DOMAIN !== "localhost",
        expires: 14,
        path: "/",
        sameSite: "lax",
      },
    },
  });
}
