import React from 'react'
import { useFeatureFlags } from '../../../hooks/use-feature-flags'

interface FeatureProps {
  name: string,
  children?: React.ReactNode
};

export default function Feature(props:FeatureProps) {

  const { isActive } = useFeatureFlags();

  // Determine if the feature is active
  const active = isActive(props.name);

  // If the flag is not active, or there are no children to render, then return null
  if (!active || props.children === undefined)
    return null;

  return (
    <>
      {props.children}
    </>
  );
}