import { makeAutoObservable, runInAction } from 'mobx';
import apiUrl from '../../data/api-url';
import requests from '../../data/requests';
import { Account, User } from '../model/account';

export default class UserStore {
	user: User | undefined = undefined;
  loading: boolean = false;
	contextAccount: Account | undefined = undefined;

	constructor() {
		makeAutoObservable(this);
	}
  
  getUser = async () => {
		try {
			runInAction(() => this.loading = true);

			const user = await requests.get<User>(apiUrl("accounts", '/users'));

			const savedContextId = window.localStorage.getItem('context_account_id');

			runInAction(() => 
			{
				this.user = user;

				if (savedContextId !== null) {
					// Get the context account based on the id for the locally stored context account id
					let accountIndex = -1;
					user.accounts.forEach((account, index) => {
						if (account.id === savedContextId)
							accountIndex = index;
					});

					// If the index is not -1, then use that as the context account, otherwise default to zero.
					this.contextAccount = user.accounts[(accountIndex !== -1 ? accountIndex : 0)];

				} else {
					// Default context account to first account
					this.contextAccount = user.accounts[0];
				}
        this.loading = false;
				return user;
			});

		} catch (error) {
			console.log('error');
			console.log(error);
		}
	}

	setContextAccount = (account:Account) => {
		window.localStorage.setItem('context_account_id', account.id);
		this.contextAccount = account;
	}

	setPaymentAccountConfirmed = () => {
		if (this.contextAccount !== undefined) {
			this.contextAccount!.paymentAccountConfirmed = true;
		}
	}

}