import RequestSubmitted from './RequestSubmitted';

import SupportForm from './SupportForm';

const supportRoutes = [
  {
    exact: true,
    path: "/account/support",
    component: SupportForm
  },
  {
    path: "/account/support/submitted",
    component: RequestSubmitted
  },
];

export default supportRoutes;
