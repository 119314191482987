import { useTranslation } from "react-i18next";
import PriceDisplay from "../../common/PriceDisplay";

export default function PlanSelection(props) {
  const { t } = useTranslation();
  const { selectPlan, defaultPlanId = "", selectedPlanId = "", plans } = props;

  const getPlanMaxUsers = (maxUsers) => {
    if (maxUsers === 1) {
      return `${maxUsers} ${t("accountPlans.singleAccountUser")}`;
    } else {
      return `${t("accountPlans.multipleAccountUsersPrefix")} ${maxUsers} ${t(
        "accountPlans.multipleAccountUsersSuffix"
      )}`;
    }
  };

  if (!plans) {
    return <div>{t("toasts.loading")}</div>;
  }

  return (
    <div className="grid lg:grid-cols-2 gap-6">
      {plans.map((plan) => (
        <div key={plan.id}>
          <div
            className={
              (selectedPlanId === plan.id ? "bg-yellow-100" : "bg-white") +
              ` h-full p-8 shadow rounded border-0`
            }
          >
            <h4 className="mb-2 text-2xl font-bold font-heading">
              {plan.name}
            </h4>{" "}
            <PriceDisplay
              suffix={t("accountPlans.priceSuffix")}
              price={plan.pricePerMonth}
              className="text-4xl font-bold"
            />
            <p className="mt-3 mb-6 text-gray-500 leading-loose">
              {plan.description}
            </p>
            <ul className="mb-6 text-gray-500">
              <li className="mb-2 flex">
                <svg
                  className="mr-2 w-5 h-5 text-brand"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>{getPlanMaxUsers(plan.maxUsers)}</span>
              </li>
              {plan.features.map((feature, index) => (
                <li className="mb-2 flex" key={index}>
                  <svg
                    className="mr-2 w-5 h-5 text-brand"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio h-6 w-6"
                name="plan-selection"
                value={plan.id}
                defaultChecked={defaultPlanId === plan.id}
                onChange={(e) => {
                  const accountPlan = plans.filter((p) => p.id === plan.id)[0];
                  selectPlan(accountPlan);
                }}
              />
              <span className="ml-4 text-lg">{plan.name}</span>
            </label>
          </div>
        </div>
      ))}
    </div>
  );
}
