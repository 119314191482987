import toast from "react-hot-toast";
import i18next from "i18next";

export function toastError(t:any, message:any, errorCode = undefined) {
  var toastMessage = message;

  if (errorCode !== undefined)
    toastMessage = i18next.exists(`remoteErrors.${errorCode}`)
      ? t(`remoteErrors.${errorCode}`)
      : message;

  toast.error(toastMessage);
}

export function toastLoading(message:any, id = undefined) {
  return toast.loading(message, {
    id: id,
    className: "loading-toast",
    iconTheme: {
      primary: "#FECD0B",
      secondary: "#E5E7EB",
    },
  });
}

export function toastSuccess(message:any, id:string | undefined = undefined) {
  return toast.success(message, {
    id: id,
    className: "success-toast",
    iconTheme: {
      primary: "#16a34a",
      secondary: "#FFFAEE",
    },
  });
}
