import { useTranslation } from "react-i18next";
import { BiMessageDetail } from "react-icons/bi";

interface OpenServiceRequestsProps {
  openRequests: number,
  loading: boolean
}

export default function OpenServiceRequests({ openRequests, loading }:OpenServiceRequestsProps) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <div className="mr-4 align-middle">
        <BiMessageDetail className="text-4xl text-blue-500 align-middle" />
      </div>
      <div>
        <small className="text-sm font-medium uppercase text-gray-400">
          {t("accountSites.metrics.openServiceRequests")}
        </small>
        <br />
        <div className="text-3xl font-bold leading-6">
          {loading ? (
            <div className="h-8 w-6 bg-gray-200 animate-pulse rounded"></div>
          ) : openRequests?.toLocaleString()}
        </div>
      </div>
    </div>
  );
}
