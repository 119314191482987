import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LoadingBlock from '../../../common/loading/LoadingBlock';
import StripePaymentMethods from '../../../payments/StripePaymentMethods';
import { useHistory, useParams } from 'react-router-dom';
import requests from '../../../../data/requests';
import { InvoicePaymentResponse } from '../../../../app/model/account';
import InvoicePaymentOverview from './InvoicePaymentOverview';
import apiUrl from '../../../../data/api-url';

export default function InvoicePayment() {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [ paymentDetails, setPaymentDetails ] = useState<InvoicePaymentResponse | undefined>(undefined);

  const { organisationId, invoiceId } = useParams<{organisationId: string, invoiceId: string}>();

  let returnUrl = `/account/organisations/${organisationId}/invoices/${invoiceId}/payment-success`;

  useEffect(() => {
    const loadInvoicePayment = () => {
      requests.get<InvoicePaymentResponse>(apiUrl('payments', `/payment-accounts/${organisationId}/invoices/${invoiceId}/payment`))
        .then((data) => {
          setPaymentDetails(data);
        })
        .finally(() => setLoading(false));
    }
    
    loadInvoicePayment();
  
  }, [organisationId, invoiceId]);
  

  const successCallback = () => {
    history.replace(returnUrl);
  };


  return (
    <div className="space-y-6">
      <h2>{t("accountOrganisations.invoices.payment.title")}</h2>
      <p>{t("accountOrganisations.invoices.payment.leadText")}</p>

      {loading && <LoadingBlock />}
      {!loading && paymentDetails !== undefined && (
        
        <div className="grid md:grid-cols-2 md:gap-8 xl:gap-16 space-y-8 md:space-y-0">
          <InvoicePaymentOverview paymentDetails={paymentDetails} /> 
          <StripePaymentMethods
            returnUrl={`${returnUrl}?invoice_no=${paymentDetails.invoiceNumber}`}
            requestId={paymentDetails.paymentRequestId}
            successCallback={successCallback}
          />
        </div>
      )}

    </div>
  )
}