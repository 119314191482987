import { createContext, useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";

import apiUrl from "../data/api-url";
import { submitAnonymousForm } from "../data/submit-form";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

async function currentAuthenticatedUser() {
  //let cognitoUser;
  //
  //try {
  //  cognitoUser = await Auth.currentAuthenticatedUser();
  //} catch (e) {
  //  return;
  //}

  try {
    const token = await getUserToken();
    const user = await fetchStorekatUser(token);

    return user;
  } catch (error) {
    console.log("Error connecting to storekat API");
  }
}

async function getUserToken() {
  const cognitoSession = await Auth.currentSession();
  return cognitoSession.idToken.jwtToken;
}

async function fetchStorekatUser(jwtToken) {
  const res = await fetch(apiUrl("accounts", "/users"), {
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  });

  if (res.ok) {
    const storekatUser = await res.json();
    return {
      ...storekatUser,
      currentAccount: storekatUser.accounts[0],
      // This may not be the best option, but this should auto-refresh token
      token: jwtToken,
    };
  } else {
    throw new Error("Something went wrong");
  }
}

function useProvideAuth() {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  const signIn = async (email, password) => {
    try {
      await Auth.signIn({ username: email, password });
      const token = await getUserToken();
      const user = await fetchStorekatUser(token);

      setUser(user);
      return { user };
    } catch (e) {
      throw e;
    }
  };

  const reloadUser = async () => {
    const token = await getUserToken();
    await fetchStorekatUser(token);

    // Cannot call setUser(user) here, as it continually reloads the request
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setUser(null);
    } catch (error) {
      console.log("error with signout", error);
    }
  };

  const signUp = async (data) => {
    const {
      email,
      password,
      firstName,
      lastName,
      displayName,
      accountPlanId,
      agreedTermsVersion,
      companyName,
    } = data;

    const postData = {
      givenName: firstName,
      familyName: lastName,
      email: email,
      password: password,
      displayName: displayName,
      accountPlanId: accountPlanId,
      agreedTermsVersion: agreedTermsVersion,
      companyName: companyName,
    };

    return await submitAnonymousForm(
      apiUrl("accounts", "/users"),
      postData
    ).then((data) => {
      return data;
    });
  };

  const confirmUser = async (email, confirmCode) => {
    await Auth.confirmSignUp(email, confirmCode)
      .then((data) => {
        return true;
      })
      .catch((error) => {
        throw error;
      });
  };

  const changePassword = async (data) => {
    const { existingPassword, newPassword, confirmPassword } = data;

    if (newPassword !== confirmPassword) {
      console.log("Passwords don’t match");
      return;
    }

    await Auth.currentAuthenticatedUser().then(async (user) => {
      await Auth.changePassword(user, existingPassword, newPassword).catch(
        (error) => {
          throw error;
        }
      );
    });
  };

  const resendConfirmCode = async (email) => {
    try {
      await Auth.resendSignUp(email);
      return true;
    } catch (err) {
      console.log("error resending code: ", err);
    }
  };

  const resetPassword = async (email) => {
    await Auth.forgotPassword(email).catch((error) => {
      throw error;
    });
  };

  const resetNewPassword = async (data) => {
    await Auth.forgotPasswordSubmit(
      data.email,
      data.confirmCode,
      data.newPassword
    ).catch((error) => {
      throw error;
    });
  };

  const setCurrentAccount = (account) => {
    const updatedUser = user;
    updatedUser.currentAccount = account;
    setUser(user);
  };

  useEffect(() => {
    currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
        setIsLoading(false);
        return user;
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  return {
    isLoading,
    signIn,
    signOut,
    signUp,
    confirmUser,
    changePassword,
    resendConfirmCode,
    resetPassword,
    resetNewPassword,
    getUserToken,
    reloadUser,
    setCurrentAccount,
    user,
  };
}
