import { observer } from 'mobx-react-lite';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import apiUrl from '../../../data/api-url';
import { tokenFetcher } from '../../../data/fetchers';
import { useStore } from '../../../hooks/use-store';
import LoadingBlock from '../../common/loading/LoadingBlock';

export default observer(function BookingInvoice() {
  const { t } = useTranslation();
  const { bookingId } = useParams<{bookingId: string}>();
  const { userStore } = useStore();

  const { data: payment } = useSWR(
    [apiUrl("payments", `/payment-accounts/${userStore.contextAccount?.id}/payments/booking/${bookingId}`), {}],
    tokenFetcher
  );

  if (!payment) return <LoadingBlock />;

  return (
    <div className="container mt-7 mx-auto">
      <h1>{t("accountBookings.bookingInvoice.title")}</h1>
      <p className='text-lg mt-6'>{t("accountBookings.bookingInvoice.leadText")}</p>
      <p className='mt-6'>
        <a href={payment.invoicePdfUrl} className='btn-brand' target='_blank' rel="noopener noreferrer">{t('accountBookings.bookingInvoice.buttonText')}</a>
      </p>
    </div>
  )
});