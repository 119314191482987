import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import OptionOverview from "../OptionOverview";
import ReceiptHandlingDispatch from "../ReceiptHandlingDispatch";
import { Form } from "../../common/forms";
import Button, { LinkButton } from "../../common/Button";

export default function BookingStepOne() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const formMethods = useForm({ mode: "onBlur" });
  const history = useHistory();

  const location = useLocation();

  // If there's no state, redirect to the home page
  if (location.state === undefined) {
    history.replace("/");
    return null;
  }
  const { selectedOption, query, bookingId } = location.state;

  const nextClick = async (formData) => {
    setLoading(true);

    // Build the reservation object
    var reservation = buildReservation(
      selectedOption.spaces,
      formData.initialReceiptQty,
      formData.estimatedMovements,
      formData.estimatedThroughput
    );

    history.push({
      pathname: "/booking/legal",
      state: {
        selectedOption: selectedOption,
        reservation: reservation,
        query: query,
        bookingId: bookingId,
      },
    });
    setLoading(false);
  };

  const buildReservation = (
    spaces,
    initialReceiptQty,
    estRhdMovements = 0,
    estRhdThroughput = 0
  ) => {
    let reservation = {
      spaces: [],
      startDate: query.startDate,
      endDate: query.endDate,
      totalQuantity: selectedOption.totalQuantity,
      receiptHandling: {
        initialReceiptQty: initialReceiptQty,
        estimatedMovements: estRhdMovements,
        estimatedThroughput: estRhdThroughput,
      },
    };

    for (let i = 0; i < spaces.length; i++) {
      reservation.spaces.push({
        id: spaces[i].id,
        quantity: spaces[i].usableQuantity,
      });
    }

    return reservation;
  };

  return (
    <div className="container mx-auto mt-7 space-y-8">
      <h1>{t("booking.stepOne.title")}</h1>

      <OptionOverview option={selectedOption} query={query} />

      <Form
        onSubmit={formMethods.handleSubmit(nextClick)}
        className="space-y-2"
      >
        <ReceiptHandlingDispatch
          formMethods={formMethods}
          bookingQty={selectedOption.spaces[0].usableQuantity}
        />

        <div className="space-y-5">
          <Button label={t("common.next")} loading={loading} />
          <LinkButton
            label={t("common.previous")}
            onClick={() => history.goBack()}
          />
        </div>
      </Form>
    </div>
  );
}
