import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import { formatDate } from "../../../../utils/date-transformations";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import Table from "../../../common/Table";
import InfoMessage from "../../../common/messages/InfoMessage";
import useSWR from "swr";
import apiUrl from "../../../../data/api-url";
import { tokenFetcher } from "../../../../data/fetchers";
import { titleCase } from "../../../../utils/text-transformations";
import { toastSuccess } from "../../../common/messages/toast-messages";
import { ServiceRequest } from '../../../../app/model/service-requests';

interface RequestStateDetails {
  bookingId: string;
  buyerAccountId: string | undefined;
  serviceId: string;
  siteId: string;
  requestDetails: string | undefined;
  requestId: any;
  timestamp: any;
};

export default function ServiceRequestList() {
  const { t } = useTranslation();
  const { bookingId } = useParams<{bookingId: string}>();
  const location = useLocation<{request:RequestStateDetails, toastId?: string}>();

  const longPolling =
    location?.state?.request !== undefined
      ? new Date(location?.state?.request?.timestamp)
      : false;

  // TODO: Get the account from the current context
  const { data: serviceRequests } = useSWR<ServiceRequest[]>(
    [
      apiUrl("bookings", `/bookings/${bookingId}/service-requests/booking`),
      { longPolling: longPolling },
    ],
    tokenFetcher
  );

  useEffect(() => {
    if (
      serviceRequests &&
      location?.state?.toastId &&
      location?.state?.request
    ) {
      toastSuccess(t("toasts.serviceRequestCreated"), location.state.toastId);
    }
  }, [serviceRequests, location?.state?.toastId, location?.state?.request, t]);

  const itemsRequiringAction = useMemo(
    () =>
      serviceRequests
        ? serviceRequests.filter((i) => i.status.toLowerCase() === "quoted")
        : [],
    [serviceRequests]
  );

  const data = useMemo(
    () =>
      serviceRequests
        ? serviceRequests.map((serviceRequest) => ({
            reference: serviceRequest,
            site: serviceRequest.siteName,
            service: serviceRequest.serviceName,
            created: formatDate(serviceRequest.created),
            status: titleCase(serviceRequest.status),
            requiresAction: itemsRequiringAction
              ? itemsRequiringAction.some((i) => i.id === serviceRequest.id)
              : false,
            action: serviceRequest,
          }))
        : [],
    [serviceRequests, itemsRequiringAction]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("accountBookings.serviceRequests.reference"),
        accessor: "reference",
        Cell: ({ value }:any) => (
          <Link
            to={`/account/bookings/${bookingId}/service-requests/${value.id}`}
            className="text-link"
          >
            {value.reference}
          </Link>
        ),
      },
      {
        Header: t("accountBookings.serviceRequests.site"),
        accessor: "site",
      },
      {
        Header: t("accountBookings.serviceRequests.service"),
        accessor: "service",
      },
      {
        Header: t("accountBookings.serviceRequests.created"),
        accessor: "created",
      },
      {
        Header: t("accountBookings.serviceRequests.status"),
        accessor: "status",
      },
      {
        Header: t("accountBookings.serviceRequests.requiresAction"),
        accessor: "requiresAction",
        Cell: ({ value }:any) => (
          <span className={value ? "text-blue-500 font-bold" : ""}>
            {value === true ? "Yes" : "No"}
          </span>
        ),
      },
    ],
    [t, bookingId]
  );

  return (
    <>
      {serviceRequests && serviceRequests.length > 0 && (
        <>
          {itemsRequiringAction && itemsRequiringAction.length > 0 && (
            <InfoMessage
              message={t(
                "accountBookings.serviceRequests.itemsRequireActionMsg"
              )}
            />
          )}
          <Table columns={columns} data={data} />
        </>
      )}
      {serviceRequests && serviceRequests.length === 0 && (
        <div>{t("accountBookings.serviceRequests.noRequests")}.</div>
      )}
      {!serviceRequests && <LoadingBlock />}
    </>
  );
}
