interface PriceDisplayProps {
  label?: string,
  price: number,
  className?: string,
  currencySign?: string,
  suffix?: string,
};

export default function PriceDisplay({
  label = "",
  price = 0,
  className = "",
  currencySign = "£",
  suffix = "",
}:PriceDisplayProps) {

  function getFormattedPrice(price: number) {
    if (price === undefined || price === null) return "na";

    return (Math.round((price + Number.EPSILON) * 100) / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
  }

  return (
    <span className={className}>
      {label} {currencySign}
      {getFormattedPrice(price)}
      {suffix}
    </span>
  );
}

export function GetCurrencySign(currencyType: string) {
  if (currencyType === "GBP") {
    return "£";
  } else {
    return "$";
  }
}
