import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { formatDate } from "../../../../../utils/date-transformations";
import LoadingBlock from "../../../../common/loading/LoadingBlock";
import Table from "../../../../common/Table";
import InfoMessage from "../../../../common/messages/InfoMessage";
import { titleCase } from "../../../../../utils/text-transformations";
import { ServiceRequestListItem } from '../../../../../app/model/service-requests';

interface ServiceRequestListProps {
  serviceRequests: ServiceRequestListItem[] | undefined
}

export default function ServiceRequestList({ serviceRequests }:ServiceRequestListProps) {
  const { t } = useTranslation();
  const { siteId } = useParams<{siteId: string}>();

  const itemsRequiringAction = useMemo(
    () =>
      serviceRequests
        ? serviceRequests.filter(
            (i) =>
              i.status.toLowerCase() === "new" ||
              i.status.toLowerCase() === "approved"
          )
        : [],
    [serviceRequests]
  );

  const data = useMemo(
    () =>
      serviceRequests
        ? serviceRequests.map((serviceRequest) => ({
            reference: serviceRequest,
            site: serviceRequest.siteName,
            service: serviceRequest.serviceName,
            created: formatDate(serviceRequest.created),
            status: titleCase(serviceRequest.status),
            requiresAction: itemsRequiringAction
              ? itemsRequiringAction.some((i) => i.id === serviceRequest.id)
              : false,
            action: serviceRequest,
          }))
        : [],
    [serviceRequests, itemsRequiringAction]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("accountBookings.serviceRequests.reference"),
        accessor: "reference",
        Cell: ({ value }:any) => (
          <Link
            to={`/account/sites/${siteId}/service-requests/${value.id}`}
            className="text-link"
          >
            {value.reference}
          </Link>
        ),
      },
      {
        Header: t("accountBookings.serviceRequests.site"),
        accessor: "site",
      },
      {
        Header: t("accountBookings.serviceRequests.service"),
        accessor: "service",
      },
      {
        Header: t("accountBookings.serviceRequests.created"),
        accessor: "created",
      },
      {
        Header: t("accountBookings.serviceRequests.status"),
        accessor: "status",
      },
      {
        Header: t("accountBookings.serviceRequests.requiresAction"),
        accessor: "requiresAction",
        Cell: ({ value }:any) => (
          <span className={value ? "text-blue-500 font-bold" : ""}>
            {value === true ? "Yes" : "No"}
          </span>
        ),
      },
    ],
    [t, siteId]
  );

  return (
    <>
      {serviceRequests && serviceRequests.length > 0 && (
        <>
          {itemsRequiringAction && itemsRequiringAction.length > 0 && (
            <InfoMessage
              message={t(
                "accountBookings.serviceRequests.itemsRequireActionMsg"
              )}
            />
          )}
          <Table columns={columns} data={data} />
        </>
      )}
      {serviceRequests && serviceRequests.length === 0 && (
        <div>{t("accountBookings.serviceRequests.noRequests")}.</div>
      )}
      {!serviceRequests && <LoadingBlock />}
    </>
  );
}
