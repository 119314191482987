import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import routes from "./routes";
import SettingsNavigation from "./SettingsNavigation";

export default function SettingsRouter() {
  const { t } = useTranslation();

  return (
    <div className="mt-7">
      <h1>{t("accountSettings.accountSettings")}</h1>

      <div className="grid md:grid-cols-12 gap-10 pt-10">
        <SettingsNavigation />
        <div className="md:col-span-8 lg:col-span-9">
          <Switch>
            {routes.map((route) => (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            ))}
          </Switch>
        </div>
      </div>
    </div>
  );
}
