import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function SettingsNavigation() {
  const { t } = useTranslation();

  return (
    <div className="md:col-span-4 lg:col-span-3">
      <ul>
        <li className="py-2 border-b-2 border-grey-500">
          <Link to="/account/settings/profile">
            {t("accountSettings.profile")}
          </Link>
        </li>
        <li className="py-2 border-b-2 border-grey-500">
          <Link to="/account/settings/account-security">
            {t("accountSettings.accountSecurity")}
          </Link>
        </li>
      </ul>
    </div>
  );
}
