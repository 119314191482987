import ChangePassword from "./ChangePassword";
import ProfileSettings from "./ProfileSettings";

const settingsRoutes = [
  {
    path: "/account/settings/profile",
    component: ProfileSettings,
    sideNav: true,
  },
  {
    path: "/account/settings/account-security",
    component: ChangePassword,
    sideNav: true,
  },
  {
    path: "/account/settings",
    component: ProfileSettings,
    exact: true,
    sideNav: true,
  },
];

export default settingsRoutes;
