import { useTranslation } from "react-i18next";
import { BiMessageCheck } from "react-icons/bi";

interface ServiceRequestsFulfilledPercentProps {
  finalised: number;
  total: number;
  percent: number;
  loading: boolean
}

export default function ServiceRequestsFulfilledPercent({ percent, finalised, total, loading }:ServiceRequestsFulfilledPercentProps) {
  const { t } = useTranslation();

  const circumference = (2 * 22 / 7 * 20);

  return (
    <div className="flex items-center justify-start relative">
      <div className="mr-4 top-[4px] align-top absolute">
        <BiMessageCheck className="text-4xl text-brand align-middle" />
      </div>
      <div className='ml-14'>
        <small className="text-sm font-medium uppercase text-gray-400">
          {t("accountSites.metrics.serviceRequestsFulfilledPercent")}
        </small>
        <br />
        <div className="text-3xl font-bold leading-6">
          {loading ? (
            <div className='flex'>
              <div className="h-12 w-12 bg-gray-200 animate-pulse rounded-full"></div>
              <div className="h-6 w-16 mt-[12px] ml-2 bg-gray-200 animate-pulse rounded"></div>
            </div>
          ) : (
            <div className="flex relative">
              <svg className="transform -rotate-90 w-12 h-12">
                <circle cx="24" cy="24" r="20" stroke="currentColor" strokeWidth="5" fill="transparent"
                  className="text-gray-200" />

                <circle cx="24" cy="24" r="20" stroke="currentColor" strokeWidth="5" fill="transparent"
                  strokeDasharray={circumference}
                  strokeDashoffset={circumference - percent / 100 * circumference}
                  className="text-brand " />
              </svg>
              <div className='left-0 w-[48px] text-center absolute pt-[15px] text-xs font-bold text-gray-500'>{percent}%</div>
              <span className='ml-2 font-bold pt-[10px]'>{finalised} / {total}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
