import { Route, Switch } from "react-router-dom";

import bookingRoutes from "./bookingRoutes";

export default function BookingRouter() {
  return (
    <>
      <div className="container mx-auto">
        <Switch>
          {bookingRoutes.map((route) => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </div>
    </>
  );
}
