import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import useSWR from "swr";

import apiUrl from "../../data/api-url";
import { submitAnonymousForm } from "../../data/submit-form";
import { toastError } from "../common/messages/toast-messages";
import AddressLookup from "../common/address-lookup/AddressLookup";

import {
  DateInput,
  Form,
  Field,
  FieldErrorMessage,
  NumberInput,
  SelectInput,
} from "../common/forms";
import Button from "../common/Button";

import getSearchOptions from "./search-options";
import SearchResultsList from "./SearchResultsList";
import defaultFetcher from "../../data/fetchers";
import PaginationContainer from "../common/pagination/PaginationContainer";
import { useLocation } from "react-router-dom";
import addressSearch, {
  parseAddress,
} from "../common/address-lookup/address-search";
import LoadingBlock from "../common/loading/LoadingBlock";
import { useStore } from "../../hooks/use-store";

const SearchForm = () => {
  const { t } = useTranslation();
  const formMethods = useForm({ mode: "onBlur" });
  const {
    getValues,
    setValue,
    register,
    formState: { errors },
  } = formMethods;
  const [submitting, setSubmitting] = useState(false);
  const searchOptions = getSearchOptions(getValues, t, {});
  const [originLocation, setOriginLocation] = useState(null);
  const [defaultLocation, setDefaultLocation] = useState();
  const [searchResults, setSearchResults] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [loadingRedirectedSearch, setLoadingRedirectedSearch] = useState(false);
  const location = useLocation();
  const { userStore } = useStore();

  const { data: spaceTypes } = useSWR(
    [apiUrl("spaces", "/space-types")],
    defaultFetcher
  );

  useEffect(() => {
    if (spaceTypes !== undefined) {
      if (
        spaceTypes !== undefined &&
        spaceTypes[0].classes.includes("Ambient")
      ) {
        setValue("spaceClass", "Ambient");
      }
    }
  }, [spaceTypes, setValue]);

  const performSearch = async (formData) => {
    setSubmitting(true);
    setSearchResults(null);

    const accountId = userStore.contextAccount?.id;

    if (formData.location === undefined) formData.location = originLocation;
    formData.radius = 500; // TODO: Update this with filter
    formData.startDate = `${formData.startDate}T00:00:00.000Z`;
    formData.endDate = `${formData.endDate}T00:00:00.000Z`;
    formData.accountId = accountId ?? null;

    await submitAnonymousForm(
      apiUrl("bookings", "/search/booking-availability"),
      formData
    )
      .then(({ data }) => {
        setSearchResults(data.bookingOptions);
        setStartDate(formData.startDate);
        setEndDate(formData.endDate);
        setQuantity(formData.quantity);
      })
      .catch((error) => {
        toastError(t, error.message, error.errorCode);
        console.log(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const selectAddress = (addr) => {
    setOriginLocation({
      lat: addr.latitude,
      lon: addr.longitude,
    });
  };

  useEffect(() => {
    function redirectedSearch() {
      const queryParams = new URLSearchParams(location.search);
      const palletQty = queryParams.get("paletteQuantity");
      const searchLocation = queryParams.get("location");
      const startDate = queryParams.get("wantedFrom");
      const endDate = queryParams.get("wantedTo");
      const spaceType = queryParams.get("spaceType");

      // If all of the values are undefined, skip further processing
      if (
        palletQty === null &&
        searchLocation === null &&
        startDate === null &&
        endDate === null &&
        spaceType === null
      ) {
        return;
      }

      // Start the search
      setLoadingRedirectedSearch(true);
      setSubmitting(true);
      setValue("type", spaceType);
      setValue("quantity", palletQty);
      setValue("startDate", startDate);
      setValue("endDate", endDate);

      // Start the address lookup
      addressSearch(searchLocation, "uk").then(async (data) => {
        var address = parseAddress(data.results[0]);
        selectAddress(address);
        setDefaultLocation(address);

        await performSearch({
          spaceClass: spaceType,
          quantity: palletQty,
          startDate: startDate,
          endDate: endDate,
          location: {
            lat: address.latitude,
            lon: address.longitude,
          },
        }).then(() => setLoadingRedirectedSearch(false));
      });
    }

    redirectedSearch();
    // Only want this to run once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mt-15">
      {loadingRedirectedSearch ? (
        <LoadingBlock />
      ) : (
        <Form onSubmit={formMethods.handleSubmit(performSearch)}>
          <div className="grid md:grid-cols-3 gap-6">
            <Field
              label={t("search.type")}
              htmlFor="type"
              data-cy="field-spaceClass"
            >
              <SelectInput
                register={register("spaceClass", searchOptions.spaceClass)}
                error={errors.spaceClass}
                defaultValue="Ambient"
              >
                {!spaceTypes && <option key="-1">{t("common.loading")}</option>}
                {spaceTypes &&
                  spaceTypes[0].classes.map((spaceClass, i) => (
                    <option key={`type-${i}`}>{spaceClass}</option>
                  ))}
              </SelectInput>
              <FieldErrorMessage error={errors.spaceClass} />
            </Field>

            <Field
              label={t("search.quantity")}
              htmlFor="quantity"
              data-cy="field-quantity"
            >
              <NumberInput
                name="quantity"
                register={register("quantity", searchOptions.quantity)}
                error={errors.quantity}
              />
              <FieldErrorMessage error={errors.quantity} />
            </Field>

            <AddressLookup
              onSelected={selectAddress}
              label={t("search.location")}
              defaultAddress={defaultLocation}
              formMethods={formMethods}
              requiredMsg={t("search.validation.locationRequired")}
              data-cy="field-location"
            />
          </div>
          <div className="grid md:grid-cols-2 gap-6">
            <Field
              label={t("search.startDate")}
              htmlFor="startDate"
              data-cy="field-startDate"
            >
              <DateInput
                name="startDate"
                register={register("startDate", searchOptions.startDate)}
                error={errors.startDate}
              />
              <FieldErrorMessage error={errors.startDate} />
            </Field>

            <Field
              label={t("search.endDate")}
              htmlFor="endDate"
              data-cy="field-endDate"
            >
              <DateInput
                name="endDate"
                register={register("endDate", searchOptions.endDate)}
                error={errors.endDate}
              />
              <FieldErrorMessage error={errors.endDate} />
            </Field>
          </div>

          <div className="grid grid-cols-1"></div>

          <div>
            <Button
              label={t("search.buttonText")}
              loading={submitting}
              type="submit"
            />
          </div>
        </Form>
      )}
      <div>
        {searchResults && (
          <PaginationContainer items={searchResults} pageSize={500}>
            <SearchResultsList
              qtyRequired={quantity}
              startDate={startDate}
              endDate={endDate}
              radius={500} // TODO: Update this with filter
            />
          </PaginationContainer>
        )}
      </div>
    </div>
  );
};
export default React.memo(SearchForm);
