export default function getRegisterFields(t, getValues) {
  return {
    firstName: {
      required: t("auth.registerForm.firstNameRequired"),
    },
    lastName: {
      required: t("auth.registerForm.lastNameRequired"),
    },
    password: {
      required: t("auth.registerForm.passwordRequired"),
      validate: {
        strongPassword: (value) => {
          var strongRegex = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})"
          );

          if (strongRegex.test(value)) {
            return true;
          } else {
            return t("auth.registerForm.passwordNotStrong");
          }
        },
      },
    },
    confirmPassword: {
      required: t("auth.registerForm.confirmPasswordRequired"),
      validate: {
        matchesPassword: (value) => {
          const { password } = getValues();

          if (password === value) {
            return true;
          } else {
            return t("auth.registerForm.passwordsNotMatch");
          }
        },
      },
    },
    agreeTerms: {
      required: t("auth.accountTermsRequired"),
    },
  };
}
