import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Account } from '../../../app/model/account';
import formatAddress from "../../../utils/address-formatter";

interface OrganisationListProps {
  organisations:Account[] | undefined;
}

export default function OrganisationList({ organisations }: OrganisationListProps) {
  const { t } = useTranslation();

  return (
    <ul className="space-y-8">
      {organisations!.map((org) => (
        <li key={org.id} className="border-gray-300 border-t space-y-3">
          <div className="flex justify-between items-center">
            <div className="space-y-3">
              <div className="font-bold mt-3">
                <Link
                  className="text-2xl"
                  to={`/account/organisations/${org.id}`}
                >
                  {org.name}
                </Link>
              </div>

              {org.streetAddress && (
                <p className="text-gray-700 text-base">
                  {formatAddress(org.streetAddress)}
                </p>
              )}
              {!org.streetAddress && (
                <p className="text-gray-500 italic text-base">
                  {t("accountOrganisations.address.noAddressMsg")}
                </p>
              )}
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
