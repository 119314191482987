export function getProfileSettingsOptions() {
  return {
    firstName: {
      required: true,
    },
    lastName: {
      required: true,
    },
    displayName: {
      required: true,
    },
  };
}

export function getChangePasswordOptions(getValues, t) {
  return {
    existingPassword: {
      required: t("changePassword.validation.existingPasswordRequired"),
    },
    newPassword: {
      required: t("changePassword.validation.newPasswordRequired"),
      validate: {
        strongPassword: (value) => {
          var strongRegex = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})"
          );

          if (strongRegex.test(value)) {
            return true;
          } else {
            return t("changePassword.validation.passwordNotStrong");
          }
        },
      },
    },
    confirmPassword: {
      required: t("changePassword.validation.confirmPasswordRequired"),
      validate: {
        matchesPassword: (value) => {
          const { newPassword } = getValues();

          if (newPassword === value) {
            return true;
          } else {
            return t("changePassword.validation.passwordsNotMatch");
          }
        },
      },
    },
  };
}

export function getBillingOptions() {
  return {
    defaultCurrency: {
      required: true,
    },
    cardNumber: {
      required: true,
    },
    cardName: {
      required: true,
    },
    expiry: {
      required: true,
    },
    ccv: {
      required: true,
    },
  };
}
