import { makeAutoObservable, runInAction } from 'mobx';
import requests from '../../data/requests';
import apiUrl from '../../data/api-url';
import { ServiceRequestListItem } from '../model/service-requests';

export default class ServiceRequestStore {
  
  accountServiceRequests: ServiceRequestListItem[] | undefined = undefined;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadAccountServiceRequests = async (accountId: string) => {

    this.loading = true;

    var accountRequests = await requests.get<ServiceRequestListItem[]>(apiUrl('bookings', `/service-requests/${accountId}`));

    runInAction(() => {
      this.loading = false;
      this.accountServiceRequests = accountRequests;
    });

  };
  
}