import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ServiceRequestListItem } from '../../../app/model/service-requests';
import { formatDate } from '../../../utils/date-transformations';
import { titleCase } from '../../../utils/text-transformations';
import InfoMessage from '../../common/messages/InfoMessage';
import Table from '../../common/Table';

interface ServiceRequestListProps {
  serviceRequests: ServiceRequestListItem[] | undefined,
  noRequestsMessage: string
  actor: 'buyer' | 'seller'
}

export default function ServiceRequestList({serviceRequests, noRequestsMessage, actor}: ServiceRequestListProps) {
  const { t } = useTranslation();
  
  const itemsRequiringAction = useMemo(
    () => {
      if (actor === 'buyer') {
        return serviceRequests
        ? serviceRequests.filter((i) => i.status.toLowerCase() === "quoted")
        : [];
      } else {
        return serviceRequests
        ? serviceRequests.filter(
            (i) =>
              i.status.toLowerCase() === "new" ||
              i.status.toLowerCase() === "approved"
          )
        : [];
      }
    },
    [serviceRequests, actor]
  );

  const data = useMemo(
    () =>
      serviceRequests
        ? serviceRequests.map((serviceRequest) => ({
            reference: serviceRequest,
            site: serviceRequest.siteName,
            service: serviceRequest.serviceName,
            created: formatDate(serviceRequest.created),
            status: titleCase(serviceRequest.status),
            requiresAction: itemsRequiringAction
              ? itemsRequiringAction.some((i) => i.id === serviceRequest.id)
              : false,
            action: serviceRequest,
          }))
        : [],
    [serviceRequests, itemsRequiringAction]
  );

  const columns = useMemo(
    () => {
      
      const getRequestLink = (request: ServiceRequestListItem) => {
        if (actor === 'buyer') {
          return `/account/bookings/${request.bookingId}/service-requests/${request.id}`;
        } else {
          return `/account/sites/${request.siteId}/service-requests/${request.id}`
        }
      }

      return [
      {
        Header: t("accountBookings.serviceRequests.reference"),
        accessor: "reference",
        Cell: ({ value }:any) => (
          <Link
            to={getRequestLink(value)}
            className="text-link"
            data-cy="requestLink"
          >
            {value.reference}
          </Link>
        ),
      },
      {
        Header: t("accountBookings.serviceRequests.site"),
        accessor: "site",
      },
      {
        Header: t("accountBookings.serviceRequests.service"),
        accessor: "service",
      },
      {
        Header: t("accountBookings.serviceRequests.created"),
        accessor: "created",
      },
      {
        Header: t("accountBookings.serviceRequests.status"),
        accessor: "status",
      },
      {
        Header: t("accountBookings.serviceRequests.requiresAction"),
        accessor: "requiresAction",
        Cell: ({ value }:any) => (
          <span className={value ? "text-blue-500 font-bold" : ""}>
            {value === true ? "Yes" : "No"}
          </span>
        ),
      },
    ]},
    [t, actor]
  );

  return (
    <>
      {serviceRequests && serviceRequests.length > 0 && (
        <>
          {itemsRequiringAction && itemsRequiringAction.length > 0 && (
            <InfoMessage
              message={t(
                "accountBookings.serviceRequests.itemsRequireActionMsg"
              )}
            />
          )}
          <Table columns={columns} data={data} />
        </>
      )}
      {(!serviceRequests || serviceRequests.length === 0) && (
        <div data-cy="noRequestMessage">{noRequestsMessage}</div>
      )}
    </>
  )
}