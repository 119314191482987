import HelpMessage from "../messages/HelpMessage";
import Tooltip from '../Tooltip';

interface FieldProps {
  label?: string,
  labelTooltip?: string,
  htmlFor?: string,
  helpText?: string,
  children?: any
}

export function Field({ label, labelTooltip, htmlFor, helpText, children, ...inputProps }: FieldProps) {
  return (
    <div className="space-y-2" {...inputProps}>
      <label htmlFor={htmlFor} className="block font-bold" data-cy='field-label'>
        {label}
        {labelTooltip && (
          <Tooltip tooltip={labelTooltip} />
        )}
      </label>
      {children}
      {helpText && <HelpMessage text={helpText} />}
    </div>
  );
}

export function CheckboxField({ label, htmlFor, helpText, children, ...inputProps }:FieldProps) {
  return (
    <div className="space-y-2" {...inputProps}>
      <label htmlFor={htmlFor} className="block space-x-2" data-cy='field-label'>
        {children}
        <span className="font-medium">{label}</span>
      </label>
      {helpText && <HelpMessage text={helpText} />}
    </div>
  );
}

export function SmallCheckboxField({ label, htmlFor, children, ...inputProps }:FieldProps) {
  return (
    <label htmlFor={htmlFor} className="block space-x-2" data-cy='field-label'>
      {children}
      <span className="text-sm">{label}</span>
    </label>
  );
}

interface CheckboxFieldsetProps {
  legend?: string,
  className?: string,
  children?: any
}

export function CheckboxFieldset({ legend, children, className, ...inputProps }:CheckboxFieldsetProps) {
  return (
    <fieldset className={`space-y-6 ${className}`} {...inputProps}>
      <legend className="font-bold" data-cy='fieldset-legend'>{legend}</legend>

      <ul className="space-y-2">
        {children.map((child:any, index:number) => (
          <li key={`checkbox-${index}`}>{child}</li>
        ))}
      </ul>
    </fieldset>
  );
}

export default Field;
