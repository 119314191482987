import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
//import useSWR from "swr";
import apiUrl from "../../../../data/api-url";
//import defaultFetcher from "../../../../data/fetchers";
import submitForm from "../../../../data/submit-form";
import { useAuth } from "../../../../hooks/use-auth";
import Button, { LinkButton } from "../../../common/Button";
import {
  CheckboxField,
  CheckboxInput,
  Field,
  FieldErrorMessage,
  Form,
  TextInput,
} from "../../../common/forms";
//import PlanSelection from "../../plans/PlanSelection";
import AddressLookup from "../../../common/address-lookup/AddressLookup";
import { useHistory } from "react-router-dom";
import MarketplaceTermsEn from "../../../common/legal/MarketplaceTermsEn";
import { Address } from '../../../../app/model/address';

interface NewOrganisationFormValues {
  companyName: string;
  agreeTerms: boolean;
}

export default function NewOrganisation() {
  const { t } = useTranslation();
  const { user } = useAuth();
  //const [selectedPlan, setSelectedPlan] = useState(); // Default to basic plan
  //const [requiresPayment, setRequiresPayment] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const formMethods = useForm<NewOrganisationFormValues>({ mode: "onBlur" });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods;
  const history = useHistory();
  const defaultPlanId = "5cb6e72d-590e-4d33-aae5-4c5b71de55b5";

  //const selectPlan = (selectedPlan) => {
  //  setRequiresPayment(selectedPlan.pricePerMonth > 0);
  //  setSelectedPlan(selectedPlan);
  //};

  const createOrganisation = async (formData:NewOrganisationFormValues) => {
    setSubmitting(true);

    // If we need to make a payment, proceed to payment screen
    //if (requiresPayment) {
    //  history.push({
    //    pathname: "/account/organisations/new/payment",
    //    state: {
    //      accountData: {
    //        accountPlan: selectedPlan ? selectedPlan.id : defaultPlanId,
    //        name: formData.companyName,
    //      },
    //    },
    //  });
    //} else {
    //accountPlanId: selectedPlan ? selectedPlan.id : defaultPlanId,
    const postData = {
      accountPlanId: defaultPlanId,
      agreedTermsVersion: 1,
      primaryUserId: user.id,
      name: formData.companyName,
      streetAddress: selectedAddress
    };
    await submitForm(apiUrl("accounts", "/account"), postData, user.token)
      .then(({ data }) => {
        history.push({
          pathname: "/account/organisations",
          state: {
            newOrganisationId: data.id,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
    //}
    setSubmitting(false);
  };

  const addressLookupCallback = (addr?:Address) => {
    setSelectedAddress(addr ?? null);
  };

  //const { data: plans } = useSWR(
  //  [apiUrl("accounts", "/account-plans")],
  //  defaultFetcher
  //);

  return (
    <div className="space-y-8">
      <h1>{t("accountOrganisations.addOrganisation")}</h1>
      <Form onSubmit={handleSubmit(createOrganisation)}>
        <Field
          label={t("accountOrganisations.new.companyName")}
          htmlFor="companyName"
        >
          <TextInput
            name="companyName"
            register={register("companyName", { required: true })}
          />
          <FieldErrorMessage error={errors.companyName} />
        </Field>

        <AddressLookup
          onSelected={addressLookupCallback}
          label={t("accountOrganisations.companyAddress")}
          formMethods={formMethods}
        />

        {/*<PlanSelection
          selectPlan={selectPlan}
          defaultPlanId={defaultPlanId}
          plans={plans}
        />*/}

        <div>
          <p>
            <strong>{t("auth.termsOfUse")}</strong>
          </p>
          <MarketplaceTermsEn
            showAll={false}
            allowCollapse={true}
            minDisplaySize={true}
          />
        </div>

        <div>
          <CheckboxField
            label={t("accountOrganisations.new.accountTerms")}
            htmlFor="agreeTerms"
          >
            <CheckboxInput
              name="agreeTerms"
              id="agreeTerms"
              className="w-auto"
              register={register("agreeTerms", {
                required: t("accountOrganisations.new.accountTermsRequired"),
              })}
              error={errors.agreeTerms}
            />
          </CheckboxField>
          <FieldErrorMessage error={errors.agreeTerms} />
        </div>

        <div className="mt-8">
          <Button
            type="submit"
            className="btn-brand"
            label={
              t("accountOrganisations.new.submitButtonText")
              /*requiresPayment
              //  ? t("accountOrganisations.new.paymentDetails")
              //  : t("accountOrganisations.new.submitButtonText")*/
            }
            loading={submitting}
          />
          <LinkButton
            label={t("common.cancel")}
            onClick={() => {
              history.push("/account/organisations");
            }}
          />
        </div>
      </Form>
    </div>
  );
}
