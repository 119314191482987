import { useTranslation } from "react-i18next";

export default function Dashboard() {
  const { t } = useTranslation();

  return (
    <div className="container mt-7 mx-auto">
      <h1>{t("account.dashboard")}</h1>
    </div>
  );
}
