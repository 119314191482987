import { FieldError } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { BiErrorCircle } from "react-icons/bi";

const errorMessage = (t:any, error:any) => {
  if (error.message) {
    return error.message;
  }

  return t(`common.validation.${error.type}`);
};

interface FieldErrorMessageProps {
  error?: string | FieldError,
  message?: string
};

export default function FieldErrorMessage({ error, message }:FieldErrorMessageProps) {
  const { t } = useTranslation();

  return error || message ? (
    <p className="text-red-700 text-base flex" data-cy='field-error'>
      <BiErrorCircle className="inline text-2xl mr-1" />
      <span className="inline-block align-middle" data-cy='field-error-msg'>
        {error && errorMessage(t, error)}
        {message}
      </span>
    </p>
  ) : null;
}
