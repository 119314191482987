import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Field, FieldErrorMessage, TextInput } from "../../common/forms";
import submitForm from "../../../data/submit-form";
import { useAuth } from "../../../hooks/use-auth";
import toast from "react-hot-toast";

import { getProfileSettingsOptions } from "./account-settings-options";
import { useHistory } from "react-router-dom";
import Button from "../../common/Button";
import { useState } from "react";
import apiUrl from "../../../data/api-url";

export default function ProfileSettings() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const history = useHistory();
  const { user } = useAuth();
  const [submitting, setSubmitting] = useState(false);

  const registerOptions = getProfileSettingsOptions(getValues, t);

  const saveProfileSettings = async (formData) => {
    setSubmitting(true);
    setTimeout(async () => {
      const { data, errors } = await submitForm(
        apiUrl("accounts", `/user`),
        formData,
        user.token,
        "PUT"
      );

      if (data) {
        setSubmitting(false);
        toast.success(t("toasts.profileSettingsSaved"));
        history.push("/account/settings/profile");
      } else if (errors) {
        setSubmitting(false);
        toast.error(t("toasts.profileSettingsFailed"));
        Object.keys(errors).forEach((fieldName) => {
          setError(fieldName, { message: errors[fieldName] });
        });
      }
    }, 2000);
  };

  return (
    <div className="container space-y-5">
      <h2>{t("profileSettings.title")}</h2>

      <form onSubmit={handleSubmit(saveProfileSettings)} className="space-y-8">
        <div className="grid grid-cols-2 gap-6">
          <Field label={t("auth.firstName")} htmlFor="firstName">
            <TextInput
              name="firstName"
              register={register("firstName", registerOptions.firstName)}
              defaultValue={user.givenName}
            />
            <FieldErrorMessage error={errors.firstName} />
          </Field>

          <Field label={t("auth.lastName")} htmlFor="lastName">
            <TextInput
              name="lastName"
              register={register("lastName", registerOptions.lastName)}
              defaultValue={user.familyName}
            />
            <FieldErrorMessage error={errors.lastName} />
          </Field>
        </div>

        <Field
          label={t("auth.displayName")}
          htmlFor="displayName"
          helpText={t("auth.displayNameHelp")}
        >
          <TextInput
            name="displayName"
            register={register("displayName", registerOptions.displayName)}
            defaultValue={user.displayName}
          />
          <FieldErrorMessage error={errors.displayName} />
        </Field>

        <div>
          <Button label={t("profileSettings.save")} loading={submitting} />
        </div>
      </form>
    </div>
  );
}
