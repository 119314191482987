import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import { useAuth } from "../../../hooks/use-auth";
import { useState } from "react";

export default function ResendAccountConfirmCode() {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const { resendConfirmCode } = useAuth();
  const [showForm, setShowForm] = useState(false);

  const submitForm = async (data) => {
    try {
      var result = await resendConfirmCode(data.email);
      if (result === true) {
        toast.success(t("resendAccountConfirmation.codeResentText"));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div>
        <button
          className="text-blue-500 underline"
          onClick={() => setShowForm(true)}
        >
          {t("resendAccountConfirmation.noCodeLinkText")}
        </button>
      </div>

      {showForm && (
        <div>
          <p>{t("resendAccountConfirmation.desc")}</p>

          <form onSubmit={handleSubmit(submitForm)} className="space-y-8 mt-4">
            <div className="space-y-2">
              <label htmlFor="email" className="block font-bold">
                {t("auth.email")}
              </label>
              <input
                className="input"
                {...register("email", { required: true })}
              />
            </div>

            <div>
              <button type="submit" className="btn-brand">
                {t("resendAccountConfirmation.formButtonText")}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
