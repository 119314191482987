import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import LoadingBlock from "../../common/loading/LoadingBlock";
import Table from "../../common/Table";
import { Link } from "react-router-dom";

export default function SpaceAllocationList({ spaces, bookingId }) {
  const { t } = useTranslation();

  const data = useMemo(
    () =>
      spaces
        ? spaces.map((space) => ({
            siteId: space.siteId,
            siteName: space.siteName,
            spaceName: space.spaceName,
            quantity: space.quantity.toLocaleString(),
          }))
        : [],
    [spaces]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("accountBookings.spaceAllocations.siteName"),
        accessor: "siteName",
      },
      {
        Header: t("accountBookings.spaceAllocations.spaceName"),
        accessor: "spaceName",
      },
      {
        Header: t("accountBookings.booking.quantity"),
        accessor: "quantity",
      },
      {
        Header: "",
        accessor: "siteId",
        Cell: ({ value }) => (
          <Link
            to={`/account/bookings/${bookingId}/new-service-request?site_id=${value}`}
            className="btn-brand"
          >
            {t("accountBookings.newServiceRequestButton")}
          </Link>
        ),
      },
    ],
    [t, bookingId]
  );
  return (
    <div>
      <main className="space-y-8">
        {spaces && spaces.length > 0 && <Table columns={columns} data={data} />}
        {spaces && spaces.length === 0 && (
          <div>{t("accountBookings.noBookings")}. </div>
        )}
        {!spaces && <LoadingBlock />}
      </main>
    </div>
  );
}
