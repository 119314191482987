import { Address } from '../../../app/model/address';

const addressSearch = async (searchTerm: string, country: string) => {

  const apiKey = process.env.REACT_APP_GOOGLE_GEOCODE_APIKEY;

  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    searchTerm
  )}&key=${apiKey}&region=${country}`;

  return fetch(url)
    .then((response) => {
      return response.json();
    })

}

export function formatAddress(address: Address): string {
  if (address === undefined || address === null)
  return '';
  
  console.log(address);
  console.log(address.addressLine1);
    
  if (address.formattedAddress !== undefined && address.formattedAddress !== '') {
    return address.formattedAddress;
  } else {
    return `${address.addressLine1} ${address.city}, ${address.state} ${address.postcode} ${address.country}`
  }
}

export const parseAddress = (addr: any):Address => {
  var line1 =
    getAddressValue(addr, ["street_number"]) +
    " " +
    getAddressValue(addr, ["route"]);

  return {
    addressLine1: line1,
    addressLine2: "",
    city: getAddressValue(addr, ["postal_town", "locality"]),
    state: getAddressValue(addr, ["administrative_area_level_1"]),
    country: getAddressValue(addr, ["country"]),
    postcode: getAddressValue(addr, ["postal_code"]),
    latitude: parseFloat(addr.geometry.location.lat),
    longitude: parseFloat(addr.geometry.location.lng),
    formattedAddress: addr.formatted_address
  };
};

// Gets the long_name component from the address, based on the component type.
const getAddressValue = (addr: any, types: string[]) => {
  for (var i = 0; i < addr.address_components.length; i++) {

    if (addr.address_components[i].types.some((item:string) => types.includes(item)))
      return addr.address_components[i].long_name;
  }
  return "";
};

export default addressSearch;