import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import apiUrl from "../../../../data/api-url";
import submitForm from "../../../../data/submit-form";
import { Form } from "../../../common/forms";
import SpaceFormFields from "./FormFields";
import {
  toastError,
  toastLoading,
} from "../../../common/messages/toast-messages";
import { useState } from "react";
import Button, { LinkButton } from "../../../common/Button";

export default function NewSpace() {
  const { t } = useTranslation();
  const formMethods = useForm({ mode: "onBlur" });
  const history = useHistory();
  const { siteId } = useParams();
  const [submitting, setSubmitting] = useState(false);

  const submitSpace = async (formData) => {
    setSubmitting(true);
    const toastId = toastLoading(t("toasts.spaceCreating"));

    // Default value
    formData.minimumBookingPeriod = 2;
    formData.type = "Storage";

    const { data, err: remoteErrors } = await submitForm(
      apiUrl("spaces", `/sites/${siteId}/spaces`),
      formData
    );

    if (data) {
      history.replace(
        `/account/sites/${siteId}/spaces/${data.id}?new_space=1`,
        {
          spaceLongPolling: new Date(data.timestamp),
          toastId: toastId,
        }
      );
    } else if (remoteErrors) {
      setSubmitting(false);
      toastError(t, remoteErrors.message, remoteErrors.errorCode);
      console.log(remoteErrors);
    }
  };

  return (
    <div className="space-y-8">
      <h1>{t("accountSites.addSpace")}</h1>

      <Form onSubmit={formMethods.handleSubmit(submitSpace)}>
        <SpaceFormFields formMethods={formMethods} space={{}} />

        <div>
          <Button label={t("accountSites.space.create")} loading={submitting} />
          <LinkButton
            label={t("common.cancel")}
            onClick={() => {
              history.push(`/account/sites/${siteId}`);
            }}
          />
        </div>
      </Form>
    </div>
  );
}
