import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import apiUrl from "../../../../../data/api-url";
import { tokenFetcher } from "../../../../../data/fetchers";
import submitForm from "../../../../../data/submit-form";
import { useAuth } from "../../../../../hooks/use-auth";
import { useStore } from "../../../../../hooks/use-store";
import Button, { LinkButton } from "../../../../common/Button";
import {
  Field,
  FieldErrorMessage,
  Form,
  TextArea,
} from "../../../../common/forms";
import LoadingBlock from "../../../../common/loading/LoadingBlock";

export default observer(function EditService() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({ mode: "onBlur" });
  const history = useHistory();
  const { user } = useAuth();
  const { userStore } = useStore();
  const { siteId, serviceId } = useParams();
  const [loading, setLoading] = useState(false);

  const { data: service } = useSWR(
    userStore.contextAccount
      ? [apiUrl("spaces", `/sites/${siteId}/services/${serviceId}`), user.token]
      : null,
    tokenFetcher
  );

  const saveService = async (formData) => {
    setLoading(true);

    var serviceData = {
      serviceId: serviceId,
      customerInformation: formData.customerInformation,
    };

    await submitForm(
      apiUrl("spaces", `/sites/${siteId}/services/${serviceId}`),
      serviceData,
      user.token,
      "PUT"
    )
      .then(() => {
        mutate(apiUrl("spaces", `/sites/${siteId}/services/${serviceId}`));
        history.push(`/account/sites/${siteId}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!service) {
    return <LoadingBlock />;
  }

  return (
    <div className="space-y-8">
      <h1>{t("accountSites.service.edit")}</h1>

      <Form onSubmit={handleSubmit(saveService)}>
        <fieldset className="space-y-8">
          <h3>{service.name}</h3>
          <p>{service.description}</p>

          <Field
            label={t("accountSites.service.customerInformation")}
            htmlFor="customerInformation"
            helpText={t("accountSites.service.customerInformationHelp")}
          >
            <TextArea
              name="customerInformation"
              rows={4}
              defaultValue={service.customerInformation}
              register={register("customerInformation")}
              error={errors.customerInformation}
            />
            <FieldErrorMessage error={errors.customerInformation} />
          </Field>
        </fieldset>

        <div>
          <Button label={t("accountSites.service.update")} loading={loading} />
          <LinkButton
            label={t("common.cancel")}
            onClick={() => {
              history.push(`/account/sites/${siteId}`);
            }}
          />
        </div>
      </Form>
    </div>
  );
});
