export default function UnauthorisedMessage() {
  return (
    <div
      className="bg-red-100 border-t-4 border-l border-r border-b border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md bg-opacity-40 border-opacity-40 text-opacity-60"
      role="alert"
    >
      <p>You currently do not have access to these details.</p>
    </div>
  );
}
