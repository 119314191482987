import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";

import { useAuth } from "../../../hooks/use-auth";
import { useEffect, useState } from "react";
import {
  EmailInput,
  PasswordInput,
  Field,
  FieldErrorMessage,
  Form,
} from "../../common/forms";

import SuccessMessage from "../../common/messages/SuccessMessage";
import Button from "../../common/Button";

export default function SignIn() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const history = useHistory();
  const location = useLocation();
  const { signIn } = useAuth();
  const [userConfirmed, setUserConfirmed] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    const confirmed = new URLSearchParams(location.search).get("confirmed");
    if (confirmed) {
      setUserConfirmed(true);
    }
  }, [location.search, t]);

  const signInUser = async (data) => {
    setSubmitting(true);
    const { email, password } = data;

    await signIn(email, password)
      .then(({ user }) => {
        let state = {};
        if (typeof location.state !== "undefined") {
          state = location.state;
        }

        state.from = { pathname: "/sign-in" };

        if (user) {
          history.replace(from, state);
        }
      })
      .catch((error) => {
        setError("email", {
          type: "manual",
          message: t("auth.loginForm.loginError"),
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="container mx-auto mt-7 space-y-8 md:w-2/3 xl:w-1/3 max-w-3xl">
      <h1 data-cy="component-title">{t("auth.signIn")}</h1>

      {userConfirmed && (
        <SuccessMessage
          title="Your account is confirmed"
          message={t("toasts.accountConfirmed")}
        />
      )}

      <Form onSubmit={handleSubmit(signInUser)} className="space-y-8">
        <Field label={t("auth.email")} htmlFor="email" data-cy="field-email">
          <EmailInput
            name="email"
            autoComplete="off"
            register={register("email", {
              required: t("auth.loginForm.emailRequired"),
            })}
          />
          <FieldErrorMessage error={errors.email} />
        </Field>

        <Field
          label={t("auth.password")}
          htmlFor="password"
          data-cy="field-password"
        >
          <PasswordInput
            name="password"
            register={register("password", {
              required: t("auth.loginForm.passwordRequired"),
            })}
          />
          <FieldErrorMessage error={errors.password} />
        </Field>

        <Button
          type="submit"
          className="btn-brand"
          label={t("auth.signIn")}
          loading={submitting}
        />
      </Form>
      <div>
        <Link to="/reset-password">I've forgotten my password</Link>
      </div>
    </div>
  );
}
