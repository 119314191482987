import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/use-auth";
import { formatDate } from "../../../utils/date-transformations";
import SpaceAllocationList from "./SpaceAllocationList";
import LoadingBlock from "../../common/loading/LoadingBlock";
import useSWR from "swr";
import apiUrl from "../../../data/api-url";
import { tokenFetcher } from "../../../data/fetchers";
import ServiceRequestList from "./service-requests/ServiceRequestList";
import Feature from '../../common/feature-flags/Feature';
import BookingStockItems from './BookingStockItems';
import { BookingStockItemQuantity } from '../../../app/model/bookings';
import { useEffect, useState } from 'react';
import requests from '../../../data/requests';
import { StockItem } from '../../../app/model/stock-management';

export default function ShowBooking() {
  const { t } = useTranslation();
  const { bookingId } = useParams<{ bookingId: string }>();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [ bookingStock, setBookingStock ] = useState<BookingStockItemQuantity[]>([]);

  const { data: booking } = useSWR(
    [apiUrl("bookings", `/bookings/${bookingId}`), user.token],
    tokenFetcher
  );

  useEffect(() => {

    async function loadStockItems() {
      setLoading(true);
      const stockItemIds = booking.stockItemQuantities.map(((item:any) => item.stockItemId));

      if (stockItemIds.length === 0) {
        setLoading(false);
        return;
      }

      var results = await requests.post<StockItem[]>(apiUrl(
        "stock",
        '/stock/list'
      ), { stockItemIds: stockItemIds });
      
      const bookingStockQuantities:BookingStockItemQuantity[] = results.map((item, index) => {
        return {
          sku: item.sku,
          name: item.name,
          quantity: booking.stockItemQuantities[index].quantity,
          siteId: booking.stockItemQuantities[index].siteId,
          stockItemId: item.id,
          description: item.description,
          gtin: item.gtin,
          upc: item.upc
        };
      });
      setBookingStock(bookingStockQuantities);

      setLoading(false);
    }

    if (booking !== undefined && booking !== null) {
      loadStockItems();
    }

  }, [booking])


  if (!booking || loading) return <LoadingBlock />;

  return (
    <div className="space-y-8">
      <header>
        <h1>{t("accountBookings.bookingDetails.title")}</h1>
      </header>

      <div>
        <h2>
          <small className="text-sm font-medium uppercase">
            {" "}
            {t("accountBookings.booking.reference")}
          </small>
          <br />
          {booking.reference}
        </h2>
      </div>

      <div className="grid md:grid-cols-2 md:gap-6 space-y-3 md:space-y-0">
        <div>
          <span className="font-bold block">
            {t("accountBookings.bookingDetails.startDate")}
          </span>
          {formatDate(booking.startDate)}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.bookingDetails.endDate")}
          </span>
          {formatDate(booking.endDate)}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.bookingDetails.totalBookedSpace")}
          </span>
          {booking.quantity.toLocaleString()} {booking.unit}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.bookingDetails.status")}
          </span>
          {booking.status}
        </div>
      </div>

      <div className="space-y-4">
        <h2>{t("accountBookings.bookingDetails.spaceAllocations")}</h2>
        <SpaceAllocationList
          spaces={booking.spaceLineItems}
          bookingId={bookingId}
        />
      </div>

      <Feature name='stock-management'>
      <div className="flex justify-between">
        <h2>{t("accountBookings.bookingStock.title")}</h2>
        <Link to={`/account/bookings/${bookingId}/stock-movements`}>{t("accountBookings.bookingDetails.stockMovementsLink")}</Link>
      </div>
        <BookingStockItems spaceItems={booking.spaceLineItems} stockQuantities={bookingStock} bookingId={bookingId} />
      </Feature>

      <div className="space-y-4">
        <h2>{t("accountBookings.serviceRequests.title")}</h2>
        <ServiceRequestList />
      </div>
    </div>
  );
}
