import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import { useAuth } from "../../../hooks/use-auth";

import ResendAccountConfirmCode from "./ResendAccountConfirmCode";
import Button from "../../common/Button";
import { useState } from "react";
import { Field, FieldErrorMessage, Form, TextInput } from "../../common/forms";

export default function ConfirmAccount() {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const { confirmUser } = useAuth();

  const submitForm = async (data) => {
    setSubmitting(true);
    try {
      await confirmUser(email, data.confirmCode).then((data) => {
        history.replace("/sign-in?confirmed=true");
      });
    } catch (error) {
      toast.error(error.message);
    }
    setSubmitting(false);
  };

  return (
    <div className="container mx-auto mt-7 space-y-8 md:w-2/3 xl:w-1/3 max-w-3xl">
      <h1>{t("confirmAccount.title")}</h1>
      <p>{t("confirmAccount.desc")}</p>

      <Form onSubmit={handleSubmit(submitForm)}>
        <Field label={t("confirmAccount.confirmCode")} htmlFor="confirmCode">
          <TextInput
            maxLength={6}
            name="confirmCode"
            className="text-2xl font-medium"
            register={register("confirmCode", {
              required: t("confirmAccount.confirmCodeRequired"),
            })}
          />
          <FieldErrorMessage error={errors.confirmCode} />
        </Field>

        <div>
          <Button
            type="submit"
            className="btn-brand"
            label={t("confirmAccount.formButtonText")}
            loading={submitting}
          />
        </div>
      </Form>

      <ResendAccountConfirmCode />
    </div>
  );
}
