import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import apiUrl from "../../../../data/api-url";
import { tokenFetcher } from "../../../../data/fetchers";
import { useAuth } from "../../../../hooks/use-auth";
import { formatDate } from "../../../../utils/date-transformations";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import PriceDisplay from "../../../common/PriceDisplay";

export default function PaymentHistory() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { organisationId } = useParams();

  const { data: payments } = useSWR(
    [
      apiUrl("payments", `/payment-accounts/${organisationId}/payments`),
      user.token,
    ],
    tokenFetcher
  );

  return (
    <div className="space-y-6">
      <h2>{t("accountOrganisations.paymentHistory.title")}</h2>
      <p>{t("accountOrganisations.paymentHistory.leadText")}</p>

      {payments && (
        <ul className="space-y-6">
          {payments.length === 0 && (
            <li>{t("accountOrganisations.paymentHistory.noPayments") + " "}</li>
          )}
          {payments.length > 0 &&
            payments.map((payment, index) => (
              <li
                key={index}
                className="border-gray-300 border-t space-y-3 pt-8"
              >
                <div className="md:flex justify-between items-top">
                  <div className="md:flex">
                    <div>
                      <PriceDisplay
                        price={payment.priceDetails?.totalChargeAmount}
                        className="text-xl font-bold"
                      />
                      <p className="text-gray-500">
                        {formatDate(payment.paymentDate)}
                      </p>
                    </div>
                    <p className="py-5 md:py-0 md:pl-5">
                      {payment.description}
                    </p>
                  </div>
                  <div className="flex">
                    <a
                      href={payment.invoicePdfUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("accountOrganisations.paymentHistory.downloadInvoice")}
                    </a>
                    {payment.receiptUrl !== null && (
                      <a
                        href={payment.receiptUrl}
                        target="_blank"
                        className="pl-5"
                        rel="noopener noreferrer"
                      >
                        {t("accountOrganisations.paymentHistory.showReceipt")}
                      </a>
                    )}
                  </div>
                </div>
              </li>
            ))}
        </ul>
      )}
      {!payments && <LoadingBlock />}
    </div>
  );
}
