import { StockItemQuantity } from './bookings';
import { PriceDetails } from './pricing';

export interface SiteService {
  id: string;
  name: string;
  description: string;
  customerInformation: string;
};

export interface ServiceRequestListItem {
  id: string;
  reference: string;
  bookingId: string;
  serviceId: string;
  siteId: string;
  serviceName: string;
  siteName: string;
  created: Date;
  updated: Date;
  status: string;
  sellerAccountId: string;
  buyerAccountId: string;
  palletQuantity?: number,
  requiredByDate?: Date
};

export interface ServiceRequest {
  id: string;
  reference: string;
  bookingId: string;
  siteId: string;
  siteName: string;
  serviceId: string;
  serviceName: string;
  sellerAccountId: string;
  buyerAccountId: string;
  requestDetails: string;
  created: Date;
  updated: Date;
  status: string;
  priceDetails: PriceDetails;
  notes: Note[];
  palletQuantity?: number,
  requiredByDate?: Date,
  stockItemQuantities?: StockItemQuantity[];
};

export interface Note {
  accountId: string;
  accountName: string;
  created: Date;
  id?: string;
  message: string;
  userDisplayName: string;
  userId?: string;
};

export interface RequestStockItem {
  name: string,
  sku: string,
  id: string,
  quantity: number
};

export enum StockMovementType {
  MoveIn = "MoveIn",
  MoveOut = "MoveOut"
};