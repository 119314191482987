import { makeAutoObservable, runInAction } from 'mobx';
import apiUrl from '../../data/api-url';
import requests from '../../data/requests';
import { CreatedResponse } from '../model/api';
import { StockItem, StockItemFormFields } from '../model/stock-management';

export default class StockStore {

  stockItems: StockItem[] | undefined;
  stockItem: StockItem | undefined;
  loading:boolean = false;
  processingImport:boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadStockItems = async (accountId: string) => {

    this.loading = true;

    var stockItems = await requests.get<StockItem[]>(apiUrl('stock', `/stock/account/${accountId}`));

    runInAction(() => {
      this.stockItems = stockItems;
      this.loading = false;
    });

  }

  loadStockItem = async (id: string) => {

    try {

      this.loading = true;
      var stockItem = await requests.get<StockItem>(apiUrl('stock', `/stock/${id}`));

      runInAction(() => {
        this.stockItem = stockItem;
        this.loading = false;
      });
    } catch (error) {
      console.log(error);
      runInAction(() => this.loading = false);
    }

  }

  clearStockItem = () => {
    this.stockItem = undefined;
  }

  clearStockItems = () => {
    this.stockItems = undefined;
  }

  createStockItem = async (stockItem: StockItemFormFields) => {
    this.loading = true;
    
    var resp = await requests.post<CreatedResponse>(apiUrl("stock", '/stock'), stockItem)
      .catch((err) => {
        runInAction(() => this.loading = false);
        throw err;
      });

    var newStockItem:StockItem = {
      id: resp.id,
      updated: resp.timestamp,
      ...stockItem
    };

    runInAction(() => {
      this.stockItem = newStockItem;

      if (this.stockItems === undefined)
        this.stockItems = [];

      this.stockItems.push(newStockItem);
      this.loading = false;
    });

    return newStockItem;
  }

  updateStockItem = async (stockItem: StockItemFormFields) => {
    this.loading = true;
    try {
      var updatedStockItem = await requests.put<StockItem>(apiUrl("stock", `/stock/${stockItem.id!}`), stockItem);
      
      runInAction(() => {
        console.log(updatedStockItem);
        this.stockItem = updatedStockItem;
        
        if (this.stockItems !== undefined)
        {
          for(let i = 0; i < this.stockItems?.length; i++)
          {
            if (this.stockItems[i].id === updatedStockItem.id)
              this.stockItems[i] = updatedStockItem;
          }
        } else {
          this.stockItems = [];
          this.stockItems.push(updatedStockItem);
        }

        this.loading = false;
      })

    } catch (error) {
      console.log(error);
      this.loading = false;
    }
  }

  removeStockItem = async (id: string) => {

    // Can only remove from the list in state if it is a list
    if (this.stockItems !== undefined) {
      runInAction(() => this.stockItems = this.stockItems!.filter((value, index, arr) => value.id !== id));

      await requests.del(apiUrl("stock", `/stock/${id!}`))
    }

  }

  setProcessingImport = (processing: boolean) => {
    this.processingImport = processing;
  }

}