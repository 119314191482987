import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import apiUrl from "../../../../data/api-url";
import submitForm from "../../../../data/submit-form";
import { useAuth } from "../../../../hooks/use-auth";
import Button, { LinkButton } from "../../../common/Button";
import {
  EmailInput,
  Field,
  FieldErrorMessage,
  Form,
  TextInput,
} from "../../../common/forms";
import ErrorMessage from "../../../common/messages/ErrorMessage";

export default function InviteUser() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { organisationId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const inviteUser = async (formData) => {
    setSubmitting(true);
    await submitForm(
      apiUrl("accounts", `/account/${organisationId}/invite-user`),
      formData,
      user.token
    )
      .then(() => {
        history.push({
          pathname: `/account/organisations/${organisationId}/users/invite-sent`,
          state: {
            inviteData: formData,
          },
        });
      })
      .catch((err) => {
        setErrorMessage(err.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="space-y-8">
      <h1>{t("accountOrganisations.inviteUser.title")}</h1>
      <p className="text-lg">{t("accountOrganisations.inviteUser.leadText")}</p>
      <p className="text-base">
        {t("accountOrganisations.inviteUser.userNotifyText")}
      </p>

      {errorMessage && (
        <ErrorMessage
          title={t("accountOrganisations.inviteUser.inviteErrorTitle")}
          message={errorMessage}
        />
      )}

      <Form onSubmit={handleSubmit(inviteUser)} className="space-y-8">
        <Field
          label={t("accountOrganisations.inviteUser.emailAddress")}
          htmlFor="emailAddress"
        >
          <div className="md:w-1/2 lg:w-1/3">
            <EmailInput
              name="emailAddress"
              register={register("emailAddress", { required: true })}
            />
          </div>
          <FieldErrorMessage error={errors.emailAddress} />
        </Field>

        <div className="grid md:grid-cols-2 md:gap-6">
          <Field
            label={t("accountOrganisations.inviteUser.givenName")}
            htmlFor="givenName"
          >
            <TextInput
              name="givenName"
              register={register("givenName", { required: true })}
            />
            <FieldErrorMessage error={errors.givenName} />
          </Field>

          <Field
            label={t("accountOrganisations.inviteUser.familyName")}
            htmlFor="familyName"
          >
            <TextInput
              name="familyName"
              register={register("familyName", { required: true })}
            />
            <FieldErrorMessage error={errors.familyName} />
          </Field>
        </div>

        <div>
          <Button
            type="submit"
            className="btn-brand"
            label={t("accountOrganisations.inviteUser.buttonText")}
            loading={submitting}
          />
          <LinkButton
            label={t("common.cancel")}
            onClick={() => {
              history.push(`/account/organisations/${organisationId}`);
            }}
          />
        </div>
      </Form>
    </div>
  );
}
