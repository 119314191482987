import { useTranslation } from "react-i18next";
import MarketplaceTermsEn from "./MarketplaceTermsEn";

export default function TermsConditions() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto mt-7 space-y-8 max-w-6xl">
      <h1 className="text-center mb-10">{t("termsConditions.title")}</h1>
      <MarketplaceTermsEn showAll={true} allowCollapse={false} />
    </div>
  );
}
