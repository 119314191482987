import { useTranslation } from "react-i18next";
import SearchForm from "./search/SearchForm";

export default function Home() {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto mt-7 space-y-8 max-w-6xl">
      <h1>{t("homeCTA")}</h1>

      <div className="space-y-6">
        <h2>{t("search.formTitle")}</h2>
        <p className="text-xl">{t("search.leadText")}</p>
        <SearchForm />
      </div>
    </div>
  );
}
