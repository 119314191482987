import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";

import OptionOverview from "../OptionOverview";
import Button, { LinkButton } from "../../common/Button";
import BookingTerms from "../BookingTerms";
import { useForm } from "react-hook-form";
import { Form } from "../../common/forms";
import submitForm from "../../../data/submit-form";
import apiUrl from "../../../data/api-url";
import { useAuth } from "../../../hooks/use-auth";
import { useStore } from "../../../hooks/use-store";
import { observer } from "mobx-react-lite";

export default observer(function BookingStepTwo() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { userStore } = useStore();
  const [loading, setLoading] = useState(false);
  const formMethods = useForm();
  const history = useHistory();

  const location = useLocation();

  // If there's no state, redirect to the home page
  if (location.state === undefined) {
    history.replace("/");
    return null;
  }
  const { selectedOption, query, reservation, bookingId } = location.state;

  const nextClick = async () => {
    setLoading(true);

    var postData = {
      accountId: userStore.contextAccount.id,
    };

    await submitForm(
      apiUrl("bookings", `/bookings/${bookingId}/payment-request`),
      postData,
      user.token
    )
      .then(({ data }) => {
        history.replace(
          `/booking/payment?payment_request=${data.paymentRequestId}`,
          {
            selectedOption: selectedOption,
            reservation: reservation,
            query: query,
            bookingId: bookingId,
            paymentRequest: data,
          }
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container mx-auto mt-7 space-y-8">
      <h1>{t("booking.stepTwo.title")}</h1>

      <OptionOverview option={selectedOption} query={query} />

      <Form
        onSubmit={formMethods.handleSubmit(nextClick)}
        className="space-y-2"
      >
        <BookingTerms formMethods={formMethods} />

        <div className="space-y-5">
          <Button
            label={t("booking.paymentButtonText")}
            type="submit"
            loading={loading}
          />
          <LinkButton
            label={t("common.previous")}
            onClick={() => history.goBack()}
          />
        </div>
      </Form>
    </div>
  );
});
