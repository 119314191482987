import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import apiUrl from "../../../../data/api-url";
import { tokenFetcher } from "../../../../data/fetchers";
import Table from "../../../common/Table";
import { formatDate } from "../../../../utils/date-transformations";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import { useStore } from "../../../../hooks/use-store";
import { observer } from "mobx-react-lite";

export default observer(function AvailabilityList({ space }) {
  const { t } = useTranslation();
  const { userStore } = useStore();
  const { siteId, spaceId } = useParams();
  const newAvailabilityUrl = `/account/sites/${siteId}/spaces/${spaceId}/availabilities/new`;
  const location = useLocation();

  const { data: availabilities } = useSWR(
    space && userStore.contextAccount
      ? [
          apiUrl(
            "spaces",
            `/sites/${siteId}/spaces/${spaceId}/availability-windows`
          ),
          { longPolling: location?.state?.availabilityLongPolling },
        ]
      : null,
    tokenFetcher
  );

  const data = useMemo(
    () =>
      availabilities
        ? availabilities.map((availability) => {
            const currency = { symbol: "£", code: "GBP" }; //TODO: Load from account
            return {
              startDate: availability, //formatDate(availability.startDate),
              endDate: formatDate(availability.endDate),
              price: `${currency.symbol}${availability.unitPrice} ${currency.code}`,
              maxAvailableQuantity: availability.maxAvailableQuantity,
              minBookingQuantity: availability.minBookingQuantity,
              action: availability.published,
            };
          })
        : [],
    [availabilities]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("accountSites.availability.startDate"),
        accessor: "startDate",
        Cell: ({ value }) => (
          <>
            <Link
              to={`/account/sites/${siteId}/spaces/${space.id}/availabilities/${value.id}/edit`}
              className="text-link"
            >
              {formatDate(value.startDate)}
            </Link>
          </>
        ),
      },
      {
        Header: t("accountSites.availability.endDate"),
        accessor: "endDate",
      },
      {
        Header: t("accountSites.availability.maxAvailableQuantity"),
        accessor: "maxAvailableQuantity",
      },
      {
        Header: t("accountSites.availability.minBookingQuantity"),
        accessor: "minBookingQuantity",
      },
      {
        Header: t("accountSites.availability.unitPrice"),
        accessor: "price",
      },
      {
        Header: t("accountSites.availability.published"),
        accessor: "action",
      },
    ],
    [t, siteId, space.id]
  );

  return (
    <div className="space-y-8">
      <div className="flex justify-between align-baseline">
        <h2>{t("accountSites.availability.availability")}</h2>

        <Link
          to={`/account/sites/${siteId}/spaces/${space.id}/availabilities/new`}
          className="btn-brand"
        >
          {t("accountSites.availability.add")}
        </Link>
      </div>

      <main className="space-y-8">
        {availabilities && availabilities.length > 0 && (
          <Table columns={columns} data={data} />
        )}
        {availabilities && availabilities.length === 0 && (
          <div>
            {t("accountSites.availability.noWindows")}.{" "}
            <Link to={newAvailabilityUrl}>
              {t("accountSites.availability.addFirstWindow")}
            </Link>
          </div>
        )}
        {!availabilities && <LoadingBlock />}
      </main>
    </div>
  );
});
