import SearchResultItem from "./SearchResultItem";
import { formatDate } from "../../utils/date-transformations";
import { usePagination } from "../common/pagination/PaginationContainer";

export default function SearchResultsList(props) {
  const query = {
    startDate: props.startDate,
    endDate: props.endDate,
    quantity: props.qtyRequired,
  };

  const { pagedItems, totalItems } = usePagination();

  return (
    <>
      <div className="mt-7">
        <p className="text-xl font-bold">
          {totalItems} options found between {formatDate(props.startDate)} and{" "}
          {formatDate(props.endDate)} within {props.radius} km
        </p>
      </div>
      <div className="mt-7">
        {pagedItems &&
          pagedItems.map((result, index) => {
            return (
              <SearchResultItem
                item={result}
                key={index}
                qtyRequired={props.qtyRequired}
                query={query}
              />
            );
          })}
      </div>
    </>
  );
}
