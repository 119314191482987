import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { Field, FieldErrorMessage, PasswordInput } from "../../common/forms";
import toast from "react-hot-toast";

import { useAuth } from "../../../hooks/use-auth";
import { getChangePasswordOptions } from "./account-settings-options";
import Button from "../../common/Button";
import { useState } from "react";
import submitForm from "../../../data/submit-form";
import apiUrl from "../../../data/api-url";
import platform from "platform";

export default function ChangePassword() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const history = useHistory();
  const location = useLocation();
  const { changePassword } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const { user } = useAuth();

  const registerOptions = getChangePasswordOptions(getValues, t);

  const { from } = location.state || {
    from: { pathname: "/account/settings" },
  };

  const handleChangePassword = async (data) => {
    setSubmitting(true);
    try {
      await changePassword(data).then(async () => {
        const clientInfo = {
          browserName: platform.name,
          operatingSystem: platform.os.toString(),
        };

        // Send the password changed notification
        await submitForm(
          apiUrl("accounts", "/users/password-changed"),
          clientInfo,
          user.token,
          "PUT"
        ).then(() => {
          setSubmitting(false);
          toast.success(t("toasts.changePasswordSuccess"));
          history.push(from);
        });
      });
    } catch (error) {
      setSubmitting(false);
      console.log(error);
      if (error.code === "NotAuthorizedException") {
        toast.error(t("toasts.changePasswordIncorrectPassword"));
      } else {
        toast.error(t("toasts.changePasswordFailed"));
      }
    }
  };

  return (
    <div className="container space-y-5">
      <h2>{t("changePassword.title")}</h2>
      <p>{t("changePassword.desc")}</p>

      <form
        onSubmit={handleSubmit(handleChangePassword)}
        className="space-y-8 md:w-2/3"
      >
        <Field
          label={t("changePassword.existingPassword")}
          htmlFor="existingPassword"
        >
          <PasswordInput
            name="existingPassword"
            register={register(
              "existingPassword",
              registerOptions.existingPassword
            )}
          />
          <FieldErrorMessage error={errors.existingPassword} />
        </Field>

        <Field
          label={t("changePassword.newPassword")}
          htmlFor="newPassword"
          helpText={t("auth.passwordRequirements")}
        >
          <PasswordInput
            name="newPassword"
            register={register("newPassword", registerOptions.newPassword)}
          />
          <FieldErrorMessage error={errors.newPassword} />
        </Field>

        <Field
          label={t("changePassword.confirmPassword")}
          htmlFor="confirmPassword"
        >
          <PasswordInput
            name="confirmPassword"
            register={register(
              "confirmPassword",
              registerOptions.confirmPassword
            )}
          />
          <FieldErrorMessage error={errors.confirmPassword} />
        </Field>

        <Button
          label={t("changePassword.formButtonText")}
          loading={submitting}
        />
      </form>
    </div>
  );
}
