import React, { useMemo } from 'react'
import { Invoice } from '../../../../app/model/account'
import PriceDisplay from '../../../common/PriceDisplay'
import { formatDate } from '../../../../utils/date-transformations'
import { useTranslation } from 'react-i18next'
import Table from '../../../common/Table'
import { Link, useParams } from 'react-router-dom'

interface InvoiceListProps {
  invoices: Invoice[] | undefined
};

export default function InvoiceList({invoices}: InvoiceListProps) {
  const { t } = useTranslation();

  const { organisationId } = useParams<{organisationId: string}>();

  const data = useMemo(
    () =>
      invoices
        ? invoices.map((invoice) => ({
            invoiceNumber: invoice.invoiceNumber,
            dueDate: invoice,
            amountDue: invoice,
            status: invoice,
            action: invoice,
          }))
        : [],
    [invoices]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("accountOrganisations.invoices.list.invoiceNumber"),
        accessor: "invoiceNumber"
      },
      {
        Header: t("accountOrganisations.invoices.list.status"),
        accessor: "status",
        Cell: ({ value }:any) => { 
          if (value.status === 'Paid') {
            return <span data-cy="invoice-status" className='text-lime-600'>{value.status}</span>
          } else if (value.status === 'Unpaid') {
            return <span data-cy="invoice-status">{value.status}</span>
          } else {
            return <span data-cy="invoice-status" className='text-rose-800 font-bold'>{value.status}</span>
          }
        }
      },
      {
        Header: t("accountOrganisations.invoices.list.amount"),
        accessor: "amountDue",
        Cell: ({ value }:any) => { 
          return (<PriceDisplay price={value.amountDue} />);
        }
      },
      {
        Header: t("accountOrganisations.invoices.list.dueDate"),
        accessor: "dueDate",
        Cell: ({ value }:any) => { 
          return (formatDate(value.dueDate));
        }
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ value }:any) => {
          return (
            <div className='flex justify-end'>
              
              { value.status !== 'Paid' && 
                <Link to={`/account/organisations/${organisationId}/invoices/${value.id}/payment`} className='pr-4' data-cy="pay-invoice-link">
                  {t("accountOrganisations.invoices.list.payInvoice")}
                </Link>
              }
              
              <a href={value.invoicePdfUrl} target="_blank" rel="noreferrer" data-cy="download-link">
                {t("accountOrganisations.invoices.list.download")}
              </a>

              { value.receiptUrl !== undefined && value.receiptUrl !== null &&
                <a href={value.receiptUrl} target="_blank" rel="noreferrer" className='pl-4' data-cy="receipt-link">
                  {t("accountOrganisations.invoices.list.receipt")}
                </a>
              }
            </div>
          );
        },
      },
    ],
    [t, organisationId]
  );

  return (
    <div className='mb-6 border-b flex justify-between'>

      {!invoices && <p data-cy="no-invoices-msg">{t('accountOrganisations.invoices.list.noInvoices')}</p>}
      {invoices && <Table columns={columns} data={data} />}
    </div>
  )
}