import React from 'react'
import { RequestStockItem } from '../../../../app/model/service-requests'

interface RequestStockItemsProps {
  stockItems?: RequestStockItem[]
}

export default function RequestStockItems({stockItems}: RequestStockItemsProps) {

  if (stockItems === undefined || stockItems.length === 0)
    return null;

  return (
    <div>
      <h2>Stock Items</h2>
      <table className='border-collapse w-full mt-4'>
        <thead>
          <tr className='border-b'>
            <th className='text-left'>Product</th>
            <th className='text-right'>Quantity</th>
          </tr>
        </thead>
        <tbody>
        {stockItems && stockItems.map(item => (
          <tr className='border-b' key={item.id}>
            <td className='py-1'>{item.name} - {item.sku}</td>
            <td className='py-1 text-right'>{item.quantity}</td>
          </tr>
        ))}
      </tbody>
      </table>
    </div>
  )
}