import { useTranslation } from "react-i18next";
import { BiCalendarEvent } from "react-icons/bi";

interface SiteRemainingCapacityProps {
  bookedSpace: number,
  totalCapacity: number,
  loading: boolean
}

export default function SiteRemainingCapacity({ bookedSpace, totalCapacity, loading }:SiteRemainingCapacityProps) {
  const { t } = useTranslation();

  const remaining = totalCapacity - bookedSpace;

  return (
    <div className="flex items-center">
      <div className="mr-4 align-middle">
        <BiCalendarEvent className="text-4xl text-brand align-middle" />
      </div>
      <div>
        <small className="text-sm font-medium uppercase text-gray-400">
          {t("accountSites.metrics.siteRemainingCapacity")}
        </small>
        <br />
        <div className="text-3xl font-bold leading-6">
          {loading ? (
            <div className="h-8 w-6 bg-gray-200 animate-pulse rounded"></div>
          ) : remaining?.toLocaleString()}
        </div>
      </div>
    </div>
  );
}
