import useSWR from "swr";
import { tokenFetcher } from "../../../data/fetchers";
import apiUrl from "../../../data/api-url";
import { useAuth } from "../../../hooks/use-auth";

export function ShowOrganisationBreadcrumb({ match }) {
  const { user } = useAuth();
  const { organisationId } = match.params;

  const { data: organisation } = useSWR(
    user && organisationId
      ? [apiUrl("accounts", `/account/${organisationId}`), user.token]
      : null,
    tokenFetcher
  );

  return organisation?.name || null;
}
