import React, { useState } from "react";
import {
  CardElement,
  useElements,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/use-auth";
import { tokenFetcher } from "../../data/fetchers";
import apiUrl from "../../data/api-url";
import getBaseUrl from "../../utils/base-url";
import useSWR from "swr";
import { useForm } from "react-hook-form";
import Button from "../common/Button";
import {
  SmallCheckboxField,
  CheckboxInput,
  FieldErrorMessage,
} from "../common/forms";
import LoadingBlock from "../common/loading/LoadingBlock";
import { ConfirmCardPaymentData, loadStripe } from "@stripe/stripe-js";
import getStripeKey from "./stripe-key";
import { useStore } from "../../hooks/use-store";
import { observer } from "mobx-react-lite";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(getStripeKey());

interface StripePaymentMethodsProps { 
  returnUrl: string,
  requestId: string,
  successCallback: () => void
};


interface StripePaymentMethodsListProps { 
  returnUrl: string,
  requestId: string,
  successCallback: () => void
};

interface StripePaymentMethodsFormValues {
  paymentMethod: string,
  saveCardDetails: boolean
}

export default observer(function StripePaymentMethods({
  returnUrl,
  requestId,
  successCallback,
}: StripePaymentMethodsProps) {
  return (
    <Elements stripe={stripePromise}>
      <StripePaymentMethodsList
        returnUrl={returnUrl}
        requestId={requestId}
        successCallback={successCallback}
      />
    </Elements>
  );
});

function StripePaymentMethodsList({ returnUrl, requestId, successCallback }: StripePaymentMethodsListProps) {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAuth();
  const { userStore } = useStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<StripePaymentMethodsFormValues>({ mode: "onBlur", reValidateMode: "onChange" });

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const { data: paymentMethods } = useSWR(
    user
      ? [
          apiUrl(
            "payments",
            `/payment-accounts/${userStore.contextAccount!.id}/payment-methods`
          ),
          user?.token,
        ]
      : null,
    tokenFetcher
  );

  const confirmPayment = async (data:any) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setErrorMessage(null);
    setLoading(true);
    let stripePaymentMethod = data.paymentMethod;

    // if the payment method is a new card,
    // set the payment method to the CardElement, instead of the
    // payment method id.
    if (data.paymentMethod === "new-card") {
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
      stripePaymentMethod = { card: cardElement };
    }

    let stripeData:ConfirmCardPaymentData = {
      payment_method: stripePaymentMethod,
      return_url: `${getBaseUrl()}${returnUrl}`,
      setup_future_usage: undefined
    };

    if (
      paymentMethods === null ||
      paymentMethods.length === 0 ||
      data.saveCardDetails === true
    ) {
      stripeData.setup_future_usage = "off_session";
    }

    await stripe
      .confirmCardPayment(requestId, stripeData)
      .then((result) => {
        if (result.error) throw result.error;

        successCallback();
      })
      .catch((error) => {
        setErrorMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form className="" onSubmit={handleSubmit(confirmPayment)}>
      <h2 className="pb-8">{t("payments.formTitle")}</h2>
      <p className="pb-8">{t("payments.selectPaymentMethodText")}</p>
      {!paymentMethods && <LoadingBlock />}
      {paymentMethods && (
        <ul className="space-y-3">
          {paymentMethods.map((paymentMethod:any) => (
            <li
              key={paymentMethod.id}
              className="flex pb-3 border-b border-gray-200"
            >
              <div className="pr-4">
                <input
                  type="radio"
                  value={paymentMethod.id}
                  {...register("paymentMethod", {
                    required: t("payments.paymentMethodRequired"),
                    onChange: () => {
                      setErrorMessage(null);
                    },
                  })}
                />
              </div>
              <div>xxxx-xxxx-xxxx-{paymentMethod.last4}</div>
            </li>
          ))}
          {console.log(paymentMethods?.length)}
          <li className="flex">
            <div className="pr-4">
              <input
                type="radio"
                value="new-card"
                defaultChecked={paymentMethods?.length === 0}
                {...register("paymentMethod", {
                  required: t("payments.paymentMethodRequired"),
                  onChange: () => {
                    setErrorMessage(null);
                  },
                })}
              />
            </div>
            <div className="flex-1">
              <CardElement />
              <div className="mt-1">
                {paymentMethods?.length === 0 && (
                  <p>{t("payments.savedAsDefaukt")}</p>
                )}
                {paymentMethods?.length > 0 && (
                  <SmallCheckboxField
                    label={t("payments.saveCardLabel")}
                    htmlFor="saveCardDetails"
                  >
                    <CheckboxInput
                      name="saveCardDetails"
                      id="saveCardDetails"
                      className="w-auto"
                      register={register("saveCardDetails")}
                    />
                  </SmallCheckboxField>
                )}
              </div>
            </div>
          </li>
        </ul>
      )}

      {errorMessage && (
        <div>
          <FieldErrorMessage error={{ message: errorMessage, type: 'required' }} />
        </div>
      )}
      <FieldErrorMessage error={errors.paymentMethod} />
      <Button
        className="btn-brand mt-8"
        disabled={!stripe}
        label={t("payments.buttonText")}
        loading={loading}
      />
    </form>
  );
}
