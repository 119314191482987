import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import apiUrl from "../../../../data/api-url";
import { useAuth } from "../../../../hooks/use-auth";

export default function PlanChanged() {
  const { t } = useTranslation();
  const { reloadUser } = useAuth();

  useMemo(async () => {
    mutate(apiUrl("accounts", "users"));
    await reloadUser();
  }, [reloadUser]);

  return (
    <div className="space-y-8">
      <h2>{t("accountOrganisations.planChanged")}</h2>
    </div>
  );
}
