import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useHistory } from "react-router-dom";

import { useAuth } from "../../../hooks/use-auth";
import {
  PasswordInput,
  TextInput,
  Field,
  FieldErrorMessage,
} from "../../common/forms";
import { getResetPasswordOptions } from "./authentication-options";
import { useState } from "react";
import Button from "../../common/Button";
import platform from "platform";
import apiUrl from "../../../data/api-url";
import { submitAnonymousForm } from "../../../data/submit-form";

export default function ResetPassword() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const { resetNewPassword } = useAuth();
  const history = useHistory();

  const search = useLocation().search;
  const email = new URLSearchParams(search).get("email");

  const registerOptions = getResetPasswordOptions();

  const submitForm = async (data) => {
    setLoading(true);
    try {
      await resetNewPassword({
        email: email,
        confirmCode: data.confirmCode,
        newPassword: data.newPassword,
      }).then(async () => {
        const clientInfo = {
          emailAddress: email,
          browserName: platform.name,
          operatingSystem: platform.os.toString(),
        };

        // Send the password changed notification
        await submitAnonymousForm(
          apiUrl("accounts", "/users/password-reset"),
          clientInfo,
          "PUT"
        ).then(() => {
          setLoading(false);
          history.replace("/sign-in?password_reset=true");
        });
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto mt-7 space-y-8">
      <h1>{t("resetPassword.setPasswordTitle")}</h1>

      <p>{t("resetPassword.setPasswordDesc")}</p>

      <form onSubmit={handleSubmit(submitForm)} className="space-y-8 md:w-2/3">
        <Field label={t("confirmAccount.confirmCode")} htmlFor="confirmCode">
          <TextInput
            name="confirmCode"
            register={register("confirmCode", registerOptions.confirmCode)}
          />
          <FieldErrorMessage error={errors.confirmCode} />
        </Field>

        <Field
          label={t("changePassword.newPassword")}
          htmlFor="newPassword"
          helpText={t("auth.passwordRequirements")}
        >
          <PasswordInput
            name="newPassword"
            register={register("newPassword", registerOptions.newPassword)}
          />
          <FieldErrorMessage error={errors.newPassword} />
        </Field>

        <Field
          label={t("changePassword.confirmPassword")}
          htmlFor="confirmPassword"
        >
          <PasswordInput
            name="confirmPassword"
            register={register(
              "confirmPassword",
              registerOptions.confirmPassword
            )}
          />
          <FieldErrorMessage error={errors.confirmPassword} />
        </Field>

        <Button
          className="btn-brand"
          label={t("resetPassword.formButtonText")}
          loading={loading}
        />
      </form>
    </div>
  );
}
