import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/date-transformations";
import LoadingBlock from "../../common/loading/LoadingBlock";
import Table from "../../common/Table";

export default function BookingList({ bookings }) {
  const { t } = useTranslation();

  const data = useMemo(
    () =>
      bookings
        ? bookings.map((booking) => ({
            reference: booking,
            startDate: formatDate(booking.startDate, true),
            endDate: formatDate(booking.endDate, true),
            quantity: booking.quantity.toLocaleString(),
            status: booking.status,
          }))
        : [],
    [bookings]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("accountBookings.booking.reference"),
        accessor: "reference",
        Cell: ({ value }) => (
          <Link to={`/account/bookings/${value.id}/`} className="text-link">
            {value.reference}
          </Link>
        ),
      },
      {
        Header: t("accountBookings.booking.startDate"),
        accessor: "startDate",
      },
      {
        Header: t("accountBookings.booking.endDate"),
        accessor: "endDate",
      },
      {
        Header: t("accountBookings.booking.quantity"),
        accessor: "quantity",
      },
      {
        Header: t("accountBookings.booking.status"),
        accessor: "status",
      },
    ],
    [t]
  );

  return (
    <div>
      <main className="space-y-8">
        {bookings && bookings.length > 0 && (
          <Table columns={columns} data={data} rightLastColumn={false} />
        )}
        {bookings && bookings.length === 0 && (
          <div>{t("accountBookings.noBookings")}. </div>
        )}
        {!bookings && <LoadingBlock />}
      </main>
    </div>
  );
}
