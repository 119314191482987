import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useSWR from "swr";

import { useAuth } from "../../../../hooks/use-auth";
import apiUrl from "../../../../data/api-url";
import { tokenFetcher } from "../../../../data/fetchers";
import Table from "../../../common/Table";
import { getSiteCertificationStatus } from "../../../../data/data-formatter";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import formatAddress from "../../../../utils/address-formatter";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../hooks/use-store';
import { Site } from '../../../../app/model/sites';

interface CellItem {
  value: Site
}

export default observer(function SiteList() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { userStore } = useStore();

  const { data: sites } = useSWR<Site[]>(
    userStore.contextAccount?.id 
      ? [
          apiUrl("spaces", `/sites/account-sites/${userStore.contextAccount.id }`),
          user.token,
        ]
      : null,
    tokenFetcher
  );
  const newSiteUrl = "/account/sites/new";

  const data = useMemo(
    () =>
      sites
        ? sites.map((site) => ({
            name: site,
            certificationStatus: getSiteCertificationStatus(
              site.certificationStatus,
              t
            ),
            address: formatAddress(site.address),
          }))
        : [],
    [sites, t]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("accountSites.site.name"),
        accessor: "name",
        Cell: ({ value }:CellItem) => (
          <Link to={`/account/sites/${value.id}`} className="text-link">
            {value.name}
          </Link>
        ),
      },
      {
        Header: t("accountSites.site.status"),
        accessor: "certificationStatus",
      },
      {
        Header: t("accountSites.site.address"),
        accessor: "address",
      },
    ],
    [t]
  );

  return (
    <div>
      <main className="space-y-8">
        {sites && sites.length > 0 && (
          <Table columns={columns} data={data} rightLastColumn={false} />
        )}
        {sites && sites.length === 0 && (
          <div>
            {t("accountSites.site.noSites")}.{" "}
            <Link to={newSiteUrl}>{t("accountSites.site.addFirst")}</Link>
          </div>
        )}
        {!sites && <LoadingBlock />}
      </main>
    </div>
  );
});