import { useTranslation } from "react-i18next";
import {
  CheckboxField,
  CheckboxInput,
  FieldErrorMessage,
} from "../common/forms";
import MarketplaceTermsEn from "../common/legal/MarketplaceTermsEn";

export default function BookingTerms({ formMethods }) {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = formMethods;

  return (
    <div className="space-y-3">
      <MarketplaceTermsEn showAll={false} allowCollapse={true} />

      <div>
        <CheckboxField
          label={t("booking.bookingTerms.checkboxLabel")}
          htmlFor="agreeTerms"
        >
          <CheckboxInput
            name="agreeTerms"
            id="agreeTerms"
            className="w-auto"
            register={register("agreeTerms", {
              required: t("booking.bookingTerms.requiredMessage"),
            })}
            error={errors.agreeTerms}
          />
        </CheckboxField>
        <FieldErrorMessage error={errors.agreeTerms} />
      </div>
    </div>
  );
}
