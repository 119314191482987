import React from 'react'
import { useTranslation } from 'react-i18next'
import { BookingStockItemQuantity, SpaceLineItem } from '../../../app/model/bookings';

interface BookingStockItemsProps {
  stockQuantities: BookingStockItemQuantity[];
  spaceItems: SpaceLineItem[];
  bookingId: string
}

export default function BookingStockItems({spaceItems, stockQuantities, bookingId}: BookingStockItemsProps) {
  const { t } = useTranslation();

  return (
    <div className='space-y-6'>

      {(stockQuantities === undefined || stockQuantities.length === 0) && (
        <p>{t("accountBookings.bookingStock.noStockItems")}</p>
      )}

      {stockQuantities.length > 0 && spaceItems.map((item) => (
        <div key={item.siteId} className='mb-10'>
          <div className='mb-2'>
            <p className='text-sm uppercase mb-[-5px]'>{t("accountBookings.bookingStock.site")}</p>
            <h3 className='font-bold'>{item.siteName}</h3>
          </div>
          <div>
              <div className='flex justify-between border-b border-gray-300 py-1'>
                <div className='font-bold'>Product</div>
                <div className='text-right font-bold'>Quantity</div>
              </div>
            {stockQuantities.map((stockItem) => (
              <div className='flex justify-between border-b border-gray-200 py-1' key={stockItem.stockItemId}>
                <div>{stockItem.name} - {stockItem.sku}</div>
                <div className='text-right'>{stockItem.quantity}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}