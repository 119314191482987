import { Auth } from "aws-amplify";

export default async function defaultFetcher(...args) {
  const res = await fetch(...args);

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.text();
    error.status = res.status;
    error.unauthorised = res.status === 401 || res.status === 403;
    throw error;
  }
  return res.json();
}

/*
 * The opts parameter used to be the id token, so we need to first check if opts is type string, and if it is, just ignore it.
 * if the opts has the 'longPolling' property set to true, we need to add the 'X-Long-Polling' header with the currect UTC time to
 * enable long polling on the server
 */
export async function tokenFetcher(url, opts) {
  const cognitoSession = await Auth.currentSession();
  const idToken = cognitoSession.idToken.jwtToken;

  let reqHeaders = {
    Authorization: `Bearer ${idToken}`,
  };
  reqHeaders["X-Long-Polling"] = "false";

  if (typeof opts !== "string" && opts?.longPolling === true) {
    reqHeaders["X-Long-Polling"] = "default";
  } else if (
    typeof opts !== "string" &&
    Object.prototype.toString.call(opts?.longPolling) === "[object Date]"
  ) {
    reqHeaders["X-Long-Polling"] = opts?.longPolling.toJSON(); // this is the JavaScript date as a c# DateTime
  }

  const res = await fetch(url, {
    headers: reqHeaders,
  });

  if (!res.ok) {
    const error = new Error("An error occurred while fetching the data.");
    error.info = await res.text();
    error.status = res.status;
    error.unauthorised = res.status === 401 || res.status === 403;
    throw error;
  }

  return res.json();
}
