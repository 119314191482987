import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import apiUrl from "../../../../../data/api-url";
import { tokenFetcher } from "../../../../../data/fetchers";
import { submitDelete } from "../../../../../data/submit-form";
import { useAuth } from "../../../../../hooks/use-auth";
import Button, { LinkButton } from "../../../../common/Button";
import LoadingBlock from "../../../../common/loading/LoadingBlock";

export default observer(function RemoveService() {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const { siteId, serviceId } = useParams();
  const { user } = useAuth();

  const { data: service } = useSWR(
    user
      ? [apiUrl("spaces", `/sites/${siteId}/services/${serviceId}`), user.token]
      : null,
    tokenFetcher
  );

  const handleRemove = async () => {
    setSubmitting(true);

    await submitDelete(
      apiUrl("spaces", `/sites/${siteId}/services/${serviceId}`),
      user.token
    )
      .then(() => {
        // Force reload of services
        mutate(apiUrl("spaces", `/sites/${siteId}/services`));

        history.push(`/account/sites/${siteId}`);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (!service) {
    return <LoadingBlock />;
  }

  return (
    <div className="space-y-8">
      <h1>{t("accountSites.removeService")}</h1>

      <p>{t("accountSites.service.removeServiceLeadText")}.</p>

      <div className="space-y-4">
        <h3>
          {t("accountSites.service.serviceName")}: {service.name}
        </h3>
        <p>
          {t("accountSites.service.customerInformation")}:<br />
          <span className="italic text-gray-500">
            {service.customerInformation}
          </span>
        </p>
      </div>

      <div>
        <Button
          label={t("accountSites.service.remove")}
          loading={submitting}
          onClick={handleRemove}
        />
        <LinkButton
          label={t("common.cancel")}
          onClick={() => {
            history.push(`/account/sites/${siteId}`);
          }}
        />
      </div>
    </div>
  );
});
