import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import apiUrl from "../../../data/api-url";
import { useAuth } from "../../../hooks/use-auth";
import { useState, useRef } from "react";
import {
  TextInput,
  EmailInput,
  PasswordInput,
  Field,
  FieldErrorMessage,
  CheckboxField,
  CheckboxInput,
  Form,
} from "../../common/forms";
import ErrorMessage from "../../common/messages/ErrorMessage";
import Button from "../../common/Button";
import FieldLoadingSpinner from "../../common/forms/FieldLoadingSpinner";
import { submitAnonymousForm } from "../../../data/submit-form";
import FieldSuccessMessage from "../../common/forms/FieldSuccessMessage";
import ReCAPTCHA from "react-google-recaptcha";
import getRegisterFields from "./register-fields";
import MarketplaceTermsEn from "../../common/legal/MarketplaceTermsEn";

export default function Register() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const history = useHistory();
  const { signUp } = useAuth();
  const [errorMessage, setErrorMessage] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [uniqueDisplayName, setUniqueDisplayName] = useState(false);
  const [submittingDisplayName, setSubmittingDisplayName] = useState(false);
  const [submittingEmail, setSubmittingEmail] = useState(false);
  const [uniqueEmail, setUniqueEmail] = useState(false);
  const defaultPlanId = "5cb6e72d-590e-4d33-aae5-4c5b71de55b5";
  const errorScrollRef = useRef(null);
  const recaptchaRef = useRef();
  const [recaptchaSuccess, setRecaptchaSuccess] = useState(false);
  const registerFields = getRegisterFields(t, getValues);

  const registerUser = async (formData) => {
    if (recaptchaSuccess === false) {
      setError("recaptcha", {
        type: "custom",
        message: t("common.validation.invalidRecaptcha"),
      });
      return;
    }

    setSubmitting(true);
    formData.accountPlanId = defaultPlanId;
    formData.agreedTermsVersion = 1;

    await signUp(formData)
      .then(() => {
        // Basic plan by default, so return to confirm account
        history.replace(`/confirm-account?email=${formData.email}`);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        errorScrollRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const checkUniqueDisplayName = async (value) => {
    setSubmittingDisplayName(true);
    setUniqueDisplayName(false);
    let isUnique = true;
    await submitAnonymousForm(apiUrl("accounts", "/account/check-unique"), {
      userProperty: "displayname",
      value: value,
    })
      .then(({ data }) => {
        isUnique = data.isUnique;
        setUniqueDisplayName(isUnique);
      })
      .catch(() => {
        // Do nothing, let the server validation pick it up when form submitted
      });
    setSubmittingDisplayName(false);
    return isUnique ? true : t("auth.registerForm.displayNameNotUnique");
  };

  const checkUniqueEmail = async (value) => {
    setSubmittingEmail(true);
    setUniqueEmail(false);
    let isUnique = true;
    await submitAnonymousForm(apiUrl("accounts", "/account/check-unique"), {
      userProperty: "email",
      value: value,
    })
      .then(({ data }) => {
        isUnique = data.isUnique;
        setUniqueEmail(isUnique);
      })
      .catch(() => {
        // Do nothing, let the server validation pick it up when form submitted
      });
    setSubmittingEmail(false);
    return isUnique ? true : t("auth.registerForm.emailNotUnique");
  };

  const onRecaptchaChange = () => {
    const value = recaptchaRef.current.getValue();
    if (value !== undefined) {
      clearErrors("recaptcha");
      setRecaptchaSuccess(true);
    }
  };

  const invalidateRecaptcha = () => {
    setRecaptchaSuccess(false);
  };

  return (
    <div className="container mx-auto mt-7 space-y-8 xl:w-1/2 max-w-5xl">
      <h1>{t("auth.register")}</h1>

      <Form onSubmit={handleSubmit(registerUser)}>
        {errorMessage && (
          <div ref={errorScrollRef}>
            <ErrorMessage
              title={t("auth.registerErrorTitle")}
              message={errorMessage}
            />
          </div>
        )}

        <div className="grid md:grid-cols-2 md:gap-6">
          <Field
            label={t("auth.firstName")}
            htmlFor="firstName"
            data-cy="field-firstName"
          >
            <TextInput
              name="firstName"
              register={register("firstName", registerFields.firstName)}
            />
            <FieldErrorMessage error={errors.firstName} />
          </Field>

          <Field
            label={t("auth.lastName")}
            htmlFor="lastName"
            data-cy="field-lastName"
          >
            <TextInput
              name="lastName"
              register={register("lastName", registerFields.lastName)}
            />
            <FieldErrorMessage error={errors.lastName} />
          </Field>
        </div>

        <Field
          label={t("auth.displayName")}
          htmlFor="displayName"
          helpText={t("auth.displayNameHelp")}
          data-cy="field-displayName"
        >
          <div className="relative">
            <TextInput
              name="displayName"
              register={register("displayName", {
                required: t("auth.registerForm.displayNameRequired"),
                onBlur: () => setUniqueDisplayName(false),
                validate: {
                  checkUnique: (v) => {
                    if (!uniqueDisplayName) checkUniqueDisplayName(v);
                  },
                },
              })}
            />
            {submittingDisplayName && <FieldLoadingSpinner />}
          </div>
          <FieldErrorMessage error={errors.displayName} />
          {uniqueDisplayName && (
            <FieldSuccessMessage
              message={t("auth.registerForm.displayNameUnique")}
            />
          )}
        </Field>

        <Field label={t("auth.email")} htmlFor="email" data-cy="field-email">
          <div className="relative">
            <EmailInput
              name="email"
              register={register("email", {
                required: t("auth.registerForm.emailRequired"),
                onBlur: () => setUniqueEmail(false),
                validate: {
                  checkUnique: (v) => {
                    if (!uniqueEmail) checkUniqueEmail(v);
                  },
                },
              })}
            />
            {submittingEmail && <FieldLoadingSpinner />}
          </div>
          <FieldErrorMessage error={errors.email} />
          {uniqueEmail && (
            <FieldSuccessMessage message={t("auth.registerForm.emailUnique")} />
          )}
        </Field>

        <div className="grid md:grid-cols-2 md:gap-6">
          <Field
            label={t("auth.password")}
            htmlFor="password"
            helpText={t("auth.passwordRequirements")}
            data-cy="field-password"
          >
            <PasswordInput
              name="password"
              register={register("password", registerFields.password)}
            />
            <FieldErrorMessage error={errors.password} />
          </Field>

          <Field
            label={t("auth.confirmPassword")}
            htmlFor="confirmPassword"
            data-cy="field-confirmPassword"
          >
            <PasswordInput
              name="confirmPassword"
              register={register(
                "confirmPassword",
                registerFields.confirmPassword
              )}
            />
            <FieldErrorMessage error={errors.confirmPassword} />
          </Field>
        </div>

        <Field
          label={t("auth.companyName")}
          htmlFor="companyName"
          helpText={t("auth.companyNameHelp")}
        >
          <TextInput name="companyName" register={register("companyName")} />
        </Field>

        <div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LfbC9kfAAAAADfgTKRrieKOAFZNpMvp8l3hszQO"
            onChange={onRecaptchaChange}
            onExpired={invalidateRecaptcha}
            onErrored={invalidateRecaptcha}
          />
          <FieldErrorMessage error={errors.recaptcha} />
        </div>

        <div>
          <p>
            <strong>{t("auth.termsOfUse")}</strong>
          </p>
          <MarketplaceTermsEn
            showAll={false}
            allowCollapse={true}
            minDisplaySize={true}
          />
        </div>

        <div data-cy="field-agreeTerms">
          <CheckboxField label={t("auth.accountTerms")} htmlFor="agreeTerms">
            <CheckboxInput
              name="agreeTerms"
              id="agreeTerms"
              className="w-auto"
              register={register("agreeTerms", registerFields.agreeTerms)}
              error={errors.agreeTerms}
            />
          </CheckboxField>
          <FieldErrorMessage error={errors.agreeTerms} />
        </div>

        <div className="space-y-8">
          <Button
            type="submit"
            className="btn-brand"
            label={t("auth.register")}
            loading={submitting}
          />
        </div>
      </Form>
    </div>
  );
}
