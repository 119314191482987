interface ButtonProps {
  label: string,
  type?: 'submit' | 'reset' | 'button' | undefined,
  loading?: boolean,
  className?: string,
  onClick?(): void,
  disabled?: boolean
}

export default function Button({
  label,
  type = "submit",
  loading = false,
  className = "btn-brand",
  onClick = undefined,
  disabled = false,
}:ButtonProps) {
  return (
    <button
      type={type}
      className={`${className} ${loading ? "btn-loading" : ""}`}
      disabled={loading || disabled ? true : false}
      onClick={onClick}
      data-cy={type}
    >
      {loading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-500 inline"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {label}
    </button>
  );
}

export const LinkButton = (props:ButtonProps) =>
  Button({
    ...props,
    type: "button",
    className: `btn-link ${props.className}`,
  });
