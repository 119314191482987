export function getResetPasswordOptions() {
  return {
    email: {
      required: true,
    },
  };
}

export function getResetNewPasswordOptions(getValues, t) {
  return {
    confirmCode: {
      required: true,
    },
    newPassword: {
      required: true,
    },
    confirmPassword: {
      required: true,
      validate: {
        passwordMatch: (value) => {
          const { newPassword } = getValues();
          return value === newPassword || t("auth.passwordsDiffer");
        },
      },
    },
  };
}
