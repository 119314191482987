interface InputProps {
  name: string,
  type?: 'text' | 'number' | 'select' | 'checkbox' | 'date' | 'email' | 'password' | 'file',
  id?: string,
  register?: any,
  error?: any,
  className?: string,
  step?: string,
  defaultValue?: string,
  autoComplete?: string,
  placeholder?: string,
  change?: (e:any) => void
}

export function Input({
  name,
  id,
  register,
  type = 'text',
  error = false,
  className = "",
  ...inputProps
}: InputProps) {
  return (
    <input
      type={type ? type : 'text'}
      id={id || name}
      className={`input ${className} ${error ? "border-red-500" : ""}`}
      {...inputProps}
      {...register}
    />
  );
}

interface CurrencyInputProps extends InputProps {
  currency: any
}

export function CurrencyInput({ currency, ...props }: CurrencyInputProps) {
  return (
    <div className="flex">
      <span className="block rounded-tl rounded-bl py-3 px-4 border border-r-0 border-gray-200 font-semibold shadow-sm bg-gray-50">
        {currency.symbol}
      </span>
      <Input
        step="0.01"
        className="input rounded-none"
        {...props}
        type="number"
      />
      <span className="block rounded-tr rounded-br py-3 px-4 border border-l-0 border-gray-200 font-semibold shadow-sm bg-gray-50">
        {currency.code}
      </span>
    </div>
  );
}

interface NumberInputProps extends InputProps {
  unit: string
}

export function NumberInputWithUnit({ unit, ...props }: NumberInputProps) {
  return (
    <div className="flex">
      <Input className="input rounded-r-none" {...props} type="number" />
      <span className="block rounded-tr rounded-br py-3 px-4 border border-l-0 border-gray-200 font-semibold shadow-sm bg-gray-50">
        {unit}
      </span>
    </div>
  );
}

interface SelectInputProps extends InputProps {
  children?: React.ReactNode
}

export function SelectInput({
  name,
  register,
  id,
  defaultValue = "Select...",
  placeholder,
  className = "",
  children,
  ...selectProps
}: SelectInputProps) {
  return (
    <select
      className={`input ${className}`}
      defaultValue={defaultValue}
      {...register}
      {...selectProps}
    >
      {placeholder && <option>{placeholder}</option>}
      {children}
    </select>
  );
}

interface CheckboxInputProps extends InputProps {
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

export function CheckboxInput({
  name,
  id,
  register,
  error = false,
  className = "",
  ...inputProps
}: CheckboxInputProps) {
  return (
    <input
      id={id || name}
      className={`${className} ${error ? "border-red-500" : ""}`}
      {...inputProps}
      type="checkbox"
      {...register}
    />
  );
}

export const DateInput = (props: InputProps) => Input({ ...props, type: "date" });
export const EmailInput = (props: InputProps) => Input({ ...props, type: "email" });
export const NumberInput = (props: InputProps) =>
  Input({ ...props, type: "number", className: "hide-spinner" });
export const PasswordInput = (props: InputProps) => Input({ ...props, type: "password" });
export const TextInput = (props: InputProps) => Input({ ...props, type: "text" });
export const FileInput = (props: InputProps) => Input({ ...props, type: "file" });

export default Input;
