import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Link } from "react-router-dom";
import apiUrl from "../../../data/api-url";
import { tokenFetcher } from "../../../data/fetchers";
import ListedPalletSpace from "../dashboard-widgets/ListedPalletSpace";
import SiteList from "./Site/SiteList";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks/use-store';
import SiteSpaceCount from '../dashboard-widgets/SiteSpaceCount';
import OpenServiceRequests from '../dashboard-widgets/OpenServiceRequests';
import { useEffect, useState } from 'react';

export default observer(function SitesDashboard() {
  const { t } = useTranslation();
  const { userStore } = useStore();
  const [loading, setLoading] = useState(true);

  const { data: sitesMetrics } = useSWR(
    userStore.contextAccount?.id
      ? [apiUrl("spaces", `/metrics/account-sites/${userStore.contextAccount.id}`)]
      : null,
    tokenFetcher
  );

  const { data: requestsMetrics } = useSWR(
    userStore.contextAccount?.id
      ? [
          apiUrl(
            "bookings",
            `/metrics/account-service-requests/${userStore.contextAccount.id}`
          ),
        ]
      : null,
    tokenFetcher
  );

  useEffect(() => {
    if (requestsMetrics !== undefined)
      setLoading(false);
  }, [requestsMetrics])
  

  return (
    <div className="space-y-10">
      <div className="flex justify-between items-center">
        <h1>{t("accountSites.sites")}</h1>
        <Link to="/account/sites/new" className="btn-brand">
          {t("accountSites.addSite")}
        </Link>
      </div>

      <ul className="grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-6">
        <li className="p-4 shadow-md rounded border border-gray-200 mb-4">
          <SiteSpaceCount
            siteCount={sitesMetrics?.siteCount}
            spaceCount={sitesMetrics?.spaceCount}
          />
        </li>
        <li className="p-4 shadow-md rounded border border-gray-200 mb-4">
          <ListedPalletSpace palletSpace={sitesMetrics?.listedPalletSpace} />
        </li>
        <li className="p-4 shadow-md rounded border border-gray-200 mb-4">
          <OpenServiceRequests openRequests={requestsMetrics?.openServiceRequests ?? 0} loading={loading} />
        </li>
      </ul>

      <SiteList />
    </div>
  );
});