import dayjs from "dayjs";

function dateIsCurrent({ value, t }) {
  const dateValue = dayjs(value);
  const today = dayjs();

  return (
    !dateValue.isBefore(today, "day") ||
    t("accountSites.availability.validation.startDate.dateInPast")
  );
}

function validateWeekValueWithinDateRange({ value, getValues, t }) {
  const { startDate, endDate } = getValues();
  const startDateValue = dayjs(startDate);
  const endDateValue = dayjs(endDate);

  if (!startDateValue.isValid() || !endDateValue.isValid()) {
    return true;
  }

  return (
    value <= endDateValue.diff(startDateValue, "week") ||
    t(
      "accountSites.availability.validation.minBookingPeriod.greaterThanDateRange"
    )
  );
}

export default function getRegisterOptions(getValues, t, space) {
  return {
    startDate: {
      required: t("accountSites.availability.validation.dateFromRequired"),
      validate: {
        dateIsCurrent: (value) => dateIsCurrent({ value, t }),
      },
    },
    endDate: {
      required: t("accountSites.availability.validation.dateToRequired"),
      validate: {
        afterStartDate: (value) => {
          const { startDate } = getValues();
          const startDateValue = dayjs(startDate);
          const endDateValue = dayjs(value);

          if (!startDateValue.isValid() || !endDateValue.isValid()) {
            return true;
          }

          return (
            !endDateValue.isBefore(startDateValue, "day") ||
            t("accountSites.availability.validation.endDate.beforeStartDate")
          );
        },
      },
    },
    minBookingPeriod: {
      required: true,
      min: {
        value: 0,
        message: t("common.validation.min", { value: 0 }),
      },
      valueAsNumber: true,
      validate: {
        validateWeekValueWithinDateRange: (value) =>
          validateWeekValueWithinDateRange({ value, getValues, t }),
      },
    },
    leadTime: {
      required: t("accountSites.availability.validation.leadTimeRequired"),
      min: {
        value: 0,
        message: t("common.validation.min", { value: 0 }),
      },
    },
    maxAvailableQuantity: {
      required: t("accountSites.availability.validation.maxQuantityRequired"),
      min: {
        value: 1,
        message: t("common.validation.min", { value: 1 }),
      },
      valueAsNumber: true,
      validate: {
        maxSpaceQuantity: (value) =>
          value <= space.quantity ||
          t(
            "accountSites.availability.validation.maxAvailableQuantity.greaterThanSpaceMaximum",
            { value: space.quantity }
          ),
      },
    },
    minBookingQuantity: {
      required: t("accountSites.availability.validation.minQuantityRequired"),
      min: {
        value: 1,
        message: t("common.validation.min", { value: 1 }),
      },
      validate: {
        maxAvailableQuantity: (value) => {
          const { maxAvailableQuantity } = getValues();
          return (
            value <= maxAvailableQuantity ||
            t(
              "accountSites.availability.validation.minBookingQuantity.greaterThanMaximum"
            )
          );
        },
      },
    },
    unitPrice: {
      required: t("accountSites.availability.validation.unitPriceRequired"),
      min: {
        value: 0,
        message: t("common.validation.min", { value: 0 }),
      },
    },
    rhdInUnitPrice: {
      required: t("accountSites.availability.validation.rhdInPriceRequired"),
      min: {
        value: 0,
        message: t("common.validation.min", { value: 0 }),
      },
    },
    rhdStdUnitPrice: {
      required: true,
      min: {
        value: 0,
        message: t("common.validation.min", { value: 0 }),
      },
    },
    rhdOutUnitPrice: {
      required: t("accountSites.availability.validation.rhdOutPriceRequired"),
      min: {
        value: 0,
        message: t("common.validation.min", { value: 0 }),
      },
    },
  };
}
