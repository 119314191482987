import i18n from "../../../i18n";
import StockItemDetails from './details/StockItemDetails';
import EditStockItem from './form/EditStockItem';
import NewStockItem from './form/NewStockItem';
import ImportComplete from './import/ImportComplete';
import ImportStockItems from './import/ImportStockItems';
import StockDashboard from './StockDashboard';

const stockManagementRoutes = [
  {
    exact: true,
    path: "/account/stock-management/new",
    component: NewStockItem,
    breadcrumb: i18n.t("stockManagement.breadcrumbs.new"),
  },
  {
    exact: true,
    path: "/account/stock-management/import/complete",
    component: ImportComplete,
    breadcrumb: i18n.t("stockManagement.breadcrumbs.importPending"),
  },
  {
    exact: true,
    path: "/account/stock-management/import",
    component: ImportStockItems,
    breadcrumb: i18n.t("stockManagement.breadcrumbs.import"),
  },
  {
    exact: true,
    path: "/account/stock-management/:stockItemId",
    component: StockItemDetails,
    breadcrumb: i18n.t("stockManagement.breadcrumbs.stockItemDetails"),
  },
  {
    exact: true,
    path: "/account/stock-management/:stockItemId/edit",
    component: EditStockItem,
    breadcrumb: i18n.t("stockManagement.breadcrumbs.edit"),
  },
  {
    path: "/account/stock-management",
    component: StockDashboard,
    exact: true,
    breadcrumb: i18n.t("stockManagement.breadcrumbs.stockManagement"),
  }
];

export default stockManagementRoutes;
