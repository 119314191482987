import {
  PaymentElement,
  useElements,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../common/Button";
import { loadStripe } from "@stripe/stripe-js";
import getStripeKey from "./stripe-key";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(getStripeKey());

export default function StripeCardDetails({ requestId, returnUrl }) {
  const options = {
    // passing the client secret obtained in step 2
    clientSecret: requestId,
    // Fully customizable with appearance API.
    appearance: {
      variables: {
        colorPrimary: "#6481aa",
        colorBackground: "#ffffff",
        colorText: "#374151",
        colorDanger: "#b91c1c",
        fontFamily:
          'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
      },
    },
    locale: "en-GB",
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeCardDetailsForm returnUrl={returnUrl} />
    </Elements>
  );
}

function StripeCardDetailsForm({ returnUrl }) {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    setSubmitting(true);

    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // TODO: Fix this
        return_url: returnUrl,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form className="space-y-6" onSubmit={handleSubmit}>
      <div className="grid md:grid-cols-2 gap-6">
        <PaymentElement />
      </div>
      <Button
        className="btn-brand mt-8"
        disabled={!stripe}
        label={t("payments.addPaymentMethod")}
        loading={submitting}
      />
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
}
