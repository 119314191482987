import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import apiUrl from "../../../../data/api-url";
import submitForm from "../../../../data/submit-form";
import { useAuth } from "../../../../hooks/use-auth";
import getBaseUrl from "../../../../utils/base-url";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import StripeCardDetails from "../../../payments/StripeCardDetails";

export default function AddPaymentMethod() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { organisationId } = useParams();
  const [requestId, setRequestId] = useState();

  useEffect(() => {
    async function loadRequestId() {
      await submitForm(
        apiUrl(
          "payments",
          `/payment-accounts/${organisationId}/payment-methods`
        ),
        {},
        user.token
      )
        .then(({ data }) => {
          setRequestId(data.paymentMethodRequestId);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    loadRequestId();
  }, [user.token, organisationId]);

  return (
    <div className="space-y-8">
      <h2>{t("accountOrganisations.paymentMethods.addPaymentMethod")}</h2>

      {!requestId && <LoadingBlock />}
      {requestId && (
        <StripeCardDetails
          requestId={requestId}
          returnUrl={`${getBaseUrl()}/account/organisations/${organisationId}/payment-methods`}
        />
      )}
    </div>
  );
}
