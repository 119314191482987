import React, { useState } from 'react'
import Button from '../../../../common/Button'
import { useTranslation } from 'react-i18next'
import submitForm from '../../../../../data/submit-form';
import apiUrl from '../../../../../data/api-url';
import { useAuth } from '../../../../../hooks/use-auth';
import { useStore } from '../../../../../hooks/use-store';
import { mutate } from 'swr';

interface CloseRequestProps {
  canClose: boolean;
  bookingId: string;
  requestId: string;
  siteId: string
  callback: () => void;
}

export default function CloseRequest({canClose, bookingId, requestId, siteId, callback}: CloseRequestProps) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { userStore } = useStore();
  const [closingRequest, setClosingRequest] = useState(false);

  const closeServiceRequest = async () => {
    setClosingRequest(true);

    await submitForm(
      apiUrl(
        "bookings",
        `/bookings/${bookingId}/service-requests/${requestId}/close`
      ),
      {
        accountId: userStore.contextAccount?.id,
        siteId: siteId,
      },
      user.token,
      "PUT"
    )
      .then(() => {
        mutate(
          apiUrl("bookings", `/site/${siteId}/service-requests/${requestId}`)
        );
        callback();
      })
      .finally(() => {
        setClosingRequest(false);
      });
  };

  return (
  <Button
    type="button"
    onClick={() => closeServiceRequest()}
    label={t("accountBookings.serviceRequestDetail.actions.close")}
    className="btn-brand mr-6"
    loading={closingRequest}
    disabled={!canClose}
  />
  )
}