import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import { Link } from "react-router-dom";

import apiUrl from "../../../../data/api-url";
import { tokenFetcher } from "../../../../data/fetchers";

import SpaceList from "../Space/List";
import ServiceList from "./services/ServiceList";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import SiteBookingList from "./bookings/SiteBookingList";
import UnauthorisedMessage from "../../../common/messages/UnauthorisedMessage";
import { swrOptions } from "../../../../data/swr-options";
import { useEffect, useState } from "react";
import { toastSuccess } from "../../../common/messages/toast-messages";
import { useAuth } from '../../../../hooks/use-auth';
import requests from '../../../../data/requests';
import { SiteBookingMetrics, SiteMetrics } from '../../../../app/model/sites';
import SiteValue from '../../dashboard-widgets/SiteValue';
import SiteCapacity from '../../dashboard-widgets/SiteCapacity';
import SiteRemainingCapacity from '../../dashboard-widgets/SiteRemainingCapacity';

export default function ShowSite() {
  const { t } = useTranslation();
  const { siteId } = useParams<{siteId: string}>();
  const location = useLocation<any>();
  const { user } = useAuth();
  const [ loading, setLoading ] = useState(true);
  const [ metrics, setMetrics ] = useState<SiteMetrics | undefined>(undefined);
  const [ bookingMetrics, setBookingMetrics ] = useState<SiteBookingMetrics | undefined>(undefined);

  const { data: site, error } = useSWR(
    siteId
      ? [
          apiUrl("spaces", `/sites/${siteId}`),
          { longPolling: location?.state?.longPolling },
        ]
      : null,
    tokenFetcher,
    swrOptions
  );

  
  const { data: spaces } = useSWR(
    site && user
      ? [apiUrl("spaces", `/sites/${site.id}/spaces`), user.token]
      : null,
    tokenFetcher
  );

  useEffect(() => {
    if (site && location?.state?.toastId) {
      toastSuccess(t("toasts.siteCreated"), location.state.toastId);
    }

    async function loadMetrics() {
      const siteResults = await requests.get<SiteMetrics>(apiUrl("spaces", `/metrics/site/${siteId}`));
      const siteBookingResults = await requests.get<SiteBookingMetrics>(apiUrl("bookings", `/metrics/site-bookings/${siteId}`));
      setMetrics(siteResults);
      setBookingMetrics(siteBookingResults);
      setLoading(false);
    }
    loadMetrics();

  }, [site, location?.state?.toastId, t, siteId]);

  if (error?.unauthorised) {
    return <UnauthorisedMessage />;
  }

  if (!site) {
    return <LoadingBlock />;
  }

  return (
    <div className="space-y-8">

      <ul className="grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-6">
        <li className="p-4 shadow-md rounded border border-gray-200 mb-4">
          <SiteValue
            value={metrics?.listedValue}
            loading={loading}
          />
        </li>
        <li className="p-4 shadow-md rounded border border-gray-200 mb-4">
          <SiteCapacity
            capacity={metrics?.listedQuantity}
            loading={loading}
          />  
        </li>
        <li className="p-4 shadow-md rounded border border-gray-200 mb-4">
          <SiteRemainingCapacity
            bookedSpace={bookingMetrics?.totalSpaceBooked ?? 0}
            totalCapacity={metrics?.listedQuantity ?? 0}
            loading={loading}
            />
        </li>
      </ul>

      <header className="flex justify-between items-end">
        <h1>
          <small className="text-base font-medium uppercase">
            {site.status} {t("accountSites.site.site")}
          </small>
          <br />
          {site.name}
        </h1>
        <Link to={`/account/sites/${siteId}/edit`} className="btn-brand">
          {t("accountSites.site.edit")}
        </Link>
      </header>
      <p>{site.description}</p>

      <SpaceList site={site} spaces={spaces} />

      <ServiceList siteId={site.id} />

      <div className="space-y-8 mt-7">
        <h2>{t("accountBookings.activeBookings")}</h2>
        <SiteBookingList />
      </div>
    </div>
  );
}
