import { observer } from 'mobx-react-lite';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiMinusCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useStore } from '../../../hooks/use-store';
import LoadingBlock from '../../common/loading/LoadingBlock';

export default observer(function StockItemList() {
  const { t } = useTranslation();
  const { stockStore, userStore } = useStore();
  const { loading, removeStockItem } = stockStore

  const removeItem = async (id:string) => {
    await removeStockItem(id);
  };

  if (!userStore.contextAccount || loading)
    return <LoadingBlock />

  return (
    <div className="space-y-10">
      <div className="flex justify-between items-center">
        <h1>{t("stockManagement.title")}</h1>
        <div className='space-x-3'>
          <Link to="/account/stock-management/import" className="btn-brand">
            {t("stockManagement.import.button")}
          </Link>
          <Link to="/account/stock-management/new" className="btn-brand">
            {t("stockManagement.addStock")}
          </Link>
        </div>
      </div>

      <div>
        <table className='border-collapse w-full'>
          <thead>
            <tr className='border-b'>
              <th className='text-left'>{t("stockManagement.stockItem.sku")}</th>
              <th className='text-left'>{t("stockManagement.stockItem.gtin")}</th>
              <th className='text-left'>{t("stockManagement.stockItem.upc")}</th>
              <th className='text-left'>{t("stockManagement.stockItem.name")}</th>
              <th className='text-left'>{t("stockManagement.stockItem.description")}</th>
              <th className='text-left'>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <>
            {(!stockStore.stockItems || stockStore.stockItems.length === 0) && (
              <tr>
                <td colSpan={6} className='py-1'>
                  <div>
                    {t("stockManagement.noStockItems")}{" "}
                    <Link to="/account/stock-management/new">
                      {t("stockManagement.addStock")}
                    </Link>
                  </div>
                </td>
              </tr>
            )}
            {stockStore.stockItems && stockStore.stockItems.length > 0 && (
              stockStore.stockItems.map((elem) => (
                <tr key={elem.id} className='border-b'>
                  <td className='py-1'>
                    <Link to={`/account/stock-management/${elem.id}`} className="text-link">
                      {elem.sku}
                    </Link>
                  </td>
                  <td className='py-1'>{elem.gtin}</td>
                  <td className='py-1'>{elem.upc}</td>
                  <td className='py-1'>{elem.name}</td>
                  <td className='py-1'>{elem.description}</td>
                  <td className='py-1'>
                    <button onClick={() => removeItem(elem.id)}>
                      <BiMinusCircle className='text-rose-700 hover:text-rose-800 text-xl' />
                    </button>
                  </td>
                </tr>
              ))
              )}
              </>
          </tbody>
        </table>

        
      </div>
    </div>
  )
});