import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../../hooks/use-auth";
import { EmailInput, Field, FieldErrorMessage } from "../../common/forms";
import { getResetPasswordOptions } from "./authentication-options";
import Button from "../../common/Button";
import { useState } from "react";

export default function ResetPassword() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });
  const { resetPassword } = useAuth();
  const history = useHistory();

  const registerOptions = getResetPasswordOptions();

  const submitForm = async (formData) => {
    setLoading(true);
    try {
      await resetPassword(formData.email).then((data) => {
        history.replace(`/set-new-password?email=${formData.email}`);
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="container mx-auto mt-7 space-y-8">
      <h1>{t("resetPassword.title")}</h1>

      <p>{t("resetPassword.desc")}</p>

      <form onSubmit={handleSubmit(submitForm)} className="space-y-8 md:w-2/3">
        <Field label={t("auth.email")} htmlFor="email">
          <EmailInput
            name="email"
            register={register("email", registerOptions.email)}
          />
          <FieldErrorMessage error={errors.email} />
        </Field>

        <Button
          className="btn-brand"
          label={t("resetPassword.formButtonText")}
          loading={loading}
        />
      </form>
    </div>
  );
}
