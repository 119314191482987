import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Address } from '../../../../app/model/address';
import formatAddress from "../../../../utils/address-formatter";
import InfoMessage from "../../../common/messages/InfoMessage";

interface AddressDisplayProps {
  address?: Address
}

export default function AddressDisplay({ address }:AddressDisplayProps) {
  const { t } = useTranslation();
  const { organisationId } = useParams<{organisationId: string}>();

  return (
    <>
      {!address && (
        <InfoMessage title={t("accountOrganisations.address.noAddress")}>
          <p className="mb-2">
            {t("accountOrganisations.address.noAddressMsg")}
          </p>
          <p>
            <Link
              to={`/account/organisations/${organisationId}/edit`}
              className="btn-link pl-0"
            >
              {t("accountOrganisations.address.noAddressBtn")}
            </Link>
          </p>
        </InfoMessage>
      )}
      {address && (
        <div>
          <h2>{t("accountOrganisations.address.title")}</h2>
          <p className="text-gray-700 text-base">{formatAddress(address)}</p>
        </div>
      )}
    </>
  );
}
