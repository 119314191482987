import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../../../hooks/use-auth";
import useSWR from "swr";
import apiUrl from "../../../../data/api-url";
import defaultFetcher from "../../../../data/fetchers";
import PlanSelection from "../../plans/PlanSelection";
import Button from "../../../common/Button";
import { Form } from "../../../common/forms";
import submitForm from "../../../../data/submit-form";
import ErrorMessage from "../../../common/messages/ErrorMessage";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../hooks/use-store";

export default observer(function ChangePlan() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { userStore } = useStore();
  const [planChanged, setPlanChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [upgradeError, setUpgradeError] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const { organisationId } = useParams();
  const history = useHistory();
  const { handleSubmit } = useForm({ mode: "onBlur" });

  const selectPlan = (selectedPlan) => {
    setPlanChanged(
      selectedPlan.id !== userStore.contextAccount?.accountPlan.id
    );
    setSelectedPlan(selectedPlan);
  };

  const { data: plans } = useSWR(
    [apiUrl("accounts", "/account-plans")],
    defaultFetcher
  );

  const changePlan = async () => {
    setLoading(true);

    submitForm(
      apiUrl(
        "payments",
        `/payment-accounts/${userStore.contextAccount.id}/change-subscription`
      ),
      {
        accountPlanId: selectedPlan.id,
      },
      user.token,
      "POST"
    )
      .then(({ data }) => {
        // If basic plan, just go to the account confirmation page.
        // If it's a paid subscription, we need to go to the
        if (selectedPlan.id === "5cb6e72d-590e-4d33-aae5-4c5b71de55b5") {
          // Basic plan
          history.replace(
            `/account/organisations/${organisationId}/plan-changed?account_plan_id=${selectedPlan.id}`
          );
        } else {
          history.replace(
            `/account/organisations/${organisationId}/payment-details?payment_request=${data.paymentRequestId}`,
            {
              plan: selectedPlan,
            }
          );
        }
      })
      .catch((error) => {
        setUpgradeError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="space-y-8">
      <h2>{t("accountOrganisations.changePlan")}</h2>
      <Form onSubmit={handleSubmit(changePlan)}>
        {upgradeError && (
          <ErrorMessage
            message={upgradeError}
            title="Unable to upgrade organisation plan"
          />
        )}

        <PlanSelection
          defaultPlanId={userStore.contextAccount.accountPlan.id}
          selectPlan={selectPlan}
          selectedPlanId={userStore.contextAccount.accountPlan.id}
          plans={plans}
        />

        <div>
          <div className="space-x-5">
            <Button
              label={t("changePlan.buttonText")}
              disabled={!planChanged}
              loading={loading}
            />
            <Link to={`/account/organisations/${organisationId}`}>
              {t("common.cancel")}
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
});
