import { useTranslation } from "react-i18next";
import { BiDollarCircle } from "react-icons/bi";
import PriceDisplay from '../../common/PriceDisplay';

interface SiteCapacityProps {
  value?: number;
  loading: boolean;
}

export default function SiteValue({ value, loading }:SiteCapacityProps) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <div className="mr-4">
        <BiDollarCircle className="text-4xl text-lime-500" />
      </div>
      <div>
        <small className="text-sm font-medium uppercase text-gray-400">
          {t("accountSites.metrics.siteTotalValue")}
        </small>
        <br />
        <div className="text-3xl font-bold leading-6">
          {loading === true ? (
            <div className="h-8 w-6 bg-gray-200 animate-pulse rounded"></div>
          ) : (
            <PriceDisplay price={value ?? 0} />
          )}
        </div>
      </div>
    </div>
  );
}
