import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom';
import { StockItemFormFields } from '../../../../app/model/stock-management';
import { useStore } from '../../../../hooks/use-store';
import Button, { LinkButton } from '../../../common/Button';
import { Form } from '../../../common/forms';
import LoadingBlock from '../../../common/loading/LoadingBlock';
import FormFields from './FormFields';

export default observer(function EditStockItem() {
  const { t } = useTranslation();
  const { stockItemId } = useParams<{stockItemId: string}>();
  const { stockStore } = useStore();
  const { loading, updateStockItem, stockItem, loadStockItem } = stockStore;

  useEffect(() => {
   
    if (stockItem === undefined)
    {
      loadStockItem(stockItemId);
    }

  }, [stockItem, loadStockItem, stockItemId]);

  const formMethods = useForm<StockItemFormFields>({ mode: "onBlur", defaultValues: stockItem });
  const history = useHistory();

  const handleNewStockItem = async (formData:StockItemFormFields) => {

    formData.id = stockItemId;

    updateStockItem(formData)
      .then(() => history.push(`/account/stock-management/${stockItem?.id}`));

  }

  if (stockItem === undefined || loading)
    return <LoadingBlock />

  return (
    <div className='space-y-6'>
      <h1>{t("stockManagement.edit.title")}</h1>

      <Form onSubmit={formMethods.handleSubmit(handleNewStockItem)}>

        <FormFields formMethods={formMethods} />        

        <div>
          <Button label={t("common.update")} loading={loading} />
          <LinkButton
            label={t("common.cancel")}
            onClick={() => {
              history.push(`/account/stock-management`);
            }}
          />
        </div>
      </Form>
    </div>
  )
});