import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import useSWR from "swr";
import { Link } from "react-router-dom";

import apiUrl from "../../../../data/api-url";
import { tokenFetcher } from "../../../../data/fetchers";
import AvailabilityList from "../Availability/List";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import { useEffect } from "react";
import { toastSuccess } from "../../../common/messages/toast-messages";

export default function ShowSpace() {
  const { t } = useTranslation();
  const { siteId, spaceId } = useParams();
  const location = useLocation();

  const { data: space } = useSWR(
    [
      apiUrl("spaces", `/sites/${siteId}/spaces/${spaceId}`),
      { longPolling: location?.state?.spaceLongPolling },
    ],
    tokenFetcher
  );

  useEffect(() => {
    if (space && location?.state?.toastId && location?.state?.newAvailability) {
      toastSuccess(t("toasts.availabilityCreated"), location.state.toastId);
    } else if (space && location?.state?.toastId) {
      toastSuccess(t("toasts.spaceCreated"), location.state.toastId);
    }
  }, [space, location?.state?.toastId, location?.state?.newAvailability, t]);

  if (!space) {
    return <LoadingBlock />;
  }

  return (
    <div className="space-y-8">
      <header className="flex justify-between items-end">
        <h1>
          <small className="text-base font-medium uppercase">
            {space.status} {t("accountSites.space.space")}
          </small>
          <br />
          {space.name}
        </h1>
        <Link
          to={`/account/sites/${siteId}/spaces/${spaceId}/edit`}
          className="btn-brand"
        >
          {t("accountSites.space.edit")}
        </Link>
      </header>
      <p>{space.description}</p>

      <AvailabilityList space={space} />
    </div>
  );
}
