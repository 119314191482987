import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import useSWR, { mutate } from "swr";
import toast from "react-hot-toast";

import apiUrl from "../../../../data/api-url";
import { Form } from "../../../common/forms";
import submitForm from "../../../../data/submit-form";
import { useAuth } from "../../../../hooks/use-auth";
import { tokenFetcher } from "../../../../data/fetchers";

import AvailabilityFormFields from "./FormFields";
import Button, { LinkButton } from "../../../common/Button";
import { useState } from "react";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import { useStore } from "../../../../hooks/use-store";
import { observer } from "mobx-react-lite";

export default observer(function EditAvailability() {
  const { t } = useTranslation();
  const formMethods = useForm({ mode: "onBlur" });
  const history = useHistory();
  const { user } = useAuth();
  const { userStore } = useStore();
  const { siteId, spaceId, availabilityId } = useParams();
  const [submitting, setSubmitting] = useState(false);

  // TODO: Load currency from account
  const currency = { symbol: "£", code: "GBP" };

  const { data: space } = useSWR(
    userStore.contextAccount
      ? [apiUrl("spaces", `/sites/${siteId}/spaces/${spaceId}`), user.token]
      : null,
    tokenFetcher
  );

  const { data: availability } = useSWR(
    userStore.contextAccount
      ? [
          apiUrl(
            "spaces",
            `/sites/${siteId}/spaces/${spaceId}/availability-windows/${availabilityId}`
          ),
          user.token,
        ]
      : null,
    tokenFetcher
  );

  if (!space || !availability) {
    return <LoadingBlock />;
  }

  const submitAvailability = async (formData) => {
    setSubmitting(true);

    formData.publish = formData.publish === "publish" ? true : false;

    const { data, errors } = await submitForm(
      apiUrl(
        "spaces",
        `/sites/${siteId}/spaces/${spaceId}/availability-windows/${availabilityId}`
      ),
      formData,
      user.token,
      "PUT"
    );

    if (data) {
      mutate(
        `/sites/${siteId}/spaces/${spaceId}/availability-windows/${availabilityId}`
      );
      mutate(`/sites/${siteId}/spaces/${spaceId}/availability-windows`);
      history.push(`/account/sites/${siteId}/spaces/${spaceId}`, {
        availabilityLongPolling: new Date(data.timestamp),
      });
    } else if (errors) {
      setSubmitting(false);
      toast.error(t("toasts.availabilityUpdateFailed"));
      Object.keys(errors).forEach((fieldName) => {
        formMethods.setError(fieldName, { message: errors[fieldName] });
      });
    }
  };

  return (
    <div className="space-y-16">
      <h1>{t("accountSites.availability.edit")}</h1>

      <Form onSubmit={formMethods.handleSubmit(submitAvailability)}>
        <AvailabilityFormFields
          availability={availability}
          space={space}
          currency={currency}
          formMethods={formMethods}
        />

        <div>
          <Button
            label={t("accountSites.availability.update")}
            loading={submitting}
          />
          <LinkButton
            label={t("common.cancel")}
            onClick={() => {
              history.push(
                `/account/sites/${siteId}/spaces/${spaceId}/availability-windows`
              );
            }}
          />
        </div>
      </Form>
    </div>
  );
});
