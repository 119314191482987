import { makeAutoObservable, runInAction } from 'mobx';
import { Account, Invoice } from '../model/account';
import requests from '../../data/requests';
import apiUrl from '../../data/api-url';
import { Address } from '../model/address';

export default class AccountStore {
  
  account: Account | undefined = undefined;
  invoices: Invoice[] | undefined = undefined;
  updatedAddress?: Address;
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  loadAccount = async (accountId: string) => {

    this.loading = true;

    var accountObj = await requests.get<Account>(apiUrl('accounts', `/account/${accountId}`));

    runInAction(() => {
      this.loading = false;
      this.account = accountObj;
    });

  };

  clearAccount = () => {
    this.account = undefined;
  }

  clearUpdatedAddress = () => {
    this.updatedAddress = undefined;
  }

  setStreetAddress = (address?: Address) => {
    this.updatedAddress = address;
    if (this.account !== undefined) {
        this.account!.streetAddress = address;
    }
  }

  loadInvoices = async (accountId: string) => {
    this.loading = true;
    var invoices = await requests.get<Invoice[]>(apiUrl('payments', `/payment-accounts/${accountId}/invoices`));

    runInAction(() => {
      this.loading = false;
      this.invoices = invoices;
    });
  }

  clearInvoices = () => {
    this.invoices = undefined;
  }
  
}