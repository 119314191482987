import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
//import apiUrl from "../../../../data/api-url";
//import submitForm from "../../../../data/submit-form";
//import { useAuth } from "../../../../hooks/use-auth";

export default function NewPayment() {
  const location = useLocation();
  const history = useHistory();
  //const { user } = useAuth();

  if (location.state === null || location.state.accountData === undefined) {
    history.push({ pathname: "/account/organisations" });
    return;
  }

  //const paymentSuccess = async (callbackData) => {
  //  const postData = {
  //    accountPlanId: location.state.accountData.accountPlan.id,
  //    agreedTermsVersion: 1,
  //    primaryUserId: user.id,
  //    name: location.state.accountData.name,
  //  };
  //
  //  await submitForm(apiUrl("accounts", "/account"), postData, user.token)
  //    .then(({ data }) => {
  //      history.push({
  //        pathname: "/account/organisations",
  //        state: {
  //          newOrganisationId: data.id,
  //        },
  //      });
  //    })
  //    .catch((err) => {
  //      console.log(err);
  //    });
  //};

  return <div className="space-y-8">Card Payment Form</div>;
}
