import useSWR from "swr";
import { useParams } from "react-router-dom";
import apiUrl from "../../../../data/api-url";
import { useAuth } from "../../../../hooks/use-auth";
import { tokenFetcher } from "../../../../data/fetchers";
import ManageBankAccounts from "./ManageBankAccounts";
import BankAccountList from "./BankAccountList";
import CompleteOnboarding from '../show/CompleteOnboarding';
import { PaymentAccount } from '../../../../app/model/account';
import LoadingBlock from '../../../common/loading/LoadingBlock';

export default function BankAccounts() {
  const { user } = useAuth();
  const { organisationId } = useParams<{ organisationId: string }>();

  const { data: paymentAccount } = useSWR<PaymentAccount>(
    [apiUrl("payments", `/payment-accounts/${organisationId}`), user.token],
    tokenFetcher
  );

  if (paymentAccount === undefined)
    return <LoadingBlock />

  // If Not yet confirmed, show complete onboarding message instead.
  if (paymentAccount !== undefined && !paymentAccount.confirmed)
    return <CompleteOnboarding />

  return (
    <div className="space-y-6">
      <BankAccountList bankAccounts={paymentAccount.externalBankAccounts} />
      <ManageBankAccounts />
    </div>
  );
}
