import React from 'react'
import { useTranslation } from 'react-i18next'
import { RequestStockItem } from '../../../../../app/model/service-requests';

interface ConfirmStockItemsProps {
  stockItems: RequestStockItem[] | undefined;
  setQuantityCallback(stockItemId: string, qty: number): void;
}

export default function ConfirmStockItems({stockItems, setQuantityCallback}: ConfirmStockItemsProps) {
  const { t } = useTranslation();

  // If stockItems is null or empty, then just return
  if (stockItems === undefined || stockItems === null || stockItems.length === 0)
    return null;

  const setStockQuantity = (stockItemId: string, quantity: string) => {
    const numQty = parseInt(quantity);
    if (!isNaN(numQty)) {
      setQuantityCallback(stockItemId, numQty);
    }
  }

  return (
    <div>
      <h3>{t("accountBookings.serviceRequestDetail.confirmStock.confirmStock")}</h3>
      <table className='border-collapse w-full mt-4'>
        <thead>
          <tr className='border-b'>
            <th className='text-left'>Product</th>
            <th className='text-right'>Quantity</th>
          </tr>
        </thead>
        <tbody>
        {stockItems && stockItems.map(item => (
          <tr className='border-b' key={item.id}>
            <td className='py-1'>{item.name} - {item.sku}</td>
            <td className='py-1 flex flex-row-reverse'>
              <input 
                className='input w-36 py-1 px-2 mt-1' 
                type='number'
                onChange={(e:any) => setStockQuantity(item.id, e.target.value)} />
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  )
}