import { Route, Switch } from "react-router-dom";
import Breadcrumbs from "../../common/Breadcrumbs";
import serviceRequestRoutes from './service-request-routes'

export default function ServiceRequestsRouter() {
  return (
    <div className="mt-8">
      <Breadcrumbs routes={serviceRequestRoutes} />

      <div className="mt-7">
        <Switch>
          {serviceRequestRoutes.map((route) => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
}
