import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Breadcrumbs from '../../common/Breadcrumbs';
import NotFound from '../../common/NotFound';
import stockManagementRoutes from './routes';

export default function StockManagementRouter() {
  return (
    <div className="mt-8">
      <Breadcrumbs routes={stockManagementRoutes} />

      <div className="mt-7">
        <Switch>
          {stockManagementRoutes.map((route) => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}

          <Route component={NotFound} />
        </Switch>
      </div>

    </div>
  )
}