import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { BiCalendarCheck } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import apiUrl from "../../../data/api-url";
import { tokenFetcher } from "../../../data/fetchers";
import { useAuth } from "../../../hooks/use-auth";
import LoadingBlock from "../../common/loading/LoadingBlock";
import { formatDate } from "../../../utils/date-transformations";
import { useEffect } from "react";
import submitForm from "../../../data/submit-form";
import { useStore } from "../../../hooks/use-store";
import { observer } from "mobx-react-lite";

export default observer(function BookingConfirmation() {
  const { t } = useTranslation();
  const { userStore } = useStore();

  let bookingId;
  const location = useLocation();
  if (location.state) {
    bookingId = location.state.bookingId;
  }

  if (bookingId === undefined) {
    const search = location.search;
    bookingId = new URLSearchParams(search).get("booking_id");
  }

  const { user } = useAuth();

  const { data: booking } = useSWR(
    [apiUrl("bookings", `/bookings/${bookingId}`), user?.token],
    tokenFetcher
  );

  // TODO: This could probably move to a booking paid message.
  useEffect(() => {
    async function confirmBooking() {
      const putData = {
        accountId: userStore.contextAccount.id,
      };

      await submitForm(
        apiUrl("bookings", `/bookings/${bookingId}/confirm`),
        putData,
        user.token,
        "PUT"
      );
    }
    confirmBooking();
  });

  if (!booking) {
    return (
      <div className="container mx-auto mt-7 space-y-8 text-center">
        <LoadingBlock />
      </div>
    );
  }

  const replacedBookingText = () => {
    let str = t("booking.confirmation.confirmedText");
    str = str.replace("#QUANTITY#", booking.quantity.toLocaleString());
    str = str.replace("#START_DATE#", formatDate(booking.startDate));
    return str;
  };

  return (
    <div className="container mx-auto mt-7 space-y-8 text-center">
      <div className="text-center text-[100px] text-lime-500">
        <BiCalendarCheck className="inline-block" />
      </div>
      <h1>{t("booking.confirmation.title")}</h1>
      <p className="text-xl max-w-3xl text-center mx-auto">
        {replacedBookingText()}
      </p>
      <p>
        <Link
          to={`/account/bookings/${bookingId}`}
          className="btn-brand text-xl"
        >
          {t("booking.confirmation.buttonText")}
        </Link>
      </p>
    </div>
  );
});
