export function formatDate(
  dateStr,
  utc = false,
  year = "numeric",
  month = "long",
  day = "numeric"
) {
  if (dateStr === undefined || dateStr === null || dateStr === "") return "";

  var date = parseDate(dateStr);

  // if date is undefined, just return the dateStr
  if (date === undefined) return dateStr;

  // Return formatted date
  return new Intl.DateTimeFormat("en-GB", {
    year: year,
    month: month,
    day: day,
    timeZone: utc === true ? "GMT" : undefined,
  }).format(date);
}

export function formatDateTime(
  dateStr,
  utc = false,
  year = "numeric",
  month = "long",
  day = "numeric",
  hour = "numeric",
  minute = "numeric"
) {
  if (dateStr === undefined || dateStr === "") return "";

  var date = parseDate(dateStr);

  // if date is undefined, just return the dateStr
  if (date === undefined) return dateStr;

  // Return formatted date
  return new Intl.DateTimeFormat("en-GB", {
    year: year,
    month: month,
    day: day,
    hour: hour,
    minute: minute,
    hour12: true,
    timeZone: utc === true ? "GMT" : undefined,
  }).format(date);
}

export function formatDateForInputs(dateStr) {
  if (dateStr === undefined || dateStr === "") return "";

  var date = new Date(Date.parse(dateStr));
  var day = date.getDate() + "";
  var month = date.getMonth() + 1 + "";
  var year = date.getFullYear() + "";

  const inputStr = `${padZero(year)}-${padZero(month)}-${padZero(day)}`;
  return inputStr;
}

function parseDate(dateStr) {
  if (dateStr === undefined || dateStr === "") return Date();

  var endsWithRegex = new RegExp(/^.*(:\d\dZ)$/);
  if (endsWithRegex.test(dateStr)) dateStr = dateStr.replace("Z", ".000Z");

  return new Date(Date.parse(dateStr));
}

//function convertDateToUTC(date) {
//  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
//}

function padZero(data) {
  if (data.length === 1) {
    data = "0" + data;
  }
  return data;
}
