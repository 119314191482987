import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';

interface PrivateRouteProps extends RouteProps {
  children?: React.ReactNode
}

export default function PrivateRoute({ children, ...rest }:PrivateRouteProps) {
  const { isLoading, user } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isLoading) {
          return null;
        }

        if (!user) {
          return (
            <Redirect
              to={{
                pathname: "/sign-in",
                state: { from: location },
              }}
            />
          );
        }

        return children;
      }}
    />
  );
}