import { createContext, useContext } from 'react';
import CommonStore from '../app/stores/common-store';
import StockStore from '../app/stores/stock-store';
import UserStore from '../app/stores/user-store';
import AccountStore from '../app/stores/account-store';
import ServiceRequestStore from '../app/stores/service-request-store';

interface Store {
  commonStore: CommonStore,
  stockStore: StockStore,
  userStore: UserStore,
  accountStore: AccountStore,
  serviceRequestStore: ServiceRequestStore
}

export const store: Store = {
  commonStore: new CommonStore(),
  stockStore: new StockStore(),
  userStore: new UserStore(),
  accountStore: new AccountStore(),
  serviceRequestStore: new ServiceRequestStore()
}

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext)
}