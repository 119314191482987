const defaultWidth = "w-52";

interface CompanyLogoProps {
  widthClass?: string
}

export default function CompanyLogo({ widthClass }:CompanyLogoProps) {
  return (
    <>
      <span className="sr-only">Storekat</span>
      <img
        src="/img/sk-logo.png"
        alt="Storekat"
        className={widthClass || defaultWidth}
      />
    </>
  );
}
