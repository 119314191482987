import { Route, Switch } from "react-router-dom";
import supportRoutes from "./support-routes";

export default function SupportRouter() {
  return (
    <div className="mt-8">
      <Switch>
        {supportRoutes.map((route) => (
          <Route
            key={route.path}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        ))}
      </Switch>
    </div>
  );
}
