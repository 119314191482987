import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import apiUrl from "../../../../data/api-url";
import Button from "../../../common/Button";
import { FieldErrorMessage } from '../../../common/forms';
import { Auth } from "aws-amplify";

export default function ManageBankAccounts() {
  const { t } = useTranslation();
  const { organisationId } = useParams<{organisationId?:string}>();
  const [loginLinkLoading, setLoginLinkLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  const getProviderLoginLink = async () => {
    setLoginLinkLoading(true);

    const cognitoSession = await Auth.currentSession();
    const idToken = cognitoSession.getIdToken().getJwtToken();

    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    };

    const url = new URL(apiUrl("payments", `/payment-accounts/${organisationId}/payment-provider-login`)!.toString());

    await fetch(url, {
      method: 'GET',
      headers: headers,
    })
    .then(async (response) => {

      if (response.ok) {
        var data = await response.json();
        window.location.replace(data.url);
      } else {
        setErrorMsg(t("accountOrganisations.bankAccounts.loginLinkError"));
        setLoginLinkLoading(false);
      }
      
    })
    .catch((err) => {
      setErrorMsg(err);
      setLoginLinkLoading(false);
    });
  };

  return (
    <div className="space-y-4">
      <h2>{t("accountOrganisations.bankAccounts.manageBankAccounts")}</h2>
      <p>{t("accountOrganisations.bankAccounts.leadText")}</p>
      <div>
        <Button
          label={t("accountOrganisations.bankAccounts.login")}
          loading={loginLinkLoading}
          onClick={getProviderLoginLink}
          className="btn-muted mb-2"
        />
        {errorMsg && <FieldErrorMessage message={errorMsg} />}
      </div>
    </div>
  );
}
