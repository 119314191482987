import React from 'react'
import { formatDateTime } from '../../../../utils/date-transformations'
import { Note } from '../../../../app/model/service-requests';

interface NoteListItemProps {
  note: Note;
}

export default function NoteListItem({note}:NoteListItemProps) {

  const { userDisplayName, accountName, created, message } = note;

  return (
    <div className="border-gray-300 border-b px-2 py-1">
      <div className="flex text-xs">
        <span className="pr-2">
          {userDisplayName} - {accountName}
        </span>
        <span className="text-brandSecondary-100">
          {formatDateTime(created)}
        </span>
      </div>
      <div className="my-1">{message}</div>
    </div>
  )
}