import React, { useState } from 'react'
import { CurrencyInput, Field, FieldErrorMessage, Form } from '../../../../common/forms'
import Button from '../../../../common/Button'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAuth } from '../../../../../hooks/use-auth';
import { useStore } from '../../../../../hooks/use-store';
import submitForm from '../../../../../data/submit-form';
import apiUrl from '../../../../../data/api-url';
import { mutate } from 'swr';

interface SetPriceFormData {
  price: string
}

interface SetRequestPriceProps {
  canSetPrice: boolean;
  bookingId: string;
  requestId: string;
  siteId: string;
  callback: (subTotal: number) => void;
}

export default function SetRequestPrice({canSetPrice, bookingId, requestId, siteId, callback}:SetRequestPriceProps) {

  const { t } = useTranslation();  
  const { user } = useAuth();
  const { userStore } = useStore();
  const [settingPrice, setSettingPrice] = useState(false);

  // TODO: Load currency from account
  const currency = { symbol: "£", code: "GBP" };

  const {
    handleSubmit,
    formState: { errors },
    register,
    //resetField,
  } = useForm<SetPriceFormData>({ mode: "onBlur" });

  const setPrice = async (formData:SetPriceFormData) => {
    setSettingPrice(true);

    const priceData = {
      accountId: userStore.contextAccount?.id,
      subTotal: parseFloat(formData.price),
      siteId: siteId,
    };

    await submitForm(
      apiUrl(
        "bookings",
        `/bookings/${bookingId}/service-requests/${requestId}/set-price`
      ),
      priceData,
      user.token,
      "PUT"
    )
      .then(() => {
        mutate(
          apiUrl("bookings", `/site/${siteId}/service-requests/${requestId}`)
        );
        callback(priceData.subTotal);
      })
      .finally(() => {
        setSettingPrice(false);
      });
  };

  return (
    <Form onSubmit={handleSubmit(setPrice)}>
      <Field
        label={t("accountBookings.serviceRequestDetail.actions.setPrice")}
        htmlFor="message"
        helpText={t(
          "accountBookings.serviceRequestDetail.actions.setPriceHelp"
        )}
      >
        <div className="flex">
          <CurrencyInput
            name="price"
            defaultValue='0'
            currency={currency}
            register={register("price", {
              required: t(
                "accountBookings.serviceRequestDetail.setPriceRequired"
              ),
              min: {
                value: 0,
                message: t("common.validation.min", { value: 0 }),
              },
            })}
            error={errors.price}
          />
          <Button
            label={t(
              "accountBookings.serviceRequestDetail.actions.setPrice"
            )}
            loading={settingPrice}
            className="btn-brand ml-6"
            disabled={!canSetPrice}
          />
        </div>
        <FieldErrorMessage error={errors.price} />
      </Field>
    </Form>
  )
}