import i18n from "../../i18n";

import BookingStepOne from "./steps/BookingStepOne";
import BookingStepTwo from "./steps/BookingStepTwo";
import BookingPayment from "./steps/BookingPayment";
import BookingConfirmation from "./steps/BookingConfirmation";
import MarketplaceTermsEn from "../common/legal/MarketplaceTermsEn";

const bookingRoutes = [
  {
    path: "/booking",
    component: BookingStepOne,
    exact: true,
    label: i18n.t("booking.stepOne.title"),
    hideFromNav: true,
  },
  {
    path: "/booking/legal",
    component: BookingStepTwo,
    exact: true,
    label: i18n.t("booking.stepTwo.title"),
    hideFromNav: true,
  },
  {
    path: "/booking/legal-test",
    component: MarketplaceTermsEn,
    exact: true,
    label: i18n.t("booking.stepTwo.title"),
    hideFromNav: true,
  },
  {
    path: "/booking/payment",
    component: BookingPayment,
    exact: true,
    label: i18n.t("booking.payment.title"),
    hideFromNav: true,
  },
  {
    path: "/booking/confirmation",
    component: BookingConfirmation,
    exact: true,
    label: i18n.t("booking.confirmation.navTitle"),
    hideFromNav: true,
  },
];

export default bookingRoutes;
