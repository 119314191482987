interface SelectProps {
  name: string,
  id?: string,
  register: any,
  error?: any,
  className?: string,
  defaultValue?: string,
  autoComplete?: string,
  placeholder?: string,
  children?: React.ReactNode,
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
}

export function SelectList({
  name,
  id = undefined,
  className = "input",
  register,
  error = false,
  placeholder = ' ',
  children,
  ...selectProps
}:SelectProps) {
  return (
    <select
      id={id || name}
      className={`${className} ${error ? "border-red-500" : ""}`}
      {...register}
      {...selectProps}
    >
      {placeholder && <option>{placeholder}</option>}
      {children}
    </select>
  );
}

export default SelectList;
