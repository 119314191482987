import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../../hooks/use-auth";
import { uppercaseFirstLetter } from "../../../../utils/text-transformations";
import { submitDelete } from "../../../../data/submit-form";
import Button from "../../../common/Button";
import apiUrl from "../../../../data/api-url";
import HelpMessage from "../../../common/messages/HelpMessage";

export default function PaymentMethodItem({
  paymentMethod,
  paymentMethods,
  setPaymentMethods,
  hideRemove = false,
}) {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { organisationId } = useParams();
  const [submitting, setSubmitting] = useState(false);

  const removeCard = async (id) => {
    setSubmitting(true);

    await submitDelete(
      apiUrl(
        "payments",
        `/payment-accounts/${organisationId}/payment-methods/${id}`
      ),
      user.token
    )
      .then(() => {
        // Remove the list item showing the payment method
        setPaymentMethods(paymentMethods.filter((i) => i.id !== id));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="flex justify-between items-center">
      <div>
        <h3 className="mt-3 mb-1">
          {uppercaseFirstLetter(paymentMethod.vendor)}
        </h3>
        <div className="flex justify-between max-w-md text-gray-500">
          <div>
            <p className="font-bold">
              {t("accountOrganisations.paymentMethods.cardNumber")}
            </p>
            <p className="">xxxx-...{paymentMethod.last4}</p>
          </div>
          <div className="mr-10">
            <p className="font-bold">
              {t("accountOrganisations.paymentMethods.cardExpiry")}
            </p>
            <p className="">{paymentMethod.cardExpiry}</p>
          </div>
        </div>
        {hideRemove && (
          <HelpMessage
            text={t(
              "accountOrganisations.paymentMethods.paymentMethodRequired"
            )}
          />
        )}
      </div>
      {hideRemove === false && (
        <Button
          label={t("generalActions.remove")}
          onClick={() => removeCard(paymentMethod.id)}
          loading={submitting}
        />
      )}
    </div>
  );
}
