import React from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";

import {
  NumberInput,
  TextInput,
  TextArea,
  SelectInput,
  Field,
  FieldErrorMessage,
} from "../../../common/forms";
import apiUrl from "../../../../data/api-url";
import { tokenFetcher } from "../../../../data/fetchers";
import { useAuth } from "../../../../hooks/use-auth";
import LoadingBlock from "../../../common/loading/LoadingBlock";

const namedSpaceType = (name, spaceTypes) => {
  return spaceTypes.find((type) => type.name === name);
};

// For Space type, wrap our usual Select with `React.forwardRef` in order to
// combine our own `onChange` handler with the one from `react-hook-form`.
// See "Custom onChange, onBlur" at:
// https://react-hook-form.com/api/useform/register
// TODO: Renable this when we start utilising all space types
//const TypeSelect = React.forwardRef(
//  ({ children, register, error, onChange, space }, ref) => {
//    const typeSelect = register("type");
//
//    return (
//      <Select
//        name="type"
//        defaultValue={space.type}
//        error={error}
//        ref={ref}
//        onChange={(e) => {
//          typeSelect.onChange(e);
//          onChange(e);
//        }}
//      >
//        {children}
//      </Select>
//    );
//  }
//);

export default function SpaceFormFields({ formMethods, space }) {
  const { t } = useTranslation();
  const spaceType = "Storage";
  //const [spaceType, setSpaceType] = useState(space.type);
  const { user } = useAuth();

  const {
    formState: { errors },
    register,
  } = formMethods;
  const { data: spaceTypes } = useSWR(
    [apiUrl("spaces", "/space-types"), user.token],
    tokenFetcher
  );

  if (!spaceTypes) {
    return <LoadingBlock />;
  }

  // TODO: Renable this when we start utilising all space types
  //const handleTypeChange = (e) => {
  //  // When the type changes, update the type in state to control what values
  //  // are present in `class`, `unit` and `measure` menus.
  //  // Then clear the values in those fields.
  //  setSpaceType(e.target.value);
  //  setValue("type", e.target.value);
  //  setValue("class", null);
  //  setValue("unit", null);
  //  setValue("measure", null);
  //};

  return (
    <>
      <Field label={t("accountSites.space.name")} htmlFor="name">
        <TextInput
          name="name"
          defaultValue={space.name}
          register={register("name", {
            required: t("accountSites.space.validation.nameRequired"),
          })}
          error={errors.name}
        />
        <FieldErrorMessage error={errors.name} />
      </Field>

      <Field label={t("accountSites.space.description")} htmlFor="description">
        <TextArea
          name="description"
          defaultValue={space.description}
          rows={4}
          register={register("description")}
          error={errors.description}
        />
        <FieldErrorMessage error={errors.description} />
      </Field>

      <div className="grid grid-cols-2 gap-6">
        {/*<Field label={t("accountSites.space.type")} htmlFor="type">
          <TypeSelect
            register={register}
            error={errors.type}
            space={space}
            onChange={handleTypeChange}
          >
            {spaceTypes.map((spaceType, i) => (
              <option key={`type-${i}`}>{spaceType.name}</option>
            ))}
          </TypeSelect>
          <FieldErrorMessage error={errors.type} />
            </Field>*/}

        <Field label={t("accountSites.space.class")} htmlFor="class">
          <SelectInput
            name="class"
            defaultValue={space.class}
            register={register("class", {
              required: t("accountSites.space.validation.classRequired"),
            })}
            error={errors.class}
          >
            {namedSpaceType(spaceType, spaceTypes)?.classes.map(
              (spaceClass, i) => (
                <option key={`class-${i}`}>{spaceClass}</option>
              )
            )}
          </SelectInput>
          <FieldErrorMessage error={errors.class} />
        </Field>

        <Field label={t("accountSites.space.quantity")} htmlFor="quantity">
          <NumberInput
            name="quantity"
            defaultValue={space.quantity}
            register={register("quantity", {
              required: t("accountSites.space.validation.quantityRequired"),
              min: 1,
            })}
            error={errors.quantity}
          />
          <FieldErrorMessage error={errors.quantity} />
        </Field>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <Field label={t("accountSites.space.unit")} htmlFor="unit">
          <SelectInput
            name="unit"
            defaultValue={space.unit}
            register={register("unit", {
              required: t("accountSites.space.validation.unitRequired"),
            })}
            error={errors.unit}
          >
            {namedSpaceType(spaceType, spaceTypes)?.units.map((unit, i) => (
              <option key={`unit-${i}`}>{unit}</option>
            ))}
          </SelectInput>
          <FieldErrorMessage error={errors.unit} />
        </Field>

        <Field
          label={t("accountSites.space.unitMeasure")}
          htmlFor="unitMeasure"
        >
          <SelectInput
            name="unitMeasure"
            defaultValue={space.unitMeasure}
            register={register("unitMeasure", {
              required: t("accountSites.space.validation.measureRequired"),
            })}
            error={errors.unitMeasure}
          >
            {namedSpaceType(spaceType, spaceTypes)?.measures.map(
              (unitMeasure, i) => (
                <option key={`unitMeasure-${i}`}>{unitMeasure}</option>
              )
            )}
          </SelectInput>
          <FieldErrorMessage error={errors.unitMeasure} />
        </Field>
      </div>
    </>
  );
}
