import { observer } from 'mobx-react-lite';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../../hooks/use-store';
import ImportForm from './ImportForm';
import ProcessingImport from './ProcessingImport';
import SampleDataFile from './SampleDataFile';

export default observer(function ImportStockItems() {

  const { t } = useTranslation();
  const { stockStore } = useStore();
  const { processingImport } = stockStore;

  return (
    <div className='space-y-6'>
      <h1>{t("stockManagement.import.title")}</h1>

      {!processingImport && (
        <>
          <ImportForm />
          <SampleDataFile />
        </>
      )}

      {processingImport && (
        <ProcessingImport />
      )}
      
    </div>
  )
});