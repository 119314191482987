import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useSWR from "swr";
import apiUrl from "../../../../../data/api-url";
import { tokenFetcher } from "../../../../../data/fetchers";
import { useAuth } from "../../../../../hooks/use-auth";
import LoadingBlock from "../../../../common/loading/LoadingBlock";
import Table from "../../../../common/Table";

export default function ServiceList({ siteId }) {
  const { t } = useTranslation();
  const addServiceUrl = `/account/sites/${siteId}/services/new`;
  const { user } = useAuth();

  const { data: services } = useSWR(
    user ? [apiUrl("spaces", `/sites/${siteId}/services`), user.token] : null,
    tokenFetcher
  );

  const data = useMemo(() => {
    return services
      ? services.map((service) => {
          return {
            name: service,
            description: service.description,
            action: service,
          };
        })
      : [];
  }, [services]);

  const columns = useMemo(
    () => [
      {
        Header: t("accountSites.service.name"),
        accessor: "name",
        Cell: ({ value }) => (
          <Link
            to={`/account/sites/${siteId}/services/${value.id}`}
            className="text-link"
          >
            {value.name}
          </Link>
        ),
      },
      {
        Header: t("accountSites.service.description"),
        accessor: "description",
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ value }) => (
          <>
            <Link
              to={`/account/sites/${siteId}/services/${value.id}/edit`}
              className="text-link"
            >
              {t("generalActions.edit")}
            </Link>
            <Link
              to={`/account/sites/${siteId}/services/${value.id}/remove`}
              className="pl-2 text-link"
            >
              {t("generalActions.remove")}
            </Link>
          </>
        ),
      },
    ],
    [t, siteId]
  );

  return (
    <div className="space-y-8">
      <header className="flex justify-between align-baseline">
        <h2>{t("accountSites.services")}</h2>

        <Link to={addServiceUrl} className="btn-brand">
          {t("accountSites.addService")}
        </Link>
      </header>
      {services && services.length > 0 && (
        <Table columns={columns} data={data} />
      )}
      {services && services.length === 0 && (
        <div>
          {t("accountSites.service.noSpaces")}.{" "}
          <Link to={addServiceUrl}>{t("accountSites.service.addFirst")}</Link>
        </div>
      )}
      {!services && <LoadingBlock />}
    </div>
  );
}
