import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom';
import { StockItemFormFields } from '../../../../app/model/stock-management';
import { useStore } from '../../../../hooks/use-store';
import Button, { LinkButton } from '../../../common/Button';
import { Form } from '../../../common/forms';
import ErrorMessage from '../../../common/messages/ErrorMessage';
import FormFields from './FormFields';

export default function NewStockItem() {
  const { t } = useTranslation();
  const formMethods = useForm<StockItemFormFields>({ mode: "onBlur" });
  const history = useHistory();
  const { stockStore, userStore } = useStore();
  const { loading, createStockItem } = stockStore;
  const [formError, setFormError] = useState<string | undefined>();

  const handleNewStockItem = async (formData:StockItemFormFields) => {

    // Clear form error
    setFormError(undefined);

    // set the account id
    formData.accountId = userStore.contextAccount?.id ?? "";

    createStockItem(formData)
      .then((newItem) => {
        history.push(`/account/stock-management/${newItem.id}`);
      })
      .catch((err) => {
        setFormError(err.message);
      });

  }

  return (
    <div className='space-y-6'>
      <h1>{t("stockManagement.new.title")}</h1>

      <Form onSubmit={formMethods.handleSubmit(handleNewStockItem)}>

        {formError && (
          <ErrorMessage title={t('stockManagement.new.formErrorTitle')} message={formError} />
        )}

        <FormFields formMethods={formMethods} />        

        <div>
          <Button label={t("common.create")} loading={loading} />
          <LinkButton
            label={t("common.cancel")}
            onClick={() => {
              history.push(`/account/stock-management`);
            }}
          />
        </div>
      </Form>
    </div>
  )
}