import { Route, Switch } from "react-router-dom";
import bookingRoutes from "./routes";
import NotFound from "../../common/NotFound";
import Breadcrumbs from "../../common/Breadcrumbs";

export default function BookingsRouter() {
  return (
    <div className="mt-8">
      <Breadcrumbs routes={bookingRoutes} />

      <div className="mt-7">
        <Switch>
          {bookingRoutes.map((route) => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}

          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  );
}
