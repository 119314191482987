import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NotFound from "../../../common/NotFound";

export default function InviteSent() {
  const { t } = useTranslation();
  const { organisationId } = useParams();
  const location = useLocation();

  if (location.state === undefined || location.state === null)
    return <NotFound />;

  const formattedLeadText = () => {
    const leadText = t("accountOrganisations.inviteSent.leadText");
    return leadText.replace(
      "#FIRST_NAME#",
      location.state.inviteData.givenName
    );
  };

  return (
    <div className="space-y-8">
      <h1>{t("accountOrganisations.inviteSent.title")}</h1>
      <p className="text-lg">{formattedLeadText()}</p>
      <div>
        <Link
          to={`/account/organisations/${organisationId}`}
          className="btn-brand"
        >
          {t("accountOrganisations.inviteSent.buttonText")}
        </Link>
      </div>
    </div>
  );
}
