import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import apiUrl from "../../../../../data/api-url";
import { tokenFetcher } from "../../../../../data/fetchers";
import { useAuth } from "../../../../../hooks/use-auth";
import LoadingBlock from "../../../../common/loading/LoadingBlock";

export default function ShowService() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { siteId, serviceId } = useParams();

  const { data: service } = useSWR(
    user
      ? [apiUrl("spaces", `/sites/${siteId}/services/${serviceId}`), user.token]
      : null,
    tokenFetcher
  );

  if (!service) {
    return <LoadingBlock />;
  }

  return (
    <div className="space-y-8">
      <header className="flex justify-between items-end">
        <h1>
          <small className="text-base font-medium uppercase">
            {t("accountSites.service.service")}
          </small>
          <br />
          {service.name}
        </h1>
        <Link
          to={`/account/sites/${siteId}/services/${serviceId}/edit`}
          className="btn-brand"
        >
          {t("accountSites.service.edit")}
        </Link>
      </header>
      <p>{service.description}</p>
      <p className="mt-8">
        <span className="font-bold">
          {t("accountSites.service.customerInformation")}:
        </span>
        <br />
        <span className="text-gray-500">{service.customerInformation}</span>
      </p>
    </div>
  );
}
