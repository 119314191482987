import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import apiUrl from "../../../../../data/api-url";
import submitForm from "../../../../../data/submit-form";
import { useAuth } from "../../../../../hooks/use-auth";
import { useStore } from "../../../../../hooks/use-store";
import { formatDate } from "../../../../../utils/date-transformations";
import { titleCase } from "../../../../../utils/text-transformations";
import Button from "../../../../common/Button";
import {
  CheckboxField,
  CheckboxInput,
  CurrencyInput,
  Field,
  FieldErrorMessage,
} from "../../../../common/forms";
import LoadingBlock from "../../../../common/loading/LoadingBlock";
import SuccessMessage from "../../../../common/messages/SuccessMessage";
import PriceDisplay from "../../../../common/PriceDisplay";
import NoteList from "../../../bookings/service-requests/NoteList";
import { RequestStockItem, ServiceRequest } from '../../../../../app/model/service-requests';
import requests from '../../../../../data/requests';
import { StockItem } from '../../../../../app/model/stock-management';
import useSWR, { mutate } from 'swr';
import Feature from '../../../../common/feature-flags/Feature';
import RequestStockItems from '../../../bookings/service-requests/RequestStockItems';
import ConfirmStockItems from './ConfirmStockItems';
import { tokenFetcher } from '../../../../../data/fetchers';
import CloseRequest from './CloseRequest';
import SetRequestPrice from './SetRequestPrice';
import { useForm } from 'react-hook-form';

interface OverstockData {
  overstock: boolean;
  overstockPrice: number;
}

export default observer(function ViewServiceRequest() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { userStore } = useStore();
  const { requestId, siteId } = useParams<{siteId: string, requestId: string}>();
  const [ serviceRequest, setServiceRequest ] = useState<ServiceRequest | undefined>(undefined);
  const [ stockItems, setStockItems ] = useState<RequestStockItem[]>([]);
  const [finalisingRequest, setFinalisingRequest] = useState(false);
  const [agreeFinalise, setAgreeFinalise] = useState(false);
  const [priceSetSuccessful, setPriceSetSuccessful] = useState(false);
  const [stockQuantities, setStockQuantities] = useState<Record<string, number>>({});
  const [invalidQuantities, setInvalidQuantities] = useState(false);
  const [overstock, setOverstock] = useState(false);
  const {
    formState: { errors },
    register,
    getValues,
    resetField,
    //resetField,
  } = useForm<{overstockPrice?: number}>({ mode: "onBlur" });

  // TODO: Load currency from account
  const currency = { symbol: "£", code: "GBP" };

  const { data: request } = useSWR(
    [apiUrl("bookings", `/site/${siteId}/service-requests/${requestId}`), user.token],
    tokenFetcher
  );

  useEffect(() => {
    async function loadServiceRequest() {

      // If there's any stock items for the service request, load them from the stock service
      if (request && request.stockItemQuantities !== undefined && request.stockItemQuantities.length > 0) {
        const stockItemIds = request.stockItemQuantities.map(((item:any) => item.stockItemId));

        const stockResult = await requests.post<StockItem[]>(apiUrl(
          "stock",
          '/stock/list'
        ), { stockItemIds: stockItemIds });

        // Loop through the stock items quantities, find the stock item, and map to a RequestStockItem
        let stockItemQuantities: RequestStockItem[] = [];
        for (var i = 0; i < request.stockItemQuantities.length; i++) {
          // Find the stock item matching the id
          const stockItem = findStockItem(stockResult, request.stockItemQuantities[i].stockItemId);
          
          if (stockItem !== undefined) {
            stockItemQuantities.push({
              id: stockItem.id, 
              name: stockItem.name,
              quantity: request.stockItemQuantities[i].quantity,
              sku: stockItem.sku
            });
          }
        }

        setStockItems(stockItemQuantities);
      }

      setServiceRequest(request);
    }
    loadServiceRequest();

  }, [siteId, requestId, request]);  

  const findStockItem = (stockResult:StockItem[], id: string) => {
    for (var i = 0; i < stockResult.length; i++) {
      if (stockResult[i].id.toLowerCase() === id.toLowerCase()) {
        return stockResult[i];
      }
    }
    return undefined;

  }

  const showPrice = () => {
    if (serviceRequest!.priceDetails === null) {
      return t("accountBookings.serviceRequestDetail.priceNotAvailable");
    } else {
      return <PriceDisplay price={serviceRequest!.priceDetails.subTotal} />;
    }
  };

  const canSetPrice = () => {
    return (
      serviceRequest!.status.toLowerCase() === "new" ||
      serviceRequest!.status.toLowerCase() === "quoted"
    );
  };

  const setPriceCallback = (subTotal: number) => {
    if (request !== undefined) {
      request.priceDetails = { 
        subTotal: subTotal,
        serviceFee: 0,
        cardProcessingFee: 0,
        currency: 'GBP',
        paymentTransferFee: 0,
        taxAmount: 0,
        taxRate: 0,
        totalChargeAmount: 0 
      };
      request.status = "Quoted";
      setPriceSetSuccessful(true);
    }
  }

  const finaliseServiceRequest = async () => {
    setInvalidQuantities(false);

    if (request.stockItemQuantities !== undefined && request.stockItemQuantities.length > 0 
        && (stockQuantities === undefined || Object.keys(stockQuantities).length === 0)) {
          setInvalidQuantities(true);
          return;
        }

    if (stockQuantities !== undefined) {
      for (const key in stockQuantities)
        if (stockQuantities[key] <= 0) {
          setInvalidQuantities(true);
          return;
        }
    }

    let overstockData: OverstockData | null = null;
    if (overstock) {
      overstockData = {
        overstock: true,
        overstockPrice: parseFloat(getValues("overstockPrice")?.toString() ?? "0")
      };
    }

    setFinalisingRequest(true);

    await submitForm(
      apiUrl(
        "bookings",
        `/bookings/${serviceRequest!.bookingId}/service-requests/${requestId}/finalise`
      ),
      {
        accountId: userStore.contextAccount?.id,
        siteId: siteId,
        stockItems: stockQuantities,
        overstock: overstockData
      },
      user.token,
      "PUT"
    )
      .then(() => {
        mutate(
          apiUrl("bookings", `/site/${siteId}/service-requests/${requestId}`)
        );
        serviceRequest!.status = "Finalised";
      })
      .finally(() => {
        setFinalisingRequest(false);
      });
  };

  const canClose = () => {
    return serviceRequest!.status.toLowerCase() === "new";
  };

  const canFinalise = () => {
    return serviceRequest!.status.toLowerCase() === "approved";
  };

  const setQuantityCallback = (stockItemId: string, quantity: number) => {
    setInvalidQuantities(false);
    stockQuantities[stockItemId] = quantity;
    checkOverstock();
    setStockQuantities(stockQuantities);
  }

  const checkOverstock = () => {

    if (stockQuantities === undefined || request === undefined)
      return;

    let overstockDetected = false;
    for (const key in stockQuantities) {

      for (var i = 0; i < request.stockItemQuantities.length; i++) {
        if (request.stockItemQuantities[i].stockItemId === key && 
          stockQuantities[key] > request.stockItemQuantities[i].quantity) {
            overstockDetected = true;
        }
      }
    }
        
    setOverstock(overstockDetected);

    // If there's no overstock, reset overstock price
    if (!overstockDetected) {
      resetField("overstockPrice");
    }
    
  }

  if (!serviceRequest) {
    return <LoadingBlock />;
  }

  return (
    <div className="space-y-8">
      <h1>{t("accountBookings.serviceRequestDetail.title")}</h1>

      <div>
        <h2>
          <small className="text-sm font-medium uppercase">
            {" "}
            {t("accountBookings.serviceRequests.reference")}
          </small>
          <br />
          {serviceRequest.reference}
        </h2>
      </div>

      <div className="grid md:grid-cols-3 md:gap-6 space-y-4 md:space-y-0">
        <div>
          <span className="font-bold block">
            {t("accountBookings.serviceRequestDetail.serviceName")}
          </span>
          {serviceRequest.serviceName}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.serviceRequestDetail.created")}
          </span>
          {formatDate(serviceRequest.created)}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.serviceRequestDetail.status")}
          </span>
          {titleCase(serviceRequest.status)}
        </div>
      </div>

      <div className="grid md:grid-cols-3 md:gap-6 space-y-4 md:space-y-0">
        <div>
          <span className="font-bold block">
            {t("accountBookings.serviceRequestDetail.palletQuantity")}
          </span>
          {serviceRequest.palletQuantity}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.serviceRequestDetail.requiredByDate")}
          </span>
          {serviceRequest.requiredByDate === null ? 'N/A' : formatDate(serviceRequest.requiredByDate)}
        </div>
      </div>

      <div>
        <span className="font-bold block">
          {t("accountBookings.serviceRequestDetail.requestDetails")}
        </span>
        {serviceRequest.requestDetails}
      </div>

      <div>
        <span className="font-bold block">
          {t("accountBookings.serviceRequestDetail.price")}
        </span>
        {showPrice()}
      </div>

      {serviceRequest.stockItemQuantities && serviceRequest.stockItemQuantities.length > 0 && (
        <Feature name='stock-management'>
          <RequestStockItems stockItems={stockItems} />
          {canFinalise() && (
            <>
              <ConfirmStockItems stockItems={stockItems} setQuantityCallback={setQuantityCallback} />
              {invalidQuantities && (
                <FieldErrorMessage message={t("accountBookings.serviceRequestDetail.confirmStock.stockQuantityReq")} />
              )}

              {overstock && (
                <div className='space-y-4'>
                  <h3>{t("accountBookings.serviceRequestDetail.confirmStock.overstock")}</h3>
                  <p className='text-orange-700'>{t("accountBookings.serviceRequestDetail.confirmStock.overstockDesc")}</p>
                  <Field
                    label={t("accountBookings.serviceRequestDetail.confirmStock.overstockPrice")}
                    htmlFor="message"
                    helpText={t(
                      "accountBookings.serviceRequestDetail.actions.setPriceHelp"
                    )}
                  >
                    <div className="flex">
                      <CurrencyInput
                        name="price"
                        defaultValue='0'
                        currency={currency}
                        register={register("overstockPrice", {
                          min: {
                            value: 0,
                            message: t("common.validation.min", { value: 0 }),
                          },
                        })}
                        error={errors.overstockPrice}
                      />
                    </div>
                    <FieldErrorMessage error={errors.overstockPrice} />
                  </Field>
                </div>
              )}

            </>
          )}
        </Feature>
      )}

      {priceSetSuccessful && (
        <SuccessMessage
          title={t(
            "accountBookings.serviceRequestDetail.actions.priceSetMsgTitle"
          )}
          message={t(
            "accountBookings.serviceRequestDetail.actions.priceSetMsgText"
          )}
        />
      )}

      {canSetPrice() && (
        <SetRequestPrice 
          canSetPrice={canSetPrice()} 
          siteId={siteId} 
          bookingId={request.bookingId} 
          requestId={requestId} 
          callback={setPriceCallback} />
      )}

      {(canClose() || canFinalise()) && (
        <div className="space-y-6">
          <h3>{t("accountBookings.serviceRequestDetail.actions.title")}</h3>

          {canClose() && (
            <CloseRequest 
              canClose={canClose()} 
              bookingId={request.bookingId} 
              requestId={requestId} 
              siteId={siteId} 
              callback={() => request.status = "Closed"} />
          )}

          {canFinalise() && (
            <>
              <CheckboxField
                label={t(
                  "accountBookings.serviceRequestDetail.actions.finaliseAgree"
                )}
                htmlFor="finaliseAgree"
              >
                <CheckboxInput
                  name="finaliseAgree"
                  id="finaliseAgree"
                  className="w-auto"
                  onChange={(e) => setAgreeFinalise(e.target.checked)}
                />
              </CheckboxField>
              <Button
                type="button"
                onClick={() => finaliseServiceRequest()}
                label={t(
                  "accountBookings.serviceRequestDetail.actions.finalise"
                )}
                className="btn-brand mr-6"
                loading={finalisingRequest}
                disabled={!agreeFinalise}
              />
            </>
          )}
        </div>
      )}

      <NoteList
        notes={serviceRequest.notes}
        requestId={requestId}
        bookingId={serviceRequest.bookingId}
      />
    </div>
  );
});
