import { useTranslation } from "react-i18next";
import { Field, FieldErrorMessage, TextInput } from "../common/forms";

export default function ReceiptHandlingDispatch({ formMethods, bookingQty }) {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
  } = formMethods;

  return (
    <div className="">
      <h2>{t("booking.rhd.title")}</h2>
      <p>{t("booking.rhd.leadText")}</p>

      <fieldset className="space-y-6 mt-5">
        <Field
          label={t("booking.rhd.initialReceipt")}
          htmlFor="initialReceiptQty"
          helpText={t("booking.rhd.initialReceiptHelp")}
        >
          <div className="md:w-1/2 lg:w-1/3">
            <TextInput
              name="initialReceiptQty"
              defaultValue=""
              register={register("initialReceiptQty", {
                required: true,
                min: {
                  value: 1,
                  message: t("common.validation.min", { value: 1 }),
                },
                validate: {
                  greaterThanBooking: (value) => {
                    return (
                      value <= bookingQty ||
                      t(
                        "booking.rhd.validation.initialReceiptQty.greaterThanBooking"
                      )
                    );
                  },
                },
              })}
              error={errors.initialReceiptQty}
            />
          </div>
          <FieldErrorMessage error={errors.initialReceiptQty} />
        </Field>
      </fieldset>

      <h2 className="mt-7">{t("booking.rhd.estimatesTitle")}</h2>
      <p>{t("booking.rhd.estimatesLeadText")}</p>
      <fieldset className="space-y-6 mt-5">
        <Field
          label={t("booking.rhd.estimatedThroughput")}
          htmlFor="estimatedThroughput"
          helpText={t("booking.rhd.estimatedThroughputHelp")}
        >
          <div className="md:w-1/2 lg:w-1/3">
            <TextInput
              name="estimatedThroughput"
              defaultValue=""
              register={register("estimatedThroughput")}
              error={errors.estimatedThroughput}
            />
          </div>
          <FieldErrorMessage error={errors.estimatedThroughput} />
        </Field>
        <Field
          label={t("booking.rhd.estimatedMovements")}
          htmlFor="estimatedMovements"
          helpText={t("booking.rhd.estimatedMovementsHelp")}
        >
          <div className="md:w-1/2 lg:w-1/3">
            <TextInput
              name="estimatedMovements"
              defaultValue=""
              register={register("estimatedMovements")}
              error={errors.estimatedMovements}
            />
          </div>
          <FieldErrorMessage error={errors.estimatedMovements} />
        </Field>
      </fieldset>
    </div>
  );
}
