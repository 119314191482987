import i18n from "../../../i18n";

import SitesDashboard from "./Dashboard";
import ShowSite from "./Site/Show";
import NewSite from "./Site/New";
import EditSite from "./Site/Edit";

import ShowSpace from "./Space/Show";
import NewSpace from "./Space/New";
import EditSpace from "./Space/Edit";

import NewAvailability from "./Availability/New";
import EditAvailability from "./Availability/Edit";

import {
  ShowServiceBreadcrumb,
  ShowSiteBreadcrumb,
  ShowSpaceBreadcrumb,
} from "./Breadcrumbs";
import NewService from "./Site/services/NewService";
import RemoveService from "./Site/services/RemoveService";
import EditService from "./Site/services/EditService";
import ShowService from "./Site/services/ShowService";
import ShowSiteBooking from "./Site/bookings/ShowSiteBooking";
import ViewServiceRequest from "./Site/service-requests/ViewServiceRequest";

const tEdit = i18n.t("accountSites.breadcrumbs.edit");

const siteRoutes = [
  { path: "/account/sites/new", component: NewSite, breadcrumb: "Add new", exact: false },
  {
    path: "/account/sites/:siteId/edit",
    component: EditSite,
    breadcrumb: tEdit,
  },
  {
    path: "/account/sites/:siteId/spaces/new",
    component: NewSpace,
    breadcrumb: i18n.t("accountSites.breadcrumbs.addSpace"),
  },
  {
    path: "/account/sites/:siteId/spaces/:spaceId/availabilities/new",
    component: NewAvailability,
    breadcrumb: i18n.t("accountSites.breadcrumbs.addAvailability"),
  },
  {
    path: "/account/sites/:siteId/spaces/:spaceId/availabilities/:availabilityId/edit",
    component: EditAvailability,
    breadcrumb: i18n.t("accountSites.breadcrumbs.editAvailability"),
  },
  {
    path: "/account/sites/:siteId/spaces/:spaceId/edit",
    component: EditSpace,
    breadcrumb: tEdit,
  },
  {
    path: "/account/sites/:siteId/spaces/:spaceId",
    component: ShowSpace,
    breadcrumb: ShowSpaceBreadcrumb,
  },
  {
    path: "/account/sites/new",
    component: NewSite,
    breadcrumb: i18n.t("accountSites.breadcrumbs.addSite"),
  },
  {
    path: "/account/sites/:siteId/services/new",
    component: NewService,
    breadcrumb: i18n.t("accountSites.breadcrumbs.addService"),
  },
  {
    path: "/account/sites/:siteId/services/:serviceId/remove",
    component: RemoveService,
    breadcrumb: i18n.t("accountSites.breadcrumbs.removeService"),
  },
  {
    path: "/account/sites/:siteId/services/:serviceId/edit",
    component: EditService,
    breadcrumb: i18n.t("accountSites.breadcrumbs.editService"),
  },
  {
    path: "/account/sites/:siteId/services/:serviceId/",
    component: ShowService,
    breadcrumb: ShowServiceBreadcrumb,
  },
  {
    path: "/account/sites/:siteId/booking/:bookingId/",
    component: ShowSiteBooking,
    breadcrumb: i18n.t("accountBookings.breadcrumbs.bookingDetails"),
  },
  {
    path: "/account/sites/:siteId/service-requests/:requestId/",
    component: ViewServiceRequest,
    breadcrumb: i18n.t("accountBookings.breadcrumbs.viewServiceRequest"),
  },
  {
    exact: true,
    path: "/account/sites/:siteId",
    component: ShowSite,
    breadcrumb: ShowSiteBreadcrumb,
  },
  {
    exact: true,
    path: "/account/sites",
    component: SitesDashboard,
    breadcrumb: i18n.t("accountSites.breadcrumbs.sites"),
  },
];

export default siteRoutes;
