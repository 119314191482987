import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useStore } from '../../../../hooks/use-store';
import LoadingBlock from '../../../common/loading/LoadingBlock';
import NotFound from '../../../common/NotFound';
import Tooltip from '../../../common/Tooltip';

export default observer(function StockItemDetails() {

  const { t } = useTranslation();
  const { stockItemId } = useParams<{stockItemId: string }>();

  const { stockStore, userStore } = useStore();
  const { loading, loadStockItem, stockItem, clearStockItem } = stockStore;

  useEffect(() => {
    if (stockItemId && userStore.contextAccount !== undefined) 
      loadStockItem(stockItemId);
      
		return () => clearStockItem();

  // No need for dependencies, only want a single load.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadStockItem, clearStockItem, userStore.contextAccount]);

  if (userStore.contextAccount === undefined || loading)
    return <LoadingBlock />

  if (!stockItem)
  {
    // Implement 404
    return <NotFound />;
  }

  return (
    <div className='space-y-6'>
      <header className="flex justify-between items-end">
        <h1>{t("stockManagement.stockItemDetails.title")}</h1>
        <Link to={`/account/stock-management/${stockItemId}/edit`} className="btn-brand">
          {t("stockManagement.edit.button")}
        </Link>
      </header>

      <div className='space-y-6'>

        <div className='grid grid-cols-12 gap-6 border-b border-b-gray-700 pb-6'>
          <div className='col-span-12 sm:col-span-2'><span className='font-bold'>Overview</span></div>
          <div className='col-span-12 sm:col-span-10'>
            <div className='grid grid-cols-12 gap-6'>
              <div className='col-span-4 sm:col-span-2 text-gray-500 pb-2'>Name</div>
              <div className='col-span-8 sm:col-span-10'>{stockItem.name}</div>
            </div>
            <div className='grid grid-cols-12 gap-6'>
              <div className='col-span-4 sm:col-span-2 text-gray-500 pb-2'>Description</div>
              <div className='col-span-8 sm:col-span-10'>{stockItem.description}</div>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-12 gap-6 border-b border-b-gray-700 pb-6'>
          <div className='col-span-12 sm:col-span-2'><span className='font-bold'>Codes</span></div>
          <div className='col-span-12 sm:col-span-10'>
            <div className='grid grid-cols-12 gap-6'>
              <div className='col-span-4 sm:col-span-2 text-gray-500 pb-2'>SKU <Tooltip tooltip={t("stockManagement.tooltips.sku")} /></div>
              <div className='col-span-8 sm:col-span-10'>{stockItem.sku}</div>
            </div>
            <div className='grid grid-cols-12 gap-6'>
              <div className='col-span-4 sm:col-span-2 text-gray-500 pb-2'>GTIN <Tooltip tooltip={t("stockManagement.tooltips.gtin")} /></div>
              <div className='col-span-8 sm:col-span-10'>{stockItem.gtin}</div>
            </div>
            <div className='grid grid-cols-12 gap-6'>
              <div className='col-span-4 sm:col-span-2 text-gray-500 pb-2'>UPC <Tooltip tooltip={t("stockManagement.tooltips.upc")} /></div>
              <div className='col-span-8 sm:col-span-10'>{stockItem.upc}</div>
            </div>
          </div>
        </div>

      </div>

    </div>
  )
});