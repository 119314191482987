import { useTranslation } from "react-i18next";
import { BiCheckCircle } from "react-icons/bi";
import { mutate } from "swr";
import apiUrl from "../../../../data/api-url";
import { useStore } from '../../../../hooks/use-store';
import { useEffect } from 'react';
import LoadingBlock from '../../../common/loading/LoadingBlock';
import { observer } from 'mobx-react-lite';

export default observer(function OnboardingComplete() {
  const { t } = useTranslation();
  const { userStore } = useStore();
  const { setPaymentAccountConfirmed, contextAccount, loading } = userStore;

  mutate(apiUrl("accounts", "/users"));

  useEffect(() => {
    if (contextAccount !== undefined && !loading) {
      setPaymentAccountConfirmed();
    }
  }, [contextAccount, setPaymentAccountConfirmed, loading]);

  if (loading) return <LoadingBlock />

  return (
    <div className="space-y-8">
      <h2>{t("accountOrganisations.onboardingComplete.title")}</h2>
      <p className="text-2xl">
        <span>
          <BiCheckCircle className="text-green-700 inline pr-2 text-5xl" />
        </span>
        {t("accountOrganisations.onboardingComplete.leadText")}
      </p>
      <p>{t("accountOrganisations.onboardingComplete.message")}</p>
    </div>
  );
});