import { useTranslation } from "react-i18next";
import { ExternalBankAccount } from '../../../../app/model/account';

interface BankAccountListProps {
  bankAccounts: ExternalBankAccount[]
}

export default function BankAccountList({ bankAccounts } : BankAccountListProps) {
  const { t } = useTranslation();

  if (!bankAccounts || bankAccounts.length === 0)
    return (
      <div>
        <h2 className="mb-6">{t("accountOrganisations.bankAccounts.title")}</h2>
        {t("accountOrganisations.bankAccounts.noBankAccounts")}
      </div>
    );

  return (
    <div>
      <h2 className="mb-6">{t("accountOrganisations.bankAccounts.title")}</h2>
      {bankAccounts.map((account, index) => (
        <div key={index} className="mb-4 border-b border-b-gray-200 pb-4">
          <h3 className="mb-1">{account.bankName}</h3>
          <div className="text-gray-500 flex justify-between max-w-2xl">
            <div>
              <p className="font-medium">Routing number:</p>
              {account.routingNumber}
            </div>
            <div>
              <p className="font-medium">Last four:</p>
              {account.last4}
            </div>
            <div className="mr-10">
              <p className="font-medium">Currency:</p>
              {account.currency.toUpperCase()}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
