import React from 'react'
import { useTranslation } from 'react-i18next'

export default function RequestSubmitted() {
  const { t } = useTranslation();
  
  return (
    <div className="space-y-8">
      <h1>{t("support.submitted.title")}</h1>
      <p className='text-xl'>{t("support.submitted.leadText")}</p>
    </div>
  )
}