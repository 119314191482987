import { Route, Switch } from "react-router-dom";
import Breadcrumbs from "../../common/Breadcrumbs";
import siteRoutes from "./site-routes";

export default function SitesRouter() {
  return (
    <div className="mt-8">
      <Breadcrumbs routes={siteRoutes} />

      <div className="mt-7">
        <Switch>
          {siteRoutes.map((route) => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </div>
    </div>
  );
}
