import { useTranslation } from "react-i18next";
import { Route, Switch, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/use-auth";
import orgDetailRoutes from "./organisation-detail-routes";
import OrganisationNavigation from "./OrganisationNavigation";
import useSWR from "swr";
import apiUrl from "../../../data/api-url";
import { tokenFetcher } from "../../../data/fetchers";
import LoadingBlock from "../../common/loading/LoadingBlock";
import { Link } from "react-router-dom";

export default function OrganisationDetailsRouter() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { organisationId } = useParams<{organisationId: string}>();

  const { data: organisation } = useSWR(
    user && organisationId
      ? [apiUrl("accounts", `/account/${organisationId}`), user.token]
      : null,
    tokenFetcher
  );

  if (!organisation) return <LoadingBlock />;

  return (
    <div className="mt-7">
      <header className="flex justify-between items-end">
        <h1>
          <small className="text-base font-medium uppercase">
            {t("accountOrganisations.organisation")}
          </small>
          <br />
          {organisation.name}
        </h1>
        <Link
          to={`/account/organisations/${organisationId}/edit`}
          className="btn-brand"
        >
          {t("accountOrganisations.edit.buttonText")}
        </Link>
      </header>

      <div className="grid md:grid-cols-12 gap-10 pt-10">
        <OrganisationNavigation />
        <div className="md:col-span-8 lg:col-span-9">
          <Switch>
            {orgDetailRoutes.map((route) => (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            ))}
          </Switch>
        </div>
      </div>
    </div>
  );
}
