import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ServiceRequestList from './ServiceRequestList';
import { useStore } from '../../../hooks/use-store';
import LoadingBlock from '../../common/loading/LoadingBlock';
import { observer } from 'mobx-react-lite';
import OpenServiceRequests from '../dashboard-widgets/OpenServiceRequests';
import { tokenFetcher } from '../../../data/fetchers';
import useSWR from 'swr';
import apiUrl from '../../../data/api-url';
import ServiceRequestsValue from '../dashboard-widgets/ServiceRequestsValue';
import ServiceRequestsFulfilledPercent from '../dashboard-widgets/ServiceRequestsFulfilledPercent';

export default observer(function ServiceRequests() {
  const { t } = useTranslation();

  const { serviceRequestStore, userStore } = useStore();
  const { loading, loadAccountServiceRequests, accountServiceRequests} = serviceRequestStore;
  const [metricsLoading, setMetricsLoading] = useState(true);

  const { data: requestsMetrics } = useSWR(
    userStore.contextAccount?.id
      ? [
          apiUrl(
            "bookings",
            `/metrics/account-service-requests/${userStore.contextAccount.id}`
          ),
        ]
      : null,
    tokenFetcher
  );

  useEffect(() => {

    if (userStore.contextAccount !== undefined)
      loadAccountServiceRequests(userStore.contextAccount.id);

    if (requestsMetrics !== undefined)
      setMetricsLoading(false);

  }, [userStore.contextAccount, loadAccountServiceRequests, requestsMetrics])

  if (loading || !accountServiceRequests) {
    return <LoadingBlock />
  }

  const sellerRequests = accountServiceRequests.filter((item) => item.sellerAccountId === userStore.contextAccount!.id);
  const buyerRequests = accountServiceRequests.filter((item) => item.buyerAccountId === userStore.contextAccount!.id);

  return (
    <div className="space-y-8">
      <h1>{t("accountServiceRequests.title")}</h1>

      <ul className="grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-6">
        <li className="p-4 shadow-md rounded border border-gray-200 mb-4">
          <ServiceRequestsValue value={requestsMetrics?.totalServiceRequestValue ?? 0} loading={metricsLoading} />
        </li>
        <li className="p-4 shadow-md rounded border border-gray-200 mb-4">
          <ServiceRequestsFulfilledPercent
            finalised={requestsMetrics?.finalisedServiceRequests}
            percent={requestsMetrics?.percentFulfilled}
            total={requestsMetrics?.totalServiceRequests}
            loading={metricsLoading} />
        </li>
        <li className="p-4 shadow-md rounded border border-gray-200 mb-4">
          <OpenServiceRequests openRequests={requestsMetrics?.openServiceRequests ?? 0} loading={metricsLoading} />
        </li>
      </ul>

      <h2>{t("accountServiceRequests.sellerRequestsTitle")}</h2>
      <ServiceRequestList serviceRequests={sellerRequests} noRequestsMessage={t("accountServiceRequests.noSellerRequests")} actor="seller" />
      <h2>{t("accountServiceRequests.buyerRequestsTitle")}</h2>
      <ServiceRequestList serviceRequests={buyerRequests} noRequestsMessage={t("accountServiceRequests.noBuyerRequests")} actor="buyer" />
    </div>
  )
});