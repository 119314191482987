import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from '../hooks/use-store';

const sleep = (delay: number) => {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
	const token = store.commonStore.token;
	if (token) 
		config.headers = {
			Authorization: `Bearer ${token}`
		};
	return config;
})

axios.interceptors.response.use(
  async response => {
		
		if (process.env.NODE_ENV === 'development')	
		await sleep(1000);

		handleDates(response.data);
		return response;
  }, 
  (error: AxiosError) => {
		
		console.log(error);
		if (error.code === 'ERR_NETWORK')
		{
			// TODO: Set some sort of API OFFLINE error 
			return;
		}

		const {status} = error.response!;
		const data:any = error.response!.data;

		const apiError = {
			errorCode: data.errorCode,
			status: status,
			message: data.message
		}
		throw apiError;
  }
)

/**
 * Determines if the value is a DateTime string format from the API (.NET format used)
 * @param value The value to check
 * @returns true if the value is datetime, otherwise false.
 */
function isIsoDateString(value: any): boolean {
	const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?$/;
  return value && typeof value === "string" && isoDateFormat.test(value);
}

/**
 * Recursively iterates through the provided body object, and tests each property value. 
 * If the property value is a string in the IsoDateFormat, then it is converted to a JS Date object.
 * @param body 
 * @returns 
 */
function handleDates(body: any) {
  if (body === null || body === undefined || typeof body !== "object")
    return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) 
		{
			body[key] = new Date(value);
		}
    else if (typeof value === "object") handleDates(value);
  }
}

const responseBody = <T> (response: AxiosResponse<T>) => {
	return response.data;
}

const requests = {
	get: <T> (url: string) => axios.get<T>(url).then(responseBody),
	post: <T> (url: string, body: {}, config?: AxiosRequestConfig<any> | undefined) => axios.post<T>(url, body, config).then(responseBody),
	put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
	del: <T> (url: string) => axios.delete<T>(url).then(responseBody),
}

export default requests;