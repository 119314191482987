import i18n from "../../i18n";

import Dashboard from "./Dashboard";
import SitesRouter from "./sites/SitesRouter";
import SettingsRouter from "./settings/SettingsRouter";
import OrganisationsRouter from "./organisations/OrganisationsRouter";
import BookingsRouter from "./bookings/BookingsRouter";
import SupportRouter from "./support/SupportRouter";
import ServiceRequestsRouter from './service-requests/ServiceRequestsRouter';

const accountRoutes = [
  {
    path: "/account",
    component: Dashboard,
    exact: true,
    label: i18n.t("account.dashboard"),
    hideFromNav: true,
  },
  {
    path: "/account/bookings",
    component: BookingsRouter,
    label: i18n.t("accountBookings.bookings"),
  },
  {
    path: "/account/service-requests",
    component: ServiceRequestsRouter,
    label: i18n.t("accountServiceRequests.menuTitle"),
  },
  {
    path: "/account/sites",
    component: SitesRouter,
    label: i18n.t("accountSites.sites"),
  },
  {
    path: "/account/settings",
    component: SettingsRouter,
    label: i18n.t("accountSettings.accountSettings"),
    hideFromNav: true,
  },
  {
    path: "/account/organisations",
    component: OrganisationsRouter,
    label: i18n.t("accountOrganisations.organisations"),
    hideFromNav: false,
  },
  {
    path: "/account/support",
    component: SupportRouter,
    label: i18n.t("accountOrganisations.organisations"),
    hideFromNav: true,
  },
];

export default accountRoutes;
