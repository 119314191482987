import i18n from "../../../i18n";

import InviteUser from "./users/InviteUser";
import InviteSent from "./users/InviteSent";
import ChangePlan from "./plans/ChangePlan";
import PaymentDetails from "./payment/PaymentDetails";
import ShowOrganisation from "./show/ShowOrganisation";
import CurrentPlanDetails from "./plans/CurrentPlanDetails";
import PaymentMethods from "./payment/PaymentMethods";
import AddPaymentMethod from "./payment/AddPaymentMethod";
import UserList from "./users/UserList";
import PaymentHistory from "./payment/PaymentHistory";
import EditOrganisation from "./edit/EditOrganisation";
import OnboardingComplete from "./show/OnboardingComplete";
import BankAccounts from "./bank-accounts/BankAccounts";
import PlanChanged from "./plans/PlanChanged";
import CompleteOnboarding from "./show/CompleteOnboarding";
import Invoices from './invocies/Invoices';
import InvoicePayment from './invocies/InvoicePayment';
import PaymentComplete from './invocies/PaymentComplete';

const orgDetailRoutes = [
  {
    path: "/account/organisations/:organisationId/users/invite",
    component: InviteUser,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.invite"),
  },
  {
    path: "/account/organisations/:organisationId/users/invite-sent",
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.inviteSent"),
    component: InviteSent,
  },
  {
    path: "/account/organisations/:organisationId/users",
    component: UserList,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.users"),
  },
  {
    path: "/account/organisations/:organisationId/change-plan",
    component: ChangePlan,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.changePlan"),
  },
  {
    path: "/account/organisations/:organisationId/plan-changed",
    component: PlanChanged,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.planChanged"),
  },
  {
    path: "/account/organisations/:organisationId/payment-details",
    component: PaymentDetails,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.paymentDetails"),
  },
  {
    path: "/account/organisations/:organisationId/payment-methods/add",
    component: AddPaymentMethod,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.addPaymentMethod"),
  },
  {
    path: "/account/organisations/:organisationId/payment-methods",
    component: PaymentMethods,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.paymentMethods"),
    exact: true,
  },
  {
    path: "/account/organisations/:organisationId/payment-history",
    component: PaymentHistory,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.paymentHistory"),
  },
  {
    path: "/account/organisations/:organisationId/plan",
    component: CurrentPlanDetails,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.planDetails"),
  },
  {
    path: "/account/organisations/:organisationId/onboarding-complete",
    component: OnboardingComplete,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.onboardingComplete"),
  },
  {
    path: "/account/organisations/:organisationId/onboarding",
    component: CompleteOnboarding,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.onboarding"),
  },
  {
    path: "/account/organisations/:organisationId/bank-accounts",
    component: BankAccounts,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.bankAccounts"),
  },
  {
    path: "/account/organisations/:organisationId/invoices/:invoiceId/payment",
    component: InvoicePayment,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.payInvoice"),
  },
  {
    path: "/account/organisations/:organisationId/invoices/:invoiceId/payment-success",
    component: PaymentComplete,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.invoicePaid"),
  },
  {
    path: "/account/organisations/:organisationId/invoices",
    component: Invoices,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.invoices"),
  },
  {
    path: "/account/organisations/:organisationId/edit",
    component: EditOrganisation,
    breadcrumb: i18n.t("accountOrganisations.breadcrumbs.edit"),
  },
  {
    path: "/account/organisations/:organisationId",
    component: ShowOrganisation,
    exact: true,
  },
];

export default orgDetailRoutes;
