import React from 'react'
import { InvoicePaymentResponse } from '../../../../app/model/account'
import PriceDisplay from '../../../common/PriceDisplay';
import { useTranslation } from 'react-i18next';

interface InvoicePaymentOverviewProps {
  paymentDetails: InvoicePaymentResponse
}

export default function InvoicePaymentOverview({paymentDetails}: InvoicePaymentOverviewProps) {
  const { t } = useTranslation();

  return (
    <div>
      <h2>{t("accountOrganisations.invoices.payment.invoiceOverview")}</h2>
      <div className="space-y-3 mt-4">
        <div className="flex justify-between border-b border-b-gray-300 pb-1">
          <p className="text-gray-500">{t("accountOrganisations.invoices.payment.invoiceNumber")}</p>
          <span className="font-bold">{paymentDetails.invoiceNumber}</span>
        </div>
        <div className="flex justify-between border-b border-b-gray-300 pb-1">
          <p className="text-gray-500">
            {t("accountOrganisations.invoices.payment.amountDue")}
          </p>
          <PriceDisplay
            price={paymentDetails.amountDue}
            className="font-bold"
          />
        </div>
      </div>
    </div>
  );
}