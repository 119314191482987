interface TextAreaProps {
  name: string,
  id?: string,
  register: any,
  error?: any,
  className?: string,
  defaultValue?: string,
  placeholder?: string,
  rows?: number
}

export function TextArea({
  name,
  register,
  id = undefined,
  error = undefined,
  className = "input",
  rows = 3,
  ...props
}:TextAreaProps) {
  return (
    <textarea
      name={name}
      id={id || name}
      className={`${className} ${error ? "border-red-500" : ""}`}
      {...register}
      {...props}
    />
  );
}

export default TextArea;
