import { useTranslation } from "react-i18next";
import Button from "../../../common/Button";
import {
  Field,
  FieldErrorMessage,
  Form,
  TextArea,
} from "../../../common/forms";
import { useForm } from "react-hook-form";
import { useState } from "react";
import ErrorMessage from "../../../common/messages/ErrorMessage";
import submitForm from "../../../../data/submit-form";
import apiUrl from "../../../../data/api-url";
import { useAuth } from "../../../../hooks/use-auth";
import NoteListItem from "./NoteListItem";
import { useStore } from "../../../../hooks/use-store";
import { observer } from "mobx-react-lite";
import { Note } from '../../../../app/model/service-requests';

interface NoteListProps {
  notes: Note[],
  requestId?: string,
  bookingId?: string
};

interface NoteFormData {
  message: string
}

export default observer(function NoteList({ notes, requestId, bookingId }:NoteListProps) {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm<NoteFormData>({ mode: "onBlur" });
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { user } = useAuth();
  const { userStore } = useStore();

  const createNote = async (formData: NoteFormData) => {
    setSubmitting(true);
    setErrorMessage(null);

    const noteData = {
      accountId: userStore.contextAccount?.id,
      message: formData.message,
    };

    await submitForm(
      apiUrl(
        "bookings",
        `/bookings/${bookingId}/service-requests/${requestId}/notes`
      ),
      noteData,
      user.token
    )
      .then(() => {
        var date = new Date();
        notes.push({
          userDisplayName: user.displayName,
          accountName: userStore.contextAccount?.name ?? "",
          accountId: userStore.contextAccount?.id ?? "",
          created: date,
          message: formData.message,
        });

        reset();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        console.log(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="space-y-8">
      <h2>{t("accountBookings.serviceRequestDetail.notes")}</h2>

      <div className="space-y-4 lg:w-2/3">
        {(!notes || notes.length === 0) &&
          t("accountBookings.serviceRequestDetail.noNotes")}
        {notes.map((note, i) => (
          <NoteListItem key={i} note={note} />
        ))}
      </div>

      {errorMessage && <ErrorMessage message={errorMessage} />}

      <Form onSubmit={handleSubmit(createNote)} className="lg:w-2/3 space-y-6">
        <Field
          label={t("accountBookings.serviceRequestDetail.message")}
          htmlFor="message"
        >
          <TextArea
            name="message"
            rows={2}
            register={register("message", {
              required: t(
                "accountBookings.serviceRequestDetail.messageRequired"
              ),
            })}
            error={errors.message}
          />
          <FieldErrorMessage error={errors.message} />
        </Field>

        <div>
          <Button
            label={t("accountBookings.serviceRequestDetail.sendNote")}
            loading={submitting}
          />
        </div>
      </Form>
    </div>
  );
});
