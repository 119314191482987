import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiCheckCircle } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';

export default function PaymentComplete() {
  const { t } = useTranslation();

  const search = useLocation().search;
  const invoiceNo = new URLSearchParams(search).get("invoice_no") ?? "";

  const replacedInvoiceText = () => {
    let str = t("accountOrganisations.invoices.paymentComplete.successText");
    str = str.replace("#INVOICE_NO#", invoiceNo);
    return str;
  };

  return (
    <div className="space-y-6 text-center">
      <div className="text-center text-[100px] text-lime-500">
        <BiCheckCircle className="inline-block" />
      </div>
      <h1>{t("accountOrganisations.invoices.paymentComplete.title")}</h1>
      <p className="text-xl max-w-3xl text-center mx-auto">
        {replacedInvoiceText()}
      </p>

    </div>
  )
}