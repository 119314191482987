import { useLoading } from "../../../hooks/use-loading";

export default function LoadingBar() {
  const { loadingCount } = useLoading();
  return (
    <>
      {loadingCount > 0 && (
        <div className="w-full mb-4">
          <div className="animate-pulse flex">
            <div className="flex-1">
              <div className="h-1 bg-brand"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
