import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import apiUrl from "../../data/api-url";
import { submitAnonymousForm } from "../../data/submit-form";
import { useAuth } from "../../hooks/use-auth";
import Button from "../common/Button";
import { toastError } from "../common/messages/toast-messages";
import PriceDisplay, { GetCurrencySign } from "../common/PriceDisplay";

export default function SearchResultItem(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  const percentAvailable = (qty) => {
    let percent = (qty / props.qtyRequired) * 100;
    return percent > 100 ? 100 : Math.round(percent * 10) / 10;
  };

  const otherSpacesCount = props.item.spaces.length - 1;

  const startBooking = async () => {
    setLoading(true);
    const booking = {
      startDate: props.query.startDate,
      endDate: props.query.endDate,
      spaceLineItems: getSpaceLineItemArray(props.item.spaces),
    };

    const { data, err: remoteErrors } = await submitAnonymousForm(
      apiUrl("bookings", "/bookings"),
      booking
    );

    if (data) {
      const bookingLocation = {
        pathname: "/booking",
        state: {
          selectedOption: props.item,
          query: props.query,
          bookingId: data.id,
        },
      };

      // If the user is not logged in, then redirect to login
      if (typeof user === "undefined") {
        bookingLocation.pathname = "/sign-in";
        bookingLocation.state.from = { pathname: "/booking" };
      }

      history.push(bookingLocation);
      setLoading(false);
    } else if (remoteErrors) {
      toastError(t, remoteErrors.message, remoteErrors.errorCode);
      console.log(remoteErrors);
      setLoading(false);
    }
  };

  const getSpaceLineItemArray = (spaces) => {
    var spaceLineItems = [];

    for (var i = 0; i < spaces.length; i++) {
      spaceLineItems.push({
        spaceId: spaces[i].spaceId,
        siteId: spaces[i].siteId,
        availabilityWindowId: spaces[i].availabilityWindowId,
        quantity: spaces[i].usableQuantity,
      });
    }

    return spaceLineItems;
  };

  return (
    <div className="mb-10">
      <div className="p-4 rounded shadow-md">
        <h2 className="text-2xl font-bold mb-2 text-gray-800 relative">
          {props.item.title}
          {props.item.spaces.length > 1 && (
            <>
              {" "}
              (+{otherSpacesCount} {otherSpacesCount === 1 ? "other" : "others"}
              )
            </>
          )}
          <div className="absolute top-0 right-0 text-sm">
            {Math.round(props.item.closestDistance * 10) / 10} km
          </div>
        </h2>
        <div className="text-sm text-gray-500">{props.item.addressSummary}</div>
        <p className="text-gray-700">{props.item.spaces[0].spaceName}</p>
        <p>
          Available: {props.item.spaces[0].usableQuantity} (
          {percentAvailable(props.item.spaces[0].usableQuantity)}%)
        </p>
        {props.item.spaces.length > 1 &&
          props.item.spaces.map((space, index) => {
            return index > 0 ? (
              <p key={index} className="text-sm">
                {space.spaceName} Available: {space.usableQuantity}(
                {percentAvailable(space.usableQuantity)}%)
              </p>
            ) : null;
          })}
        <hr className="my-3" />
        <div className="grid grid-cols-2 gap-6">
          <PriceDisplay
            price={props.item.totalPrice}
            currencySign={GetCurrencySign(props.item.currency)}
            className="font-bold text-xl text-secondary"
          />
          <div>
            <Button
              label="Book now"
              className="btn-brand float-right"
              type="button"
              onClick={startBooking}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
