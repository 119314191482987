import { useTranslation } from "react-i18next";

import {
  CheckboxField,
  CheckboxFieldset,
  CheckboxInput,
  Field,
  FieldErrorMessage,
  TextInput,
} from "../../../common/forms";
import AddressInput from "../../../common/forms/AddressInput";
import LoadingBlock from "../../../common/loading/LoadingBlock";

export default function SiteFormFields({ formMethods, site, allAmenities }) {
  const { t } = useTranslation();
  const countries = [
    { id: "001", name: "England" },
    { id: "002", name: "Australia" },
  ];

  const {
    register,
    setValue,
    formState: { errors },
  } = formMethods;

  if (!allAmenities || !countries) {
    return <LoadingBlock />;
  }

  const openAmenities = site?.amenities?.length;
  const amenities = [
    {
      legend: t("accountSites.site.accessAmenities"),
      name: "accessAmenities",
      options: allAmenities.filter((a) => a.type.toLowerCase() === "access"),
    },
    {
      legend: t("accountSites.site.generalAmenities"),
      name: "generalAmenities",
      options: allAmenities.filter((a) => a.type.toLowerCase() === "general"),
    },
    {
      legend: t("accountSites.site.securityAmenities"),
      name: "securityAmenities",
      options: allAmenities.filter((a) => a.type.toLowerCase() === "security"),
    },
  ];

  const selectAddress = (addr) => {
    register("address");
    setValue("address", addr);
  };

  console.log(t("accountSites.site.validation.addressRequired"));

  return (
    <>
      <fieldset className="space-y-6">
        <Field label={`${t("accountSites.site.name")} *`} htmlFor="name">
          <TextInput
            name="name"
            defaultValue={site.name}
            register={register("name", {
              required: t("accountSites.site.validation.nameRequired"),
            })}
            error={errors.name}
          />
          <FieldErrorMessage error={errors.name} />
        </Field>
        <Field label={t("accountSites.site.description")} htmlFor="description">
          <TextInput
            name="description"
            defaultValue={site.description}
            register={register("description")}
            error={errors.description}
          />
          <FieldErrorMessage error={errors.description} />
        </Field>
      </fieldset>

      <AddressInput
        formMethods={formMethods}
        defaultAddress={site.address}
        onSelected={selectAddress}
        requiredMsg={t("accountSites.site.validation.addressRequired")}
      />

      <details className="space-y-8" open={openAmenities}>
        <summary className="font-extrabold text-lg cursor-pointer">
          {t("accountSites.site.addAmenities")}
        </summary>

        <div className="rounded border-gray-400 p-8 bg-gray-50 shadow-inner">
          <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-6">
            {amenities.map(({ name, legend, options }) => (
              <CheckboxFieldset key={legend} legend={legend}>
                {options.map((amenity) => {
                  const inputId = `${name}-${amenity.id}`;

                  return (
                    <CheckboxField
                      key={inputId}
                      label={amenity.name}
                      htmlFor={inputId}
                    >
                      <CheckboxInput
                        name={name}
                        id={inputId}
                        value={amenity.id}
                        register={register(name)}
                        defaultChecked={site.amenities
                          ?.map((x) => {
                            return x.id;
                          })
                          .includes(amenity.id)}
                        className="w-auto"
                      />
                    </CheckboxField>
                  );
                })}
              </CheckboxFieldset>
            ))}
          </div>
        </div>
      </details>
    </>
  );
}
