import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import apiUrl from "../../../../../data/api-url";
import { tokenFetcher } from "../../../../../data/fetchers";
import submitForm from "../../../../../data/submit-form";
import { useAuth } from "../../../../../hooks/use-auth";
import { useStore } from "../../../../../hooks/use-store";
import Button, { LinkButton } from "../../../../common/Button";
import {
  Field,
  FieldErrorMessage,
  Form,
  SelectInput,
  TextArea,
} from "../../../../common/forms";
import LoadingBlock from "../../../../common/loading/LoadingBlock";

export default observer(function NewService() {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm({ mode: "onBlur" });
  const history = useHistory();
  const { user } = useAuth();
  const { userStore } = useStore();
  const { siteId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [selectedService, setSelectedService] = useState();

  const { data: allServices } = useSWR(
    userStore.contextAccount
      ? [apiUrl("spaces", "/service-items"), user.token]
      : null,
    tokenFetcher
  );

  const { data: siteServices } = useSWR(
    userStore.contextAccount
      ? [apiUrl("spaces", `/sites/${siteId}/services`), user.token]
      : null,
    tokenFetcher
  );

  const submitService = async (formData) => {
    setSubmitting(true);

    var serviceData = {
      serviceId: selectedService.id,
      customerInformation: formData.customerInformation,
    };

    await submitForm(
      apiUrl("spaces", `/sites/${siteId}/services`),
      serviceData,
      user.token
    )
      .then(() => {
        // Force reload of services
        mutate(apiUrl("spaces", `/sites/${siteId}/services`));

        history.push(`/account/sites/${siteId}`);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const availableServices = () => {
    return allServices.filter((i) => !siteServices.find((o) => o.id === i.id));
  };

  if (!allServices || !siteServices) {
    return <LoadingBlock />;
  }

  return (
    <div className="space-y-8">
      <h1>{t("accountSites.addService")}</h1>

      <Form onSubmit={handleSubmit(submitService)}>
        <Field label={t("accountSites.service.service")} htmlFor="service">
          <div className="md:w-1/2 lg:w-1/3">
            <SelectInput
              name="service"
              placeholder={t("generalActions.pleaseSelect")}
              register={register("service", { required: true })}
              error={errors.service}
              onChange={(e) => {
                setValue(e.target.value);
                setSelectedService(
                  allServices.filter((p) => p.id === e.target.value)[0]
                );
              }}
            >
              {availableServices().map((service, i) => (
                <option key={`service-${i}`} value={service.id}>
                  {service.name}
                </option>
              ))}
            </SelectInput>
          </div>
          <FieldErrorMessage error={errors.service} />
        </Field>

        {selectedService && (
          <fieldset className="space-y-8">
            <h3>{selectedService.name}</h3>
            <p>{selectedService.description}</p>

            <Field
              label={t("accountSites.service.customerInformation")}
              htmlFor="customerInformation"
              helpText={t("accountSites.service.customerInformationHelp")}
            >
              <TextArea
                name="customerInformation"
                rows={4}
                register={register("customerInformation")}
                error={errors.customerInformation}
              />
              <FieldErrorMessage error={errors.customerInformation} />
            </Field>
          </fieldset>
        )}

        <div>
          <Button
            label={t("accountSites.service.add")}
            loading={submitting}
            disabled={selectedService === undefined}
          />
          <LinkButton
            label={t("common.cancel")}
            onClick={() => {
              history.push(`/account/sites/${siteId}`);
            }}
          />
        </div>
      </Form>
    </div>
  );
});
