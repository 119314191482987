import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CompanyLogo from "../CompanyLogo";
import styles from "./Footer.module.css";

export default function Footer({ menus }) {
  const { t } = useTranslation();

  const findMenu = (menuName) => {
    const skDomain = "https://storekat.com";

    if (menuName === "About us") {
      return {
        pages: [
          {
            title: "Investor relations",
            href: `${skDomain}/investor-relations`,
            slug: "/investor-relations",
          },
          {
            title: "Our story",
            href: `${skDomain}/our-story`,
            slug: "/our-story",
          },
          {
            title: "Sustainability",
            href: `${skDomain}/sustainability`,
            slug: "/sustainability",
          },
        ],
      };
    }

    if (menuName === "Support") {
      return {
        pages: [
          {
            title: "Contact Us",
            href: `${skDomain}/contact-us`,
            slug: "/contact-us",
          },
        ],
      };
    }

    if (menuName === "Community") {
      return {
        pages: [
          { title: "Careers", href: `${skDomain}/careers`, slug: "/careers" },
          {
            title: "Trust & Safety",
            href: `${skDomain}/trust-safety`,
            slug: "/trust-safety",
          },
        ],
      };
    }

    if (menuName === "How it works") {
      return {
        pages: [
          {
            title: "Find Space",
            href: `${skDomain}/find-space`,
            slug: "/find-space",
          },
          {
            title: "Sell Space",
            href: `${skDomain}/sell-space`,
            slug: "/sell-space",
          },
        ],
      };
    }

    return [];
  };

  const navigation = {
    about: [
      { name: "Our story", href: "/our-story" },
      { name: "Investor relations", href: "/investor-relations" },
      { name: "Sustainability", href: "/sustainability" },
    ],
    support: [],
    community: [
      { name: "Careers", href: "/careers" },
      { name: "Trust & safety", href: "/trust-safety" },
    ],
    studies: [
      { name: "Sell space", href: "/sell-space" },
      { name: "Find space", href: "/find-space" },
    ],
    social: [
      {
        name: "LinkedIn",
        href: "https://www.linkedin.com/company/storekat/",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
          </svg>
        ),
      },
      {
        name: "Twitter",
        href: "https://twitter.com/storekat",
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
    ],
  };
  return (
    <footer
      className="bg-white border-t-8 text-gray-700 border-brandSecondary-100"
      aria-labelledby="footerHeading"
    >
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <a href="/">
              <CompanyLogo widthClass="w-32" />
            </a>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div
            className={`${styles.footerLinks} mt-7 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2`}
          >
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-md text-gray-700 font-bold">About us</h3>
                <ul className="mt-4 space-y-4">
                  {findMenu("About us")?.pages.map((item) => (
                    <li key={item.slug}>
                      <a href={item.href}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-7 md:mt-0">
                <h3 className="text-md text-gray-700 font-bold">Support</h3>
                <ul className="mt-4 space-y-4">
                  {findMenu("Support")?.pages.map((item) => (
                    <li key={item.slug}>
                      <a href={item.href}>{item.title}</a>
                    </li>
                  ))}
                  <li>
                    <Link to="/terms-conditions">
                      {t("termsConditions.menuTitle")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/account/support">{t("support.menuTitle")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-md text-gray-700 font-bold">Community</h3>
                <ul className="mt-4 space-y-4">
                  {findMenu("Community")?.pages.map((item) => (
                    <li key={item.slug}>
                      <a href={item.href}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-7 md:mt-0">
                <h3 className="text-md text-gray-700 font-bold">
                  How it works
                </h3>
                <ul className="mt-4 space-y-4">
                  {findMenu("How it works")?.pages.map((item) => (
                    <li key={item.slug}>
                      <a href={item.href}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-7 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; Storekat is brought to you by Delta Kilo Pty Ltd. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
