import { useMemo } from "react";
import useSWR from "swr";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { formatDate } from "../../../../../utils/date-transformations";
import Table from "../../../../common/Table";
import LoadingBlock from "../../../../common/loading/LoadingBlock";
import apiUrl from "../../../../../data/api-url";
import { tokenFetcher } from "../../../../../data/fetchers";

export default function SiteBookingList() {
  const { t } = useTranslation();
  const { siteId } = useParams();

  const { data: siteBookings } = useSWR(
    [apiUrl("bookings", `/site-bookings/site/${siteId}`)],
    tokenFetcher
  );

  const data = useMemo(
    () =>
      siteBookings
        ? siteBookings.map((booking) => ({
            reference: booking,
            startDate: formatDate(booking.startDate),
            endDate: formatDate(booking.endDate),
            quantity: booking.quantity.toLocaleString(),
            status: booking.status,
          }))
        : [],
    [siteBookings]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("accountBookings.booking.reference"),
        accessor: "reference",
        Cell: ({ value }) => (
          <Link
            to={`/account/sites/${siteId}/booking/${value.bookingId}/`}
            className="text-link"
          >
            {value.reference}
          </Link>
        ),
      },
      {
        Header: t("accountBookings.booking.startDate"),
        accessor: "startDate",
      },
      {
        Header: t("accountBookings.booking.endDate"),
        accessor: "endDate",
      },
      {
        Header: t("accountBookings.booking.quantity"),
        accessor: "quantity",
      },
      {
        Header: t("accountBookings.booking.status"),
        accessor: "status",
      },
    ],
    [t, siteId]
  );

  return (
    <div>
      <main className="space-y-8">
        {siteBookings && siteBookings.length > 0 ? (
          <Table columns={columns} data={data} rightLastColumn={false} />
        ) : (
          <div>{t("accountBookings.noBookings")}</div>
        )}
        {!siteBookings && <LoadingBlock />}
      </main>
    </div>
  );
}
