import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import AddressInput from "../../../common/forms/AddressInput";
import { Form } from "../../../common/forms";
import { useState } from "react";
import { useAuth } from "../../../../hooks/use-auth";
import { useHistory, useParams } from "react-router-dom";
import Button, { LinkButton } from "../../../common/Button";
import submitForm from "../../../../data/submit-form";
import ErrorMessage from "../../../common/messages/ErrorMessage";
import apiUrl from "../../../../data/api-url";
import { Address } from '../../../../app/model/address';
import { useStore } from '../../../../hooks/use-store';
import { observer } from 'mobx-react-lite';

interface AddressPutFormData {
  address?: Address
};

export default observer(function EditOrganisation() {
  const { t } = useTranslation();
  const formMethods = useForm<AddressPutFormData>({ mode: "onBlur" });
  const { user } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const { organisationId } = useParams<{ organisationId: string }>();
  const history = useHistory();

  const { accountStore } = useStore();
  const { setStreetAddress } = accountStore;

  const selectAddress = (addr?:Address) => {
    formMethods.register("address");
    formMethods.setValue("address", addr);
  };

  const setAddresses = async (formData:AddressPutFormData) => {
    setSubmitting(true);

    await submitForm(
      apiUrl("accounts", `/account/${organisationId}/addresses`),
      formData,
      user.token,
      "PUT"
    )
      .then(() => {
        setStreetAddress(formData.address);
        history.push({
          pathname: `/account/organisations/${organisationId}`,
        });
      })
      .catch((err) => {
        setErrorMessage(err.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="space-y-8">
      <Form onSubmit={formMethods.handleSubmit(setAddresses)}>
        {errorMessage && <ErrorMessage title="" message={errorMessage} />}

        <AddressInput
          formMethods={formMethods}
          defaultAddress={undefined}
          onSelected={selectAddress}
        />

        <div>
          <Button
            label={t("accountOrganisations.edit.saveAddress")}
            loading={submitting}
          />
          <LinkButton
            label={t("common.cancel")}
            onClick={() => {
              history.push(`/account/organisations/${organisationId}`);
            }}
          />
        </div>
      </Form>
    </div>
  );
});
