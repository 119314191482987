import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams, useHistory, useLocation, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../../../hooks/use-auth";
import Button, { LinkButton } from "../../../common/Button";
import {
  DateInput,
  Field,
  FieldErrorMessage,
  Form,
  NumberInputWithUnit,
  SelectInput,
  SelectList,
  TextArea,
} from "../../../common/forms";
import useSWR, { mutate } from "swr";
import apiUrl from "../../../../data/api-url";
import { tokenFetcher } from "../../../../data/fetchers";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import submitForm from "../../../../data/submit-form";
import ErrorMessage from "../../../common/messages/ErrorMessage";
import { isGuid } from "../../../../utils/guid-helper";
import InfoMessage from "../../../common/messages/InfoMessage";
import { toastLoading } from "../../../common/messages/toast-messages";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useStore } from "../../../../hooks/use-store";
import { SiteService, StockMovementType } from '../../../../app/model/service-requests';
import Feature from '../../../common/feature-flags/Feature';
import Select from 'react-tailwindcss-select';
import { BiMinusCircle } from 'react-icons/bi';
import { reaction, runInAction, toJS } from 'mobx';
import { StockItem } from '../../../../app/model/stock-management';
import dayjs from 'dayjs';
import { SpaceLineItem } from '../../../../app/model/bookings';

interface ServiceRequestFormValues {
  service: string,
  requestDetails?: string,
  palletQuantity?: number,
  requiredByDate?: Date,
  stockMovement?: string,
  selectedStockValidator?: string
}

interface ServiceStockItem {
  id: string;
  sku: string;
  quantity: number;
}

export default observer(function NewServiceRequest() {

  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    setError, 
    clearErrors
  } = useForm<ServiceRequestFormValues>({ mode: "onBlur" });
  const history = useHistory();
  const { user } = useAuth();
  const { bookingId } = useParams<{bookingId: string}>();
  const [submitting, setSubmitting] = useState(false);
  const [selectedService, setSelectedService] = useState<SiteService | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { userStore, stockStore } = useStore();
  const selectedStock = useLocalObservable<ServiceStockItem[]>(() => []);
  const [availableStock, setAvailableStock] = useState<StockItem[] | undefined>(undefined);

  useEffect(() => {
    
    if (userStore.contextAccount !== undefined) {
      stockStore.loadStockItems(userStore.contextAccount.id);
    }

  }, [stockStore, stockStore.loadStockItems, userStore.contextAccount]);

  reaction(() => stockStore.stockItems, stockItems => {
    if (stockItems !== undefined) {
      setAvailableStock(stockItems);
    }
  })

  const search = useLocation().search;
  const siteId = new URLSearchParams(search).get("site_id");

  const { data: booking } = useSWR(
    userStore.contextAccount
      ? [apiUrl("bookings", `/bookings/${bookingId}`), user.token]
      : null,
    tokenFetcher
  );

  const { data: siteServices } = useSWR<SiteService[] | null>(
    userStore.contextAccount
      ? [apiUrl("spaces", `/sites/${siteId}/services`), user.token]
      : null,
    tokenFetcher
  );

  if (!siteId || !isGuid(siteId)) {
    return (
      <div className="space-y-8" data-cy="siteIdErrorMessage">
        <h1>{t("accountBookings.newServiceRequest.title")}</h1>
        <ErrorMessage
          title={t("accountBookings.newServiceRequest.siteIdErrorTitle")}
        >
          {t("accountBookings.newServiceRequest.siteIdErrorMessage")} <br />
          <Link to={`/account/bookings/${bookingId}`} className="text-link">
            {t("accountBookings.returnToBooking")}
          </Link>
        </ErrorMessage>
      </div>
    );
  }

  const submitServiceRequest = async (formData: ServiceRequestFormValues) => {
    
    if (selectedStock !== undefined) {
      if (selectedStock.filter((item) => item.quantity <= 0).length > 0) {
        setError('selectedStockValidator', { type: 'custom', message: t("accountBookings.newServiceRequest.stockQuantityReq") });
        return;
      }
    }

    const toastId = toastLoading(t("toasts.serviceRequestCreating"));
    setSubmitting(true);
    setErrorMessage(null);

    var stockItems: Record<string,number> = {}

    selectedStock.map((item) => stockItems[item.id] = item.quantity);

    const srData = {
      bookingId: bookingId,
      buyerAccountId: userStore.contextAccount?.id,
      serviceId: formData.service,
      siteId: siteId,
      requestDetails: formData.requestDetails,
      stockItems: stockItems,
      palletQuantity: formData.palletQuantity,
      requiredByDate: formData.requiredByDate,
      movementType: formData.stockMovement
    };

    await submitForm(
      apiUrl("bookings", `/bookings/${bookingId}/service-requests`),
      srData,
      user.token
    )
      .then(({ data }) => {
        // Clear previous request
        mutate(`/bookings/${bookingId}/service-requests/booking`);

        const request = {
          requestId: data.id,
          timestamp: data.timestamp,
          ...srData
        };

        history.push(`/account/bookings/${bookingId}`, {
          requestSubmitted: true,
          request: request,
          toastId: toastId,
        });
      })
      .catch((error) => {
        setErrorMessage(error.message);
        console.log(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const selectStockItem = (value:any) => {
  
    runInAction(() => selectedStock.push({
      id: value.value,
      sku: value.label,
      quantity: 0
    }));

    var items = toJS(availableStock)!.filter((item) => item.id !== value.value);

    setAvailableStock(items);
    
  };

  const removeStockItem = (index:number) => {
    // Clear errors to be able to re-trigger validation on submit.
    clearErrors('selectedStockValidator');

    runInAction(() => selectedStock.splice(index, 1));

    var items = stockStore.stockItems!.filter((item) => {
      for(var i = 0; i < selectedStock.length; i++) {
        if (item.id === selectedStock[i].id) {
          return false;
        }
      }
      return true;
    });
    setAvailableStock(items);
  }

  const updateStockQuantity = (id: string, quantity?:number) => {
    // Clear errors to be able to re-trigger validation on submit.
    clearErrors('selectedStockValidator');

    // If the quantity not valid, return
    if (!quantity) {
      return;
    }

    for (var i = 0; i < selectedStock.length; i++) {
      if (selectedStock[i].id === id) {
        selectedStock[i].quantity = quantity;
      }
    }
  }

  const getMaxQuantity = () => {

    // If no booking, then we don't have a valid object, so return 0
    if (booking === undefined) {
      return 0;
    }

    var siteItems = booking.spaceLineItems.filter((item:SpaceLineItem) => item.siteId === siteId);
    if (siteItems === undefined || siteItems.length === 0) {
      return 0;
    }

    return siteItems[0].quantity;

  }

  if (!siteServices || !booking) {
    return <LoadingBlock />;
  }

  return (
    <div className="space-y-8">
      <h1>{t("accountBookings.newServiceRequest.title")}</h1>

      {siteServices?.length === 0 && (
        <InfoMessage
          title={t("accountBookings.newServiceRequest.noServicesTitle")}
        >
          <div>
            {t("accountBookings.newServiceRequest.noServicesMessage")} <br />
            <Link to={`/account/bookings/${bookingId}`} className="text-link">
              {t("accountBookings.returnToBooking")}
            </Link>
          </div>
        </InfoMessage>
      )}

      {siteServices?.length > 0 && (
        <>
          {errorMessage && <ErrorMessage message={errorMessage} />}

          <Form onSubmit={handleSubmit(submitServiceRequest)}>
            <Field
              label={t("accountBookings.newServiceRequest.service")}
              htmlFor="service"
            >
              <div className="md:w-1/2 lg:w-1/3">
                <SelectList
                  data-cy="field-serviceList"
                  name="service"
                  placeholder={t("generalActions.pleaseSelect")}
                  register={register("service", {
                    required: t(
                      "accountBookings.newServiceRequest.serviceRequired"
                    ),
                  })}
                  error={errors.service}
                  onChange={(e) => {
                    setValue("service", e.target.value);
                    setSelectedService(
                      siteServices.filter((p) => p.id === e.target.value)[0]
                    );
                  }}
                >
                  {siteServices.map((service, i) => (
                    <option key={`service-${i}`} value={service.id}>
                      {service.name}
                    </option>
                  ))}
                </SelectList>
              </div>
              <FieldErrorMessage error={errors.service} />
            </Field>

            {selectedService && (
              <fieldset className="space-y-4">
                <div>
                  <h3>{selectedService.name}</h3>
                  <p>{selectedService.description}</p>
                </div>

                <div>
                  <h3>
                    {t("accountBookings.newServiceRequest.customerDetails")}
                  </h3>
                  <p>{selectedService.customerInformation}</p>
                </div>

                <Field
                  label={t("accountBookings.newServiceRequest.requestDetails")}
                  htmlFor="requestDetails"
                  helpText={t(
                    "accountBookings.newServiceRequest.requestDetailsHelp"
                  )}
                >
                  <TextArea
                    name="requestDetails"
                    rows={4}
                    register={register("requestDetails", {
                      required: t(
                        "accountBookings.newServiceRequest.requestDetailsRequired"
                      ),
                    })}
                    error={errors.requestDetails}
                  />
                  <FieldErrorMessage error={errors.requestDetails} />
                </Field>

                <Field 
                  label={t("accountBookings.newServiceRequest.palletQuantity")}
                  htmlFor='palletQuantity'
                  data-cy='field-palletQuantity'
                  helpText={t(
                    "accountBookings.newServiceRequest.palletQuantityHelp"
                  )}
                >
                  <p className='text-sm text-gray-500'>{t("accountBookings.newServiceRequest.palletQuantityBookedHelp")} {getMaxQuantity()}</p>
                  <NumberInputWithUnit
                    name="palletQuantity"
                    unit={t("accountBookings.newServiceRequest.palletQuantityUnit")}
                    className='w-full md:w-1/3'
                    register={register(
                      "palletQuantity",
                      {
                        required: t("accountBookings.newServiceRequest.palletQuantityReq"),
                        min: {
                          value: 0,
                          message: t("accountBookings.newServiceRequest.palletQuantityMin", { value: 0 }),
                        },
                        max: {
                          value: getMaxQuantity(),
                          message: `${t("accountBookings.newServiceRequest.palletQuantityMax", { value: getMaxQuantity() })} ${getMaxQuantity()}`
                        },
                        valueAsNumber: true
                      },
                    )}
                    error={errors.palletQuantity}
                  />
                  <FieldErrorMessage error={errors.palletQuantity} />
                </Field>

                <Field
                  label={t("accountBookings.newServiceRequest.requiredByDate")}
                  htmlFor="requiredByDate"
                  data-cy="field-requiredByDate"
                  helpText={t(
                    "accountBookings.newServiceRequest.requiredByDateHelp"
                  )}
                >
                  <DateInput
                    name="requiredByDate"
                    className='w-full md:w-1/3'
                    register={register("requiredByDate", {
                      required: t("accountBookings.newServiceRequest.requiredByDateReq"),
                      validate: {
                        afterStartDate: (value) => {
                          const startDateValue = dayjs(Date.now());
                          const endDateValue = dayjs(value);
                
                          if (!endDateValue.isValid()) {
                            return true;
                          }
                
                          return (
                            !endDateValue.isBefore(startDateValue, "day") ||
                            t("accountBookings.newServiceRequest.requiredByMinVal")
                          );
                        },
                      },
                    })}
                    error={errors.requiredByDate}
                  />
                  <FieldErrorMessage error={errors.requiredByDate} />
                </Field>

                <Feature name='stock-management'>
                  <>
                  {stockStore.loading && <LoadingBlock />}
                  
                  {(!stockStore.loading && availableStock !== undefined) && (
                    <>
                      <div>
                        <h3>
                          {t("accountBookings.newServiceRequest.stockItems")}
                        </h3>
                      </div>
                      <div className="w-full mt-10 md:mt-14 flex items-center justify-center">
            
                      <Select 
                        formatGroupLabel={(data) => (
                            <div className={`py-2 text-xs flex items-center justify-between`}>
                                <span className="font-bold">{data.label}</span>
                                <span className="bg-gray-200 h-5 p-1.5 flex items-center justify-center rounded-full">{data.options.length}</span>
                            </div>
                        )}
                        primaryColor={'blue'}
                        options={availableStock!.map((item) => { return {value: item.id, label: `${item.name} - ${item.sku}`} })} 
                        onChange={selectStockItem} 
                        value={null} 
                        isSearchable={true}
                        ></Select>
                        </div>
                    </>
                  )}

                  {(selectedStock !== undefined && selectedStock.length > 0) &&  (
                    <>
                      <table className='border-collapse w-full'>
                        <thead>
                          <tr>
                            <th className='text-left'>Stock item</th>
                            <th className='text-left'>Quantity</th>
                            <th className='text-left'>Remove</th>
                          </tr>
                        </thead>
                        <tbody>
    
                        {selectedStock?.map((value, index) => (
                          <tr key={index}>
                            <td>{value.sku}</td>
                            <td className='pr-5'>
                              <input 
                                type='number' 
                                step={1} 
                                className='input w-36 py-1 px-2 mt-1' 
                                onChange={(e:any) => updateStockQuantity(value.id, e.target.value)} />
                              </td>
                            <td width={75}>
                              <button type='button' className="flex flex-row-reverse" onClick={() => removeStockItem(index)}>
                                <BiMinusCircle className='text-rose-700 hover:text-rose-800 text-xl' />
                              </button>
                            </td>
                          </tr>
                        ))}
                        </tbody>
                      </table>

                      <FieldErrorMessage error={errors.selectedStockValidator} />

                      <div className='mt-4 '>
                        <Field label={t("accountBookings.newServiceRequest.stockMovementLabel")}>
                          <SelectInput name='stockMovement' className='w-full md:w-1/3' register={register("stockMovement", {
                            validate: (value) => {
                                if (selectedStock.length === 0 || 
                                    (value !== undefined && value.length > 0))
                                  return true;
                                
                                return t("accountBookings.newServiceRequest.stockMovementReq");
                              },
                            })}>
                            <option value=''>{t('generalActions.pleaseSelect')}...</option>
                            <option value={StockMovementType.MoveIn}>{t("accountBookings.newServiceRequest.moveStockIn")}</option>
                            <option value={StockMovementType.MoveOut}>{t("accountBookings.newServiceRequest.moveStockOut")}</option>
                          </SelectInput>
                          <FieldErrorMessage error={errors.stockMovement} />
                        </Field>
                      </div>
                    </>
                  )}
                  </>
                </Feature>

              </fieldset>
            )}

            <div>
              <Button
                label={t("accountBookings.newServiceRequest.submit")}
                loading={submitting}
                disabled={submitting || !selectedService}
              />
              <LinkButton
                label={t("common.cancel")}
                onClick={() => {
                  history.push(`/account/bookings/${bookingId}`);
                }}
              />
            </div>
          </Form>
        </>
      )}
    </div>
  );
});
