import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import apiUrl from "../../../../data/api-url";
import defaultFetcher from "../../../../data/fetchers";
import { useStore } from "../../../../hooks/use-store";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import PriceDisplay from "../../../common/PriceDisplay";

export default observer(function CurrentPlanDetails() {
  const { t } = useTranslation();
  const { organisationId } = useParams();

  const { userStore } = useStore();
  const accountPlanId = userStore.contextAccount.accountPlan.id;

  const { data: plans } = useSWR(
    [apiUrl("accounts", "/account-plans")],
    defaultFetcher
  );

  if (!plans) {
    return <LoadingBlock />;
  }
  const currentPlan = plans.filter((p) => p.id === accountPlanId)[0];

  const getPlanMaxUsers = (maxUsers) => {
    if (maxUsers === 1) {
      return `${maxUsers} ${t("accountPlans.singleAccountUser")}`;
    } else {
      return `${t("accountPlans.multipleAccountUsersPrefix")} ${maxUsers} ${t(
        "accountPlans.multipleAccountUsersSuffix"
      )}`;
    }
  };

  return (
    <div className="space-y-8">
      <div className="flex flex-wrap">
        <div className="w-full xl:w-1/3 xl:pr-4 mb-8 xl:mb-0">
          <div className="p-4 bg-white shadow rounded border-0">
            <p className="mb-3">{t("planDetails.cardsCta.currentBill")}:</p>
            <p className="font-bold mb-3">
              <PriceDisplay
                price={currentPlan.pricePerMonth}
                className="text-xl font-bold mt-3"
              />
            </p>
          </div>
        </div>
        <div className="w-full xl:w-1/3 xl:px-4 mb-8 xl:mb-0">
          <div className="p-4 bg-white shadow rounded border-0">
            <p className="mb-3">{t("planDetails.cardsCta.nextPaymentDue")}:</p>
            <p className="font-bold mb-3">
              {currentPlan.pricePerMonth > 0 && <>24 Jan 2022</>}
              {currentPlan.pricePerMonth === 0 && <>N/A</>}
            </p>
          </div>
        </div>
        <div className="w-full xl:w-1/3 xl:pl-4 mb-8">
          <div className="p-4 bg-white shadow rounded border-0">
            <p className="mb-3">{t("planDetails.cardsCta.changePlan")}:</p>
            <Link
              to={`/account/organisations/${organisationId}/change-plan`}
              className="btn-brand  text-sm"
            >
              {t("changePlan.buttonText")}
            </Link>
          </div>
        </div>
      </div>

      <h2>{t("planDetails.currentPlanTitle")}</h2>

      <div className="container">
        <h4 className="mb-2 text-xl font-bold font-heading">
          {currentPlan.name}
        </h4>
        <p className="mb-4 text-sm">{currentPlan.description}</p>
        <ul className="mb-6 text-gray-500">
          <li className="mb-2 flex">
            <svg
              className="mr-2 w-5 h-5 text-brand"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span>{getPlanMaxUsers(currentPlan.maxUsers)}</span>
          </li>
          {currentPlan.features.map((feature, index) => (
            <li className="mb-2 flex" key={index}>
              <svg
                className="mr-2 w-5 h-5 text-brand"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span>{feature}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});
