import i18n from "../../../i18n";
import BookingsDashboard from "./BookingDashboard";
import BookingInvoice from "./BookingInvoice";
import NewServiceRequest from "./service-requests/NewServiceRequest";
import ServiceRequestInvoice from "./service-requests/ServiceRequestInvoice";
import ViewServiceRequest from "./service-requests/ViewServiceRequest";
import ShowBooking from "./ShowBooking";
import StockMovementHistory from './StockMovementHistory';

const bookingRoutes = [
  {
    path: "/account/bookings",
    component: BookingsDashboard,
    exact: true,
    breadcrumb: i18n.t("accountBookings.breadcrumbs.bookings"),
  },
  {
    path: "/account/bookings/:bookingId/service-requests/:requestId/invoice",
    component: ServiceRequestInvoice,
    breadcrumb: i18n.t("accountBookings.breadcrumbs.invoice"),
    exact: true,
  },
  {
    path: "/account/bookings/:bookingId/service-requests/:requestId",
    breadcrumb: i18n.t("accountBookings.breadcrumbs.viewServiceRequest"),
    component: ViewServiceRequest,
  },
  {
    path: "/account/bookings/:bookingId/new-service-request",
    breadcrumb: i18n.t("accountBookings.breadcrumbs.newServiceRequest"),
    component: NewServiceRequest,
  },
  {
    path: "/account/bookings/:bookingId/stock-movements",
    breadcrumb: i18n.t("accountBookings.breadcrumbs.stockMovements"),
    component: StockMovementHistory,
  },
  {
    path: "/account/bookings/:bookingId/invoice",
    component: BookingInvoice,
    breadcrumb: i18n.t("accountBookings.breadcrumbs.invoice"),
    exact: true,
  },
  {
    path: "/account/bookings/:bookingId",
    component: ShowBooking,
    breadcrumb: i18n.t("accountBookings.breadcrumbs.bookingDetails"),
    exact: true,
  },
];

export default bookingRoutes;
