import { useState } from "react";
import Button from "../Button";

interface MarketplaceTermsEnProps {
  showAll: boolean,
  allowCollapse: boolean,
  minDisplaySize?: boolean
};

export default function MarketplaceTermsEn({
  showAll,
  allowCollapse,
  minDisplaySize,
}: MarketplaceTermsEnProps) {
  const [expanded, setExpanded] = useState(showAll);
  const minimisedSize =
    minDisplaySize === true ? "terms-minimised-sm" : "terms-minimised-lg";

  return (
    <div className="container mx-auto mt-2 terms-container">
      <div
        className={`${expanded ? "terms-expanded" : minimisedSize} mb-4 z-10`}
      >
        <div className="terms-fader w-full absolute bottom-0 bg-gradient-to-t from-white h-32">
          &nbsp;
        </div>
        <p>
          Whilst we at Storekat have the utmost faith in all comers to the
          Storekat platform to be good actors and behave responsibly when using
          our platform, we also require your agreements to be clear and for all
          users to stick to them. This is our fine print, and ensures that
          everyone acts fairly with each other including us, whilst contributing
          to our goal to transform our industry in the right way.
        </p>
        <p>
          Please read these Terms of Use (the &ldquo;<strong>Terms</strong>
          &rdquo;) carefully. By accessing or using the Platform (as defined
          below), you agree to comply with and be bound by these Terms.
        </p>
        <p>
          These Terms (together with the other policy documents referred to
          below) constitute a legally binding agreement between you and Storekat
          Limited (&ldquo;<strong>Storekat</strong>&rdquo;, &ldquo;
          <strong>us</strong>&rdquo;, &ldquo;
          <strong>our</strong>&rdquo; or &ldquo;<strong>we</strong>&rdquo;)
          governing your access to and use of the Storekat.com storage space
          platform (the &ldquo;
          <strong>Platform</strong>&rdquo;) accessed via the following web
          address{" "}
          <a
            href="https://www.storekat.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            www.storekat.com/privacy
          </a>{" "}
          and any other website through which Storekat makes its Platform
          available.
        </p>
        <p>The following policies also apply to your use of our Platform:</p>
        <ol className="list-[lower-roman] ml-4">
          <li>
            Our privacy policy which can be found at{" "}
            <a
              href="https://www.storekat.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              www.storekat.com/privacy
            </a>
            . By using our Platform you are consenting to us processing any
            personal data that you provide to us or that we collect from you as
            a result of your use of our Platform. Our privacy policy sets out
            what we do with that personal data.
          </li>
          <li>
            Our cookies policy which can be found at{" "}
            <a
              href="https://www.storekat.com/cookies"
              target="_blank"
              rel="noreferrer"
            >
              www.storekat.com/cookies
            </a>
            .
          </li>
        </ol>
        <div className="subnumbering">
          <ol className="list-decimal">
            <li>
              <strong>About us</strong>
              <ol>
                <li>
                  We, Storekat Limited, are a company registered in England and
                  Wales under company number <strong>13938690</strong> whose
                  registered office is at Storekat, 71-75 Shelton Street, Covent
                  Garden, London, United Kingdom, WC2H 9JQ
                </li>
                <li>
                  You can contact us by email at{" "}
                  <a href="mailto:getintouch@storekat.com">
                    getintouch@storekat.com
                  </a>
                  .
                </li>
              </ol>
            </li>
            <li>
              <strong>Scope of Services</strong>
              <ol>
                <li>
                  The Platform is an online marketplace that enables registered
                  users of the Platform (&ldquo;<strong>Members</strong>&rdquo;,
                  or &ldquo;
                  <strong>you</strong>&rdquo;) to:
                  <ol>
                    <li>
                      publish listings on the Platform (the &ldquo;
                      <strong>Listings</strong>&rdquo;) of available storage
                      space (the &ldquo;<strong>Storage</strong>&rdquo;) within
                      the United Kingdom which they can provide to other Members
                      for their storage requirements together with any related
                      receipt, handling and despatch services (“RH&amp;D”). The
                      Storage and “RH&amp;D”, together the &ldquo;
                      <strong>Services</strong>&rdquo;; and
                    </li>
                    <li>
                      communicate and transact directly with other Members that
                      are seeking to purchase the Services.
                    </li>
                  </ol>
                </li>
                <li>
                  For the purposes of these Terms:
                  <ol>
                    <li>
                      those Members who use the Platform to publish Listings to
                      provide the Services will be referred to as the &ldquo;
                      <strong>Seller</strong>&rdquo;;
                    </li>
                    <li>
                      those Members who use the Platform to purchase the
                      Services in response to a Listing will be referred to as
                      the &ldquo;<strong>Buyer</strong>&rdquo;;
                    </li>
                    <li>
                      where the Buyer purchases the Services from the Seller in
                      response to a Listing on the Platform, this will be
                      referred to as a &ldquo;<strong>Transaction</strong>
                      &rdquo;;
                    </li>
                    <li>
                      the total amount payable for the Transaction agreed
                      between the Buyer and the Seller (excluding all applicable
                      taxes in connection with the Transaction which will be{" "}
                      <strong>payable by the Buyer</strong> in addition to the
                      Transaction Value) will be referred to as the &ldquo;
                      <strong>Transaction Value</strong>&rdquo;
                    </li>
                    <li>
                      The Transaction Value (excluding all applicable taxes in
                      connection with the Transaction) will be payable in weekly
                      instalments by the Buyer, unless agreed otherwise by us in
                      writing and will be referred to as the &ldquo;
                      <strong>Weekly Transaction Value</strong>&rdquo;.
                      Transactions that do not fall within seven (7) day weekly
                      periods will be made payable at the prorated amount in
                      accordance with the Weekly Transaction Value and included
                      in the Transaction Value.
                    </li>
                    <li>
                      the Transaction Value, together with all applicable taxes
                      which are payable in connection with the Transaction, will
                      be referred to as the &ldquo;
                      <strong>Total Transaction Amount</strong>&rdquo;;
                    </li>
                    <li>
                      the Weekly Transaction Value, together with all applicable
                      taxes which are payable in connection with the
                      Transaction, will be payable in weekly instalments and
                      referred to as the &ldquo;
                      <strong>Total Weekly Transaction Amount</strong>&rdquo;;
                    </li>
                    <li>
                      &ldquo;<strong>United Kingdom</strong>&rdquo; means
                      England, Wales, Scotland and Northern Ireland; and
                    </li>
                    <li>
                      &ldquo;<strong>VAT</strong>&rdquo; means value added tax.
                    </li>
                  </ol>
                </li>
                <li>
                  These Terms apply only to your use of our Platform. All terms
                  and conditions applicable to the sale of the Services will be
                  as agreed between the Seller and the Buyer and are separate
                  from these Terms.
                </li>
                <li>
                  We are not and do not become a party to or other participant
                  in any contractual relationship between Members, nor are we a
                  real estate broker or insurer. We are not acting as an agent
                  in any capacity for any Member. As the provider of the
                  Platform, we do not own, create, sell, resell, provide,
                  control, manage, offer, deliver or supply any Listing or
                  Services.
                </li>
                <li>
                  The Seller will be solely responsible for their Listing and
                  provisions of the Services, including (without limitation):
                  <ol>
                    <li>
                      identifying, understanding and complying with all laws,
                      rules and regulations that apply to their Listings and the
                      Services; and
                    </li>
                    <li>
                      obtaining and maintaining any required licences, consents,
                      permits or registrations for any Services it offers on the
                      Platform.
                    </li>
                  </ol>
                </li>
                <li>
                  We have no control over and do not guarantee:
                  <ol>
                    <li>
                      the existence, quality, safety, suitability, legality,
                      truth or accuracy of any Listings or Services; or
                    </li>
                    <li>
                      the performance or conduct of any Member (or third party)
                      in connection with a Transaction or otherwise.
                    </li>
                  </ol>
                </li>
                <li>
                  If you choose to use the Platform as a Seller, your
                  relationship with us is limited to being an independent, third
                  party contractor and not an employee, agent, joint venture or
                  partner. You acknowledge and agree that you act exclusively on
                  your own behalf and for your own benefit and not on behalf of
                  or for the benefit of us.
                </li>
              </ol>
            </li>
            <li>
              <strong>Changes to these Terms and our other policies</strong>
              <ol>
                <li>
                  We make changes to these Terms from time to time. If we make
                  changes to these Terms, we will notify you of the revised
                  terms. By continuing to use and access the Platform following
                  receipt of notification, you will be deemed to have accepted
                  the change in the Terms.
                </li>
                <li>
                  We also make changes to our privacy policy and cookies policy
                  from time to time, so you should also check these regularly to
                  see whether any changes have been made. We will provide you
                  with notice of any changes to these policies from time to
                  time.
                </li>
              </ol>
            </li>
            <li>
              <strong>Account Registration</strong>
              <ol>
                <li>
                  You must register an account with us (a &ldquo;
                  <strong>Storekat Account</strong>&rdquo;) in order to access
                  and use certain features of the Platform, such as publishing
                  Listings or purchasing Services.
                </li>
                <li>
                  By creating a Storekat Account, you agree to comply with these
                  Terms and you represent, warrant and undertake to us that:
                  <ol>
                    <li>
                      you have the authority to enter into these Terms and to
                      perform your obligations set out in these Terms; and
                    </li>
                    <li>
                      you are not:
                      <ol>
                        <li>
                          subject to or the target of a sanction either
                          personally or by virtue of being incorporated, located
                          or otherwise operating in a country or territory which
                          is the subject of a sanction;
                        </li>
                        <li>
                          owned, operated or controlled by a person that is
                          subject to or the target of a sanction; or
                        </li>
                        <li>
                          on or owned, operated or controlled by any person who
                          appears on any list issued or maintained by any
                          sanction authority or is referred to in any list or
                          public announcement issued by any sanction authority,
                          in each case as amended, supplemented or substituted
                          from time to time.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  You can register a Storekat Account using an email address and
                  creating a password. You must not register more than one (1)
                  Storekat Account unless we have authorised you to do so. If
                  you register a Storekat Account on our Platform, you must
                  provide accurate and complete registration information and
                  keep that information up to date at all times. You may not
                  assign, novate or otherwise transfer your Storekat Account to
                  any other party.
                </li>
                <li>
                  Use of the Platform is password protected. In order to use it
                  you will need a user ID, password and/or pre-selected answers
                  to security questions in order to access it. It is your
                  responsibility to keep this information secret and
                  confidential and not to disclose it to any other person. You
                  are liable for any and all activities conducted through your
                  Storekat Account.
                </li>
                <li>
                  If you know or suspect that your user ID, password and/or
                  pre-selected answers to security questions have been
                  compromised or made available to a third party, you must log
                  in to your account and change these and immediately notify us
                  emailing us using the contact details above as soon as
                  possible.
                </li>
                <li>
                  We reserve the right to disable any user ID, password and/or
                  pre-selected answers to security questions at any time if, in
                  our reasonable opinion, you fail to comply with any of these
                  Terms, if we consider that you are using our Platform to
                  download content or infringe in any manner the rights of
                  another person or if we consider that there may be a security
                  risk.
                </li>
              </ol>
            </li>
            <li>
              <strong>Terms specific to Sellers</strong>
              <ol>
                <li>
                  When creating a Listing through the Platform, you must:
                  <ol>
                    <li>
                      provide full, complete and accurate information about your
                      Services and Listings (such as description, location,
                      requirements or restrictions) and keep the same up to date
                      at all times;
                    </li>
                    <li>
                      provide the Buyer with any applicable terms and conditions
                      of sale prior to the formation of a contract with the
                      Buyer pursuant to clause 5.5; and
                    </li>
                    <li>
                      provide any other pertinent information requested by us
                      from time to time.
                    </li>
                  </ol>
                </li>
                <li>
                  You must ensure that any terms and conditions of sale which
                  you provide to the Buyer as part of your Listing do not
                  conflict with these Terms. In the event of any conflict or
                  inconsistency between your terms and conditions of sale and
                  these Terms, these Terms will prevail.
                </li>
                <li>
                  You are responsible for setting a price (including any
                  applicable taxes) for your Listing and Services.
                </li>
                <li>
                  Once a Buyer requests to purchase the Services in response to
                  your Listing on the Platform, you may not:
                  <ol>
                    <li>
                      charge the Buyer a higher price than set out in the
                      Listing; or
                    </li>
                    <li>
                      refuse to provide the Services as described in your
                      Listing, unless you are permitted to do so in accordance
                      with your terms and conditions of sale agreed with the
                      Buyer. In the event that a refusal to provide Services has
                      occurred, you may be charged for any reasonable loss
                      incurred by the Storekat platform.
                    </li>
                  </ol>
                </li>
                <li>
                  Upon receipt of confirmation of a Transaction from us, a
                  legally binding agreement is formed between you and the Buyer,
                  subject to any additional terms and conditions which you may
                  agree with the Buyer.
                </li>
                <li>
                  If the Buyer elects to pay the Total Transaction Amount via
                  our agreed third party payments provider (
                  <strong>Payment Provider</strong>):
                  <ol>
                    <li>
                      We will raise invoices for the Total Weekly Transaction
                      Amount for Storage weekly in advance. You shall provide us
                      with weekly written confirmation of the RH&amp;D and
                      associated costs at the end of each week for RH&amp;D
                      provided by you to the Buyer in the preceding week. Upon
                      receipt of the same, we shall verify the costs and if
                      correct, we will raise invoices in respect of the Total
                      Weekly Transaction Amount for the RH&amp;D provided during
                      the preceding week, weekly in arrears using the Payment
                      Provider within the Platform;
                    </li>
                    <li>
                      The Payment Provider will manage the Total Weekly
                      Transaction Amount for Services paid to it by the Buyer
                      each week; and
                    </li>
                    <li>
                      In consideration of your use of the Platform, the Weekly
                      Transaction Value (<strong>Service Fee</strong>) (plus
                      VAT) will transfer via the Payment Provider. Once we have
                      received payment in full of the Total Weekly Transaction
                      Amount from the Buyer, our Payment Provider will deduct
                      the Service Fee (plus VAT) payable to us from the Total
                      Weekly Transaction Amount paid to us each week, and will
                      pay to you the remaining amount of the Total Weekly
                      Transaction Amount within 14 days of receipt from the
                      Payment Provider.
                    </li>
                  </ol>
                </li>
                <li>
                  If the Buyer elects to pay the Total Transaction Amount via
                  invoice:
                  <ol>
                    <li>
                      We will raise invoices for the Total Weekly Transaction
                      Amount for Storage weekly in advance and RH&amp;D provided
                      by you to the Buyer during the preceding week, weekly in
                      arrears and present them to the Buyer for payment; and
                    </li>
                    <li>
                      Once we have received payment in full of the Total Weekly
                      Transaction Amount from the Buyer, in consideration of
                      your use of the Platform to conduct the Transaction, we
                      will deduct the Service Fee (plus VAT) payable to us from
                      the Total Weekly Transaction Amount paid to us each week,
                      and will pay to you the remaining amount of the Total
                      Weekly Transaction Amount within 14 days of receipt from
                      the Buyer.
                    </li>
                  </ol>
                </li>
                <li>
                  All weeks will be calculated from and including Sunday to
                  Saturday. Any Services which you provide during part of a
                  Sunday to Saturday week will be prorated based on the actual
                  number of days within that week that the Services were
                  provided. For example, if the Buyer purchases Services on a
                  Thursday, the Weekly Transaction Value will be prorated to 3
                  days (being Thursday, Friday and Saturday).
                </li>
                <li>
                  We will notify you if the Buyer has not made payment of the
                  Total Weekly Transaction Amount without undue delay and in any
                  event within 14 days of the payment being due and payable by
                  the Buyer.
                </li>
                <li>The Service Fee is non-refundable.</li>
              </ol>
            </li>
            <li>
              <strong>Terms specific to Buyers</strong>
              <ol>
                <li>
                  You may purchase the Services from Sellers by responding to a
                  Listing on the Platform and following the booking process. The
                  Total Transaction Amount together with the Total Weekly
                  Transaction Amount and any applicable terms and conditions of
                  sale of the Seller will be presented to you prior to you
                  purchasing the Services.
                </li>
                <li>
                  You are solely responsible for ensuring that the Listing and
                  the Services meet your requirements.
                </li>
                <li>
                  Upon receipt of confirmation of a Transaction from us:
                  <ol>
                    <li>
                      a legally binding agreement is formed between you and the
                      Seller, subject to any additional terms and conditions of
                      the Seller that apply; and
                    </li>
                    <li>
                      you agree to:
                      <ol>
                        <li>
                          pay the Total Weekly Transaction Amount for Storage to
                          us weekly in advance and RH&amp;D provided to you by
                          the Seller during the preceding week, weekly in
                          arrears, using our Payment Provider within the
                          Platform.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  All weeks will be calculated from and including Sunday to
                  Saturday.
                  <ol>
                    <li>
                      Any Services which you purchase during part of a Sunday to
                      Saturday week will be prorated based on the actual number
                      of days within that week that the Services are provided to
                      you. For example, if you purchase Services on a Thursday,
                      the Transaction Value will be prorated to 3 days (being
                      Thursday, Friday and Saturday).
                    </li>
                    <li>
                      All RH&amp;D which you purchase will be charged on actual
                      RH&map;D provided during the preceding week.
                    </li>
                  </ol>
                </li>
                <li>
                  If you pay via the Payment Provider:
                  <ol>
                    <li>
                      you are responsible for any charges which the Payment
                      Provider may charge you in connection with the processing
                      of the Transaction for you, in addition to the Total
                      Transaction Amount; and
                    </li>
                    <li>
                      you agree:
                      <ol>
                        <li>
                          to make payment in full and cleared funds of the Total
                          Weekly Transaction Amount immediately after receipt of
                          confirmation of the Transaction; and
                        </li>
                        <li>
                          for all subsequent payments, the Payment Provider may
                          store your payment details so that all amounts due can
                          be collected automatically by the Payment Provider
                          each week until the Total Transaction Amount is paid.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  In addition to these Terms, you must comply with the
                  applicable terms and conditions of sale of the Seller in
                  relation to the Transaction. If you breach any of the Seller’s
                  terms and conditions of sale, we reserve the right to suspend
                  your use of the Platform and you may not be able to continue
                  using the Services.
                </li>
              </ol>
            </li>
            <li>
              <strong>Your use of our Platform</strong>
              <ol>
                <li>
                  You may only use our Platform for lawful purposes and in
                  compliance with all applicable laws, including without
                  limitation data protection and privacy laws, laws relating to
                  copyright of content and laws relating to anti-money
                  laundering.
                </li>
                <li>
                  When using the Platform, you must act in good faith at all
                  times with other Members.
                </li>
                <li>
                  In addition, in connection with your use of the Platform, you
                  will not and you will not allow or enable others to:
                  <ol>
                    <li>
                      breach or circumvent any applicable laws or regulations,
                      agreements with third parties, third party rights or our
                      Terms;
                    </li>
                    <li>
                      use the Platform to request, accept or make a Transaction
                      independent or outside of the Platform, whether to
                      circumvent any Service Fee or for any other reason;
                    </li>
                    <li>
                      request, accept or make payment of the Transaction Value
                      outside of the Platform to anyone other than the Payment
                      Provider or Us via invoice;
                    </li>
                    <li>
                      offer and provide, as a Seller:
                      <ol>
                        <li>
                          any Listings or Services which you do not have the
                          authority, rights, licences, permits or consents to
                          offer and make available to Members using the
                          Platform;
                        </li>
                        <li>
                          any Listings or Services which are misleading or
                          inaccurate; or
                        </li>
                        <li>
                          any Listing or Services for storage space which is
                          located outside of the United Kingdom;
                        </li>
                      </ol>
                    </li>
                    <li>
                      use the Platform to send or transmit, whether directly or
                      indirectly, unsolicited or unauthorised advertising or
                      promotional material, chain letters or pyramid selling
                      schemes;
                    </li>
                    <li>
                      use the Platform to post content or link to content where
                      you know or ought to have known that the owner of that
                      content has not consented to or authorised that posting or
                      linking or where that posting or linking infringes the
                      rights of that owner;
                    </li>
                    <li>
                      knowingly or recklessly introduce to the Platform, or use
                      our Platform to transmit, any application, material or
                      ware (such as viruses, spyware, malware or adware) which
                      are malicious or harmful;
                    </li>
                    <li>
                      attempt to gain unauthorised access to our Platform, the
                      server, equipment or network on which our Platform is
                      stored, any server, computer or database connected to our
                      Platform or any software;
                    </li>
                    <li>
                      use the Platform to attempt to gain unauthorised access to
                      any other website, internet account, server, computer,
                      equipment, system, network, data or information;
                    </li>
                    <li>
                      use the Platform to collect or use information, including
                      without limitation email addresses, screen names or other
                      identifiers, by deceit (such as phishing, internet
                      scamming, password robbery, spearing, scraping and
                      harvesting);
                    </li>
                    <li>
                      attempt to decipher, decompile, disassemble or reverse
                      engineer any of the software used to provide the Platform;
                    </li>
                    <li>
                      carry out a denial-of-service attack or a distributed
                      denial-of-service attack on the Platform or use the
                      Platform to carry out any such attack on any other
                      website;
                    </li>
                    <li>
                      use the Platform to groom, harm or take advantage of
                      minors in any way or to attempt to do so; or
                    </li>
                    <li>
                      use the Platform in violation of export laws, controls,
                      regulations or sanction policies of any country.
                    </li>
                  </ol>
                </li>
                <li>
                  If you breach any term of these Terms we may terminate or
                  suspend your use of and access to the Platform indefinitely at
                  our discretion. In addition we may take such other actions
                  including, without limitation, legal action as we consider
                  appropriate.
                </li>
                <li>
                  If you breach any term of these Terms, or if we receive a
                  request from a law enforcement authority to do so, we may
                  report your identity, details of any breach and any other
                  information we consider necessary to relevant law enforcement
                  authorities.
                </li>
              </ol>
            </li>
            <li>
              <strong>Our Platform and its content</strong>
              <ol>
                <li>
                  The Platform is made available to you free of charge. A
                  Service Fee only becomes payable upon the completion of a
                  Transaction.
                </li>
                <li>
                  We do not guarantee that access to our Platform, or the
                  content on it, will always be available or uninterrupted. Your
                  right to access our Platform is given on a temporary basis
                  only.
                </li>
                <li>
                  We reserve the right to change our Platform and the content on
                  it, including without limitation by adding or removing content
                  or functionality, at any time.
                </li>
                <li>
                  We regularly review the content on our Platform. However, we
                  cannot guarantee that all content on our Platform is accurate,
                  complete or up to date. We therefore make no warranties,
                  representations, undertakings or guarantees that the content
                  on our Platform is accurate, complete or up to date.
                </li>
                <li>
                  We make no warranties, representations, undertakings or
                  guarantees that our Platform or any content on it will be free
                  from errors or omissions.
                </li>
                <li>
                  The content on our Platform is provided for general
                  information purposes only. Nothing on our Platform is intended
                  to be advice on which you should rely. You should always
                  obtain appropriate specialist advice before taking, or not
                  taking, action on the basis of any content on our Platform.
                </li>
                <li>
                  We make no warranties, representations, undertakings or
                  guarantees that our Platform is secure or free from bugs or
                  viruses. You are responsible for ensuring that the device from
                  which you access our Platform is suitably secure and has
                  suitable anti-virus software in place.
                </li>
              </ol>
            </li>
            <li>
              <strong>Modifications, cancellations and refunds</strong>
              <ol>
                <li>
                  If you wish to modify or cancel a Transaction once a
                  Transaction has been confirmed, you must notify the relevant
                  Member as soon as possible. Any modifications or cancellations
                  of a Transaction must be agreed between the Buyer and the
                  Seller and will be subject to the applicable terms and
                  conditions of sale agreed between the Buyer and the Seller.
                </li>
                <li>
                  If there is an agreed cancellation or modification of a
                  Transaction and the Seller has agreed to refund the Buyer all
                  or part of the amount it received from the Buyer in connection
                  with the Transaction, the Seller will refund the agreed amount
                  to the Buyer in full (&ldquo;<strong>Refund Amount</strong>
                  &rdquo;) The Service Fee and the Payment Provider’s fee paid
                  in connection with the relevant Transaction are non-refundable
                  and will not be included in the Refund Amount. The Seller will
                  pay the Refund Amount to us and we will pay the Refund Amount
                  to the Buyer within sixty (60) days of receipt from the
                  Seller.
                </li>
              </ol>
            </li>
            <li>
              <strong>Intellectual property rights</strong>
              <ol>
                <li>
                  We own the copyright and all other intellectual property
                  rights in our Platform and all content on it.
                </li>
                <li>
                  You grant to us a non-exclusive, non-transferrable, royalty
                  free licence to use any of your intellectual property rights
                  in the content you upload onto the Platform for the purpose of
                  operating the Platform.
                </li>
                <li>
                  You may download the content we own from our Platform and/or
                  print a reasonable number of copies of any page on our
                  Platform. You must not make any changes to any content which
                  you have downloaded or printed from our Platform. If you
                  reproduce the downloaded or printed content in any medium you
                  must ensure that you reproduce it accurately and completely
                  and include a statement acknowledging that it is sourced from
                  our Platform.
                </li>
                <li>
                  If you use, download, print or reproduce content from our
                  Platform in breach of these Terms your right to use our
                  Platform will immediately end and you must return to us or
                  destroy (at our option) all digital and paper copies of the
                  content that you have made.
                </li>
              </ol>
            </li>
            <li>
              <strong>
                Links from our Platform to other websites, third party solutions
                or platforms
              </strong>
              <ol>
                <li>
                  Our Platform may contain links to other websites or third
                  party solutions or platforms from time to time. These links
                  are provided for your convenience. We have no control over,
                  and no responsibility or liability to you for, those other
                  websites, third party solutions or platforms or their content.
                </li>
              </ol>
            </li>
            <li>
              <strong>Our liability to you</strong>
              <ol>
                <li>
                  Nothing in these Terms limits or excludes our liability to you
                  for:
                  <ol>
                    <li>death or personal injury resulting from negligence;</li>
                    <li>fraud or fraudulent misrepresentation; or</li>
                    <li>
                      any other matter for which we are not permitted by law to
                      exclude or limit our liability.
                    </li>
                  </ol>
                </li>
                <li>
                  As these Terms govern your access to and use of our Platform,
                  all warranties, conditions and other terms relating to our
                  Platform and/or its content which would otherwise be implied
                  by law (whether by statute, common law or otherwise) are
                  excluded.
                </li>
                <li>
                  Subject to clause 12.1, we will not be liable to you, whether
                  in contract, tort (including without limitation negligence),
                  misrepresentation, restitution, under statute or otherwise,
                  for any loss or damage (whether direct, indirect or
                  consequential) arising out of or in connection with:
                  <ol>
                    <li>your use of (or inability to use) our Platform;</li>
                    <li>
                      your use of or reliance upon any content on our Platform;
                    </li>
                    <li>any Listing or Services;</li>
                    <li>
                      any failure of the Seller or the Buyer to comply with
                      these Terms and/or the terms and conditions of sale agreed
                      between the Seller and the Buyer in connection with a
                      Transaction.
                    </li>
                  </ol>
                </li>
                <li>
                  Subject to clause 12.1 and 12.5, our maximum aggregate
                  liability to you per Transaction, whether in contract, tort
                  (including without limitation negligence), misrepresentation,
                  restitution, under statute or otherwise, will not exceed the
                  lower of:
                  <ol>
                    <li>£1,000; or</li>
                    <li>
                      the Service Fee paid or payable to us in respect of the
                      relevant Transaction to which the claim relates.
                    </li>
                  </ol>
                </li>
                <li>
                  Subject to clause 12.1, we will not have any liability to you,
                  whether in contract, tort (including without limitation
                  negligence), misrepresentation, restitution, under statute or
                  otherwise, for any:
                  <ol>
                    <li>
                      loss of profit (whether direct, indirect or
                      consequential);
                    </li>
                    <li>
                      loss of revenue or anticipated savings (whether direct,
                      indirect or consequential);
                    </li>
                    <li>
                      loss of bargain (whether direct indirect or
                      consequential);
                    </li>
                    <li>
                      liability you have to third parties (whether direct,
                      indirect or consequential); and
                    </li>
                    <li>indirect, consequential or special loss.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>Indemnification</strong>
              <ol>
                <li>
                  You will indemnify us from and against any claims,
                  liabilities, damages, losses and expenses (including
                  reasonable professional fees) which we suffer or incur or may
                  suffer or incur as a result of or in connection with:
                  <ol>
                    <li>
                      your breach of these Terms and/or the terms and conditions
                      of sale agreed between the Seller and the Buyer in
                      connection with a Transaction;
                    </li>
                    <li>a Transaction;</li>
                    <li>your improper use of the Platform;</li>
                    <li>your interaction with any Member;</li>
                    <li>
                      your breach of any laws, regulations or third party
                      rights; and
                    </li>
                    <li>
                      any damage to or improper use of any storage space leased
                      or licenced as part of a Transaction.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>Term and Termination</strong>
              <ol>
                <li>
                  These Terms will be effective until terminated by you or us in
                  accordance with these Terms.
                </li>
                <li>
                  You may terminate your Storekat Account at any time upon
                  providing us with at least 14 days; written notice providing
                  there are no current or pending Transactions. Within 14 days
                  following receipt of your request to terminate we will
                  deactivate your Storekat Account. Upon deactivation of your
                  account, we reserve the right to retain your account data.
                </li>
                <li>
                  We may terminate your Storekat Account if you:
                  <ol>
                    <li>
                      commit a material breach of these Terms which is incapable
                      of remedy, or where capable of remedy, you fail to remedy
                      within 30 days of us notifying you to remedy the breach;
                      or
                    </li>
                    <li>
                      have a receiver or administrator appointed over any of
                      your assets, undertaking or income;
                    </li>
                    <li>are subject to a winding up order or petition;</li>
                    <li>
                      cease to trade or are unable to pay your debts as and when
                      they fall due;
                    </li>
                    <li>
                      propose, make or are subject to:
                      <ol>
                        <li>a company voluntary arrangement;</li>
                        <li>a composition with creditors generally; or</li>
                        <li>
                          an application to a court of competent jurisdiction
                          for protection from your creditors generally.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  We may suspend access to your Storekat Account immediately and
                  for such period as we deem necessary in our sole discretion
                  where we reasonably suspect that you are in breach of these
                  Terms and/or any applicable laws in connection with your
                  Storekat Account.
                </li>
              </ol>
            </li>
            <li>
              <strong>Entire Agreement</strong>
              <ol>
                <li>
                  These Terms (together with the policy documents referred to at
                  the start of these Terms) constitute the entire agreement
                  between you and us and supersede any prior agreement or
                  arrangement in respect of its subject matter and:
                  <ol>
                    <li>
                      you have not entered into these Terms in reliance upon,
                      and will have no remedy in respect of, any
                      misrepresentation, representation or statement (whether
                      made by us or any other party and whether made to you or
                      any other party) which is not expressly set out in these
                      Terms;
                    </li>
                    <li>
                      the only remedies available for any misrepresentation or
                      breach of any representation or statement which was made
                      prior to entering into these Terms and which is expressly
                      set out in thee Terms will be for breach of contract; and
                    </li>
                    <li>
                      nothing in this clause 15 will be interpreted or construed
                      as limiting or excluding the liability of any person for
                      fraud or fraudulent misrepresentation.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>Severance</strong>
              <ol>
                <li>
                  If any term of these Terms is found by any court or body or
                  authority of competent jurisdiction to be illegal, unlawful
                  void or unenforceable, it will be deemed to be severed from
                  these Terms and this will not affect the remainder of these
                  Terms which will continue in full force and effect.
                </li>
              </ol>
            </li>
            <li>
              <strong>No Waiver</strong>
              <ol>
                <li>
                  A delay in exercising or failure to exercise a right or remedy
                  under or in connection with these Terms will not constitute a
                  waiver of, or prevent or restrict future exercise of, that or
                  any other right or remedy. A waiver of any right, remedy,
                  breach or default will only be valid if it is in writing and
                  signed by the party giving it and only in the circumstances
                  and for the purpose for which it was given and will not
                  constitute a waiver of any other right, remedy, breach or
                  default.
                </li>
              </ol>
            </li>
            <li>
              <strong>Assignment and Sub-contracting</strong>
              <ol>
                <li>
                  You are not permitted to assign, novate, transfer or otherwise
                  deal in any manner with any or all of your rights or
                  obligations under these Terms without our prior written
                  consent.
                </li>
              </ol>
            </li>
            <li>
              <strong>Governing law and jurisdiction</strong>
              <ol>
                <li>
                  These Terms (and any non-contractual obligations arising out
                  of or in connection with them) are governed by the law of
                  England and Wales and the courts of England and Wales have
                  exclusive jurisdiction to determine any dispute arising out of
                  or in connection with them (including without limitation in
                  relation to any non-contractual obligations).
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>

      {allowCollapse === true && (
        <p className="mb-4">
          <Button
            type="button"
            className="btn-muted"
            label={expanded ? "- Show less" : "+ Read all"}
            onClick={() => setExpanded(!expanded)}
          />
        </p>
      )}
    </div>
  );
}
