interface HelpMessageProps {
  text: string
};

export default function HelpMessage({ text }:HelpMessageProps) {
  return (
    <div className="text-sm text-gray-500 italic block border-l-2 border-yellow-500 px-2 mt-2">
      {text}
    </div>
  );
}
