import { Column, useTable } from "react-table";
import { usePagination } from "./pagination/PaginationContainer";

interface TableProps {
  columns: Column<{}>[],
  data: any, //TODO: Update pagination container and set type to: object[],
  rightLastColumn?: boolean
}

export default function Table({ columns, data, rightLastColumn = true }:TableProps) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const rightClassName = (colCount:number, cellIndex:number) => {
    return colCount === cellIndex + 1 && rightLastColumn ? "text-right" : "";
  };

  return (
    <table {...getTableProps()} className="border-collapse w-full">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="border-b border-gray-700"
          >
            {headerGroup.headers.map((col, index) => (
              <th
                {...col.getHeaderProps()}
                className={`p-2 text-left ${rightClassName(
                  headerGroup.headers.length,
                  index
                )}`}
              >
                {col.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row, rowIndex) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, cellIndex) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className={`p-2 ${
                      rowIndex % 2 === 0 ? "" : "bg-gray-100"
                    } ${rightClassName(row.cells.length, cellIndex)}`}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

interface PagedTableProps {
  columns: Column<{}>[],
  rightLastColumn: boolean
}

export function PagedTable({ columns, rightLastColumn = true }:PagedTableProps) {
  const pagedData = usePagination();
  return Table({
    columns: columns, 
    data: pagedData, 
    rightLastColumn: rightLastColumn
  });
}
