import { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDateForInputs } from "../../../../utils/date-transformations";
import {
  CurrencyInput,
  DateInput,
  NumberInput,
  NumberInputWithUnit,
  Field,
  FieldErrorMessage,
  CheckboxField,
  CheckboxInput,
} from "../../../common/forms";

import getRegisterOptions from "./register-options";
import { useStore } from "../../../../hooks/use-store";

const DEFAULT_MINIMUM_BOOKING_PERIOD = 1; // weeks

export default function AvailabilityFormFields({
  availability,
  space,
  currency,
  formMethods,
}) {
  const { t } = useTranslation();
  const { userStore } = useStore();
  const {
    getValues,
    register,
    formState: { errors },
  } = formMethods;
  const registerOptions = getRegisterOptions(getValues, t, space);
  const [publishChecked, setPublishChecked] = useState(
    availability?.published === "Published"
  );

  return (
    <>
      <fieldset className="space-y-8">
        <div className="grid grid-cols-2 gap-6">
          <Field
            label={t("accountSites.availability.startDate")}
            htmlFor="startDate"
          >
            <DateInput
              name="startDate"
              defaultValue={formatDateForInputs(availability.startDate)}
              register={register("startDate", registerOptions.startDate)}
              error={errors.startDate}
            />
            <FieldErrorMessage error={errors.startDate} />
          </Field>

          <Field
            label={t("accountSites.availability.endDate")}
            htmlFor="endDate"
          >
            <DateInput
              name="endDate"
              defaultValue={formatDateForInputs(availability.endDate)}
              register={register("endDate", registerOptions.endDate)}
              error={errors.endDate}
            />
            <FieldErrorMessage error={errors.endDate} />
          </Field>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <Field
            label={t("accountSites.availability.minBookingPeriod")}
            htmlFor="minBookingPeriod"
          >
            <NumberInputWithUnit
              name="minBookingPeriod"
              defaultValue={
                availability.minBookingPeriod || DEFAULT_MINIMUM_BOOKING_PERIOD
              }
              unit={t("accountSites.availability.minBookingPeriodUnit")}
              register={register(
                "minBookingPeriod",
                registerOptions.minBookingPeriod
              )}
              error={errors.minBookingPeriod}
            />
            <FieldErrorMessage error={errors.minBookingPeriod} />
          </Field>

          <Field
            label={t("accountSites.availability.leadTime")}
            htmlFor="leadTime"
          >
            <NumberInputWithUnit
              name="leadTime"
              defaultValue={availability.leadTime}
              unit={t("accountSites.availability.leadTimeUnit")}
              register={register("leadTime", registerOptions.leadTime)}
              error={errors.leadTime}
            />
            <FieldErrorMessage error={errors.leadTime} />
          </Field>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <Field
            label={t("accountSites.availability.maxAvailableQuantity")}
            htmlFor="maxAvailableQuantity"
          >
            <NumberInput
              name="maxAvailableQuantity"
              defaultValue={availability.maxAvailableQuantity}
              register={register(
                "maxAvailableQuantity",
                registerOptions.maxAvailableQuantity
              )}
              error={errors.maxAvailableQuantity}
            />
            <FieldErrorMessage error={errors.maxAvailableQuantity} />
          </Field>

          <Field
            label={t("accountSites.availability.minBookingQuantity")}
            htmlFor="minBookingQuantity"
          >
            <NumberInput
              name="minBookingQuantity"
              defaultValue={availability.minBookingQuantity}
              register={register(
                "minBookingQuantity",
                registerOptions.minBookingQuantity
              )}
              error={errors.minBookingQuantity}
            />
            <FieldErrorMessage error={errors.minBookingQuantity} />
          </Field>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <Field
            label={t("accountSites.availability.unitPriceWithUnitAndMeasure", {
              unit: space.unit,
              unitMeasure: space.unitMeasure,
            })}
            htmlFor="unitPrice"
            helpText={t("accountSites.availability.unitPriceHelp")}
          >
            <CurrencyInput
              name="unitPrice"
              defaultValue={availability.unitPrice}
              currency={currency}
              register={register("unitPrice", registerOptions.unitPrice)}
              error={errors.unitPrice}
            />
            <FieldErrorMessage error={errors.unitPrice} />
          </Field>
        </div>
      </fieldset>

      <fieldset className="space-y-6">
        <legend className="block font-extrabold text-lg">
          {t("accountSites.availability.rhd")}
        </legend>

        <div className="grid grid-cols-2 gap-6">
          <Field
            label={t("accountSites.availability.rhdInUnitPrice")}
            htmlFor="rhdInUnitPrice"
            helpText={t("accountSites.availability.unitPriceHelp")}
          >
            <CurrencyInput
              name="rhdInUnitPrice"
              defaultValue={availability.rhdInUnitPrice}
              currency={currency}
              register={register(
                "rhdInUnitPrice",
                registerOptions.rhdInUnitPrice
              )}
              error={errors.rhdInUnitPrice}
            />
            <FieldErrorMessage error={errors.rhdInUnitPrice} />
          </Field>

          <Field
            label={t("accountSites.availability.rhdOutUnitPrice")}
            htmlFor="rhdOutUnitPrice"
          >
            <CurrencyInput
              name="rhdOutUnitPrice"
              defaultValue={availability.rhdOutUnitPrice}
              currency={currency}
              register={register(
                "rhdOutUnitPrice",
                registerOptions.rhdOutUnitPrice
              )}
              error={errors.rhdOutUnitPrice}
            />
            <FieldErrorMessage error={errors.rhdOutUnitPrice} />
          </Field>
        </div>

        <div>
          <CheckboxField
            label={t("accountSites.availability.publish")}
            htmlFor="publish"
            helpText={t("accountSites.availability.publishHelp")}
          >
            <CheckboxInput
              name="publish"
              className="w-auto"
              register={register("publish")}
              value="publish"
              defaultChecked={publishChecked}
              onChange={() => setPublishChecked(!publishChecked)}
              disabled={
                userStore.contextAccount?.paymentAccountConfirmed !== true
              }
            />
          </CheckboxField>
        </div>
      </fieldset>
    </>
  );
}
