import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddressLookup from "../address-lookup/AddressLookup";
import { LinkButton } from "../Button";
import { Field, FieldErrorMessage, SelectInput, TextInput } from ".";
import { Address } from '../../../app/model/address';

interface AddressInputProps {
  formMethods: any,
  defaultAddress?: Address,
  onSelected?: (address?:Address) => void;
  requiredMsg?: string
}

export default function AddressInput({
  formMethods,
  defaultAddress,
  onSelected,
  requiredMsg
}: AddressInputProps) {
  const { t } = useTranslation();
  const [manualInput, setManualInput] = useState(false);
  const {
    register,
    formState: { errors },
  } = formMethods;

  const countries = [{ id: "UK", name: "United Kingdom" }];

  const setManualAddress = (manualInput:boolean) => {
    setManualInput(manualInput);

    if (manualInput === true) {
      // Set manual field validations
    } else {
      // Set search address validations
    }
  };

  return (
    <fieldset className="space-y-6">
      <legend className="font-extrabold text-lg">
        {t("common.address.address")}
      </legend>

      {!manualInput && (
        <>
          <AddressLookup 
            onSelected={onSelected} 
            label="" 
            defaultAddress={defaultAddress} 
            requiredMsg={requiredMsg} 
            formMethods={formMethods} />
          <div>
            <LinkButton
              label={t("addressLookup.manualAddress")}
              onClick={() => setManualAddress(true)}
              className="pl-0"
            />
          </div>
        </>
      )}

      {manualInput && (
        <>
          <Field
            label={`${t("common.address.country")} *`}
            htmlFor="addressCountry"
          >
            <SelectInput
              name="addressCountry"
              defaultValue={defaultAddress?.country}
              register={register("address.country", { required: true })}
              error={errors.addressCountry}
              placeholder={t("generalActions.pleaseSelect")}
            >
              {countries.map((country) => (
                <option key={`country-${country.id}`} value={country.id}>
                  {country.name}
                </option>
              ))}
            </SelectInput>
            <FieldErrorMessage error={errors.addressCountry} />
          </Field>

          <Field
            label={`${t("common.address.line1")} *`}
            htmlFor="addressLine1"
          >
            <TextInput
              name="addressLine1"
              defaultValue={defaultAddress?.addressLine1}
              register={register("address.addressLine1", { required: true })}
              error={errors.addressLine1}
            />
            <FieldErrorMessage error={errors.addressLine1} />
          </Field>

          <Field label={t("common.address.line2")} htmlFor="addressLine2">
            <TextInput
              name="addressLine2"
              defaultValue={defaultAddress?.addressLine2}
              register={register("address.addressLine2")}
            />
            <FieldErrorMessage error={errors.addressLine2} />
          </Field>

          <Field label={`${t("common.address.postcode")} *`} htmlFor="postcode">
            <TextInput
              name="postcode"
              defaultValue={defaultAddress?.postcode}
              register={register("address.postcode", { required: true })}
              error={errors.postcode}
            />
            <FieldErrorMessage error={errors.postcode} />
          </Field>

          <Field label={`${t("common.address.city")} *`} htmlFor="addressCity">
            <TextInput
              name="addressCity"
              defaultValue={defaultAddress?.city}
              register={register("address.city", { required: true })}
              error={errors.addressCity}
            />
            <FieldErrorMessage error={errors.addressCity} />
          </Field>

          <Field label={t("common.address.state")} htmlFor="addressState">
            <TextInput
              name="addressState"
              defaultValue={defaultAddress?.state}
              register={register("address.state")}
              error={errors.addressState}
            />
            <FieldErrorMessage error={errors.addressState} />
          </Field>

          <div>
            <LinkButton
              label={t("addressLookup.searchAddress")}
              onClick={() => setManualAddress(false)}
              className="pl-0"
            />
          </div>
        </>
      )}
    </fieldset>
  );
}
