import { Route, Switch } from "react-router-dom";
import toast, { ToastBar, Toaster } from "react-hot-toast";

import { useAuth } from "../hooks/use-auth";

import Home from "./Home";
import SignIn from "./account/login-register/SignIn";
import Register from "./account/login-register/Register";
import ConfirmAccount from "./account/login-register/ConfirmAccount";
import ResetPassword from "./account/login-register/ResetPassword";
import ResetNewPassword from "./account/login-register/ResetNewPassword";
import AccountRouter from "./account/Router";

import BookingRouter from "./bookings/BookingRouter";

import SiteHeader from "./common/site-header/SiteHeader";
import Footer from "./common/footer/Footer";
import NotFound from "./common/NotFound";
import OnboardingTopBanner from "./common/site-header/OnboardingTopBanner";
import TermsConditions from "./common/legal/TermsConditions";
import Search from "./search/Search";
import Feature from "./common/feature-flags/Feature";
import { useEffect } from 'react';
import { useStore } from '../hooks/use-store';
import PrivateRoute from './common/PrivateRoute';

function App() {
  
  const { userStore, commonStore } = useStore();
  const { isLoading, user } = useAuth();
  const { getUser } = userStore;
  const { token, setToken } = commonStore;

  useEffect(() => {
    async function loadUserData() {
      if (user !== null && user.token !== undefined)
      setToken(user.token);
      await getUser();
    }
    
    if (!isLoading)
      loadUserData();
  }, [token, getUser, setToken, user, isLoading]);

  return (
    <div className="text-gray-700 pb-20">
      <OnboardingTopBanner />
      <SiteHeader />
      <Feature name="test" />

      <div className="pb-20 px-4">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/sign-in" component={SignIn} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/set-new-password" component={ResetNewPassword} />
          <Route exact path="/confirm-account" component={ConfirmAccount} />
          <Route exact path="/search" component={Search} />
          <Route path="/booking">
            <BookingRouter />
          </Route>

          <PrivateRoute path="/account">
            <AccountRouter />
          </PrivateRoute>

          <Route exact path="/terms-conditions" component={TermsConditions} />

          <Route component={NotFound} />
        </Switch>
      </div>

      <Footer menus={[]} />

      <Toaster>
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== "loading" && (
                  <button onClick={() => toast.dismiss(t.id)}>X</button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </div>
  );
}

export default App;
