import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/use-auth';
import useSWR from 'swr';
import apiUrl from '../../../data/api-url';
import { tokenFetcher } from '../../../data/fetchers';
import LoadingBlock from '../../common/loading/LoadingBlock';
import { StockItem } from '../../../app/model/stock-management';
import requests from '../../../data/requests';
import { BookingStockItemQuantity, BookingStockMovement, StockMovement } from '../../../app/model/bookings';
import { formatDate } from '../../../utils/date-transformations';
import BookingStockItems from './BookingStockItems';

export default function StockMovementHistory() {
  const { t } = useTranslation();
  const { bookingId } = useParams<{ bookingId: string }>();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [ bookingMovements, setBookingMovements ] = useState<BookingStockMovement[]>([]);
  const [ bookingStock, setBookingStock ] = useState<BookingStockItemQuantity[]>([]);

  const { data: booking } = useSWR(
    [apiUrl("bookings", `/bookings/${bookingId}`), user.token],
    tokenFetcher
  );
  
  useEffect(() => {

    async function loadStockItems() {
      setLoading(true);
      const stockItemIds = booking.stockItemQuantities.map(((item:any) => item.stockItemId));

      if (stockItemIds.length === 0) {
        setLoading(false);
        return;
      }

      var stockItems = await requests.post<StockItem[]>(apiUrl(
        "stock",
        '/stock/list'
      ), { stockItemIds: stockItemIds });

      var stockMovements = await requests.get<StockMovement[]>(apiUrl('bookings', `/bookings/${bookingId}/stock-movements`));

      const bookingMovements = stockMovements.map((movement) => {

        const stockItem = stockItems.filter((item) => item.id === movement.stockItemId)[0];

        const item:BookingStockMovement = {
          name: stockItem.name,
          sku: stockItem.sku,
          description: stockItem.description,
          gtin: stockItem.gtin,
          upc: stockItem.upc,
          ...movement
        };
        return item;

      });
      setBookingMovements(bookingMovements);

      const bookingStockQuantities:BookingStockItemQuantity[] = stockItems.map((item, index) => {
        return {
          sku: item.sku,
          name: item.name,
          quantity: booking.stockItemQuantities[index].quantity,
          siteId: booking.stockItemQuantities[index].siteId,
          stockItemId: item.id,
          description: item.description,
          gtin: item.gtin,
          upc: item.upc
        };
      });
      setBookingStock(bookingStockQuantities);

      setLoading(false);
    }

    if (booking !== undefined && booking !== null) {
      loadStockItems();
    }

  }, [booking, bookingId])
  


  if (loading) return <LoadingBlock />;

  return (
    <div className="space-y-6">
      <header>
        <h1>{t("accountBookings.stockMovements.title")}</h1>
      </header>

      <div>
        <h2>
          <small className="text-sm font-medium uppercase">
            {" "}
            {t("accountBookings.booking.reference")}
          </small>
          <br />
          {booking.reference}
        </h2>
      </div>

      {(bookingMovements === undefined || bookingMovements.length === 0) && (
        <p>{t("accountBookings.bookingStock.noStockItems")}</p>
      )}

      <h2>{t("accountBookings.bookingStock.title")}</h2>
      <p className='text-brandSecondary-100'>{t('accountBookings.stockMovements.bookingQuantityDesc')}</p>
      <BookingStockItems spaceItems={booking.spaceLineItems} stockQuantities={bookingStock} bookingId={bookingId} />

      <h2>{t('accountBookings.stockMovements.detailedMovements')}</h2>
      <p className='text-brandSecondary-100'>{t('accountBookings.stockMovements.detailedMovementsDesc')}</p>

      {bookingMovements.length > 0 && booking.spaceLineItems.map((item:any) => (
        <div key={item.siteId} className='mb-10'>
          <div className='mb-2'>
            <p className='text-sm uppercase mb-[-5px]'>{t("accountBookings.bookingStock.site")}</p>
            <h3 className='font-bold'>{item.siteName}</h3>
          </div>
          <table className='w-full'>
            <thead>
              <tr>
                <th className='text-left'>{t('accountBookings.stockMovements.date')}</th>
                <th className='text-left'>{t('accountBookings.stockMovements.product')}</th>
                <th className='text-left'>{t('accountBookings.stockMovements.movement')}</th>
                <th className='text-right'>{t('accountBookings.stockMovements.quantity')}</th>
              </tr>
            </thead>
            <tbody>
            {bookingMovements.map((movement) => (
              <tr className='border-gray-200 py-1' key={movement.id}>
                <td>{formatDate(movement.movementDate)}</td>
                <td>{movement.name} - {movement.sku}</td>
                <td>{movement.movementType === "MoveIn" ? t('accountBookings.stockMovements.moveIn') : t('accountBookings.stockMovements.moveOut')}</td>
                <td className='text-right'>
                  <span className={movement.movementType === "MoveIn" ? 'text-lime-600' : 'text-rose-800'}>
                    {movement.movementType === "MoveIn" ? '+' : '-'}{movement.quantity}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      ))}

    </div>
  )

}