import React from 'react'

interface TooltipProps {
  tooltip: string
  children?: React.ReactNode
}

export default function Tooltip(props:TooltipProps) {
  return (
    <span className="group relative inline-block">
      <span className="pointer-events-none absolute -top-10 left-1/2 -translate-x-1/2 whitespace-nowrap rounded bg-gray-600/95 px-2 py-1 text-white text-sm font-normal opacity-0 transition before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-gray-600/95 before:content-[''] group-hover:opacity-100">
        {props.tooltip}
      </span>
      <span className='align-text-top px-1 font-medium text-sm text-blue-300 underline decoration-dashed cursor-pointer'>{props.children ? props.children : '?'}</span>
    </span>
  )
}