import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { uppercaseFirstLetter } from "../../../../utils/text-transformations";
import Table from "../../../common/Table";
import LoadingBlock from "../../../common/loading/LoadingBlock";

export default function SpaceList({ site, spaces }) {
  const { t } = useTranslation();
  const newSpaceUrl = `/account/sites/${site.id}/spaces/new`;

  const data = useMemo(() => {
    return spaces
      ? spaces.map((space) => {
          return {
            name: space,
            status: uppercaseFirstLetter(space.status),
            class: space.class,
            unit: space.unit,
            unitMeasure: space.unitMeasure,
            type: space.type,
            quantity: space.quantity,
          };
        })
      : [];
  }, [spaces]);

  const columns = useMemo(
    () => [
      {
        Header: t("accountSites.space.name"),
        accessor: "name",
        Cell: ({ value }) => (
          <Link
            to={`/account/sites/${site.id}/spaces/${value.id}`}
            className="text-link"
          >
            {value.name}
          </Link>
        ),
      },
      {
        Header: t("accountSites.space.type"),
        accessor: "type",
      },
      {
        Header: t("accountSites.space.class"),
        accessor: "class",
      },
      {
        Header: t("accountSites.space.quantity"),
        accessor: "quantity",
      },
      {
        Header: t("accountSites.space.unit"),
        accessor: "unit",
      },
      {
        Header: t("accountSites.space.unitMeasure"),
        accessor: "unitMeasure",
      },
      {
        Header: t("accountSites.space.status"),
        accessor: "status",
      },
    ],
    [t, site.id]
  );

  return (
    <div className="space-y-8">
      <header className="flex justify-between align-baseline">
        <h2>{t("accountSites.spaces")}</h2>

        <Link to={newSpaceUrl} className="btn-brand">
          {t("accountSites.addSpace")}
        </Link>
      </header>

      {spaces && spaces.length > 0 && (
        <Table columns={columns} data={data} rightLastColumn={false} />
      )}
      {spaces && spaces.length === 0 && (
        <div>
          {t("accountSites.space.noSpaces")}.{" "}
          <Link to={newSpaceUrl}>{t("accountSites.space.addFirst")}</Link>
        </div>
      )}
      {!spaces && <LoadingBlock />}
    </div>
  );
}
