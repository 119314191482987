import dayjs from "dayjs";

function dateIsCurrent({ value, t }) {
  const dateValue = dayjs(value);
  const today = dayjs();

  return (
    !dateValue.isBefore(today, "day") ||
    t("accountSites.availability.validation.startDate.dateInPast")
  );
}

export default function getSearchOptions(getValues, t, space) {
  return {
    spaceClass: {
      required: t("search.validation.spaceTypeRequired"),
    },
    startDate: {
      required: t("search.validation.fromRequired"),
      validate: {
        dateIsCurrent: (value) => dateIsCurrent({ value, t }),
      },
    },
    endDate: {
      required: t("search.validation.toRequired"),
      validate: {
        afterStartDate: (value) => {
          const { startDate } = getValues();
          const startDateValue = dayjs(startDate);
          const endDateValue = dayjs(value);

          if (!startDateValue.isValid() || !endDateValue.isValid()) {
            return true;
          }

          return (
            !endDateValue.isBefore(startDateValue, "day") ||
            t("accountSites.availability.validation.endDate.beforeStartDate")
          );
        },
      },
    },
    quantity: {
      required: t("search.validation.quantityRequired"),
      min: {
        value: 1,
        message: t("common.validation.min", { value: 1 }),
      },
      valueAsNumber: true,
    },
    radius: {
      required: t("search.validation.locationRequired"),
      min: {
        value: 1,
        message: t("common.validation.min", { value: 1 }),
      },
      max: {
        value: 10000,
        message: t("common.validation.max", { value: 10000 }),
      },
      valueAsNumber: true,
    },
  };
}
