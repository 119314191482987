import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import InvoiceList from './InvoiceList';
import { useStore } from '../../../../hooks/use-store';
import LoadingBlock from '../../../common/loading/LoadingBlock';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

export default observer(function Invoices() {
  const { t } = useTranslation();
  const { accountStore } = useStore();
  const { invoices, loadInvoices, clearInvoices, loading } = accountStore;

  const { organisationId } = useParams<{organisationId: string}>();

  useEffect(() => {

    if (organisationId !== undefined) {
      loadInvoices(organisationId);
    }
  
    return () => {
      clearInvoices();
    }
  }, [loadInvoices, clearInvoices, organisationId]);

  if (loading || organisationId === undefined) {
    return <LoadingBlock />
  }  

  return (
    <div className="space-y-6">
      <h2>{t("accountOrganisations.invoices.title")}</h2>
      <p>{t("accountOrganisations.invoices.leadText")}</p>

      <InvoiceList invoices={invoices} />

    </div>
  )
});