import { useTranslation } from "react-i18next";

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto mt-7 space-y-8">
      <h1>{t("notFound.title")}</h1>

      <p className="text-lg">{t("notFound.leadText")}</p>
    </div>
  );
}
