import { useTranslation } from "react-i18next";
import { BiBuilding } from "react-icons/bi";

export default function SiteSpaceCount({ siteCount, spaceCount }) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <div className="mr-4">
        <BiBuilding className="text-4xl text-brand" />
      </div>
      <div className="mr-4">
        <small className="text-sm font-medium uppercase text-gray-400">
          {t("accountSites.metrics.sites")}
        </small>
        <br />
        {siteCount === undefined && (
          <div className="h-8 w-6 bg-gray-200 animate-pulse rounded"></div>
        )}
        <p className="text-3xl font-bold leading-6">{siteCount}</p>
      </div>
      <div>
        <small className="text-sm font-medium uppercase text-gray-400">
          {t("accountSites.metrics.spaces")}
        </small>
        <br />
        {spaceCount === undefined && (
          <div className="h-8 w-6 bg-gray-200 animate-pulse rounded"></div>
        )}
        <p className="text-3xl font-bold leading-6">{spaceCount}</p>
      </div>
    </div>
  );
}
