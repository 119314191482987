import { BiInfoCircle } from "react-icons/bi";
import { MessageProps } from './message';

export default function InfoMessage({ title, message, children }:MessageProps) {
  return (
    <div
      className="bg-blue-100 border-t-4 border-l border-r border-b border-blue-500 rounded-b text-blue-800 px-4 py-3 shadow-sm bg-opacity-40 border-opacity-40 text-opacity-60"
      role="alert"
    >
      <div className="flex">
        <div className="py-1 mr-2">
          <BiInfoCircle className="text-xl" />
        </div>
        <div>
          {title && <p className="font-bold" data-cy='message-title'>{title}</p>}
          {message && <p data-cy='message-body'>{message}</p>}
          {children}
        </div>
      </div>
    </div>
  );
}
