import { createContext, useContext, useState } from "react";

const loadingContext = createContext({
  loadingCount: 0,
  showLoading: () => {},
  hideLoading: () => {},
});

export function LoadingProvider({ children }) {
  const loading = useProvideLoading();
  return (
    <loadingContext.Provider value={loading}>
      {children}
    </loadingContext.Provider>
  );
}

// Hook for child components to get the auth object and re-render when it changes.
export const useLoading = () => {
  return useContext(loadingContext);
};

export function useProvideLoading() {
  const [loadingCount, setLoadingCount] = useState(0);

  const showLoading = () => {
    setLoadingCount((prevState) => {
      return prevState + 1;
    });
  };

  const hideLoading = () => {
    setLoadingCount((prevState) => {
      return prevState > 0 ? prevState - 1 : 0;
    });
  };

  return {
    loadingCount,
    showLoading,
    hideLoading,
  };
}
