import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BiCog } from 'react-icons/bi';

export default function ProcessingImport() {
  const { t } = useTranslation();

  // Url path is not changing, so manually scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className='space-y-6 text-center bg-gray-50 border border-gray-200 rounded-md pb-10'>
      <div className="text-center text-[75px] text-brand-500">
        <BiCog className="inline-block animate-[spin_3s_infinite]" />
      </div>
      <h2>{t("stockManagement.import.processing.title")}</h2>
      <p className="text-xl max-w-3xl text-center mx-auto">
      {t('stockManagement.import.processing.leadText')}
      </p>
  </div>
  )
}