import React, { useEffect, useState } from "react";
import { Address } from '../../../app/model/address';
import { parseAddress } from "./address-search";

interface ResultsSelectProps {
  data?: any;
  onSelected?: (address:Address) => void;
  onChange: (val: string) => void;
}

export default function ResultsSelect({
  data,
  onSelected,
  onChange,
}:ResultsSelectProps) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (data) {
      setVisible(true);
    }
  }, [data]);

  const handleSelect = (index:number) => {
    setVisible(false);

    if (onSelected !== undefined) {
      onSelected(parseAddress(data[index]));
    }

    if (onChange !== undefined) {
      onChange(data[index].formatted_address);
    }
  };

  return visible ? (
    <div className="address-lookup-list block w-full border bg-white border-gray-200 rounded shadow-md absolute bottom-[-35px]">
      <ul>
        {data.length === 0 && (
          <li className="block py-1 px-4 hover:bg-gray-100 cursor-pointer">
            No results found...
          </li>
        )}
        {data.map((address:any, index:number) => {
          return (
            <li
              className="block py-1 px-4 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelect(index)}
              key={index}
            >
              {address.formatted_address}
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
}
