import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import apiUrl from "../../../../data/api-url";
import submitForm from "../../../../data/submit-form";
import { Form } from "../../../common/forms";
import SiteFormFields from "./FormFields";
import {
  toastError,
  toastLoading,
} from "../../../common/messages/toast-messages";
import { useState } from "react";
import Button, { LinkButton } from "../../../common/Button";
import useSWR from "swr";
import { tokenFetcher } from "../../../../data/fetchers";
import { useStore } from "../../../../hooks/use-store";
import { observer } from "mobx-react-lite";

export default observer(function NewSite() {
  const { t } = useTranslation();
  const formMethods = useForm({ mode: "onBlur" });
  const history = useHistory();
  const { userStore } = useStore();
  const [submitting, setSubmitting] = useState(false);

  const { data: allAmenities } = useSWR(
    [apiUrl("spaces", "/amenities"), {}],
    tokenFetcher
  );

  const submitSite = async (formData) => {
    setSubmitting(true);
    const toastId = toastLoading(t("toasts.siteCreating"));

    const siteData = {
      accountId: userStore.contextAccount.id,
      name: formData.name,
      description: formData.description,
      address: formData.address,
      amenities: [],
    };

    siteData.amenities = siteData.amenities.concat(
      formData.accessAmenities !== false ? formData.accessAmenities : []
    );
    siteData.amenities = siteData.amenities.concat(
      formData.generalAmenities !== false ? formData.generalAmenities : []
    );
    siteData.amenities = siteData.amenities.concat(
      formData.securityAmenities !== false ? formData.securityAmenities : []
    );

    const { data, err: remoteErrors } = await submitForm(
      apiUrl("spaces", "/sites"),
      siteData
    );

    if (data) {
      history.replace(`/account/sites/${data.id}?new_site=1`, {
        longPolling: new Date(data.timestamp),
        toastId: toastId,
      });
    } else if (remoteErrors) {
      setSubmitting(false);
      toastError(t, remoteErrors.message, remoteErrors.errorCode);
      console.log(remoteErrors);
    }
  };

  return (
    <div className="space-y-8">
      <h1>{t("accountSites.addSite")}</h1>

      <Form onSubmit={formMethods.handleSubmit(submitSite)}>
        <SiteFormFields
          formMethods={formMethods}
          site={{ address: undefined, amenities: [] }}
          allAmenities={allAmenities}
        />

        <div>
          <Button label={t("accountSites.site.create")} loading={submitting} />
          <LinkButton
            label={t("common.cancel")}
            onClick={() => {
              history.push(`/account/sites`);
            }}
          />
        </div>
      </Form>
    </div>
  );
});
