import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import apiUrl from "../../../../data/api-url";
import { formatDate } from "../../../../utils/date-transformations";
import { titleCase } from "../../../../utils/text-transformations";
import LoadingBlock from "../../../common/loading/LoadingBlock";
import PriceDisplay from "../../../common/PriceDisplay";
import NoteList from "./NoteList";
import HelpMessage from "../../../common/messages/HelpMessage";
import { observer } from "mobx-react-lite";
import RequestStockItems from './RequestStockItems';
import Feature from '../../../common/feature-flags/Feature';
import { RequestStockItem } from '../../../../app/model/service-requests';
import requests from '../../../../data/requests';
import { StockItem } from '../../../../app/model/stock-management';
import ApproveDeclineServiceRequest from './ApproveDeclineServiceRequest';
import { tokenFetcher } from '../../../../data/fetchers';
import useSWR from 'swr';
import { useAuth } from '../../../../hooks/use-auth';

export default observer(function ViewServiceRequest() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { requestId, bookingId } = useParams<{requestId?: string, bookingId?: string}>();
  const [ stockItems, setStockItems ] = useState<RequestStockItem[]>([]);

  const { data: request } = useSWR(
    [apiUrl("bookings", `/bookings/${bookingId}/service-requests/${requestId}`), user.token],
    tokenFetcher
  );

  useEffect(() => {
    async function loadServiceRequest() {

      // If there's any stock items for the service request, load them from the stock service
      if (request && request.stockItemQuantities !== undefined && request.stockItemQuantities.length > 0) {
        const stockItemIds = request.stockItemQuantities.map(((item:any) => item.stockItemId));
        
        const stockResult = await requests.post<StockItem[]>(apiUrl(
          "stock",
          '/stock/list'
        ), { stockItemIds: stockItemIds });

        // Loop through the stock items quantities, find the stock item, and map to a RequestStockItem
        let stockItemQuantities: RequestStockItem[] = [];
        for (var i = 0; i < request.stockItemQuantities.length; i++) {
          // Find the stock item matching the id
          const stockItem = findStockItem(stockResult, request.stockItemQuantities[i].stockItemId);
          
          if (stockItem !== undefined) {
            stockItemQuantities.push({
              id: stockItem.id, 
              name: stockItem.name,
              quantity: request.stockItemQuantities[i].quantity,
              sku: stockItem.sku
            });
          }
        }

        setStockItems(stockItemQuantities);
      }
    }
    loadServiceRequest();

  }, [bookingId, requestId, request]);  

  const findStockItem = (stockResult:StockItem[], id: string) => {
    for (var i = 0; i < stockResult.length; i++) {
      if (stockResult[i].id.toLowerCase() === id.toLowerCase()) {
        return stockResult[i];
      }
    }
    return undefined;

  }

  const showPrice = () => {
    if (request!.priceDetails === null) {
      return t("accountBookings.serviceRequestDetail.priceNotAvailable");
    } else {
      return (
        <>
          <PriceDisplay price={request!.priceDetails.totalChargeAmount} />
          <HelpMessage
            text={t("accountBookings.serviceRequestDetail.priceHelpText")}
          />
        </>
      );
    }
  };

  const canApprove = () => {
    return request!.status.toLowerCase() === "quoted";
  };

  const canDecline = () => {
    // Currently same business rules for approveing
    return canApprove();
  };

  if (!request) {
    return <LoadingBlock />;
  }

  return (
    <div className="space-y-8">
      <h1>{t("accountBookings.serviceRequestDetail.title")}</h1>

      <div>
        <h2>
          <small className="text-sm font-medium uppercase">
            {" "}
            {t("accountBookings.serviceRequests.reference")}
          </small>
          <br />
          {request.reference}
        </h2>
      </div>

      <div className="grid md:grid-cols-2 xl:grid-cols-4 md:gap-6 space-y-4 md:space-y-0">
        <div>
          <span className="font-bold block">
            {t("accountBookings.serviceRequestDetail.serviceName")}
          </span>
          {request.serviceName}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.serviceRequestDetail.siteName")}
          </span>
          {request.siteName}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.serviceRequestDetail.created")}
          </span>
          {formatDate(request.created)}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.serviceRequestDetail.status")}
          </span>
          {titleCase(request.status)}
        </div>
      </div>

      <div className="grid md:grid-cols-2 xl:grid-cols-4 md:gap-6 space-y-4 md:space-y-0">
        <div>
          <span className="font-bold block">
            {t("accountBookings.serviceRequestDetail.palletQuantity")}
          </span>
          {request.palletQuantity}
        </div>
        <div>
          <span className="font-bold block">
            {t("accountBookings.serviceRequestDetail.requiredByDate")}
          </span>
          {request.requiredByDate === null ? 'N/A' : formatDate(request.requiredByDate)}
        </div>
      </div>

      <div>
        <span className="font-bold block">
          {t("accountBookings.serviceRequestDetail.requestDetails")}
        </span>
        {request.requestDetails}
      </div>

      <div>
        <span className="font-bold block">
          {t("accountBookings.serviceRequestDetail.price")}
        </span>
        {showPrice()}
      </div>

      {request.stockItemQuantities && request.stockItemQuantities.length > 0 && (
        <Feature name='stock-management'>
          <RequestStockItems stockItems={stockItems} />
        </Feature>
      )}

      {(canApprove() || canDecline()) && (
        <ApproveDeclineServiceRequest canApprove={canApprove()} canDecline={canDecline()} serviceRequest={request} />
      )}

      <NoteList
        notes={request.notes}
        requestId={requestId}
        bookingId={request.bookingId}
      />
    </div>
  );
});
