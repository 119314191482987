import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStore } from '../../../hooks/use-store';

export default function OnboardingTopBanner() {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const { userStore } = useStore();

  if (!userStore.contextAccount || userStore.contextAccount.paymentAccountConfirmed === true)
    return null;

  // If not in the sites management path, return null to hide the banner
  if (!pathname.toLowerCase().startsWith("/account/sites")) return null;

  return (
    <div className="bg-brand text-center py-3 lg:px-4">
      <div
        className="px-2 py-1 bg-brand-100 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
        role="alert"
      >
        <span className="flex rounded-full bg-brandSecondary uppercase px-2 py-1 text-xs font-bold mr-3">
          {t("accountOrganisations.onboarding.topBannerBadge")}
        </span>
        <span className="font-semibold mr-2 text-left flex-auto text-gray-800">
          <Link
            to={`/account/organisations/${userStore.contextAccount?.id}/onboarding`}
            className="text-gray-800"
          >
            {t("accountOrganisations.onboarding.topBannerMsg")}
          </Link>
        </span>
        <svg
          className="fill-gray-800 h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
        </svg>
      </div>
    </div>
  );
}
