import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import apiUrl from "../../../data/api-url";
import { tokenFetcher } from "../../../data/fetchers";
import { useAuth } from "../../../hooks/use-auth";
import { useStore } from '../../../hooks/use-store';
import LoadingBlock from "../../common/loading/LoadingBlock";
import BookingList from "./BookingList";

export default observer(function BookingsDashboard() {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { userStore } = useStore();

  // TODO: Get the account from the current context
  const { data: bookings } = useSWR(
    userStore.contextAccount?.id 
    ? [
      apiUrl("bookings", `/bookings/account/${userStore.contextAccount?.id}`),
      user.token,
    ] 
    : null,
    tokenFetcher
  );

  return (
    <div className="container mt-7 mx-auto">
      <h1>{t("accountBookings.dashboard")}</h1>

      <div className="space-y-8 mt-7">
        <h2>{t("accountBookings.activeBookings")}</h2>

        {bookings && <BookingList bookings={bookings} />}
        {!bookings && <LoadingBlock />}
      </div>
    </div>
  );
});
